import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { LastLocationProvider } from "react-router-last-location";
import { connect } from "react-redux";
import _ from "lodash";
import { Beforeunload } from "react-beforeunload";

import { components as Shared, constants as sharedConstants } from "../modules/shared";
import { constants as coreConstants, components as Core, translate } from "../modules/core";
import { selectors as authSelectors } from "../modules/auth";
import { selectors as accountSelectors } from "../modules/account";
import { selectors as linnworksSelectors } from "../modules/linnworks";


class Router extends PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isLoggedIn: PropTypes.bool,
        syncInProgress: PropTypes.bool,
        permissions: PropTypes.array,
        accountRole: PropTypes.number,
        storedToken: PropTypes.string,
    };

    static defaultProps = {
        isLoggedIn: false,
        syncInProgress: false,
        permissions: [],
        accountRole: coreConstants.ROLE.companyUser,
        storedToken: null,
    };

    get renderRoutes() {
        if (this.props.isLoggedIn) {
            return (
                <Shared.Root>
                    <Switch>
                        {_.map(sharedConstants.ALLOWED_ROUTES(this.props.accountRole, this.props.permissions), this.renderRoute)}
                        {this.props.accountRole === coreConstants.ROLE.companyCreator && this.props.storedToken ?
                            (<Redirect to={coreConstants.ROUTES.linnworks()} />) :
                            (<Redirect to={coreConstants.ROUTES.inventory()} />)}
                    </Switch>
                </Shared.Root>
            );
        }
        return (
            <Switch>
                {_.map(sharedConstants.loggedOutRoutes, this.renderRoute)}
                <Redirect to={coreConstants.ROUTES.signIn()} />
            </Switch>
        );
    }

    confirmClose = () => {
        if (this.props.syncInProgress) {
            return this.props.i18n.t('linnworks.isSyncInProgress');
        }
    };

    renderComponent = (item, customProps) => (<Core.LoadingComponent item={item} customProps={customProps} />);

    renderRoute = item => (
        <Route
            key={item.route}
            exact={true}
            path={item.route}
            render={props => this.renderComponent(item, props)}
        />
    );

    render() {
        return (
            <Beforeunload onBeforeunload={this.confirmClose}>
                <ConnectedRouter history={this.props.history}>
                    <LastLocationProvider>
                        {this.renderRoutes}
                    </LastLocationProvider>
                </ConnectedRouter>
            </Beforeunload>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoggedIn: authSelectors.isLoggedIn(state),
        permissions: accountSelectors.getAccountPermissions(state),
        accountRole: accountSelectors.accountRole(state),
        storedToken: linnworksSelectors.getStoredToken(state),
        syncInProgress: linnworksSelectors.isSyncing(state),
    };
}

export default connect(mapStateToProps)(translate()(Router));
