import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { constants as coreConstants, getPagination, translate } from "../../../core";
import { CURRENCY_RATE_FIELDS, TIMEOUT } from "../../constants";
import * as actions from "../../actions";
import * as selectors from "../../selectors";

export const COLUMNS = {
    id: "id",
    currency: CURRENCY_RATE_FIELDS.name,
    exchangeRate: CURRENCY_RATE_FIELDS.value,
};

export default function withCurrencyRateTableBase(WrappedComponent) {
    class CurrencyRateTableBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            pagination: PropTypes.object,
            actions: PropTypes.object.isRequired,
        };

        static defaultProps = {
            pagination: null
        };

        constructor(props) {
            super(props);
            const { i18n } = this.props;

            this.tableColumns = {
                [COLUMNS.currency]: {
                    label: i18n.t("settings.currencyTable.currency"),
                    name: COLUMNS.currency,
                },
                [COLUMNS.exchangeRate]: {
                    label: i18n.t("settings.currencyTable.exchangeRate"),
                    name: COLUMNS.exchangeRate,
                },
                [COLUMNS.id]: {
                    label: i18n.t("settings.currencyTable.id"),
                    name: COLUMNS.id,
                }
            };

            this.props.actions.getCurrenciesRate();
            this.debouncedOnSearch = _.debounce(this.getCurrenciesRate, TIMEOUT);
        }

        getCurrenciesRate = (page, filterText) => {
            this.props.actions.getCurrenciesRate({
                page,
                count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                filterText
            });
        };

        deleteCurrency = currencyId => {
            const { pagination } = this.props;
            this.props.actions.deleteCurrency(currencyId).then(
                () => this.getCurrenciesRate(pagination.itemCount > 0 ? pagination.pageNumber : pagination.pageNumber-1)
            );
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    tableColumns={this.tableColumns}
                    deleteCurrency={this.deleteCurrency}
                    getCurrenciesRate={this.getCurrenciesRate}
                    onSearch={this.debouncedOnSearch}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isCurrenciesRateLoading: selectors.isCurrenciesRate(state),
            isDeletingCurrency: selectors.isDeletingCurrency(state),
            pagination: getPagination(selectors.getCurrenciesRatePagination(state)),
            currenciesRate: selectors.getCurrenciesRateArray(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CurrencyRateTableBase));
}
