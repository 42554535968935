import _ from 'lodash';
import moment from "moment";
import * as actionTypes from "./actionTypes";
import * as api from './api';
import { normalize, actions as coreActions, handleErrors, Toast, constants as coreConstants, createFileLink } from '../core';
import i18n from "../../i18n/i18n";

const EXPORT_FILE_NAMES = {
    inventory: 'InventoryItems',
    profitReport: 'ProfitReport',
};

export function getInventory(data) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_INVENTORY.REQUEST });
        return api.getInventory(data)
            .then(async res => {
                const { items, ...pagination } = res.data;

                await dispatch(getColumns());
                await dispatch({
                    type: actionTypes.GET_INVENTORY.SUCCESS,
                    payload: {
                        data: normalize.normalizeArray({ items }),
                        pagination

                    }
                })
            })
            .catch(error => {
                const errors = _.get(error, 'response.data.errors');
                dispatch(coreActions.addError(actionTypes.GET_ITEM.NAME, errors));
                handleErrors(error);
                dispatch({ type: actionTypes.GET_INVENTORY.ERROR });
            });
    }
}

export function getColumns() {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_COLUMNS.REQUEST });
        return api.getColumns()
            .then(res => {
                dispatch({
                    type: actionTypes.GET_COLUMNS.SUCCESS,
                    payload: {
                        data: res.data
                    }
                })
            })
            .catch(() => {
                dispatch({ type: actionTypes.GET_COLUMNS.ERROR });
            });
    }
}

export function updateColumns(columns) {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.UPDATE_COLUMNS.NAME));
        dispatch({ type: actionTypes.UPDATE_COLUMNS.REQUEST });
        return api.updateColumns(columns)
            .then(() => {
                Toast.success();
                dispatch({
                    type: actionTypes.UPDATE_COLUMNS.SUCCESS,
                    payload: {
                        data: columns,
                    }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.UPDATE_COLUMNS.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.UPDATE_COLUMNS.ERROR });
            });
    }
}

export function getItem(id) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_ITEM.REQUEST, requestId: id });
        return api.getItem(id)
            .then(res => {
                dispatch({
                    type: actionTypes.GET_ITEM.SUCCESS,
                    requestId: id,
                    payload: {
                        data: res.data,
                    }
                });
            })
            .catch(error => {
                const errors = _.get(error, 'response.data.errors');
                dispatch(coreActions.addError(actionTypes.GET_ITEM.NAME, errors));
                handleErrors(error);
                dispatch({ type: actionTypes.GET_ITEM.ERROR, requestId: id });
            });
    }
}

export function editItem(item) {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.EDIT_ITEM.NAME));
        dispatch({ type: actionTypes.EDIT_ITEM.REQUEST });
        return api.editItem(item)
            .then(async res => {
                await dispatch({
                    type: actionTypes.EDIT_ITEM.SUCCESS,
                    payload: {
                        data: res.data,
                    }
                });
                await dispatch(getItem(item.id));
                Toast.success();
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.EDIT_ITEM.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.EDIT_ITEM.ERROR });
            });
    }
}

export function createItem(item) {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.CREATE_ITEM.NAME));
        dispatch({ type: actionTypes.CREATE_ITEM.REQUEST });
        return api.createItem(item)
            .then(res => {
                Toast.success();
                dispatch({
                    type: actionTypes.CREATE_ITEM.SUCCESS,
                    payload: {
                        data: res.data,
                    }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.CREATE_ITEM.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.CREATE_ITEM.ERROR });
            });
    }
}

export function deleteItem(id) {
    return function (dispatch) {
        dispatch({ type: actionTypes.DELETE_ITEM.REQUEST });
        return api.deleteItem(id)
            .then(() => {
                Toast.success();
                dispatch({
                    type: actionTypes.DELETE_ITEM.SUCCESS,
                    payload: {
                        id
                    }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.DELETE_ITEM.ERROR });
            });
    }
}

export function getMarkets(params = { page: 1, count: 100 }) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_MARKETPLACES.REQUEST });
        return api.getMarkets(params)
            .then(res => {
                dispatch({
                    type: actionTypes.GET_MARKETPLACES.SUCCESS,
                    payload: {
                        items: res.data.items
                    }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.GET_MARKETPLACES.ERROR });
            });
    }
}

export function getAdditionalInfo() {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_ADDITIONAL_DATA.REQUEST });
        return Promise.resolve()
            .then(async () => {
                await dispatch(getMarkets());
                await dispatch({ type: actionTypes.GET_ADDITIONAL_DATA.SUCCESS });
            })
            .catch(() => {
                dispatch({ type: actionTypes.GET_ADDITIONAL_DATA.ERROR });
            });
    }
}

export function exportFile() {
    return function (dispatch) {
        dispatch({ type: actionTypes.EXPORT_FILE.REQUEST });
        Toast.info({ description: i18n.t('inventory.messages.exportStart') });
        return api.exportFile()
            .then(response => {
                createFileLink(response, EXPORT_FILE_NAMES.inventory);
                dispatch({ type: actionTypes.EXPORT_FILE.SUCCESS })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.EXPORT_FILE.ERROR });
            });
    }
}

export function importFile(file, params) {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.IMPORT_FILE.NAME));
        dispatch({ type: actionTypes.IMPORT_FILE.REQUEST });
        const formData = new FormData();
        formData.append('file', file);
        return api.importFile(formData)
            .then(async () => {
                Toast.success();
                await dispatch(getInventory(params));
                await dispatch({ type: actionTypes.IMPORT_FILE.SUCCESS });
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.IMPORT_FILE.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.IMPORT_FILE.ERROR });
            });
    }
}

export function resetStore() {
    return function (dispatch) {
        dispatch({ type: actionTypes.RESET_STORE.SUCCESS });
    }
}

export function getCategories(params = { page: 1, count: coreConstants.DEFAULT_ITEMS_PER_PAGE }) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_FILTER_CATEGORIES.REQUEST });
        return api.getFilterCategories(params)
            .then(res => {
                const { items, ...pagination } = res.data;
                dispatch({
                    type: actionTypes.GET_FILTER_CATEGORIES.SUCCESS,
                    payload: {
                        data: items,
                        pagination,
                    }
                })
            })
            .catch(() => {
                dispatch({ type: actionTypes.GET_FILTER_CATEGORIES.ERROR });
            });
    }
}

export function getCalculations(item) {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.GET_CALCULATIONS.NAME));
        dispatch({ type: actionTypes.GET_CALCULATIONS.REQUEST });
        return api.getCalculations(item)
            .then(res => {
                Toast.success();
                dispatch({
                    type: actionTypes.GET_CALCULATIONS.SUCCESS,
                    payload: {
                        data: res.data,
                    }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.GET_CALCULATIONS.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.GET_CALCULATIONS.ERROR });
            });
    }
}

export function getReportByDate(date) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_REPORT_BY_DATE.REQUEST });
        return api.exportFileByDate(date)
            .then(response => {
                const isSameDate = moment(date.startDate).isSame(moment(date.endDate, "DD-MM-YYYY").startOf("day"));
                const format = "YYYY.MM.DD";
                createFileLink(
                    response,
                    `${EXPORT_FILE_NAMES.profitReport} ${isSameDate ?
                        `(${moment(date.startDate).format(format)})` :
                        `(${moment(date.startDate).format(format)}-${moment(date.endDate).format(format)}`})`,
                    false);
                dispatch({ type: actionTypes.GET_REPORT_BY_DATE.SUCCESS });
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.GET_REPORT_BY_DATE.ERROR });
            });
    }
}
