import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { translate } from '../../../core';
import { selectors as accountSelectors } from '../../../account';
import { actions as settingsActions } from '../../../settings';

export default function withHeaderBase(WrappedComponent) {
    class HeaderBase extends React.PureComponent {
        static propTypes = {
            settingsActions: PropTypes.object.isRequired,
        };

        onSettingsImport = () => this.props.settingsActions.isVisibleImportModal();

        onSettingsExport = () => this.props.settingsActions.isVisibleExportModal();

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onSettingsImport={this.onSettingsImport}
                    onSettingsExport={this.onSettingsExport}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            accountRole: accountSelectors.accountRole(state),
            subscription: accountSelectors.getAccountSubscription(state),
            itemLimit: accountSelectors.subscriprionItemsLimit(state)
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            settingsActions: bindActionCreators(settingsActions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(HeaderBase));
}
