import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { actionTypes as coreActionTypes } from '../core';
import * as actionTypes from './actionTypes';
import { NAME } from './constants';

const initialState = {
    isLoggedIn: false,
    isTrialExpired: false,
    isNoPlan: false,
    user: {
        email: undefined,
        accessToken: null,
        refreshToken: null
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SIGN_UP.SUCCESS:
            return state;
        case actionTypes.LOGIN_SUCCESS: {
            const { email, tokens } = action.payload;
            return {
                ...state,
                user: {
                    email,
                    ...tokens
                }
            };
        }
        case actionTypes.SET_TRIAL_EXPIRED_ERROR: {
            return {
                ...state,
                isTrialExpired: true,
            }
        }
        case actionTypes.REMOVE_TRIAL_EXPIRED_ERROR: {
            return {
                ...state,
                isTrialExpired: false,
            }
        }
        case actionTypes.SIGN_IN.SUCCESS: {
            return {
                ...state,
                isLoggedIn: true,
                isTrialExpired: false,
                isNoPlan: false,
            };
        }
        case actionTypes.SET_NO_PLAN_ERROR: {
            return {
                ...state,
                isNoPlan: true,
            }
        }
        case actionTypes.REMOVE_NO_PLAN_ERROR: {
            return {
                ...state,
                isNoPlan: false,
            }
        }
        case actionTypes.REFRESH_TOKEN.SUCCESS: {
            const { tokens } = action.payload;
            return {
                ...state,
                user: {
                    ...state.user,
                    ...tokens
                }
            };
        }
        case actionTypes.SIGN_OUT: {
            return initialState;
        }
        case coreActionTypes.RESET:
            return initialState;
        default:
            return state;
    }
};

const persistConfig = {
    key: NAME,
    storage,
    whitelist: ['isLoggedIn', 'user', 'account']
};

export default persistReducer(persistConfig, reducer);