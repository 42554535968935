import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { constants as coreConstants, getPagination, translate } from "../../../core";
import { STATIC_VALUE_FIELDS, TIMEOUT } from "../../constants";
import * as actions from "../../actions";
import * as selectors from "../../selectors";

export const COLUMNS = {
    id: "id",
    name: STATIC_VALUE_FIELDS.name,
    value: STATIC_VALUE_FIELDS.value
};

export default function withStaticValueTableBase(WrappedComponent) {
    class StaticValueTableBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            pagination: PropTypes.object,
            actions: PropTypes.object.isRequired,
        };

        static defaultProps = {
            pagination: null
        };

        constructor(props) {
            super(props);
            const { i18n } = this.props;

            this.tableColumns = {
                [COLUMNS.name]: {
                    label: i18n.t("settings.staticValuesTable.name"),
                    name: COLUMNS.name,
                },
                [COLUMNS.value]: {
                    label: i18n.t("settings.staticValuesTable.value"),
                    name: COLUMNS.value,
                },
                [COLUMNS.id]: {
                    label: i18n.t("settings.staticValuesTable.id"),
                    name: COLUMNS.id,
                }
            };

            this.props.actions.getStaticValues();
            this.debouncedOnSearch = _.debounce(this.getStaticValues, TIMEOUT);
        }

        getStaticValues = (page, filterText) => {
            this.props.actions.getStaticValues({
                page,
                count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                filterText
            });
        };

        deleteStaticValue = staticValueId => {
            const { pagination } = this.props;
            this.props.actions.deleteStaticValue(staticValueId).then(
                () => this.getStaticValues(pagination.itemCount > 0 ? pagination.pageNumber : pagination.pageNumber-1)
            );
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    tableColumns={this.tableColumns}
                    deleteStaticValue={this.deleteStaticValue}
                    getStaticValues={this.getStaticValues}
                    onSearch={this.debouncedOnSearch}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isStaticValuesLoading: selectors.isStaticValues(state),
            isDeletingStaticValue: selectors.isDeletingStaticValue(state),
            pagination: getPagination(selectors.getStaticValuesPagination(state)),
            staticValues: selectors.getStaticValuesArray(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(StaticValueTableBase));
}
