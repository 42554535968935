import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import withPersonalInfoBase from './PersonalInfo.base';
import { components as Core } from '../../../core';
import { spacing, fonts } from '../../../../styles';

class PersonalInfo extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        account: PropTypes.object.isRequired,
        isSaveButtonDisabled: PropTypes.bool,
        onSave: PropTypes.func.isRequired,
        fields: PropTypes.array.isRequired,
    };

    static defaultProps = {
        isSaveButtonDisabled: true,
    };

    onChangeInput = (item, event) => {
        if (item.onChange) {
            return item.onChange(event.target.value);
        }

        return null;
    };

    getFormItem = item => (
        <Core.Grid key={item.name}>
            <Core.FormInput
                key={item.name}
                disabled={item.disabled}
                htmlFor={item.name}
                value={_.get(this.props.account, item.name, '')}
                inputLabel={item.placeholder}
                isRequired={item.isRequired}
                autoFocus={item.autoFocus}
                type={item.type}
                onChange={event => this.onChangeInput(item, event)}
            />
        </Core.Grid>
    );


    get form() {
        return _.map(this.props.fields, this.getFormItem);
    }

    render() {
        const { i18n, classes, isSaveButtonDisabled, onSave } = this.props;
        return (
            <div>
                <div className={classes.accountHeader}>
                    <span>{i18n.t('account.editPersonalInfo')}</span>
                </div>
                <div className={classes.container}>
                    {this.form}
                    <div className={classes.button}>
                        <Core.Button
                            fullWidth={true}
                            variant="contained"
                            onClick={onSave}
                            color="primary"
                            disabled={isSaveButtonDisabled}>
                            {i18n.t('Save')}
                        </Core.Button>
                    </div>
                </div>
            </div>
        );
    }
}

const styles = () => ({
    container: {
        padding: `0px 0px 0px ${spacing.s5}px`
    },
    accountHeader: {
        '& span': {
            ...fonts.mdBold
        }
    },
    button: {
        padding: `${spacing.s2}px`
    }
});


export default withPersonalInfoBase(withStyles(styles)(PersonalInfo))
