import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Files from 'react-files'
import { withStyles, translate } from "../../../../core";
import * as actions from "../../../actions";
import { spacing, colors } from "../../../../../styles";


class DropZone extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        file: PropTypes.object,
        onFilesChange: PropTypes.func.isRequired,
        onFilesError: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
    };

    static defaultProps = {
        file: null,
    };

    onChange = files => {
        this.props.onFilesChange(files, this.props.name);
    };

    onError = (error, file) => {
        this.props.onFilesError(error, file, this.props.name);
    };

    render() {
        const { i18n, file, classes } = this.props;
        return (
            <div>
                <Files
                    ref="files"
                    className={classes.dropZone}
                    dropActiveClassName={classes.activeDropZone}
                    onChange={this.onChange}
                    onError={this.onError}
                    accepts={['application/vnd.ms-excel', '.csv']}
                    multiple={false}
                    maxFiles={1}
                    clickable={true}>
                    <div className={classes.overflowText}>
                        <span>{ file ? file.name : i18n.t('settings.upload') }</span>
                    </div>
                </Files>
            </div>
        )
    }
}
const MAX_WIDTH = 200;

const styles = () => ({
    overflowText: {
        textOverflow: 'ellipsis',
        textDecorationStyle: 'unset',
        overflow: 'hidden',
        maxWidth: `${MAX_WIDTH}px`,
    },
    dropZone: {
        padding: `${spacing.s1}px`,
        border: `1px dashed`,
        borderColor: colors.gray.mediumGray,
        height: `${spacing.s12}px`,
        minWidth: `${MAX_WIDTH}px`,
        backgroundColor: colors.gray.transTransGray,
        cursor: 'pointer',
        '&:hover,&:focus': {
            borderColor: colors.black
        },
    },
    activeDropZone: {
        borderColor: colors.black
    }
});

const mapStateToProps = state => ({
});

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(withStyles(styles)(DropZone)));
