import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Settings from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import withStyles from '@material-ui/core/styles/withStyles';
import withDetailsRowsBase, { COLUMNS } from './DetailsRows.base';
import { components as Core, constants as coreConstants } from '../../../../core';
import { spacing } from "../../../../../styles";
import FeesModal from "./FeesModal";
import { ROUND_VALUES } from "../../../constants";

const ACTION_FOR_ALL_ROW = (i18n, onClick) => [
    {
        label: i18n.t("table.edit"),
        onClick,
        icon: <Settings />
    }
];

const ACTION_FOR_MORE_THEN_ONE_ROW = (i18n, onClick) => [
    {
        label: i18n.t("table.delete"),
        onClick,
        icon: <Delete />
    }
];

const sortOptions = {
    sort: false
};

class DetailsRows extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]).isRequired,
        fees: PropTypes.array,
        itemId: PropTypes.string.isRequired,
        tableColumns: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        isLoadingFees: PropTypes.bool,
        isUpdating: PropTypes.bool,
        deleteFeeFromMarketplace: PropTypes.func.isRequired,
    };

    static defaultProps = {
        fees: [],
        isLoadingFees: false,
        isUpdating: false,
    };

    constructor(props) {
        super(props);

        const { tableColumns } = this.props;

        this.state = {
            isVisibleFeeModal: false,
            modalType: coreConstants.MODAL_TYPE.create,
            isVisibleDeleteModal: false,
            fee: {},
        };

        this.tableColumns = [
            { ...tableColumns[COLUMNS.company], options: { customBodyRender: this.renderSelectionBody, ...sortOptions } },
            { ...tableColumns[COLUMNS.subSource], options: sortOptions },
            { ...tableColumns[COLUMNS.suffix], options: sortOptions },
            { ...tableColumns[COLUMNS.fee], options: { customBodyRender: this.renderPercentageBody, ...sortOptions } },
            { ...tableColumns[COLUMNS.transactionFee], options: sortOptions },
            { ...tableColumns[COLUMNS.cost], options: { customBodyRender: this.renderPercentageBody, ...sortOptions } },
            { ...tableColumns[COLUMNS.paymentProvider], options: sortOptions },
            { ...tableColumns[COLUMNS.minFinalValueFee], options: sortOptions },
        ];
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isUpdating && !this.props.isUpdating) {
            this.onCloseConfirmationModal();
        }
    }

    get options() {
        return {
            selectableRows: "single",
            responsive: 'scroll',
            rowHover: false,
            onRowsSelect: this.onRowsSelect,
            filter: false,
            print: false,
            viewColumns: false,
            pagination: false,
            search: false,
            download: false,
            customToolbar: this.customToolbar,
            customToolbarSelect: this.customActionsToolbar,
        };
    }

    get tableActions() {
        const { i18n } = this.props;
        return (this.props.fees.length > 1 ?
            [
                ...ACTION_FOR_ALL_ROW(i18n, this.openEditFeeModal),
                ...ACTION_FOR_MORE_THEN_ONE_ROW(i18n, this.openConfirmationModal),
            ] :
            ACTION_FOR_ALL_ROW(i18n, this.openEditFeeModal))
    }

    onRowsSelect = currentRowsSelected => {
        const { fees } = this.props;
        this.setState({ fee: fees[currentRowsSelected[0].dataIndex] })
    };

    customToolbar = () => {
        const { i18n } = this.props;
        return (
            <Core.TooltipButton onClick={this.openAddFeeModal} icon={<AddIcon />} title={i18n.t("settings.marketplaceTable.addFee")} />
        )
    };

    openAddFeeModal = () => this.setState({ isVisibleFeeModal: true, modalType: coreConstants.MODAL_TYPE.create, fee: {} });

    openEditFeeModal = () => this.setState({ isVisibleFeeModal: true, modalType: coreConstants.MODAL_TYPE.edit });

    onCloseFeeModal = () => this.setState({ isVisibleFeeModal: false });

    customToolbarSelected = item => <Core.TooltipButton key={item.label} onClick={item.onClick} icon={item.icon} title={item.label} />;

    customActionsToolbar = () => (<div>{_.map(this.tableActions, this.customToolbarSelected)}</div>);

    openConfirmationModal = () => this.setState({ isVisibleDeleteModal: true });

    onCloseConfirmationModal = () => this.setState({ isVisibleDeleteModal: false });

    deleteMarketplace = () => this.props.deleteFeeFromMarketplace(this.state.fee.id);

    renderSelectionBody = value => _.get(value, "name");

    renderPercentageBody = value => `${_.round(value * 100, ROUND_VALUES)} %`;

    render() {
        const { classes, fees, isLoadingFees, itemId, isUpdating } = this.props;
        const { isVisibleFeeModal, isVisibleDeleteModal, modalType, fee } = this.state;

        return (
            <Core.TableRow>
                <Core.TableCell colSpan={24} className={classes.tableCell}>
                    <Core.Table
                        options={this.options}
                        columns={this.tableColumns}
                        data={fees}
                        isLoading={isLoadingFees}
                    />
                    {isVisibleFeeModal ? (
                        <FeesModal
                            isVisible={isVisibleFeeModal}
                            onClose={this.onCloseFeeModal}
                            item={fee}
                            modalType={modalType}
                            marketplaceId={itemId}
                        />
                    ): null
                    }
                    {isVisibleDeleteModal ? (
                        <Core.ConfirmationModal
                            isVisible={isVisibleDeleteModal}
                            onClose={this.onCloseConfirmationModal}
                            onConfirm={this.deleteMarketplace}
                            isLoading={isUpdating}
                        />
                    ): null
                    }
                </Core.TableCell>
            </Core.TableRow>
        );
    }
}

const styles = () => ({
    tableCell: {
        borderBottom: 0,
        paddingBottom: spacing.s2,
    }
});

DetailsRows.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withDetailsRowsBase(DetailsRows));
