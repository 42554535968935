import React from 'react';
import PropTypes from 'prop-types';
import * as _ from "lodash";
import { CUSTOM_SELECT, SPECIAL_COMPOSITE_COST_TYPE, MODAL_WIDTH } from "../../../constants";
import withPostalServiceModalBase from "./PostalServiceModal.base";
import { components as Core, withStyles, constants as coreConstants } from "../../../../core";
import { colors, fonts } from "../../../../../styles";
import Tabs from "./Tabs";

class PostalServiceModal extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
        fields: PropTypes.array.isRequired,
        onChangeValue: PropTypes.func.isRequired,
        addPostalService: PropTypes.func.isRequired,
        modalType: PropTypes.number.isRequired,
        tabFields: PropTypes.array,
        isAdding: PropTypes.bool,
        isUpdating: PropTypes.bool,
        isLoadingPostalService: PropTypes.bool,
        isLoading: PropTypes.object.isRequired,
        pagination: PropTypes.object,
        addPostalServiceError: PropTypes.array,
        updatePostalServiceError: PropTypes.array,
    };

    static defaultProps = {
        isAdding: false,
        isUpdating: false,
        isLoadingPostalService: false,
        tabFields: [],
        pagination: {},
        addPostalServiceError: undefined,
        updatePostalServiceError: undefined,
    };

    componentDidUpdate(prevProps) {
        if ((prevProps.isAdding && !this.props.isAdding && !this.props.addPostalServiceError) ||
            (prevProps.isUpdating && !this.props.isUpdating && !this.props.updatePostalServiceError)) {
            this.props.onClose();
        }
    }

    getSelectOption = item => ({ value: item.id, label: item.name });

    fieldInputComponent = field => {
        switch (field.type) {
            case CUSTOM_SELECT:
                return (
                    <Core.FormSelect
                        key={field.name}
                        disabled={field.disabled}
                        htmlFor={field.name}
                        items={field.options}
                        defaultValue={_.toString(_.get(this.props.values, field.name, SPECIAL_COMPOSITE_COST_TYPE.higher21Centimeters))}
                        inputLabel={field.placeholder}
                        onChange={event => this.props.onChangeValue(event.target.value, field)}
                    />
                );
            case coreConstants.INPUT_TYPES.select:
                return (
                    <Core.SearchableFormSelect
                        key={field.name}
                        inputLabel={field.placeholder}
                        isRequired={field.isRequired}
                        onChange={event => this.props.onChangeValue(event.value, field)}
                        value={_.get(this.props.values, field.name)}
                        onSearch={field.onSearch}
                        selectName={field.selectName}
                        isLoading={this.props.isLoading[field.selectName]}
                        pagination={this.props.pagination[field.selectName]}
                        options={_.map(this.props[field.selectName], this.getSelectOption)}
                    />
                );
            default:
                return (
                    <Core.FormInput
                        key={field.name}
                        required={field.isRequired}
                        htmlFor={field.name}
                        autoFocus={field.autoFocus}
                        inputLabel={field.placeholder}
                        onChange={event => this.props.onChangeValue(event.target.value, field)}
                        defaultValue={_.toString(_.get(this.props.values, field.name, 0))}
                        type={field.type}
                        endAdornment={field.endAdornment}
                    />
                );
        }
    };

    renderField = field => (
        <Core.Grid
            item={true}
            xs={12}
            sm={this.props.fields.length > 1 ? 6 : 12}
            key={field.name}>
            {this.fieldInputComponent(field)}
        </Core.Grid>
    );

    render() {
        const {
            i18n,
            isVisible,
            onClose,
            isAdding,
            fields,
            classes,
            modalType,
            isLoadingPostalService,
            isUpdating,
            tabFields,
            addPostalService,
        } = this.props;
        return (
            <Core.Modal
                visible={isVisible}
                title={modalType === coreConstants.MODAL_TYPE.edit ?
                    i18n.t('settings.postalServiceModal.editTitle') :
                    i18n.t('settings.postalServiceModal.title')}
                onClose={onClose}
                onAccept={addPostalService}
                isLoading={isAdding || isLoadingPostalService || isUpdating}
                className={classes.modalContainer}>
                <div className={classes.container}>
                    <Core.Grid
                        container={true}
                        spacing={24}>
                        {_.map(fields, this.renderField)}
                    </Core.Grid>
                    { this.props.modalType !== coreConstants.MODAL_TYPE.edit ? (
                        <div key="Tabs">
                            <Tabs
                                onChangeValue={this.props.onChangeValue}
                                tabFields={tabFields}
                                values={this.props.values}
                            />
                        </div>
                    ) : (null)}
                </div>
            </Core.Modal>
        )
    }
}

const styles = theme => ({
    container: {
        color: colors.background,
        flex: 1,
        ...fonts.smNormal
    },
    modalContainer: {
        [theme.breakpoints.up("md")]: {
            width: MODAL_WIDTH.partWidth,
        },
        [theme.breakpoints.down("sm")]: {
            width: `${MODAL_WIDTH.fullWidth}vw`,
        },
    },
});


export default withPostalServiceModalBase(withStyles(styles)(PostalServiceModal));
