import React from 'react';

class FourZeroFour extends React.Component {
    render() {
        return (
            <div>404</div>
        );
    }
}

export default FourZeroFour;
