import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import className from "classnames";
import _ from 'lodash';
import Menu from "@material-ui/icons/Menu";

import { spacing, colors, hexToRGB } from '../../../../styles';
import { components as Core, withStyles, translate, constants as coreConstants } from "../../../core";
import { ALLOWED_ROUTES, APPBAR_HEIGHT } from "../../constants";
import withHeaderBase from "./Header.base";

class Header extends React.PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        handleDrawerToggle: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
        accountRole: PropTypes.number.isRequired,
        subscription: PropTypes.number.isRequired,
        itemLimit: PropTypes.number.isRequired
    };

    get isSettingsPage() {
        const { location } = this.props;
        return _.get(location, "pathname") === coreConstants.ROUTES.settings();
    }

    pageTitle = () => {
        const { accountRole, location } = this.props;
        return _.get(_.find(ALLOWED_ROUTES(accountRole), item => item.route === location.pathname), "name", "");
    };

    render() {
        const { handleDrawerToggle, classes, i18n, accountRole, subscription, itemLimit } = this.props;
        const subscriptionType = _.find(coreConstants.SUBSCRIPTION_TYPES, i => i.value === subscription);
        return (
            <Core.AppBar className={className(classes.appBar)}>
                <Core.Toolbar className={classes.container}>
                    <Core.Hidden mdUp={true} implementation="css">
                        <Core.IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerToggle}>
                            <Menu />
                        </Core.IconButton>
                    </Core.Hidden>
                    <div className={className(classes.flex, classes.title)}>
                        <Core.CustomButton href="#">
                            {i18n.t(this.pageTitle())}
                        </Core.CustomButton>
                        <div className={className(classes.headerSubscription)}>
                            {subscriptionType? (
                                <Core.CustomButton href="#" 
                                    className={className([classes.subscription, classes[`subscription_${subscriptionType.name}`]])}>
                                    {
                                        subscriptionType.value === coreConstants.SUBSCRIPTION_TYPES.full.value? (
                                            i18n.t("plan.itemLimit", { itemLimit })
                                        ) : (
                                            `${i18n.t("plan.aboutSubscription")} ${i18n.t(`plan.subscription.${subscription}`)}`
                                        )
                                    }
                                </Core.CustomButton>
                            ) : null
                            }
                        </div>
                    </div>
                </Core.Toolbar>
            </Core.AppBar>
        );
    }
}

const APPBAR_SHADOW = `0 12px 20px -10px rgba(${hexToRGB(colors.blue.blue)},.28), 0 4px 20px 0 rgba(${hexToRGB(colors.black)} ,.12), 0 7px 8px -5px rgba(${hexToRGB(colors.blue.blue)}, .2)`;

const styles = theme => ({
    appBar: {
        backgroundColor: colors.transparent,
        boxShadow: "none",
        position: "absolute",
        width: "100%",
        paddingTop: spacing.s1,
        zIndex: "1029",
        color: colors.gray.darkGray,
        borderRadius: "3px",
        padding: `${spacing.s2} 0`,
        transition: "all 150ms ease 0s",
        minHeight: APPBAR_HEIGHT,
        display: "block"
    },
    container: {
        paddingRight: spacing.s3,
        paddingLeft: spacing.s3,
        marginRight: "auto",
        marginLeft: "auto"
    },
    flex: {
        flex: 1
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'center',
        },
    },
    appBarTheme: {
        backgroundColor: colors.blue.blue,
        boxShadow: APPBAR_SHADOW,
        "&:hover": {
            backgroundColor: colors.blue,
            boxShadow: APPBAR_SHADOW
        }
    },
    headerSubscription: {
        display: 'flex',
        flexDirection: "row",
    },
    subscription: {
        display: "flex"
    },
    [`subscription_${coreConstants.SUBSCRIPTION_TYPES.expired.name}`]: {
        color: colors.subscription.red,
        padding: spacing.s3,
        "&:hover": {
            backgroundColor: colors.subscription.ligthRed
        }
    },
    [`subscription_${coreConstants.SUBSCRIPTION_TYPES.trial.name}`]: {
        color: colors.subscription.green,
        padding: spacing.s3,
        "&:hover": {
            backgroundColor: colors.subscription.lightGreen
        }
    },
    [`subscription_${coreConstants.SUBSCRIPTION_TYPES.full.name}`]: {
        color: colors.subscription.blue,
        padding: spacing.s3,
        "&:hover": {
            backgroundColor: colors.subscription.lightBlue
        },
    },
    [`subscription_${coreConstants.SUBSCRIPTION_TYPES.owner.name}`]: {
        color: colors.subscription.violet,
        padding: spacing.s3,
        "&:hover": {
            backgroundColor: colors.subscription.lightViolet
        },
    },
});


export default withRouter(withHeaderBase(translate()(withStyles(styles)(Header))));
