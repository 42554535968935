import * as actionTypes from './actionTypes';
import _ from "lodash";

const initialState = {
    users: {
        items: {},
        ids: [],
        pagination: {
            pageCount: 1,
            totalItemCount: 0,
            pageNumber: 0
        }
    },
};


export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USERS.SUCCESS: {
            const { data: { items, ids }, pagination } = action.payload;
            return {
                ...state,
                users: {
                    ...state.users,
                    items: {
                        ...state.users.items,
                        ...items,
                    },
                    ids,
                    pagination
                }
            };
        }
        case actionTypes.INVITE_USER.SUCCESS: {
            const { user } = action.payload;

            return {
                ...state,
                users: {
                    ...state.users,
                    items: {
                        ...state.users.items,
                        [user.id]: user,
                    },
                    ids: [
                        ...state.users.ids,
                        user.id
                    ],
                    pagination: {
                        ...state.users.pagination,
                        totalItemCount: state.users.pagination.totalItemCount + 1,
                    }
                }
            };
        }
        case actionTypes.UPDATE_USER_PERMISSIONS.SUCCESS: {
            const { data, userId } = action.payload;
            return {
                ...state,
                users: {
                    ...state.users,
                    items: {
                        ...state.users.items,
                        [userId]: {
                            ...state.users.items[userId],
                            ...data
                        }
                    }
                }
            };
        }
        case actionTypes.DELETE_USER.SUCCESS: {
            const { userId } = action.payload;
            return {
                ...state,
                users: {
                    ...state.users,
                    items: _.omit(state.users.items, userId),
                    ids: _.filter(state.users.ids, id => id !== userId),
                    pagination: {
                        ...state.users.pagination,
                        totalItemCount: state.users.pagination.totalItemCount - 1,
                    }
                }
            };
        }
        default:
            return state;
    }
}
