import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions";
import { translate } from '../../../../core';
import * as selectors from "../../../selectors";
import { FIELDS } from "../../../constants";

export default function withImageColumnBase(WrappedComponent) {
    class ImageColumn extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            smallImg: PropTypes.string,
            fullImg: PropTypes.string,
            itemId: PropTypes.string.isRequired,
        };

        static defaultProps = {
            smallImg: null,
            fullImg: null,
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const item = selectors.getItem(state, ownProps.itemId);
        return {
            smallImg: _.get(item, FIELDS.thumbnailUrl, null),
            fullImg: _.get(item, FIELDS.imageUrl, null),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ImageColumn));
}
