import { NAME } from './constants';
import { createActionType } from '../core';


export const GET_INVENTORY = createActionType(`${NAME}/GET_INVENTORY`);
export const GET_ITEM = createActionType(`${NAME}/GET_ITEM`);
export const EDIT_ITEM = createActionType(`${NAME}/EDIT_ITEM`);
export const DELETE_ITEM = createActionType(`${NAME}/DELETE_ITEM`);
export const GET_ITEM_ADDITIONAL_DATA = createActionType(`${NAME}/GET_ITEM_ADDITIONAL_DATA`);
export const EXPORT_FILE = createActionType(`${NAME}/EXPORT_FILE`);
export const GET_MARKETPLACES = createActionType(`${NAME}/GET_MARKETPLACES`);
export const GET_ADDITIONAL_DATA = createActionType(`${NAME}/GET_ADDITIONAL_DATA`);
export const CREATE_ITEM = createActionType(`${NAME}/CREATE_ITEM`);
export const GET_COLUMNS = createActionType(`${NAME}/GET_COLUMNS`);
export const UPDATE_COLUMNS = createActionType(`${NAME}/UPDATE_COLUMNS`);
export const IMPORT_FILE = createActionType(`${NAME}/IMPORT_FILE`);
export const RESET_STORE = createActionType(`${NAME}/RESET_STORE`);
export const GET_FILTER_CATEGORIES = createActionType(`${NAME}/GET_FILTER_CATEGORIES`);
export const GET_CALCULATIONS = createActionType(`${NAME}/GET_CALCULATIONS`);
export const GET_REPORT_BY_DATE = createActionType(`${NAME}/GET_REPORT_BY_DATE`);
