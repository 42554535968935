import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import withStyles from '@material-ui/core/styles/withStyles';
import withCompetitorsDetailsBase from './CompetitorsDetails.base';
import { components as Core } from '../../../../core';
import { spacing, fonts, colors } from '../../../../../styles';


class CompetitorsDetails extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        competitors: PropTypes.array.isRequired,
        isItemLoading: PropTypes.bool,
    };

    static defaultProps = {
        isItemLoading: false,
    };

    get header() {
        return _.map(this.props.competitors, this.getHeader)
    }

    get rowMinValues() {
        const { classes, competitors } = this.props;

        return _.map(competitors, (item, index) => ([
            <td key={`${index}-${item.name}-price`}>
                <div className={classes.valuesText}>
                    <span>{item.price}</span>
                </div>
            </td>
        ]))
    }

    get rowMaxValues() {
        const { classes, competitors } = this.props;

        return _.map(competitors, (item, index) => ([
            <td key={`${index}-${item.name}-shipping`}>
                <div className={classes.valuesText}>
                    <span>{item.shippingPrice}</span>
                </div>
            </td>
        ]))
    }

    getHeader = (item, index) => (
        <th key={`${index}-${item.name}`} colSpan={1}>{item.name}</th>
    );

    render() {
        const { i18n, classes, isItemLoading, competitors } = this.props;
        return (
            <Core.Spin spinning={isItemLoading} className={classes.spin}>
                {competitors.length && !isItemLoading ? (
                    <table
                        className={classes.table}
                        align="center"
                        border="1px"
                        cellPadding="7">
                        <tbody>
                            <tr className={classes.headerText}>
                                <td />
                                {this.header}
                            </tr>
                            <tr>
                                <td><div><span>{i18n.t('inventory.form.price')}</span></div></td>
                                {this.rowMinValues}
                            </tr>
                            <tr>
                                <td><div><span>{i18n.t('inventory.form.shipping')}</span></div></td>
                                {this.rowMaxValues}
                            </tr>
                        </tbody>
                    </table>
                ) : (null)}
            </Core.Spin>
        );
    }
}

const styles = () => ({
    table: {
        margin: 0,
        textAlign: 'center',
        verticalAlign: 'middle',
        borderCollapse: 'collapse',
        border: `1px ${colors.gray.lightGray}`,
        color: colors.gray.mediumGray,
    },
    headerText: {
        ...fonts.mdNormal,
        color: colors.gray.mediumGray
    },
    subHeaderText: {
        ...fonts.smBold,
        color: colors.gray.mediumGray
    },
    valuesText: {
        ...fonts.xsNormal,
        color: colors.gray.mediumGray
    },
    tableCell: {
        borderBottom: '0px',
    },
    customTableInput: {
        width: `${spacing.s10 * 2}`,
        maxWidth: `${spacing.s14 * 2}px`
    },
    spin: {
        minHeight: `${spacing.s14}px`
    }
});

CompetitorsDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withCompetitorsDetailsBase(CompetitorsDetails));
