import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { constants as coreConstants, Toast, translate } from '../../../../core';
import { STATIC_VALUE_FIELDS } from "../../../constants"
import * as actions from "../../../actions";
import * as selectors from "../../../selectors";

export default function withStaticValueModalBase(WrappedComponent) {
    class StaticValueModalBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            id: PropTypes.string,
            isVisible: PropTypes.bool.isRequired,
            onClose: PropTypes.func.isRequired,
            modalType: PropTypes.number.isRequired,
            staticValue: PropTypes.object,
        };

        static defaultProps = {
            id: '',
            staticValue: undefined
        };

        constructor(props) {
            super(props);

            const { i18n, staticValue } = this.props;

            this.state = {
                fields: {
                    [STATIC_VALUE_FIELDS.name]: _.get(staticValue, `${[STATIC_VALUE_FIELDS.name]}`, ''),
                    [STATIC_VALUE_FIELDS.value]: _.get(staticValue, `${[STATIC_VALUE_FIELDS.value]}`, ''),
                },
            };

            this.fields = [
                {
                    name: STATIC_VALUE_FIELDS.name,
                    placeholder: i18n.t('settings.staticValueModal.name'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.text,
                    autoFocus: true,
                },
                {
                    name: STATIC_VALUE_FIELDS.value,
                    placeholder: i18n.t('settings.staticValueModal.value'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.number,

                }
            ];
        }

        onChangeValue = (val, field) => {
            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    [field.name]: val
                }
            }))
        };


        addStaticValue = () => {
            const { fields } = this.state;
            const { i18n, modalType, id } = this.props;
            if (_.isEmpty(fields[STATIC_VALUE_FIELDS.name])) {
                Toast.error({ description: i18n.t('settings.staticValueModal.error.staticValueName') })
            }
            if (_.toNumber(fields[STATIC_VALUE_FIELDS.value]) === 0) {
                Toast.error({ description: i18n.t('settings.staticValueModal.error.value') })
            }
            else {
                modalType === coreConstants.MODAL_TYPE.edit ?
                    this.props.actions.updateStaticValue(id, fields) :
                    this.props.actions.addStaticValue(fields);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    addStaticValue={this.addStaticValue}
                    values={this.state.fields}
                    fields={this.fields}
                    onChangeValue={this.onChangeValue}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        isAdding: selectors.isAddingStaticValue(state),
        isUpdating: selectors.isUpdatingStaticValue(state),
        staticValue: ownProps.modalType === coreConstants.MODAL_TYPE.edit ? selectors.getStaticValuesById(state, ownProps.id) : null,
        addStaticValueError: selectors.getAddStaticValueError(state),
        updateStaticValueError: selectors.getUpdateStaticValueError(state)
    });

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(StaticValueModalBase));
}
