import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { translate, constants as coreConstants, Toast } from '../../../core';
import * as selectors from '../../selectors';

const TIMEOUT_ACCOUNT_REQUEST = 60000;

export default function withLinnworksBase(WrappedComponent) {
    class LinnworksBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            account: PropTypes.object.isRequired,
            storedToken: PropTypes.string,
            isAccountLoading: PropTypes.bool,
            isSyncing: PropTypes.bool,
            accountRole: PropTypes.number.isRequired,
        };

        static defaultProps = {
            storedToken: null,
            isAccountLoading: false,
            isSyncing: false
        };

        constructor(props) {
            super(props);

            this.state = {
                visibleForm: false,
                popConfirmVisible: false,
                isAccountLoading: false,
                intervalId: null,
            };

            this.checkIsSyncing = this.checkIsSyncing.bind(this);
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            // if (prevState.isAccountLoading
            //     && !nextProps.isAccountLoading
            //     && nextProps.storedToken
            //     && nextProps.accountRole === coreConstants.ROLE.companyCreator) {
            //     if (nextProps.account.linnworksKey && nextProps.account.linnworksKey !== nextProps.storedToken) {
            //         Toast.error({ description: nextProps.i18n.t('toast.setStoredTokenError') });
            //     } else if (!nextProps.account.linnworksKey) {
            //         nextProps.actions.setToken(nextProps.storedToken);
            //     }
            // }

            return {
                isAccountLoading: nextProps.isAccountLoading,
                visibleForm: prevState.visibleForm,
                popConfirmVisible: prevState.popConfirmVisible,
            };
        }

        componentDidMount = () => {
            this.props.actions.getAccount();
            const intervalId = setInterval(this.checkIsSyncing, TIMEOUT_ACCOUNT_REQUEST);
            this.setState({ intervalId });
        };

        componentWillUnmount() {
            const { intervalId } = this.state;

            if (intervalId) {
                clearInterval(intervalId);
            }
        }

        get tableData() {
            const { i18n, account } = this.props;

            if (account.linnworksKey) {
                return [
                    {
                        linnworksKey: account.linnworksKey,
                        linnworksUsername: account.linnworksUsername,
                        lastSyncDate: account.lastSyncDate,
                        totalItems: account.totalItems
                    },
                ]
            }

            return [
                { linnworksKey: i18n.t('linnworks.notSync') },
            ]
        }

        get isKeyExist() {
            return !!this.props.account.linnworksKey;
        }

        onChangeFormState = () => (this.setState(prevState => ({ visibleForm: !prevState.visibleForm })));

        onRevokeToken = () => (this.setState(prevState => ({ popConfirmVisible: !prevState.popConfirmVisible }), this.props.actions.revokeToken));

        closePopConfirm = () => {
            this.setState(prevState => ({ popConfirmVisible: !prevState.popConfirmVisible }))
        };

        checkIsSyncing = () => this.props.actions.getAccount();

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    data={this.tableData}
                    onChangeFormState={this.onChangeFormState}
                    onRevokeToken={this.onRevokeToken}
                    visibleForm={this.state.visibleForm}
                    popConfirmVisible={this.state.popConfirmVisible}
                    closePopConfirm={this.closePopConfirm}
                    isKeyExist={this.isKeyExist}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            account: selectors.getAccount(state),
            storedToken: selectors.getStoredToken(state),
            accountRole: selectors.accountRole(state),
            isAccountLoading: selectors.isAccountLoading(state),
            isLoading: selectors.isAccountLoading(state) || selectors.isTokenUpdating(state) || selectors.isTokenRevoking(state),
            isSyncing: selectors.isSyncing(state)
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(LinnworksBase));
}
