const colors = {
    white: "#fff",
    black: "#212121",
    blue: {
        blue: "#1976d2",
        darkBlue: "#3c4858",
        primary: '#3f51b5',
    },
    gray: {
        lightGray: "#b4b4b4",
        gray: "#eeeeee",
        darkGray: "#555555",
        transGray: '#92929259',
        transTransGray: '#9292921f',
        mediumGray: '#818181',
    },
    transparent: "rgba(0,0,0,0)",
    red: "#f15e5e",
    link: '#3f51b5',
    subscription: {
        green: "#6BA36C",
        lightGreen: "#d2e3d2",
        red: "#d06a64",
        ligthRed: "#f0d2d0",
        blue: "#5ea0c7",
        lightBlue: "#bed9e8",
        violet: "#8e81a3",
        lightViolet: "#c6c0d1"
    }
};

export default colors;
