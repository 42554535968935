import React from 'react';
import PropTypes from 'prop-types';
import Send from '@material-ui/icons/SaveAlt';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton";

class UploadFile extends React.PureComponent {
    static propTypes = {
        onUpload: PropTypes.func.isRequired,
        className: PropTypes.string,
        tooltipTitle: PropTypes.string.isRequired,
    };

    static defaultProps = {
        className: undefined,
    };

    constructor(props) {
        super(props);
        this.inputDefaultProps = {
            type: "file",
            name: "filename",
            accept: ".csv"
        };
    }

    openFileInput = () => {
        this.file.click();
    };

    onFileChanged = async () => {
        if (!this.file.files[0]) return;
        this.props.onUpload(this.file.files[0]);
        this.file.value = '';
    };

    onRefFileInput = ref => this.file = ref;

    render() {
        return (
            <Tooltip title={this.props.tooltipTitle}>
                <IconButton
                    className={this.props.className}
                    onClick={this.openFileInput}
                    aria-label={this.props.tooltipTitle}>
                    <Send />
                    <input
                        {...this.inputDefaultProps}
                        ref={this.onRefFileInput}
                        onChange={this.onFileChanged}
                        style={{ display: 'none' }}
                    />
                </IconButton>
            </Tooltip>
        );
    }
}

export default UploadFile;
