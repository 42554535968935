import moment from "moment";
import _ from 'lodash';

const TIME_FORMAT = "YYYY-MM-DD HH.mm.ss";

export default function getFile(data, filename, noDateDownload = true) {
    const url = window.URL.createObjectURL(new Blob([data.data]));
    const link = document.createElement('a');
    const newName = !noDateDownload ? filename : formatFileName(filename);
    link.href = url;
    link.setAttribute('download', `${newName}${noDateDownload ? ` (${moment().format(TIME_FORMAT)})` : ""}.csv`);
    document.body.appendChild(link);
    link.click();
}

function formatFileName(filename) {
    const splittedName = _.split(filename, '-');

    if (splittedName.length <= 0) {
        return 'Default';
    }

    if (splittedName.length > 1) {
        let buff = '';
        _.map(splittedName, item => buff += _.startCase(item));
        return buff;
    }

    return _.replace(_.startCase(splittedName[0]), ' ', '');
}
