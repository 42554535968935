import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import logo from "../../../../../assets/img/Logo.png";
import { withStyles, translate } from '../../../core';
import { colors, spacing, hexToRGB } from "../../../../styles";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";

import { DRAWER_WIDTH, APPBAR_HEIGHT } from "../../constants";


class Root extends React.PureComponent {
    static propTypes = {
        children: PropTypes.element.isRequired,
        i18n: PropTypes.object.isRequired,
        isLoggedIn: PropTypes.bool,
        history: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
    };

    static defaultProps = {
        isLoggedIn: false
    };

    constructor(props) {
        super(props);

        this.state = {
            mobileOpen: false
        };
    }

    onMainPanel = ref => this.mainPanel = ref;

    handleDrawerToggle = () => this.setState(prevState => ({ mobileOpen: !prevState.mobileOpen }));

    resizeFunction = () => {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
    };

    componentDidMount() {
        window.addEventListener("resize", this.resizeFunction);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.history.location.pathname !== prevProps.location.pathname) {
            this.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeFunction);
    }

    render() {
        const { classes, i18n, children } = this.props;
        return (
            <div className={classes.wrapper}>
                <Sidebar
                    logoText={i18n.t('appName')}
                    logo={logo}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                />
                <div className={classes.mainPanel} ref={this.onMainPanel}>
                    <Header
                        handleDrawerToggle={this.handleDrawerToggle}
                    />
                    <div className={classes.content}>
                        <div className={classes.container}>
                            {children}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    }
}

const HEADER_MARGIN = APPBAR_HEIGHT + spacing.s2;

const styles = theme => ({
    wrapper: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: `rgba(${hexToRGB(colors.gray.lightGray)} , 0.15)`,
        color: colors.blue.darkBlue,
    },
    mainPanel: {
        [theme.breakpoints.up("md")]: {
            width: `calc(100% - ${DRAWER_WIDTH}px)`
        },
        position: "relative",
        float: "right",
        transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    content: {
        marginTop: HEADER_MARGIN,
        padding: `${spacing.s6}px ${spacing.s3}px`,
        minHeight: `calc(100vh - 160px)`
    },
    container: {
        paddingRight: spacing.s3,
        paddingLeft: spacing.s3,
    }
});

export default withRouter(
    connect(mapStateToProps)(
        translate()(
            withStyles(styles)(Root)
        )
    )
);
