import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Input from "@material-ui/core/Input/Input";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputAdornment from "../InputAdornment";
import { colors, spacing, fonts } from "../../../../styles";
import { INPUT_TYPES } from '../../constants';

class FormInput extends React.PureComponent {
    static propTypes = {
        key: PropTypes.string,
        margin: PropTypes.string,
        required: PropTypes.bool,
        fullWidth: PropTypes.bool,
        htmlFor: PropTypes.string.isRequired,
        inputLabel: PropTypes.string,
        onChange: PropTypes.func,
        type: PropTypes.string,
        autoFocus: PropTypes.bool,
        startAdornment: PropTypes.string,
        endAdornment: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        disabled: PropTypes.bool,
        className: PropTypes.string,
        inputProps: PropTypes.object,
        labelClassName: PropTypes.string,
        isError: PropTypes.bool,
    };

    static defaultProps = {
        key: '',
        margin: "normal",
        isError: false,
        required: false,
        fullWidth: true,
        inputLabel: '',
        autoFocus: false,
        disabled: false,
        defaultValue: '',
        type: 'text',
        startAdornment: null,
        value: undefined,
        onChange: undefined,
        className: undefined,
        labelClassName: undefined,
        endAdornment: undefined,
        inputProps: undefined,
    };

    render() {
        const {
            key,
            margin,
            required,
            fullWidth,
            htmlFor,
            inputLabel,
            onChange,
            type,
            autoFocus,
            defaultValue,
            disabled,
            startAdornment,
            labelClassName,
            className,
            value,
            endAdornment,
            isError,
            inputProps,
            link,
            linkText,
            classes
        } = this.props;
        return (
            <FormControl key={`${htmlFor}-control`} margin={margin} required={required} fullWidth={fullWidth} disabled={disabled} className={className}>
                <InputLabel className={labelClassName} htmlFor={htmlFor} shrink={true}>{inputLabel}</InputLabel>
                <Input
                    id={`${htmlFor}-input`}
                    name={htmlFor}
                    error={isError}
                    autoFocus={autoFocus}
                    onChange={onChange}
                    type={type}
                    defaultValue={defaultValue}
                    inputProps={inputProps}
                    endAdornment={endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment>: null}
                    startAdornment={startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment>: null}
                    value={value}
                />
                {type === INPUT_TYPES.textWithLink ? (
                    <a className={classes.link} href={link} target="_blank">{linkText}</a>
                ) : null}
            </FormControl>
        );
    }
}

const styles = () => ({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiFormLabel: {
            root: {
                "&$focused": {
                    color: `${colors.blue.blue} !important`
                }
            }
        },
        MuiInput: {
            underline: {
                "&:after": {
                    borderColor: `${colors.blue.blue} !important`
                },
                "&:hover": {
                    "&:before": {
                        borderBottom: `2px solid ${colors.blue.blue} !important`,
                    },
                },
            },
        }
    },
    link: {
        alignSelf: "center",
        marginTop: spacing.s1,
        ...fonts.smLight
    }
});


export default withStyles(styles)(FormInput);
