import React from 'react';
import PropTypes from 'prop-types';
import withInviteUserModalBase from "./InviteUserModal.base";
import { components as Core, withStyles } from "../../../core";
import { colors, fonts } from "../../../../styles"


class InviteUserModal extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        fields: PropTypes.array.isRequired,
        onChangeValue: PropTypes.func.isRequired,
        inviteUser: PropTypes.func.isRequired,
        isInvitingUser: PropTypes.bool,
        inviteUserError: PropTypes.array
    };

    static defaultProps = {
        isInvitingUser: false,
        inviteUserError: undefined
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isInvitingUser && !this.props.isInvitingUser && !this.props.inviteUserError) {
            this.props.onClose();
        }
    }

    renderField = field => (
        <Core.FormInput
            key={field.name}
            required={field.isRequired}
            htmlFor={field.name}
            autoFocus={field.autoFocus}
            inputLabel={field.placeholder}
            onChange={event => this.props.onChangeValue(event.target.value, field)}
            type={field.type}
        />
    );

    render() {
        const { i18n, isVisible, onClose, isInvitingUser, fields, classes, inviteUser } = this.props;
        return (
            <Core.Modal
                visible={isVisible}
                title={i18n.t('users.inviteModal.title')}
                onClose={onClose}
                onAccept={inviteUser}
                acceptButtonTitle={i18n.t("users.inviteModal.buttonOk")}
                isLoading={isInvitingUser}
                className={classes.modalContainer}>
                <div className={classes.container}>
                    {_.map(fields, this.renderField)}
                </div>
            </Core.Modal>
        )
    }
}

const MODAL_WIDTH = {
    fullWidth: 75,
    partWidth: 600
};

const styles = theme => ({
    container: {
        color: colors.background,
        ...fonts.smNormal
    },
    modalContainer: {
        [theme.breakpoints.up("md")]: {
            width: MODAL_WIDTH.partWidth,
        },
        [theme.breakpoints.down("sm")]: {
            width: `${MODAL_WIDTH.fullWidth}vw`,
        },
    },
});


export default withInviteUserModalBase(withStyles(styles)(InviteUserModal));
