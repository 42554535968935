import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { constants as coreConstants, getPagination, translate } from "../../../core";
import { PACKAGING_FIELDS, TIMEOUT } from "../../constants";
import * as actions from "../../actions";
import * as selectors from "../../selectors";

export const COLUMNS = {
    id: "id",
    packaging: PACKAGING_FIELDS.name,
    cost: PACKAGING_FIELDS.cost
};

export default function withPackagingTableBase(WrappedComponent) {
    class PackagingTableBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            pagination: PropTypes.object,
            actions: PropTypes.object.isRequired,
        };

        static defaultProps = {
            pagination: null
        };

        constructor(props) {
            super(props);
            const { i18n } = this.props;

            this.tableColumns = {
                [COLUMNS.packaging]: {
                    label: i18n.t("settings.packagingTable.packageName"),
                    name: COLUMNS.packaging,
                },
                [COLUMNS.cost]: {
                    label: i18n.t("settings.packagingTable.packageCost"),
                    name: COLUMNS.cost,
                },
                [COLUMNS.id]: {
                    label: i18n.t("settings.packagingTable.id"),
                    name: COLUMNS.id,
                }
            };

            this.props.actions.getPackagings();
            this.debouncedOnSearch = _.debounce(this.getPackagings, TIMEOUT);
        }

        getPackagings = (page, filterText) => {
            this.props.actions.getPackagings({
                page,
                count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                filterText
            });
        };

        deletePackaging = packagingId => {
            const { pagination } = this.props;
            this.props.actions.deletePackaging(packagingId).then(
                () => this.getPackagings(pagination.itemCount > 0 ? pagination.pageNumber : pagination.pageNumber-1)
            );
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    tableColumns={this.tableColumns}
                    deletePackaging={this.deletePackaging}
                    getPackagings={this.getPackagings}
                    onSearch={this.debouncedOnSearch}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isPackagingsLoading: selectors.isPackagings(state),
            isDeletingPackaging: selectors.isDeletingPackaging(state),
            pagination: getPagination(selectors.getPackagingsPagination(state)),
            packagings: selectors.getPackagingsArray(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(PackagingTableBase));
}
