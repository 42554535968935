import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import withSupplierBase from './Supplier.base';
import { components as Core } from "../../../../core";

class Supplier extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        item: PropTypes.array.isRequired,
    };

    getFormItems = item => {
        const { i18n } = this.props;

        const params = {
            disabled: true,
            required: false,
            htmlFor: item.key,
            inputLabel: i18n.t(`inventory.supplier.fields.${item.key}`),
            type: 'text',
            value: _.get(item, 'value', ''),
        };

        return (
            <Core.Grid key={item.key} item={true} xs={10} sm={3}>
                <Core.FormInput {...params} />
            </Core.Grid>
        )
    };

    render() {
        const { i18n, item } = this.props;
        return (
            <Core.Panel title={i18n.t('inventory.supplier.title')}>
                <Core.Grid container={true} spacing={24}>
                    {_.map(item, this.getFormItems)}
                </Core.Grid>
            </Core.Panel>
        );
    }
}

const styles = () => ({
    defaultValuesContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        padding: '0px !important',
    },
    flexContainer: {
        display: 'flex',
    }
});

Supplier.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSupplierBase(Supplier));
