import { AUTH_FIELDS, PASSWORD_MIN_LENGTH } from '../constants';
import { fieldValidator } from '../../core';

const validateSignUpData = (values, i18n) => {
    const errors = [];

    if (!fieldValidator.isValidEmail(values[AUTH_FIELDS.email])) {
        errors.push({ description: i18n.t('toast.incorrectEmail') });
    }
    if (values[AUTH_FIELDS.password] !== values[AUTH_FIELDS.confirmPassword]) {
        errors.push({ description: i18n.t('toast.passwordAreNotSame') });
    }
    if (values[AUTH_FIELDS.password].length < PASSWORD_MIN_LENGTH) {
        errors.push({ description: i18n.t('toast.passwordLength') });
    }
    if (!((values[AUTH_FIELDS.password]).match("[a-zA-Z]+"))) {
        errors.push({ description: i18n.t('toast.passwordMustContainLetter') });
    }
    return errors;
};

export default { validateSignUpData }