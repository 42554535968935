import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Background from "../../../../../assets/img/bg.jpg";

class MainContainer extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    render() {
        const { children, classes } = this.props;
        return (
            <main className={classes.main}>
                <CssBaseline />
                {children}
            </main>
        );
    }
}

const styles = theme => ({
    main: {
        flex: 1,
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        minHeight: "100vh",
        backgroundImage: `url(${Background})`,
        flexWrap: "wrap",
        padding: "15px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
    },
});

MainContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainContainer);
