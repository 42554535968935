import * as actionTypes from "./actionTypes";
import * as api from './api';
import { actions as coreActions, handleErrors, actionTypes as coreActionTypes, Toast } from '../core';
import { actions as accountActions } from '../account';
import { ERROR_CODES } from "./constants";
import { getRefreshToken, getSubscriptionExpiredStatus, getNoPlanStatus } from './selectors';

const TOAST_TYPES = {
    changePasswordSuccess: 'changePassword.success',
    changeEmailSuccess: 'changeEmail.success',
    addAdminSuccess: 'addAdmin.success',
    signUpSuccess: 'signUp.success',
    signUpError: 'signUp.error',
    signInNotConfirmed: 'signIn.notConfirmed',
    resetPasswordTokenSuccess: 'resetPasswordToken.success',
    resetPasswordSuccess: 'resetPassword.success',
    refreshTokenError: 'refreshToken.error'
};

export function signUp(data) {
    return function (dispatch) {
        dispatch(removeSignUpError());
        dispatch({ type: actionTypes.SIGN_UP.REQUEST });
        return api.signUp(data)
            .then(() => {
                Toast.success({ type: TOAST_TYPES.signUpSuccess });
                dispatch({
                    type: actionTypes.SIGN_UP.SUCCESS
                })
            })
            .catch(error => {
                const errors = _.get(error, 'response.data.errors');
                dispatch(coreActions.addError(actionTypes.SIGN_UP.NAME, errors));
                handleErrors(error);
                dispatch({ type: actionTypes.SIGN_UP.ERROR });
            });
    }
}

export function removeSignUpError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.SIGN_UP.NAME));
    }
}

export function verifyEmail(email, token) {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.VERIFY_EMAIL.NAME));
        dispatch({ type: actionTypes.VERIFY_EMAIL.REQUEST });
        return api.verifyEmail({ email, token })
            .then(() => {
                dispatch({ type: actionTypes.VERIFY_EMAIL.SUCCESS })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.VERIFY_EMAIL.ERROR });
                const errors = _.get(error, 'response.data.errors');
                dispatch(coreActions.addError(actionTypes.VERIFY_EMAIL.NAME, errors));
            });
    }
}

export function sendVerifyEmailToken(email) {
    return function (dispatch) {
        dispatch({ type: actionTypes.SEND_VERIFY_EMAIL_TOKEN.REQUEST });
        return api.sendVerifyEmailToken({ email })
            .then(() => {
                dispatch({ type: actionTypes.SEND_VERIFY_EMAIL_TOKEN.SUCCESS })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.SEND_VERIFY_EMAIL_TOKEN.ERROR });
            });
    }
}

export function signOut(isTrialExpired = false, isNoPlan = false) {
    return async function (dispatch) {
        await dispatch(accountActions.clearStore());
        await dispatch({
            type: actionTypes.SIGN_OUT
        });
        await dispatch({
            type: coreActionTypes.RESET
        });

        if (isTrialExpired) {
            await dispatch({
                type: actionTypes.SET_TRIAL_EXPIRED_ERROR
            })
        }

        if (isNoPlan) {
            await dispatch({
                type: actionTypes.SET_NO_PLAN_ERROR
            })
        }
    }
}

export function signIn(email, password) {
    return function (dispatch) {
        dispatch(removeSignInError());
        dispatch({ type: actionTypes.SIGN_IN.REQUEST });
        return api.signIn({ email, password })
            .then(async response => {
                dispatch({ type: coreActionTypes.RESET });
                await dispatch({
                    type: actionTypes.LOGIN_SUCCESS,
                    payload: { email, tokens: response.data }
                });
                await dispatch(accountActions.getAccount());
                await dispatch({ type: actionTypes.SIGN_IN.SUCCESS });
            })
            .catch(error => {
                const errors = _.get(error, 'response.data.errors');
                let isHandlerError = false;

                _.forEach(errors, err => {
                    const code = _.get(err, 'code');
                    if (code === ERROR_CODES.EmailNotConfirmed) {
                        dispatch(coreActions.addError(actionTypes.SIGN_IN.NAME, code));
                        isHandlerError = true;
                    }
                });
                if (!isHandlerError) {
                    dispatch(coreActions.addError(actionTypes.SIGN_IN.NAME, errors));
                    handleErrors(error);
                }

                dispatch({ type: actionTypes.SIGN_IN.ERROR });
            });
    }
}

export function removeSignInError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.SIGN_IN.NAME));
    }
}

export function removeTrialExpiredError() {
    return function (dispatch) {
        dispatch({ type: actionTypes.REMOVE_TRIAL_EXPIRED_ERROR })
    }
}

export function removeNoPlanError() {
    return function (dispatch) {
        dispatch({ type: actionTypes.REMOVE_NO_PLAN_ERROR })
    }
}

export function sendResetPasswordToken(email) {
    return function (dispatch) {
        dispatch({ type: actionTypes.SEND_RESET_PASSWORD_TOKEN.REQUEST });
        return api.sendResetPasswordToken({ email })
            .then(() => {
                Toast.success({ type: TOAST_TYPES.resetPasswordTokenSuccess });
                dispatch({ type: actionTypes.SEND_RESET_PASSWORD_TOKEN.SUCCESS })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.SEND_RESET_PASSWORD_TOKEN.ERROR });
            });
    }
}

export function resetPassword(email, token) {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.RESET_PASSWORD.NAME));
        dispatch({ type: actionTypes.RESET_PASSWORD.REQUEST });
        return api.resetPassword({ email, token })
            .then(() => {
                Toast.success({ type: TOAST_TYPES.resetPasswordSuccess });
                dispatch({ type: actionTypes.RESET_PASSWORD.SUCCESS })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.RESET_PASSWORD.ERROR });
                const errors = _.get(error, 'response.data.errors');
                dispatch(coreActions.addError(actionTypes.RESET_PASSWORD.NAME, errors));
            });
    }
}

export function refreshToken() {
    return function (dispatch, getState) {
        const refreshToken = getRefreshToken(getState()).token;
        dispatch({ type: actionTypes.REFRESH_TOKEN.REQUEST });
        return api.refreshToken({ refreshToken })
            .then(response => {
                dispatch({
                    type: actionTypes.REFRESH_TOKEN.SUCCESS,
                    payload: { tokens: response.data }
                });
            })
            .catch(() => {
                Toast.error({ type: TOAST_TYPES.refreshTokenError });
                dispatch({ type: actionTypes.REFRESH_TOKEN.ERROR });
                const trialExpired = getSubscriptionExpiredStatus(getState());
                const noPlan = getNoPlanStatus(getState());
                signOut(trialExpired, noPlan);
            });
    }
}
