import _ from 'lodash';
import * as actionTypes from './actionTypes';
import { formatColumnsObject, deepUpdateStoreItems } from './services';
import { COLUMNS } from "./constants";

const initialState = {
    inventory: {
        items: {},
        ids: [],
        pagination: {
            pageCount: 1,
            totalItemCount: 0,
            pageNumber: 0
        }

    },
    filters: {
        category: {
            items: [],
            pagination: {
                pageCount: 1,
                totalItemCount: 0,
                pageNumber: 0
            },
        }
    },
    columns: {
        [COLUMNS.company]: {
            label: `inventory.table.${COLUMNS.company}`,
            name: COLUMNS.company,
            isObject: true,
            enabled: true,
            order: 2
        },
        [COLUMNS.sku]: {
            label: `inventory.table.${COLUMNS.sku}`,
            name: COLUMNS.sku,
            enabled: true,
            order: 3
        },
        [COLUMNS.id]: {
            label: `inventory.table.${COLUMNS.id}`,
            name: COLUMNS.id,
            enabled: true,
            order: 1
        }
    },
    marketplaces: [],
    fullItems: {},
    calculations: {}
};


export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INVENTORY.SUCCESS: {
            const { data: { items, ids }, pagination } = action.payload;
            const newItems = deepUpdateStoreItems(items, state.inventory.items);

            return {
                ...state,
                inventory: {
                    ...state.inventory,
                    items: {
                        ...state.inventory.items,
                        ...newItems
                    },
                    ids,
                    pagination,
                }
            };
        }
        case actionTypes.GET_COLUMNS.SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                columns: formatColumnsObject(data)
            }
        }
        case actionTypes.UPDATE_COLUMNS.SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                columns: formatColumnsObject(data)
            }
        }
        case actionTypes.GET_ITEM.SUCCESS: {
            const item = action.payload.data;
            return {
                ...state,
                fullItems: { ...state.fullItems, [item.id]: item },
            };
        }
        case actionTypes.GET_MARKETPLACES.SUCCESS: {
            return {
                ...state,
                marketplaces: action.payload.items
            }
        }
        case actionTypes.EDIT_ITEM.SUCCESS: {
            const item = action.payload.data;

            return {
                ...state,
                inventory: {
                    ...state.inventory,
                    items: {
                        ...state.inventory.items,
                        [item.id]: item,
                    }
                },
                fullItems: { ...state.fullItems, [item.id]: item },
            };
        }
        case actionTypes.CREATE_ITEM.SUCCESS: {
            const item = action.payload.data;
            return {
                ...state,
                inventory: {
                    ...state.inventory,
                    items: {
                        ...state.inventory.items,
                        [item.id]: item,
                    },
                    ids: [...state.inventory.ids, item.id],
                    pagination: {
                        ...state.inventory.pagination,
                        totalItemCount: state.inventory.pagination.totalItemCount + 1,
                    },
                },
                fullItems: { ...state.fullItems, [item.id]: item },
            };
        }
        case actionTypes.DELETE_ITEM.SUCCESS: {
            const deletedId = action.payload.id;
            const newKeys = _.filter(_.keys(state.inventory.items), i => i !== deletedId);

            return {
                ...state,
                inventory: {
                    ...state.inventory,
                    ids: _.filter(state.inventory.ids, id => id !== deletedId),
                    items: _.pick(state.inventory.items, newKeys)
                },
                fullItems: _.pick(state.fullItems, newKeys)
            };
        }
        case actionTypes.GET_FILTER_CATEGORIES.SUCCESS: {
            const { data, pagination } = action.payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    category: {
                        ...state.filters.category,
                        items: data,
                        pagination,
                    }
                }
            };
        }
        case actionTypes.GET_CALCULATIONS.SUCCESS: {
            const { data } = action.payload;
            return { ...state, calculations: data };
        }
        case actionTypes.RESET_STORE.SUCCESS: {
            return initialState;
        }
        default:
            return state;
    }
}
