import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { persistStore } from 'redux-persist';
import rootReducer from './rootReducer';
import { history } from '../modules/core';

const routeMiddleware = routerMiddleware(history);
let middleware = [thunk, routeMiddleware];
const isDevelopmentMode = process.env.NODE_ENV === 'development';
if (isDevelopmentMode) {
    const logger = require('redux-logger').createLogger({ collapsed: true });
    middleware = [...middleware, logger];
}

const composeEnhancers = isDevelopmentMode && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
);

const persistor = persistStore(store, null);

export { store, persistor };
