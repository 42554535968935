import _ from 'lodash';

export default function parseQueryString(allUrl) {
    const url = allUrl.substr(1);
    const strings = url.split('&');
    const params = {};
    _.forEach(strings, str => {
        const index = str.indexOf('=');
        if (index > 0) {
            const param = str.substr(0, index);
            const value = str.substr(index+1);
            params[param] = value;
        }
    });
    return params;
}