import _ from 'lodash';
import { Toast, constants as coreConstants } from '../core';
import { DEFAULT_MARKET, FIELDS } from './constants';


export const detailsObjectFormatter = (item, additionalFields) => {
    let res = [];
    let order = _.size(additionalFields) + 1;

    _.forOwn(item, (val, key) => {
        let value = val;

        if (key === FIELDS.taxRate) {
            value *= 100;
        }

        if (key === FIELDS.prices) {
            let result = {};

            _.forOwn(value, (val, key) => {
                result = {
                    ...result,
                    [key]: mapValues(val, true, true)
                }
            });

            value = result;
        }

        if (additionalFields[key]) {
            res = [...res, {
                name: key,
                label: `inventory.details.${key}`,
                value,
                ...additionalFields[key]
            }]
        } else {
            res = [...res, {
                name: key,
                label: `inventory.details.${key}`,
                value,
                order,
            }]
        }

        order += 1;
    });

    return _.orderBy(res, ['order'], ['asc']);
};

export const validateData = (item, fields) => {
    let validate = false;

    _.forOwn(item, (value, key) => {
        if (fields[key] && fields[key].required) {
            if (!value && value !== 0) {
                validate = true;
            }

            if (fields[key].type === coreConstants.INPUT_TYPES.number && parseFloat(value) < 0) {
                validate = true;
            }
        }
    });

    return validate;
};

export const formatFullItem = item => {
    let prices = {};
    _.forOwn(item.prices, (val, key) => {
        prices = {
            ...prices,
            [key]: {
                ...val,
                minMargin: divValue(val.minMargin,true, true),
                maxMargin: divValue(val.maxMargin, true, true),
                currentMargin: divValue(val.currentMargin, val.isPercentage, true)
            },
        }
    });

    return {
        ...item,
        prices,
    }
};

export const validateObject = obj => {
    let validate = false;

    _.map(obj, (value, name) => {
        const newValue = _.get(value, 'value', value);

        if (value.required) {
            if (!newValue && newValue !== 0) {
                validate = true;
            }

            if (value.type === coreConstants.INPUT_TYPES.number && parseFloat(newValue) < 0) {
                validate = true;
            }
        }
    });

    return validate;
};

export const validatePrice = (marketName, min, max, current, i18n) => {
    if (min <= 0) {
        Toast.error({ description: i18n.t('inventory.messages.minPriceError', { marketName }) });
        return false;
    }
    if (max <= 0) {
        Toast.error({ description: i18n.t('inventory.messages.maxPriceError', { marketName }) });
        return false;
    }
    /*if (current <= 0) {
        Toast.error({ description: i18n.t('inventory.messages.currentPriceError', { marketName }) });
        return false;
    }*/

    return true;
};

export const getMarketPlaces = (markets, allMarkets) => {
    let res = [];

    _.forOwn(allMarkets, (val, key) => {
        const props = _.get(markets, key, null) ? mapValues(markets[key], true, true) : {};
        res = [...res, {
            id: key,
            ...val,
            ...props,
        }]
    });

    return res;
};

export const getCompetitors = competitors => {
    let res = [];
    let counter = 0;
    _.forOwn(competitors, (val, key) => {
        res = [...res, {
            name: key,
            ...val,
            id: counter,
        }];
        counter += 1;
    });

    return res;
};

export const getSupplierFields = supplier => {
    let res = [];

    _.forOwn(supplier, (value, key) => {
        res = [...res, {
            key,
            value,
        }]
    });

    return res;
};

export const formatMarketPlaces = markets => (_.chain(markets)
    .keyBy('id')
    .mapValues(item => ({
        ...DEFAULT_MARKET,
        marketplace: item.name,
        ...item,
        minMargin: divValue(item.minMargin, item.isPercentage, true),
        maxMargin: divValue(item.maxMargin, item.isPercentage, true),
        currentMargin: divValue(item.currentMargin, item.isPercentage, true),
    }))
    .value());

export const formatColumnsObject = columns => {
    let formattedColumns = {};

    _.forOwn(columns, (value, key) => {
        formattedColumns = { ...formattedColumns,
            [key]: {
                ...value,
                isObject: _.isObject(value),
                label: `inventory.details.${key}`,
                name: key,
            } }
    });

    return formattedColumns;
};

export const deepUpdateStoreItems = (responseItems, stateItems) => {
    let newItems = {};

    _.forOwn(responseItems, (val, key) => {
        const oldItem = stateItems[key];

        if (oldItem) {
            newItems = { ...newItems, [key]: { ...oldItem, ...val } }
        } else {
            newItems = { ...newItems, [key]: val }
        }
    });

    return newItems;
};

export const mapValues = (value, isPercentage, isMul = false) => ({
    ...value,
    minMargin: divValue(value.minMargin, isPercentage, isMul),
    maxMargin: divValue(value.maxMargin, isPercentage, isMul),
    currentMargin: divValue(value.currentMargin, isPercentage, isMul)
});

const divValue = (value, isPercentage, isMul = false) => {
    if (!value) {
        return 0;
    }

    const changedVal = isMul ? (value * 100) : (value / 100);
    return _.round(isPercentage ? changedVal : value, 5);
};