import React from 'react';
import PropTypes from 'prop-types';
import * as _ from "lodash";
import withCostModalBase from "./CostModal.base";
import { components as Core, withStyles, constants as coreConstants } from "../../../../../core";
import { colors, fonts } from "../../../../../../styles";
import { CUSTOM_SELECT, SPECIAL_COMPOSITE_COST_TYPE, MODAL_WIDTH } from "../../../../constants";
import CompositeCostType from '../../СompositeСostType';

class CostModal extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
        fields: PropTypes.array.isRequired,
        onChangeValue: PropTypes.func.isRequired,
        updatePostalService: PropTypes.func.isRequired,
        modalType: PropTypes.number.isRequired,
        isUpdating: PropTypes.bool,
        isLoading: PropTypes.object.isRequired,
        pagination: PropTypes.object,
        updatePostalServiceError: PropTypes.array,
        onAddCompositeCost: PropTypes.func.isRequired,
        onDeleteCompositeCost: PropTypes.func.isRequired,
        onChangeCompositeCost: PropTypes.func.isRequired,
        onChangeCompositeOrder: PropTypes.func.isRequired,
        onEditCompositeCost: PropTypes.func.isRequired,
        selectedForEditTypeId: PropTypes.string,
        onCloseEditCompositeCostModal: PropTypes.func.isRequired,
        onSaveEditedCompositeCost: PropTypes.func.isRequired,
        modalValues: PropTypes.object.isRequired,
        modalFields: PropTypes.array.isRequired,
        onChangeModalValue: PropTypes.func.isRequired,
        isDisabledEditBtn: PropTypes.bool,
    };

    static defaultProps = {
        isUpdating: false,
        pagination: {},
        updatePostalServiceError: undefined,
        selectedForEditTypeId: null,
        isDisabledEditBtn: false
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isUpdating && !this.props.isUpdating && !this.props.updatePostalServiceError) {
            this.props.onClose();
        }
    }

    get costs() {
        return _.chain(this.props.values)
            .get('costs', [])
            .map((i, key) => (_.isObject(i) ? { key, data: i } : null))
            .compact()
            .keyBy('key')
            .mapValues(i => i.data)
            .value();
    }

    getSelectOption = item => ({ value: item.id, label: item.name });

    fieldInputComponent = field => {
        switch (field.type) {
            case CUSTOM_SELECT:
                return (
                    <Core.FormSelect
                        key={field.name}
                        disabled={field.disabled}
                        htmlFor={field.name}
                        items={field.options}
                        defaultValue={_.toString(_.get(this.props.values, field.name, SPECIAL_COMPOSITE_COST_TYPE.higher21Centimeters))}
                        inputLabel={field.placeholder}
                        onChange={event => this.props.onChangeValue(event.target.value, field)}
                    />
                );
            case coreConstants.INPUT_TYPES.select:
                return (
                    <Core.SearchableFormSelect
                        key={field.name}
                        inputLabel={field.placeholder}
                        isRequired={field.isRequired}
                        onChange={event => this.props.onChangeValue(event.value, field)}
                        value={_.get(this.props.values, field.name)}
                        onSearch={field.onSearch}
                        selectName={field.selectName}
                        isLoading={this.props.isLoading[field.selectName]}
                        pagination={this.props.pagination[field.selectName]}
                        options={_.map(this.props[field.selectName], this.getSelectOption)}
                    />
                );
            default:
                return (
                    <Core.FormInput
                        key={field.name}
                        required={field.isRequired}
                        htmlFor={field.name}
                        autoFocus={field.autoFocus}
                        inputLabel={field.placeholder}
                        onChange={event => this.props.onChangeValue(event.target.value, field)}
                        defaultValue={_.toString(_.get(this.props.values, field.name, 0))}
                        type={field.type}
                        endAdornment={field.endAdornment}
                    />
                );
        }
    };

    optionFormatter = option => ({
        id: option.id,
        name: this.props.i18n.t(option.measure),
        type: option.type,
    });

    renderField = (field, index, array) => (
        <Core.Grid item={true} xs={12} sm={array.length <= 1 ? 12 : 6} key={field.name}>
            {this.fieldInputComponent(field)}
        </Core.Grid>
    );

    renderModalField = field => (
        <Core.Grid
            item={true}
            xs={12}
            sm={6}
            key={field.name}>
            {field.type === CUSTOM_SELECT ? (
                <Core.FormSelect
                    key={field.name}
                    disabled={field.disabled}
                    htmlFor={field.name}
                    items={_.map(field.options, this.optionFormatter)}
                    defaultValue={_.toString(_.get(this.props.modalValues, `${field.name}.id`))}
                    inputLabel={field.placeholder}
                    onChange={event => this.props.onChangeModalValue(event.target.value, field)}
                />
            ) : (
                <Core.FormInput
                    key={field.name}
                    required={field.isRequired}
                    htmlFor={field.name}
                    autoFocus={field.autoFocus}
                    inputLabel={field.placeholder}
                    onChange={event => this.props.onChangeModalValue(event.target.value, field)}
                    value={_.toString(_.get(this.props.modalValues, field.name, 0))}
                    type={field.type}
                    endAdornment={field.endAdornment}
                />
            )}
        </Core.Grid>
    );

    render() {
        const {
            i18n,
            isVisible,
            onClose,
            fields,
            classes,
            modalType,
            isUpdating,
            updatePostalService,
            onAddCompositeCost,
            onDeleteCompositeCost,
            onChangeCompositeCost,
            onChangeCompositeOrder,
            onEditCompositeCost,
            selectedForEditTypeId,
            onCloseEditCompositeCostModal,
            onSaveEditedCompositeCost,
            modalFields
        } = this.props;
        return (
            <Core.Modal
                visible={isVisible}
                title={modalType === coreConstants.MODAL_TYPE.edit ?
                    i18n.t('settings.postalServiceModal.editCost') :
                    i18n.t('settings.postalServiceModal.addCost')}
                onClose={onClose}
                onAccept={updatePostalService}
                isLoading={isUpdating}
                className={classes.modalContainer}>
                <div className={classes.container}>
                    <Core.Grid container={true} spacing={24} >
                        {_.map(fields, this.renderField)}
                    </Core.Grid>
                    <CompositeCostType
                        costs={this.costs}
                        onAddCost={onAddCompositeCost}
                        onDelete={onDeleteCompositeCost}
                        onChangeValue={onChangeCompositeCost}
                        onChangeOrder={onChangeCompositeOrder}
                        onEdit={onEditCompositeCost}
                    />
                    {selectedForEditTypeId ? (
                        <Core.Modal
                            visible={!!selectedForEditTypeId}
                            title={i18n.t('settings.postalServiceModal.editCost')}
                            onClose={onCloseEditCompositeCostModal}
                            onAccept={onSaveEditedCompositeCost}
                            isLoading={false}
                            className={classes.innerModalContainer}>
                            <div className={classes.container}>
                                <Core.Grid container={true} spacing={24} >
                                    {_.map(modalFields, this.renderModalField)}
                                </Core.Grid>
                            </div>
                        </Core.Modal>
                    ) : (null)}
                </div>
            </Core.Modal>
        )
    }
}

const EDIT_MODAL_WIDTH = {
    fullWidth: 75,
    partWidth: 400
};

const styles = theme => ({
    container: {
        color: colors.background,
        flex: 1,
        ...fonts.smNormal
    },
    modalContainer: {
        [theme.breakpoints.up("md")]: {
            width: MODAL_WIDTH.partWidth,
        },
        [theme.breakpoints.down("sm")]: {
            width: `${MODAL_WIDTH.fullWidth}vw`,
        },
    },
    innerModalContainer: {
        [theme.breakpoints.up("md")]: {
            width: EDIT_MODAL_WIDTH.partWidth,
        },
        [theme.breakpoints.down("sm")]: {
            width: `${EDIT_MODAL_WIDTH.fullWidth}vw`,
        },
    },
});


export default withCostModalBase(withStyles(styles)(CostModal));
