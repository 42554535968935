import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import { translate, parseQueryString } from '../../../core';
import * as actions from "../../actions";
import * as selectors from "../../selectors";

export default function withVerifyBase(WrappedComponent) {
    class VerifyBase extends React.PureComponent {
        static propTypes = {
            email: PropTypes.string,
            token: PropTypes.string,
            actions: PropTypes.object.isRequired,
            isLoading: PropTypes.bool,
            error: PropTypes.object,
            isLoggedIn: PropTypes.bool
        };

        static defaultProps = {
            email: undefined,
            token: undefined,
            isLoading: false,
            error: null,
            isLoggedIn: false
        };

        constructor(props) {
            super(props);
            this.state = {
                isValid: props.email && props.token
            };

            this.verifyEmail();
        }

        verifyEmail = async () => {
            if (this.state.isValid) {
                if (this.props.isLoggedIn) {
                    await this.props.actions.signOut(true);
                }
                this.props.actions.verifyEmail(this.props.email, this.props.token);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isValid={this.state.isValid}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const params = parseQueryString(_.get(ownProps, 'location.search'));
        return {
            email: params.email,
            token: params.token,
            isLoading: selectors.isVerifyingEmail(state),
            error: selectors.getVerifyError(state),
            isLoggedIn: selectors.isLoggedIn(state)
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(VerifyBase));
}