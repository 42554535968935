import axios from 'axios';
import moment from "moment";

const endpoints = {
    profitReportsByDate: 'orders/profit-report',
};

export const profitReportsByDate = date => axios({
    url: endpoints.profitReportsByDate,
    method: 'GET',
    responseType: 'blob',
    params: { from: moment(date.startDate).format(), to: moment(date.endDate).format() }
});