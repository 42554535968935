import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { constants as coreConstants, Toast, translate } from '../../../../core';
import { PACKAGING_FIELDS } from "../../../constants"
import * as actions from "../../../actions";
import * as selectors from "../../../selectors";

export default function withPackagingModalBase(WrappedComponent) {
    class PackagingModalBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            id: PropTypes.string,
            isVisible: PropTypes.bool.isRequired,
            onClose: PropTypes.func.isRequired,
            modalType: PropTypes.number.isRequired,
            packaging: PropTypes.object,
        };

        static defaultProps = {
            id: '',
            packaging: undefined
        };

        constructor(props) {
            super(props);

            const { i18n, packaging } = this.props;

            this.state = {
                fields: {
                    [PACKAGING_FIELDS.name]: _.get(packaging, `${[PACKAGING_FIELDS.name]}`, ''),
                    [PACKAGING_FIELDS.cost]: _.get(packaging, `${[PACKAGING_FIELDS.cost]}`, ''),
                },
            };

            this.fields = [
                {
                    name: PACKAGING_FIELDS.name,
                    placeholder: i18n.t('settings.packagingModal.packaging'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.text,
                    autoFocus: true,
                },
                {
                    name: PACKAGING_FIELDS.cost,
                    placeholder: i18n.t('settings.packagingModal.packageCost'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.number,
                }
            ];
        }

        onChangeValue = (val, field) => {
            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    [field.name]: val
                }
            }))
        };


        addPackaging = () => {
            const { fields } = this.state;
            const { i18n, modalType, id } = this.props;
            if (_.isEmpty(fields[PACKAGING_FIELDS.name])) {
                Toast.error({ description: i18n.t('settings.packagingModal.error.packagingName') })
            }
            if (_.toNumber(fields[PACKAGING_FIELDS.cost]) < 0) {
                Toast.error({ description: i18n.t('settings.packagingModal.error.cost') })
            }
            else {
                modalType === coreConstants.MODAL_TYPE.edit ? this.props.actions.updatePackaging(id, fields) : this.props.actions.addPackaging(fields);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    addPackaging={this.addPackaging}
                    values={this.state.fields}
                    fields={this.fields}
                    onChangeValue={this.onChangeValue}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        isAdding: selectors.isAddingPackaging(state),
        isUpdating: selectors.isUpdatingPackaging(state),
        packaging: ownProps.modalType === coreConstants.MODAL_TYPE.edit ? selectors.getPackagingById(state, ownProps.id) : null,
        addPackageError: selectors.getAddPackageError(state),
        updatePackageError: selectors.getUpdatePackageError(state)
    });

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(PackagingModalBase));
}
