import _ from "lodash";

export const itemsPredicate = (a, b) => a.type === b.type && a.value === b.value;

const hasPermissions = (permissions, item) => {
    const intersected = _.intersectionWith(permissions, item.permissions, itemsPredicate);
    return intersected.length === item.permissions.length;
};

export default { hasPermissions, itemsPredicate };
