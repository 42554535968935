import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Delete from '@material-ui/icons/Delete';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import IconButton from "@material-ui/core/IconButton/IconButton";

const DragHandle = SortableHandle(({ className }) => <div className={className} />);

const SortableItem = SortableElement(({ value, className, dragHandlerClassName, onChange, onDelete }) => (
    <div className={className}>
        <DragHandle className={dragHandlerClassName} />
        <div>
            <span>{value.label}</span>
        </div>
        {onChange ? (
            <div>
                <Checkbox
                    checked={value.enabled}
                    onChange={event => onChange(event.target.checked, value.name)}
                    color="primary"
                />
            </div>
        ): null
        }
        {onDelete ? (
            <IconButton key="deleteIcon" edge="end" aria-label="Delete" onClick={() => onDelete(value)}>
                <Delete />
            </IconButton>
        ): null
        }
    </div>
));

const Sortable = SortableContainer(({ children, className }) => <div className={className}>{children}</div>);

class DnDList extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        items: PropTypes.array,
        onChange: PropTypes.func,
        onDelete: PropTypes.func,
        onChangeVisibility: PropTypes.func,
    };

    static defaultProps = {
        items: [],
        onChange: undefined,
        onDelete: undefined,
        onChangeVisibility: undefined,
    };

    onSortEnd = ({ oldIndex, newIndex }) => this.props.onChange(arrayMove(this.props.items, oldIndex, newIndex));

    getSortableItem = (value, index) => (
        <SortableItem
            className={this.props.classes.item}
            dragHandlerClassName={this.props.classes.handler}
            onChange={this.props.onChangeVisibility}
            key={`item-${value.label}`}
            index={index} value={value}
            onDelete={this.props.onDelete}
        />
    );

    render() {
        const { items, classes } = this.props;
        return (
            <Sortable onSortEnd={this.onSortEnd} useDragHandle={true} className={classes.listContainer}>
                {_.map(items, this.getSortableItem)}
            </Sortable>
        );
    }
}

const styles = () => ({
    listContainer: {
        backgroundColor: '#f3f3f3',
    },
    item: {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#fff',
        borderColor: '1px solid #efefef',
        color: '#333',
        fontWeight: '400',
        zIndex: 2000,
        justifyContent: 'space-between'
    },
    handler: {
        position: 'relative',
        top: '1px',
        display: 'block',
        width: '18px',
        height: '11px',
        opacity: '.25',
        marginRight: '20px',
        cursor: 'row-resize',
        background: 'linear-gradient(180deg,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000)',
    },
});

export default withStyles(styles)(DnDList);
