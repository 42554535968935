import _ from 'lodash';
import i18n from '../../../i18n/i18n';
import Toast from './Toast';
import { ERROR_TYPES } from '../constants';

const handleErrors = info => {
    if (info.message === ERROR_TYPES.REFRESH_TOKEN_ERROR ||
        info.message === ERROR_TYPES.ERROR_404) return;
    const errors = _.get(info, 'response.data.errors');
    if (!errors || !errors.length) {
        Toast.error({ description: i18n.t('toast.serverUnavailable') });
    } else {
        _.forEach(errors, error => {
            Toast.error({ description: error.error });
        });
    }
};

export default handleErrors;
