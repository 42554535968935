import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import { connect } from "react-redux";
import { translate } from "../../../../core";
import * as selectors from "../../../selectors";

export default function withReportItemBase(WrappedComponent) {
    class ReportItemBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            item: PropTypes.object,
            reportDaysLimit: PropTypes.number
        };

        static defaultProps = {
            item: {
                onExport: undefined,
                title: "",
                key: ""
            },
            reportDaysLimit: 0
        };

        constructor(props) {
            super(props);

            this.state = {
                selectedDateRange: {
                    startDate: moment(),
                    endDate: moment(),
                    key: "selected"
                },
                rangeReached: false
            };
        }

        onExport = () => this.props.item.onExport(this.state.selectedDateRange);

        onChangeDateRange = date => {
            const { reportDaysLimit } = this.props;
            const endDateMoment = moment(date.selected.endDate).endOf("day");
            const startDateMoment = moment(date.selected.startDate).startOf("day");

            const rangeReached = endDateMoment.diff(startDateMoment, 'days') >= reportDaysLimit;

            this.setState({
                selectedDateRange: {
                    ...date.selected,
                    endDate: endDateMoment.toDate(),
                    key: "selected"
                },
                rangeReached
            });
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onChangeValue={this.onChangeValue}
                    onExport={this.onExport}
                    onChangeDateRange={this.onChangeDateRange}
                    selectedDateRange={this.state.selectedDateRange}
                    rangeReached={this.state.rangeReached}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            isLoading: selectors[`is${ownProps.item.key}`](state),
            reportDaysLimit: selectors.reportDaysLimit(state),
        }
    }

    return connect(mapStateToProps, null)(translate()(ReportItemBase));
}
