export const NAME = 'core';
export const LOADING_NAME = 'loading';
export const ERROR_NAME = 'error';

export const BASE_URL = process.env.BASE_URL;

export const ANONYMOUS_ROUTES = [
    'identity/token',
    'identity/sign-up',
    'identity/verify-email-token',
    'identity/verify-email',
    'identity/reset-password-token',
    'identity/reset-password',
    'identity/change-password'
]

export const ROUTES = {
    inventory: () => '/inventory',
    users: () => '/users',
    settings: () => '/settings',
    linnworks: () => '/linnworks',
    signIn: () => '/signIn',
    signUp: () => '/signUp',
    verify: () => '/verify',
    resetPasswordToken: () => '/resetPasswordToken',
    resetPassword: () => '/restore-password',
    root: () => '/',
    calculator: () => '/calculator',
    reports: () => '/reports',
};

export const ERROR_TYPES = {
    REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR',
    ERROR_404: 'ERROR_404'
};

export const INPUT_TYPES = {
    text: 'text',
    textWithLink: 'textWithLink',
    password: 'password',
    email: 'email',
    number: 'number',
    select: 'select',
    date: 'date'
};

export const ROLE = {
    companyCreator: 0,
    admin: 1,
    companyUser: 2
};

export const SUBSCRIPTION_TYPES = {
    noPlan: {
        name: "owner",
        value: 0
    },
    expired: {
        name: "expired",
        value: 1
    },
    trial: {
        name: "trial",
        value: 2
    },
    full: {
        name: "full",
        value: 3
    },
    owner: {
        name: "owner",
        value: 4
    },
};

export const PERMISSION_TYPE = {
    inventoryItems: 0,
    settings: 1,
    calculator: 2,
    profitReports: 3,
    users: 4,
    account: 5
};

export const PERMISSION_VALUE = {
    edit: 0,
    importAndExport: 1,
    view: 2,
};

export const MODAL_TYPE = {
    create: 0,
    edit: 1,
};

export const INPUT_ADORNMENT = {
    percentage: "%",
};

export const DEFAULT_ITEMS_PER_PAGE = 10;