import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withExportModalBase from "./ExportModal.base";
import { components as Core, withStyles } from "../../../../core";
import { colors, fonts, spacing } from "../../../../../styles";

class ExportModal extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        items: PropTypes.array.isRequired,
        values: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        onExport: PropTypes.func.isRequired,
        isFilesExporting: PropTypes.bool,
    };

    static defaultProps = {
        isFilesExporting: false,
    };

    get items() {
        return _.map(this.props.items, this.getItem)
    }

    getItem = item => {
        const { i18n, values, onChange } = this.props;
        const checked = _.findIndex(values, i => i === item.name) >= 0;

        return (
            <Core.Grid key={item.name} item={true} xs={12} sm={12} className={this.props.classes.item}>
                <span>{i18n.t(`settings.modules.${item.label}`)}</span>
                <Core.Checkbox
                    checked={checked}
                    onChange={event => onChange(event.target.checked, item.name)}
                    color="primary"
                />
            </Core.Grid>
        )
    };

    render() {
        const { i18n, isVisible, onClose, classes, values, onExport, isFilesExporting } = this.props;

        return (
            <Core.Modal
                visible={isVisible}
                title={i18n.t('settings.export')}
                onClose={onClose}
                className={classes.modalContainer}
                isLoading={isFilesExporting}
                footer={[
                    <Core.Button
                        key="exportBtn"
                        fullWidth={false}
                        variant="contained"
                        color="primary"
                        disabled={!_.get(values, 'length', false)}
                        onClick={onExport}>
                        {i18n.t('settings.export')}
                    </Core.Button>,
                    <Core.Button
                        key="exportCancelBtn"
                        fullWidth={false}
                        variant="contained"
                        color="primary"
                        onClick={onClose}>
                        {i18n.t('Cancel')}
                    </Core.Button>
                ]}>
                <div className={classes.container}>
                    <Core.Grid container={true} spacing={24}>
                        {this.items}
                    </Core.Grid>
                </div>
            </Core.Modal>
        )
    }
}

const MODAL_WIDTH = {
    fullWidth: 95,
    partWidth: 500
};

const styles = theme => ({
    container: {
        color: colors.background,
        flex: 1,
        ...fonts.smNormal
    },
    modalContainer: {
        [theme.breakpoints.up("md")]: {
            width: MODAL_WIDTH.partWidth,
        },
        [theme.breakpoints.down("sm")]: {
            width: `${MODAL_WIDTH.fullWidth}vw`,
        },
    },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${spacing.s2}px ${spacing.s7}px ${spacing.s2}px ${spacing.s7}px`,
        ...fonts.mdNormal
    }
});


export default withExportModalBase(withStyles(styles)(ExportModal));
