import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FIELDS } from "../../constants";
import { translate } from "../../../core";
import * as actions from "../../actions";

export default function withReportsBase(WrappedComponent) {
    class ReportsBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
        };

        constructor(props) {
            super(props);

            const { i18n } = this.props;

            this.reports = [
                {
                    title: i18n.t("reports.profitReport"),
                    onExport: this.getProfitReportByDate,
                    key: FIELDS.profitReport
                }]
        }

        getProfitReportByDate = date => this.props.actions.getProfitReportByDate(date);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    reports={this.reports}
                />
            );
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(null, mapDispatchToProps)(translate()(ReportsBase));
}
