import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import { withRouter } from 'react-router-dom';
import { translate, withStyles } from '../../../core';
import { fonts, spacing, colors } from '../../../../styles';

class Footer extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired
    };

    render() {
        const { classes, i18n } = this.props;
        return (
            <footer className={classes.footer}>
                <div className={classes.container}>
                    <p className={classes.right}>
                        <span>
                            &copy;
                            {moment().year()}
                            {' '}
                            {i18n.t("footer")}
                        </span>
                    </p>
                </div>
            </footer>
        );
    }
}

const styles = theme => ({
    footer: {
        bottom: 0,
        borderTop: `1px solid ${colors.gray.lightGray}`,
        padding: `${spacing.s3}px 0`,
        ...fonts.mdNormal
    },
    container: {
        paddingRight: spacing.s3,
        paddingLeft: spacing.s3,
        marginRight: "auto",
        marginLeft: "auto"
    },
    right: {
        padding: `${spacing.s3} 0`,
        margin: 0,
        float: "right!important",
        ...fonts.smNormal
    },
});

export default withRouter(translate()(withStyles(styles)(Footer)));
