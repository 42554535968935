import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as _ from "lodash";
import { translate, constants as coreConstants } from '../../../core';
import { PERMISSION_ITEMS as permissionItems } from "../../constants"
import * as actions from "../../actions";
import { selectors } from "../../index";
import { itemsPredicate } from "../../services";

export default function withPermissionModalBase(WrappedComponent) {
    class PermissionModalBase extends React.PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            user: PropTypes.object.isRequired,
            userId: PropTypes.string.isRequired,
            isVisible: PropTypes.bool.isRequired,
            onClose: PropTypes.func.isRequired,
        };

        constructor(props) {
            super(props);

            const { user } = this.props;

            this.state = {
                permissions: user.permissions,
                role: _.get(user, 'role', coreConstants.ROLE.companyUser),
            };
        }

        onChangePermission = (permissions, val) => {
            this.setState(prevState => {
                if (val) {
                    return {
                        permissions: _.union(prevState.permissions, permissions)
                    }
                }
                return {
                    permissions: _.reject(prevState.permissions, p => _.some(permissions, p, this.itemsPredicate))
                }
            })
        };

        onChangeRole = (e, val) => this.setState({ role: val ? coreConstants.ROLE.admin : coreConstants.ROLE.companyUser });

        updateUserPermissions = () => {
            const { permissions, role } = this.state;
            const { userId } = this.props;

            this.props.actions.updateUserPermissions({ permissions, role }, userId);
        };

        render() {
            const { fields, role } = this.state;
            return (
                <WrappedComponent
                    {...this.props}
                    onChangePermission={this.onChangePermission}
                    onChangeRole={this.onChangeRole}
                    role={role}
                    values={fields}
                    updateUser={this.updateUserPermissions}
                    permissionItems={permissionItems}
                    itemsPredicate={itemsPredicate}
                    permissions={this.state.permissions}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        user: selectors.getUserById(state, ownProps.userId),
        isUpdatingUser: selectors.isUpdatingUser(state),
        updateUserPermissionsError: selectors.getUpdateUserPermissionsError(state),
    });

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(PermissionModalBase));
}
