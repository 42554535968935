import React from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import i18n from '../../../i18n/i18n';
import { fonts } from '../../../styles';

export default class Toast {
    static errorProps = {
        message: i18n.t('toast.error.message'),
        description: i18n.t('toast.error.description')
    };

    static successProps = {
        message: i18n.t('toast.success.message'),
    };

    static infoProps = {
        message: i18n.t('toast.info.message'),
        description: i18n.t('toast.info.description')
    };

    static toastConfig = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    };

    static getPropsByType = (props, defaultProps) => {
        if (_.has(props, 'type')) {
            const message = i18n.t(`toast.${props.type}.message`) || defaultProps.message;
            const description = i18n.t(`toast.${props.type}.description`);
            return { ...props, message, description };
        }
        return { ...defaultProps, ...props };
    };

    static getToastBody = props => (
        <div>
            <div style={{ ...fonts.mdNormal }}>
                <span>{props.message}</span>
            </div>
            <div>
                <span style={{ ...fonts.smNormal }}>{props.description}</span>
            </div>
        </div>
    );

    static getManyErrorsBody = props => (
        <div>
            <div style={{ ...fonts.mdNormal }}>
                <span>{props.message}</span>
            </div>
            <div>
                <ul>
                    {_.map(props.errors, (value, index) => (
                        <li key={`many-errors-${index}`}>
                            <span style={{ ...fonts.smNormal }}>{_.get(value, 'error', '')}</span>
                        </li>
                    )
                    )}
                </ul>
            </div>
        </div>
    );

    static errors = props => {
        toast.error(Toast.getManyErrorsBody(props), Toast.toastConfig);
    };

    static error = props => {
        const newProps = Toast.getPropsByType(props, Toast.errorProps);
        toast.error(Toast.getToastBody(newProps), Toast.toastConfig);
    };

    static success = props => {
        const newProps = Toast.getPropsByType(props, Toast.successProps);
        toast.success(Toast.getToastBody(newProps), Toast.toastConfig);
    };

    static info = props => {
        const newProps = Toast.getPropsByType(props, Toast.infoProps);
        toast.info(Toast.getToastBody(newProps), Toast.toastConfig);
    };
}
