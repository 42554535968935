import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Cancel from '@material-ui/icons/Cancel';
import Check from '@material-ui/icons/Check';
import List from "@material-ui/core/List/List";
import withCompetitorsBase from './Competitors.base';
import { components as Core } from "../../../../core";
import { spacing, fonts } from "../../../../../styles";

class Competitors extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        items: PropTypes.array.isRequired,
        onRemove: PropTypes.func.isRequired,
        isDisabledAddButton: PropTypes.bool.isRequired,
        onAddItem: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        defaultValues: PropTypes.object.isRequired,
        canEdit: PropTypes.bool.isRequired,
        onUpdate: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onClickEdit: PropTypes.func.isRequired,
        selectedItem: PropTypes.object,
        onUpdateChangeItem: PropTypes.func.isRequired,
        onClickUpdate: PropTypes.func.isRequired,
        isDisabledSaveBtn: PropTypes.bool,
        isDisabledUpdateBtn: PropTypes.bool,
    };

    static defaultProps = {
        selectedItem: null,
        isDisabledSaveBtn: false,
        isDisabledUpdateBtn: false,
    };

    getItemList = value => {
        const { i18n, onRemove, canEdit, selectedItem, onClickEdit, onCancel, onUpdateChangeItem, onClickUpdate, isDisabledSaveBtn, isDisabledUpdateBtn } = this.props;
        const isEdit = selectedItem && value.id === selectedItem.id;

        return (
            <ListItem key={value.name}>
                <Core.Grid container={true} spacing={24}>
                    <Core.Grid item={true} xs={8} sm={4}>
                        <Core.FormInput
                            key={`${value.name}-nameInput`}
                            htmlFor={`${value.name}-nameInput`}
                            isError={isDisabledSaveBtn && isEdit}
                            disabled={!isEdit}
                            onChange={event => onUpdateChangeItem('name', event.target.value)}
                            inputLabel={i18n.t('inventory.competitors.name')}
                            value={isEdit ? selectedItem.name : value.name}
                            type="text"
                        />
                    </Core.Grid>
                    <Core.Grid item={true} xs={8} sm={2}>
                        <Core.FormInput
                            key={`${value.name}-priceInput`}
                            disabled={!isEdit}
                            htmlFor={`${value.name}-priceInput`}
                            onChange={event => onUpdateChangeItem('price', event.target.value)}
                            inputLabel={i18n.t('inventory.competitors.price')}
                            value={isEdit ? selectedItem.price : value.price}
                            type="number"
                        />
                    </Core.Grid>
                    <Core.Grid item={true} xs={8} sm={2}>
                        <Core.FormInput
                            key={`${value.name}-shippingInput`}
                            disabled={!isEdit}
                            htmlFor={`${value.name}-shippingInput`}
                            onChange={event => onUpdateChangeItem('shippingPrice', event.target.value)}
                            inputLabel={i18n.t('inventory.competitors.shipping')}
                            value={isEdit ? selectedItem.shippingPrice : value.shippingPrice}
                            type="number"
                        />
                    </Core.Grid>
                </Core.Grid>
                {canEdit ? (
                    <ListItemSecondaryAction>
                        {isEdit ? (
                            <Core.Grid container={true} spacing={12}>
                                <Core.Grid item={true} xs={12} sm={6}>
                                    <IconButton disabled={isDisabledSaveBtn || isDisabledUpdateBtn} edge="end" aria-label="Delete" onClick={() => onClickUpdate(value)}>
                                        <Check />
                                    </IconButton>
                                </Core.Grid>
                                <Core.Grid item={true} xs={12} sm={6}>
                                    <IconButton edge="end" aria-label="Delete" onClick={onCancel}>
                                        <Cancel />
                                    </IconButton>
                                </Core.Grid>
                            </Core.Grid>
                        ) : (
                            <Core.Grid container={true} spacing={12}>
                                <Core.Grid item={true} xs={12} sm={6}>
                                    <IconButton key="editIcon" edge="end" aria-label="Delete" onClick={() => onClickEdit(value)}>
                                        <Edit />
                                    </IconButton>
                                </Core.Grid>
                                <Core.Grid item={true} xs={12} sm={6}>
                                    <IconButton key="deleteIcon" edge="end" aria-label="Delete" onClick={() => onRemove(value.name)}>
                                        <Delete />
                                    </IconButton>
                                </Core.Grid>
                            </Core.Grid>
                        )}
                    </ListItemSecondaryAction>
                ): null
                }
            </ListItem>
        )
    };

    render() {
        const { i18n, items, classes, onAddItem, isDisabledAddButton, onChange, defaultValues, canEdit } = this.props;
        return (
            <Core.Panel title={i18n.t('inventory.competitors.title')}>
                <div className={classes.list}>
                    <div className={classes.container}>
                        <Core.Grid container={true} spacing={24}>
                            <Core.Grid item={true} xs={8} sm={4}>
                                <Core.FormInput
                                    key="nameCompetitor"
                                    htmlFor="nameCompetitor"
                                    inputLabel={i18n.t('inventory.competitors.name')}
                                    onChange={event => onChange('name', event.target.value)}
                                    value={defaultValues.name}
                                    disabled={!canEdit}
                                    labelClassName={classes.inputLabel}
                                    type="text"
                                />
                            </Core.Grid>
                            <Core.Grid item={true} xs={8} sm={2}>
                                <Core.FormInput
                                    key="defaultPriceCompInput"
                                    htmlFor="defaultPriceComp"
                                    inputLabel={i18n.t('inventory.competitors.price')}
                                    onChange={event => onChange('price', event.target.value)}
                                    value={defaultValues.price}
                                    disabled={!canEdit}
                                    labelClassName={classes.inputLabel}
                                    type="number"
                                />
                            </Core.Grid>
                            <Core.Grid item={true} xs={8} sm={2}>
                                <Core.FormInput
                                    key="defaultShippingCompInput"
                                    htmlFor="defaultShippingComp"
                                    inputLabel={i18n.t('inventory.competitors.shipping')}
                                    onChange={event => onChange('shippingPrice', event.target.value)}
                                    value={defaultValues.shippingPrice}
                                    labelClassName={classes.inputLabel}
                                    disabled={!canEdit}
                                    type="number"
                                />
                            </Core.Grid>
                        </Core.Grid>
                        <div className={classes.buttonContainer} key="defaultPricesBtnContainer">
                            <Core.Button
                                fullWidth={false}
                                disabled={isDisabledAddButton || !canEdit}
                                variant="contained"
                                color="primary"
                                onClick={onAddItem}>
                                {i18n.t('inventory.competitors.add')}

                            </Core.Button>
                        </div>
                    </div>
                    <List className={classes.list}>
                        {_.map(items, this.getItemList)}
                    </List>
                </div>
            </Core.Panel>
        );
    }
}

const styles = () => ({
    container: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    list: {
        width: '100%'
    },
    input: {
        maxWidth: '60px',
    },
    buttonContainer: {
        alignSelf: 'center',
        paddingTop: `${spacing.s4}px`,
    },
    inputLabel: {
        paddingLeft: `${spacing.s2}px`,
    },
    inputName: {
        maxWidth: '200px',
    },
    valueContainer: {
        paddingLeft: `${spacing.s7}px`,
        paddingTop: `${spacing.s2}px`,
    },
    nameText: {
        ...fonts.mdBold,
    }
});

Competitors.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withCompetitorsBase(Competitors));
