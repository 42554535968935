import * as actionTypes from "./actionTypes";
import * as api from "./api";
import { createFileLink, handleErrors } from "../core/services";

export function getProfitReportByDate(date) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_PROFIT_REPORT_BY_DATE.REQUEST });
        return api.profitReportsByDate(date)
            .then(response => {
                createFileLink(response, "reportInventory");
                dispatch({ type: actionTypes.GET_PROFIT_REPORT_BY_DATE.SUCCESS });
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.GET_PROFIT_REPORT_BY_DATE.ERROR });
            });
    }
}
