import React from 'react';
import PropTypes from 'prop-types';
import Create from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';
import Send from '@material-ui/icons/SaveAlt';
import Add from '@material-ui/icons/Add';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Visibility from '@material-ui/icons/Visibility';
import withStyles from '@material-ui/core/styles/withStyles';
import { components as Core, constants as coreConstants, permissionHelper } from '../../../core';
import withInventoryBase from './Inventory.base';
import { default as DetailsComponent } from './Details';
import DetailsBase from './DetailsBase';
import CompetitorsDetails from './CompetitorsDetails';
import { ITEMS_PER_PAGE } from "../../constants";
import EditForm from './EditForm';
import ColumnSettings from './ColumnSettings';
import CategoryFilter from './CategoryFilter';

import { colors, spacing } from "../../../../styles";
import { PERMISSION_TYPE, PERMISSION_VALUE } from "../../../users/constants";

class Inventory extends React.Component {
    static propTypes = {
        tableColumns: PropTypes.array.isRequired,
        items: PropTypes.array,
        onSearch: PropTypes.func.isRequired,
        isInventoryLoading: PropTypes.bool,
        onFilterCategory: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        editFormVisible: PropTypes.bool.isRequired,
        onChangeEditForm: PropTypes.func.isRequired,
        createFormVisible: PropTypes.bool.isRequired,
        onChangeCreateForm: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        isItemDelete: PropTypes.bool,
        columnSettingsFormVisible: PropTypes.bool,
        onChangeColumnSettingsForm: PropTypes.func.isRequired,
        tableFormattedColumns: PropTypes.array.isRequired,
        onSort: PropTypes.func.isRequired,
        onUploadFile: PropTypes.func.isRequired,
        onChangePagination: PropTypes.func.isRequired,
        pagination: PropTypes.object,
        permissions: PropTypes.array.isRequired,
        reqParams: PropTypes.object.isRequired,
        isInventoryItemsImporting: PropTypes.bool,
        isInventoryItemsExporting: PropTypes.bool,
        isUserAdmin: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        items: [],
        isInventoryLoading: false,
        isItemDelete: false,
        columnSettingsFormVisible: false,
        isInventoryItemsImporting: false,
        isInventoryItemsExporting: false,
    };

    constructor(props) {
        super(props);

        const { permissions, isUserAdmin } = this.props;

        this.state = {
            itemId: '',
            firstPopConfirmVisible: false,
            secondPopConfirmVisible: false,
            isSearchOpen: false,
        };

        this.permissions = {
            canImportExport: isUserAdmin || permissionHelper.hasPermissions(permissions, { permissions: [{
                type: coreConstants.PERMISSION_TYPE.inventoryItems,
                value: coreConstants.PERMISSION_VALUE.importAndExport
            }] }),
            canEdit: isUserAdmin || permissionHelper.hasPermissions(permissions, { permissions: [{
                type: coreConstants.PERMISSION_TYPE.inventoryItems,
                value: coreConstants.PERMISSION_VALUE.edit
            }] }),
        }
    }

    get options() {
        return {
            selectableRows: "single",
            responsive: 'scroll',
            rowHover: false,
            onRowsSelect: this.onRowsSelect,
            filter: false,
            print: false,
            viewColumns: false,
            download: false,
            serverSide: true,
            expandableRows: true,
            sort: true,
            onColumnSortChange: this.props.onSort,
            customToolbar: this.customToolbar,
            searchText: this.props.reqParams.filterText,
            onSearchChange: this.props.onSearch,
            renderExpandableRow: this.renderDetails,
            customToolbarSelect: this.customToolbarSelected,
            onTableChange: this.tableChanged,
            onSearchOpen: this.onSearchOpen,
        };
    }

    get popConfirm() {
        const { firstPopConfirmVisible, secondPopConfirmVisible } = this.state;
        const { i18n, isItemDelete } = this.props;
        let params = null;

        if (firstPopConfirmVisible) {
            params = {
                descriptions: i18n.t('inventory.delete.first.title'),
                isVisible: firstPopConfirmVisible,
                onConfirm: this.openSecondPopConfirm,
                onClose: this.closeAllPopConfirms,
                confirmTextPath: 'inventory.delete.first.yes',
                cancelTextPath: 'inventory.delete.first.no',
                isLoading: isItemDelete
            }
        }

        if (secondPopConfirmVisible) {
            params = {
                descriptions: i18n.t('inventory.delete.second.title'),
                isVisible: secondPopConfirmVisible,
                onConfirm: this.deleteItem,
                confirmTextPath: 'inventory.delete.second.yes',
                cancelTextPath: 'inventory.delete.second.no',
                onClose: this.closeAllPopConfirms,
                isLoading: isItemDelete
            }
        }

        return params ? (
            <Core.ConfirmationModal
                {...params}
            />
        ) : (null)
    }

    tableChanged = (action, tableState) => {
        const { isSearchOpen } = this.state;
        if (action === "search" && isSearchOpen && !tableState.searchText) {
            this.setState({ isSearchOpen: false });
            this.props.onSearch("");
        }
    };

    onSearchOpen = () => this.setState({ isSearchOpen: true });

    customToolbar = () => {
        const { i18n, classes, onChangeCreateForm, onChangeColumnSettingsForm, onFilterCategory, reqParams, isInventoryItemsExporting } = this.props;
        return ([
            this.permissions.canEdit ? (
                <Core.TooltipButton
                    key="createBtn"
                    onClick={onChangeCreateForm}
                    icon={<Add />}
                    title={i18n.t("inventory.form.create")}
                />
            ): null,
            <Core.TooltipButton
                key="columnSettingsBtn"
                onClick={onChangeColumnSettingsForm}
                icon={<ViewColumn />}
                title={i18n.t("inventory.form.columnSettings")}
            />,
            <CategoryFilter key="filters" onFilter={onFilterCategory} requestParams={reqParams} />,
            this.permissions.canImportExport ?
                [
                    <Core.UploadFile
                        key="importBtn"
                        tooltipTitle={i18n.t("inventory.form.import")}
                        onUpload={this.props.onUploadFile}
                    />,
                    <Core.Spin key="exportSpin" spinning={isInventoryItemsExporting} styles={CUSTOM_TOOLTIP_SPINNER_STYLE}>
                        <Core.TooltipButton
                            key="exportBtn"
                            onClick={this.props.actions.exportFile}
                            icon={<Send className={classes.exportIcon} />}
                            title={i18n.t("inventory.form.export")}
                        />
                    </Core.Spin>
                ] : null
        ])
    };

    customToolbarSelected = () => {
        const { i18n, onChangeEditForm } = this.props;
        return (
            <div>
                <Core.TooltipButton
                    key={i18n.t("inventory.form.edit")}
                    onClick={onChangeEditForm}
                    icon={this.permissions.canEdit ? <Create /> : <Visibility />}
                    title={i18n.t(this.permissions.canEdit ? "inventory.form.edit": "inventory.form.view")}
                />
                <Core.TooltipButton
                    key={i18n.t("inventory.form.delete")}
                    onClick={this.openFirstPopConfirm}
                    icon={<Delete />}
                    title={i18n.t("inventory.form.delete")}
                />
            </div>
        )
    };

    onRowsSelect = currentRowsSelected => {
        const { items } = this.props;
        this.setState({ itemId: items[currentRowsSelected[0].dataIndex].id })
    };

    renderDetails = rowData => {
        const itemId = _.get(rowData[0], 'props.itemId', rowData[0]);
        return (
            <Core.TableRow>
                <Core.TableCell colSpan={rowData.length + 1} className={this.props.classes.details}>
                    <DetailsBase
                        tabs={[
                            this.props.i18n.t('inventory.details.marketplaces'),
                            this.props.i18n.t('inventory.details.competitors'),
                        ]}
                        components={[
                            <DetailsComponent key="detailsComponent" editable={false} loading={true} itemId={itemId} />,
                            <CompetitorsDetails key="competitorsComponent" itemId={itemId} />
                        ]}
                    />
                </Core.TableCell>
            </Core.TableRow>
        ) };

    openFirstPopConfirm = () => {
        this.setState({ firstPopConfirmVisible: true, secondPopConfirmVisible: false, })
    };

    openSecondPopConfirm = () => {
        this.setState({ firstPopConfirmVisible: false, secondPopConfirmVisible: true, });
    };

    closeAllPopConfirms = () => {
        this.setState({ firstPopConfirmVisible: false, secondPopConfirmVisible: false, });
    };

    deleteItem = () => {
        this.closeAllPopConfirms();
        this.props.onDelete(this.state.itemId);
    };

    changePage = (e, page) => this.props.onChangePagination(page + 1);

    render() {
        const {
            items,
            isInventoryLoading,
            editFormVisible,
            onChangeEditForm,
            createFormVisible,
            onChangeCreateForm,
            isItemDelete,
            i18n,
            tableColumns,
            onChangeColumnSettingsForm,
            columnSettingsFormVisible,
            tableFormattedColumns,
            isInventoryItemsImporting,
            pagination,
        } = this.props;
        const { itemId } = this.state;
        return (
            <div>
                <Core.Spin spinning={isInventoryLoading || isItemDelete || isInventoryItemsImporting}>
                    <Core.Table
                        title={i18n.t('inventory')}
                        options={this.options}
                        columns={tableFormattedColumns}
                        data={items}
                        changePage={this.changePage}
                        pagination={pagination}
                        overrides={TABLE_OVERRIDES}
                        itemPerPage={ITEMS_PER_PAGE}
                    />
                </Core.Spin>
                {editFormVisible ? (
                    <EditForm
                        itemId={itemId}
                        editFormVisible={editFormVisible}
                        onChangeEditForm={onChangeEditForm}
                        canEdit={this.permissions.canEdit}
                    />
                ) : (null)}
                {createFormVisible ? (
                    <EditForm
                        isCreate={true}
                        editFormVisible={createFormVisible}
                        onChangeEditForm={onChangeCreateForm}
                        canEdit={this.permissions.canEdit}
                    />
                ) : (null)}
                {columnSettingsFormVisible? (
                    <ColumnSettings
                        visible={columnSettingsFormVisible}
                        onClose={onChangeColumnSettingsForm}
                        columns={tableColumns}
                    />
                ) : (null)}
                {this.popConfirm}
            </div>
        );
    }
}

const MAX_HEIGHT_RESPONSIVE_SCROLL = 70;
const MAX_WIDHT_DETAILS = 30;
const ROW_WIDTH_CHECKBOX = 68;

const TABLE_OVERRIDES = {
    MUIDataTable: {
        responsiveScroll: {
            maxHeight: `${MAX_HEIGHT_RESPONSIVE_SCROLL}vh`
        }
    },
    MUIDataTableHeadCell: {
        fixedHeader: {
            zIndex: 110,
            left: spacing.s6,
        }
    },
    MUIDataTableSelectCell: {
        root: {
            "@media (max-width: 960px)": {
                display: "table-cell"
            }
        },
        checkboxRoot: {
            position: "absolute",
            opacity: 0,
            width: `${ROW_WIDTH_CHECKBOX}vw`,
            padding: 0,
            marginLeft: spacing.s14,
        },
        fixedHeader: {
            background: colors.white,
        }
    }
};

const CUSTOM_TOOLTIP_SPINNER_STYLE = {
    overlay: {
        background: colors.transparent
    }
}


const styles = () => ({
    actionButton: {
        borderRadius: 0,
    },
    exportIcon: {
        transform: "rotate(180deg)"
    },
    details: {
        overflowX: 'auto',
        maxWidth: `${MAX_WIDHT_DETAILS}vh`,
        borderBottom: '0px',
        padding: "0px !important",
    }
});


export default withInventoryBase(withStyles(styles)(Inventory));
