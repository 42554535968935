import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from "react-router-dom";

import withVerifyBase from './Verify.base';
import { constants as coreConstants, components as Core } from "../../../core";

class Verify extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        linnworkstoken: PropTypes.string.isRequired,
        isLoggedIn: PropTypes.bool.isRequired,
        accountRole: PropTypes.number.isRequired,
        actions: PropTypes.object.isRequired,
    };

    get content() {
        const { isLoggedIn, linnworkstoken, accountRole, actions } = this.props;

        if (isLoggedIn && accountRole !== coreConstants.ROLE.companyCreator) {
            return (<Redirect to={coreConstants.ROUTES.inventory()} />);
        }

        if (!linnworkstoken && isLoggedIn) {
            return (<Redirect to={coreConstants.ROUTES.inventory()} />);
        }

        if (linnworkstoken && isLoggedIn && accountRole === coreConstants.ROLE.companyCreator) {
            actions.storeToken(linnworkstoken);
            return (<Redirect to={coreConstants.ROUTES.linnworks()} />);
        }

        if (linnworkstoken && !isLoggedIn) {
            actions.storeToken(linnworkstoken);
        }

        return (<Redirect to={coreConstants.ROUTES.signIn()} />)
    }

    render() {
        return this.content;
    }
}

export default withRouter(withVerifyBase(Verify));
