import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions";
import { constants as coreConstants, translate } from '../../../../core';
import { actions as settingsActions, selectors as settingsSelectors } from "../../../../settings";
import { getMarketplaces } from "../../../selectors";
import { SELECTOR_NAMES, DEFAULT_MARKET_VALUES } from "../../../constants";

export default function withDefaultValuesBase(WrappedComponent) {
    class DefaultValues extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            settingsActions: PropTypes.object.isRequired,
            onApply: PropTypes.func.isRequired,
            formattedMarkets: PropTypes.array,
            isPercentage: PropTypes.bool.isRequired,
        };

        static defaultProps = {
            formattedMarkets: [],
        };

        constructor(props) {
            super(props);

            this.state = {
                defaultMarketValues: DEFAULT_MARKET_VALUES,
                selectedZone: '',
            }
        }

        onChangeDefaultMarketValues = (key, value) => {
            this.setState(prevState => ({ defaultMarketValues: { ...prevState.defaultMarketValues, [key]: value } }))
        };

        onApplyDefaultMarketValues = () => {
            this.props.onApply(_.map(this.props.formattedMarkets, this.getDefaultItem))
        };

        getDefaultItem = item => {
            const { defaultMarketValues, selectedZone } = this.state;
            const result = !this.props.isPercentage ? {
                ...item,
                max: defaultMarketValues.max,
                min: defaultMarketValues.min,
                currentCalculated: defaultMarketValues.currentCalculated
            } : {
                ...item,
                maxMargin: defaultMarketValues.max,
                minMargin: defaultMarketValues.min,
                currentMargin: defaultMarketValues.currentCalculated
            };

            if (selectedZone) {
                if (selectedZone === item.zone.id) {
                    return result;
                }

                return item;
            }

            return result;
        };

        onChangeZone = selectedZone => (this.setState({ selectedZone }));

        onSearch = (filterText, field, isMore, page) => {
            switch (field) {
                case SELECTOR_NAMES.zones: {
                    return this.props.settingsActions.getZones({
                        page: isMore ? page : 1,
                        count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                        filterText
                    }, true);
                }
                default:
                    break;
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onChangeDefaultMarketValues={this.onChangeDefaultMarketValues}
                    onApplyDefaultMarketValues={this.onApplyDefaultMarketValues}
                    onSearch={this.onSearch}
                    selectedZone={this.state.selectedZone}
                    onChangeZone={this.onChangeZone}
                    defaultMarketValues={this.state.defaultMarketValues}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            zones: settingsSelectors.getZonesItems(state),
            isLoading: settingsSelectors.isZones(state),
            pagination: settingsSelectors.getZonesScrollPagination(state),
            marketplaces: getMarketplaces(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch),
            settingsActions: bindActionCreators(settingsActions, dispatch),
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(DefaultValues));
}
