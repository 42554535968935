import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import { components as Core, constants as coreConstants, translate } from '../../../../../core';
import { spacing } from "../../../../../../styles";
import { ROUND_VALUES, COMPOSITE_DEFAULT_ID } from "../../../../constants";

const FIELDS = {
    perGramCost: {
        name: 'perGramCost',
        placeholder: i18n => i18n.t('settings.postalServiceModal.perGramCost'),
        type: coreConstants.INPUT_TYPES.number
    },
    perPackageCost: {
        name: 'perPackageCost',
        placeholder: i18n => i18n.t('settings.postalServiceModal.perPackageCost'),
        type: coreConstants.INPUT_TYPES.number
    },
    surcharge: {
        name: 'surcharge',
        placeholder: i18n => i18n.t('settings.postalServiceModal.surcharge'),
        type: coreConstants.INPUT_TYPES.number,
        endAdornment: coreConstants.INPUT_ADORNMENT.percentage,
        isPercentage: true,
    }
};

class DnD extends React.PureComponent {
    static propTypes = {
        items: PropTypes.node.isRequired,
        classes: PropTypes.object.isRequired,
        onDelete: PropTypes.func.isRequired,
        i18n: PropTypes.object.isRequired,
        onChangeValue: PropTypes.func.isRequired,
        onChangeOrder: PropTypes.func.isRequired,
        defaultCost: PropTypes.object.isRequired,
        onEdit: PropTypes.func.isRequired,
    };

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = this.reorder(
            this.props.items,
            result.source.index,
            result.destination.index
        );

        this.props.onChangeOrder(items);
    };

    getDragItem = (value, index) => (
        <Draggable draggableId={value.id} key={value.id} index={index}>
            {provided => (
                <div
                    className={this.props.classes.panelContainer}
                    key={value.id}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}>
                    <Core.Panel
                        title={value.name}
                        id={value.id === COMPOSITE_DEFAULT_ID ? null : value.id}
                        onDelete={this.props.onDelete}
                        onEdit={this.props.onEdit}>
                        <Core.Grid key={`panelItem${value.id}`} container={true} spacing={24} className={this.props.classes.gridContainer}>
                            {_.map(FIELDS, field => this.renderField(field, value))}
                        </Core.Grid>
                    </Core.Panel>
                </div>
            )}
        </Draggable>

    );

    renderField = (field, value) => (
        <Core.Grid item={true} xs={12} sm={6} key={field.name}>
            <Core.FormInput
                key={`${value[field.name].key}-${value[field.name].id}`}
                required={false}
                htmlFor={`${value[field.name].key}-${value[field.name].id}`}
                inputLabel={field.placeholder(this.props.i18n)}
                onChange={event => this.props.onChangeValue(field.isPercentage ? event.target.value / 100 : event.target.value, field.name, value.id)}
                defaultValue={_.toString(field.isPercentage ? _.round(_.get(value, field.name, 0) * 100, ROUND_VALUES) : _.get(value, field.name, 0))}
                type={field.type}
                endAdornment={field.endAdornment}
            />
        </Core.Grid>
    );

    render() {
        const { defaultCost } = this.props;
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="list">
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {_.map(this.props.items, this.getDragItem)}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                <Core.Panel title={defaultCost.name}>
                    <Core.Grid key={`panelItem${defaultCost.id}`} container={true} spacing={24} className={this.props.classes.gridContainer}>
                        {_.map(FIELDS, field => this.renderField(field, defaultCost))}
                    </Core.Grid>
                </Core.Panel>
            </DragDropContext>
        );
    }
}

const styles = () => ({
    panelContainer: {
        paddingTop: spacing.s1,
        paddingBottom: spacing.s1,
    }
});

DnD.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate()(DnD));
