import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import withStyles from '@material-ui/core/styles/withStyles';
import * as _ from "lodash";
import { components as Core } from '../../../../core';
import withEditFormBase from './EditForm.base';
import Details from '../Details';
import Competitors from '../Competitors';
import Supplier from '../Supplier';
import { spacing } from '../../../../../styles';
import { FIELDS, TAX_RATE_PROPS } from '../../../constants';
import noImage from "../../../../../../assets/img/noImage.png";
import DefaultValues from '../DefaultValues';

class EditForm extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        isItemLoading: PropTypes.bool.isRequired,
        item: PropTypes.array,
        fullItem: PropTypes.object.isRequired,
        itemId: PropTypes.string,
        editFormVisible: PropTypes.bool.isRequired,
        onChangeEditForm: PropTypes.func.isRequired,
        onUpdateCompetitor: PropTypes.func.isRequired,
        onChangeValue: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        isDisabledSaveButton: PropTypes.bool,
        isItemUpdate: PropTypes.bool,
        postalServices: PropTypes.array,
        onChangeMarketsTable: PropTypes.func.isRequired,
        formattedMarkets: PropTypes.array,
        formattedCompetitors: PropTypes.array,
        isAdditionalDataLoading: PropTypes.bool.isRequired,
        isItemCreating: PropTypes.bool,
        isCreate: PropTypes.bool.isRequired,
        onApplyDefaultMarketValues: PropTypes.func.isRequired,
        onChangeDefaultMarketValues: PropTypes.func.isRequired,
        defaultMarketValues: PropTypes.object.isRequired,
        onRemoveCompetitors: PropTypes.func.isRequired,
        onAddCompetitors: PropTypes.func.isRequired,
        formattedSupplier: PropTypes.array,
        canEdit: PropTypes.bool,
        isLoading: PropTypes.object.isRequired,
        initNames: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        onSearch: PropTypes.func.isRequired,
        onChangeZone: PropTypes.func.isRequired,
        selectedZone: PropTypes.string.isRequired,
        isPercentage: PropTypes.bool,
        onChangeInputType: PropTypes.func.isRequired,
    };

    static defaultProps = {
        item: [],
        formattedMarkets: [],
        isItemUpdate: false,
        postalServices: [],
        isDisabledSaveButton: true,
        isItemCreating: false,
        formattedCompetitors: [],
        formattedSupplier: [],
        canEdit: false,
        itemId: null,
        isPercentage: false,
    };

    get form() {
        return _.map(this.props.item, this.getFormItem);
    }

    get title() {
        const { isCreate, canEdit, i18n } = this.props;

        if (!canEdit) {
            return i18n.t('inventory.form.view')
        }
        return isCreate ? i18n.t('inventory.form.create') : i18n.t('inventory.form.edit')
    }

    getFormItem = item => {
        const { onChangeValue, i18n, isCreate, classes, canEdit } = this.props;
        const isDisabled = this.props.isCreate ? false : item.disabled;
        const params = {
            disabled: canEdit ? isDisabled : true,
            required: item.required,
            htmlFor: item.name,
            inputLabel: i18n.t(item.label),
        };

        let itemSize = item.size || 3;

        if (item.hidden) {
            return null;
        }

        if (item.isImage && !isCreate) {
            return (
                <Core.Grid item={true} xs={12} sm={4}>
                    <Card className={classes.card}>
                        <CardMedia
                            className={classes.media}
                            image={item.value || noImage}
                        />
                    </Card>
                </Core.Grid>
            )
        }

        if (!isCreate && item.name === FIELDS.title) {
            itemSize = 8;
        }

        return (
            <Core.Grid key={item.name} item={true} xs={12} sm={itemSize}>
                {item.isObject ? (
                    <Core.SearchableFormSelect
                        {...params}
                        key={item.name}
                        onChange={event => onChangeValue(item.name, { ...item.value, id: event.value })}
                        value={_.get(item, 'value.id', '')}
                        onSearch={this.props.onSearch}
                        isLoading={this.props.isLoading[item.selectName]}
                        selectName={item.selectName}
                        placeholder={this.props.initNames[item.selectName]}
                        pagination={this.props.pagination[item.selectName]}
                        options={_.map(this.props[item.selectName], this.getSelectOption)}
                    />
                ) : (
                    <Core.FormInput
                        {...params}
                        onChange={event => onChangeValue(item.name, event.target.value, item)}
                        defaultValue={_.get(item, 'value', '')}
                        endAdornment={FIELDS.taxRate === item.name ? '%' : undefined}
                        inputProps={FIELDS.taxRate === item.name ? TAX_RATE_PROPS : undefined}
                        type={item.type || 'text'}
                    />
                )}
            </Core.Grid>
        )
    };

    getSelectOption = item => ({ value: _.get(item, "id"), label: _.get(item, "name") });

    render() {
        const {
            item,
            classes,
            i18n,
            isItemLoading,
            onChangeMarketsTable,
            editFormVisible,
            onChangeEditForm,
            onSave,
            isDisabledSaveButton,
            isItemUpdate,
            formattedMarkets,
            isAdditionalDataLoading,
            isItemCreating,
            onApplyDefaultMarketValues,
            formattedCompetitors,
            onAddCompetitors,
            formattedSupplier,
            onRemoveCompetitors,
            onUpdateCompetitor,
            isCreate,
            canEdit,
            isPercentage,
            onChangeInputType,
        } = this.props;
        return (
            <div>
                <Core.Modal
                    containerClassName={classes.modalContainer}
                    isLoading={isItemLoading || isItemUpdate || isAdditionalDataLoading || isItemCreating}
                    title={this.title} visible={editFormVisible} onClose={onChangeEditForm} footer={[
                        <Core.Button
                            key="saveEditButton"
                            fullWidth={false}
                            variant="contained"
                            color="primary"
                            disabled={isDisabledSaveButton}
                            onClick={onSave}>
                            {i18n.t('Save')}
                        </Core.Button>,
                        <Core.Button
                            key="cancelEditButton"
                            fullWidth={false}
                            variant="contained"
                            color="primary"
                            onClick={onChangeEditForm}>
                            {i18n.t('Cancel')}
                        </Core.Button>
                    ]}>
                    <div className={classes.spin}>
                        <div className={classes.container}>
                            <Core.Grid container={true} spacing={24}>
                                {item.length ? this.form : null}
                                {isCreate || !formattedSupplier.length ? (null) : (
                                    <Core.Grid item={true} xs={12} sm={12} className={classes.defaultValuesContainer}>
                                        <div className={classes.fullContainer}>
                                            <Supplier item={formattedSupplier} canEdit={canEdit} />
                                        </div>
                                    </Core.Grid>
                                )}
                                <DefaultValues
                                    isEditForm={true}
                                    formattedMarkets={formattedMarkets}
                                    onApply={onApplyDefaultMarketValues}
                                    isPercentage={isPercentage}
                                    onChangeInputType={onChangeInputType}
                                />
                                <Core.Grid item={true} xs={12} sm={12} className={classes.overflowDetails}>
                                    {!isAdditionalDataLoading || !isItemUpdate ? (
                                        <Details onChangeMarketsTable={onChangeMarketsTable} shops={formattedMarkets} canEdit={canEdit} isPercentage={isPercentage} />
                                    ) : (null)}
                                </Core.Grid>
                                <Core.Grid item={true} xs={12} sm={12} className={classes.defaultValuesContainer}>
                                    <div className={classes.fullContainer}>
                                        <Competitors onUpdate={onUpdateCompetitor} onAdd={onAddCompetitors} onRemove={onRemoveCompetitors} items={formattedCompetitors} canEdit={canEdit} />
                                    </div>
                                </Core.Grid>
                            </Core.Grid>
                        </div>
                    </div>
                </Core.Modal>
            </div>
        );
    }
}

const styles = () => ({
    spin: {
        maxWidth: '100%',
        height: '100%',
    },
    container: {
        padding: `${spacing.s3}px`,
    },
    overflowDetails: {
        overflowX: 'auto'
    },
    defaultValuesContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    flexContainer: {
        display: 'flex',
    },
    fullContainer: {
        width: '100%',
    },
    contentContainer: {
        alignSelf: 'center',
        paddingTop: `${spacing.s4}px`,
        paddingRight: `${spacing.s4}px`,
    },
    input: {
        maxWidth: '60px',
        padding: `0px ${spacing.s2}px 0px ${spacing.s2}px`,
    },
    inputLabel: {
        paddingLeft: `${spacing.s2}px`,
    },
    modalContainer: {
        overflowY: 'auto',
        display: "block",
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
    },
});


export default withEditFormBase(withStyles(styles)(EditForm));
