import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { translate } from "../../../../core";
import { MODULES } from '../../../constants';
import { isFilesExporting } from '../../../selectors';

export default function withExportModalBase(WrappedComponent) {
    class ExportModalBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            onClose: PropTypes.func.isRequired,
        };

        constructor(props) {
            super(props);

            this.state = {
                modules: MODULES,
                selectedModules: [],
            };
        }

        onChange = (checked, name) => {
            if (!checked) {
                this.setState(prevState => ({
                    selectedModules: _.filter(prevState.selectedModules, i => i !== name)
                }))
            } else {
                this.setState(prevState => ({
                    selectedModules: [...prevState.selectedModules, name]
                }))
            }
        };

        onExport = () => {
            this.props.actions.exportFiles(this.state.selectedModules);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    items={this.state.modules}
                    values={this.state.selectedModules}
                    onChange={this.onChange}
                    onExport={this.onExport}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        isFilesExporting: isFilesExporting(state),
    });

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)

        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ExportModalBase));
}
