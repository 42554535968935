import { NAME } from './constants';
import { createActionType } from '../core';

// Currencies

export const GET_CURRENCIES_RATE = createActionType(`${NAME}/GET_CURRENCIES_RATE`);
export const ADD_CURRENCY = createActionType(`${NAME}/ADD_CURRENCY`);
export const UPDATE_CURRENCY = createActionType(`${NAME}/UPDATE_CURRENCY`);
export const DELETE_CURRENCY = createActionType(`${NAME}/DELETE_CURRENCY`);

// Zones

export const GET_ZONES = createActionType(`${NAME}/GET_ZONES`);
export const ADD_ZONE = createActionType(`${NAME}/ADD_ZONE`);
export const UPDATE_ZONE = createActionType(`${NAME}/UPDATE_ZONE`);
export const DELETE_ZONE= createActionType(`${NAME}/DELETE_ZONE`);

// Companies

export const GET_COMPANIES = createActionType(`${NAME}/GET_COMPANIES`);
export const ADD_COMPANY = createActionType(`${NAME}/ADD_COMPANY`);
export const UPDATE_COMPANY = createActionType(`${NAME}/UPDATE_COMPANY`);
export const DELETE_COMPANY = createActionType(`${NAME}/DELETE_COMPANY`);

// Marketplace

export const GET_MARKETPLACES = createActionType(`${NAME}/GET_MARKETPLACES`);
export const GET_MARKETPLACE_FEES = createActionType(`${NAME}/GET_MARKETPLACE_FEES`);
export const ADD_MARKETPLACE = createActionType(`${NAME}/ADD_MARKETPLACE`);
export const UPDATE_MARKETPLACE = createActionType(`${NAME}/UPDATE_MARKETPLACE`);
export const DELETE_MARKETPLACE = createActionType(`${NAME}/DELETE_MARKETPLACE`);

// Postal Services

export const GET_POSTAL_SERVICES = createActionType(`${NAME}/GET_POSTAL_SERVICES`);
export const GET_POSTAL_SERVICE_COSTS = createActionType(`${NAME}/GET_POSTAL_SERVICE_COSTS`);
export const GET_COMPOSITE_COST_TYPE = createActionType(`${NAME}/GET_COMPOSITE_COST_TYPE`);
export const UPDATE_COMPOSITE_COST_TYPE = createActionType(`${NAME}/UPDATE_COMPOSITE_COST_TYPE`);

export const ADD_POSTAL_SERVICE = createActionType(`${NAME}/ADD_POSTAL_SERVICE`);
export const UPDATE_POSTAL_SERVICE = createActionType(`${NAME}/UPDATE_POSTAL_SERVICE`);
export const DELETE_POSTAL_SERVICE = createActionType(`${NAME}/DELETE_POSTAL_SERVICE`);

// Packaging

export const GET_PACKAGINGS = createActionType(`${NAME}/GET_PACKAGINGS`);
export const ADD_PACKAGING = createActionType(`${NAME}/ADD_PACKAGING`);
export const UPDATE_PACKAGING = createActionType(`${NAME}/UPDATE_PACKAGING`);
export const DELETE_PACKAGING = createActionType(`${NAME}/DELETE_PACKAGING`);

// Static Values

export const GET_STATIC_VALUES = createActionType(`${NAME}/GET_STATIC_VALUES`);
export const ADD_STATIC_VALUE = createActionType(`${NAME}/ADD_STATIC_VALUE`);
export const UPDATE_STATIC_VALUE = createActionType(`${NAME}/UPDATE_STATIC_VALUE`);
export const DELETE_STATIC_VALUE = createActionType(`${NAME}/DELETE_STATIC_VALUE`);

// Export Settings

export const EXPORT_SETTINGS = createActionType(`${NAME}/EXPORT_SETTINGS`);

// Import and Export Local

export const IS_VISIBLE_EXPORT_MODAL = `${NAME}/IS_VISIBLE_EXPORT_MODAL`;
export const IS_VISIBLE_IMPORT_MODAL = `${NAME}/IS_VISIBLE_IMPORT_MODAL`;
export const EXPORT_FILES = createActionType(`${NAME}/EXPORT_FILES`);
export const IMPORT_FILES = createActionType(`${NAME}/IMPORT_FILES`);