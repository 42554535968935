import React from 'react';
import PropTypes from 'prop-types';
import Settings from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import _ from "lodash";
import withStaticValueTableBase, { COLUMNS } from './StaticValueTable.base';
import { components as Core, constants as coreConstants } from "../../../core"
import StaticValueModal from "./StaticValueModal";

const hiddenOptions = {
    display: false,
};

const sortOptions = {
    sort: false
};

class StaticValueTable extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        tableColumns: PropTypes.object.isRequired,
        deleteStaticValue: PropTypes.func.isRequired,
        isStaticValuesLoading: PropTypes.bool,
        isDeletingStaticValue: PropTypes.bool,
        staticValues: PropTypes.array,
        getStaticValues: PropTypes.func.isRequired,
        pagination: PropTypes.object,
        onSearch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isStaticValuesLoading: false,
        isDeletingStaticValue: false,
        staticValues: [],
        pagination: null
    };

    constructor(props) {
        super(props);

        const { tableColumns, i18n } = this.props;

        this.state = {
            isVisibleStaticValueModal: false,
            modalType: coreConstants.MODAL_TYPE.create,
            isVisibleDeleteModal: false,
            staticValueId: '',
            isSearchOpen: false,
            searchText: ""
        };

        this.tableColumns = [
            { ...tableColumns[COLUMNS.id], options: hiddenOptions },
            { ...tableColumns[COLUMNS.name], options: sortOptions },
            { ...tableColumns[COLUMNS.value], options: sortOptions }
        ];

        this.tableActions = {
            base: [
                {
                    label: i18n.t("settings.staticValuesTable.addStaticValue"),
                    onClick: this.openAddStaticValueModal,
                    icon: <AddIcon />
                }
            ],
            select: [
                {
                    label: i18n.t("table.edit"),
                    onClick: this.openEditStaticValueModal,
                    icon: <Settings />
                },
                {
                    label: i18n.t("table.delete"),
                    onClick: this.openConfirmationModal,
                    icon: <Delete />
                }
            ]
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isDeletingStaticValue && !this.props.isDeletingStaticValue) {
            this.onCloseConfirmationModal();
        }
    }

    get options() {
        return {
            selectableRows: "single",
            responsive: 'scroll',
            rowHover: false,
            onRowsSelect: this.onRowsSelect,
            filter: false,
            print: false,
            searchText: this.state.searchText,
            viewColumns: false,
            download: false,
            onSearchChange: this.onSearch,
            onTableChange: this.tableChanged,
            onSearchOpen: this.onSearchOpen,
        };
    }

    tableChanged = (action, tableState) => {
        const { isSearchOpen } = this.state;
        this.setState({ searchText: tableState.searchText });
        if (action === "search" && isSearchOpen && !tableState.searchText) {
            this.setState({ isSearchOpen: false });
            this.onSearch("");
        }
    };

    onSearchOpen = () => this.setState({ isSearchOpen: true });

    onRowsSelect = currentRowsSelected => {
        const { staticValues } = this.props;
        this.setState({ staticValueId: staticValues[currentRowsSelected[0].dataIndex].id })
    };

    openAddStaticValueModal = () => this.setState({ isVisibleStaticValueModal: true, modalType: coreConstants.MODAL_TYPE.create, staticValueId: '' });

    openEditStaticValueModal = () => this.setState({ isVisibleStaticValueModal: true, modalType: coreConstants.MODAL_TYPE.edit });

    onCloseStaticValueModal = () => this.setState({ isVisibleStaticValueModal: false });

    openConfirmationModal = () => this.setState({ isVisibleDeleteModal: true });

    onCloseConfirmationModal = () => this.setState({ isVisibleDeleteModal: false });

    deleteStaticValue = () => this.props.deleteStaticValue(this.state.staticValueId);

    changePage = (e, page) => this.props.getStaticValues(page + 1, this.state.searchText);

    onSearch = searchText => this.props.onSearch(1, searchText);

    render() {
        const { staticValues, isStaticValuesLoading, i18n, isDeletingStaticValue, pagination } = this.props;
        const { isVisibleStaticValueModal, isVisibleDeleteModal, modalType, staticValueId } = this.state;
        return (
            <div>
                <Core.Table
                    title={i18n.t('settings.staticValuesTable.title')}
                    options={this.options}
                    columns={this.tableColumns}
                    data={staticValues}
                    isLoading={isStaticValuesLoading || isDeletingStaticValue}
                    changePage={this.changePage}
                    pagination={pagination}
                    actionConfig={this.tableActions}
                />

                {isVisibleStaticValueModal ? (
                    <StaticValueModal
                        isVisible={isVisibleStaticValueModal}
                        onClose={this.onCloseStaticValueModal}
                        id={staticValueId}
                        modalType={modalType}
                    />
                ): null
                }
                {isVisibleDeleteModal ? (
                    <Core.ConfirmationModal
                        isVisible={isVisibleDeleteModal}
                        onClose={this.onCloseConfirmationModal}
                        onConfirm={this.deleteStaticValue}
                        isLoading={isDeletingStaticValue}
                    />
                ): null
                }
            </div>
        );
    }
}

export default withStaticValueTableBase(StaticValueTable);
