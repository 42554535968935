import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Settings from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from "classnames";
import ViewColumn from "@material-ui/icons/ViewColumn";
import withDetailsRowsBase, { COLUMNS } from './DetailsRows.base';
import { components as Core, constants as coreConstants } from '../../../../core';
import { colors, hexToRGB, spacing } from "../../../../../styles";
import CostModal from "./CostModal";
import { ROUND_VALUES } from "../../../constants";

const sortOptions = {
    sort: false
};

const ACTION_FOR_ALL_ROW = (i18n, onClick) => [
    {
        label: i18n.t("table.edit"),
        onClick,
        icon: <Settings />
    }
];

const ACTION_FOR_MORE_THEN_ONE_ROW = (i18n, onClick) => [
    {
        label: i18n.t("table.delete"),
        onClick,
        icon: <Delete />
    }
];

class DetailsRows extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]).isRequired,
        costs: PropTypes.array,
        itemId: PropTypes.string.isRequired,
        tableColumns: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        isLoadingCosts: PropTypes.bool,
        isUpdating: PropTypes.bool,
        deleteCostFromPostalService: PropTypes.func.isRequired,
        getCustomFields: PropTypes.func.isRequired,
        compositeCostTypes: PropTypes.array
    };

    static defaultProps = {
        costs: [],
        isLoadingCosts: false,
        isUpdating: false,
        compositeCostTypes: []
    };

    constructor(props) {
        super(props);

        this.state = {
            isVisibleCostModal: false,
            modalType: coreConstants.MODAL_TYPE.create,
            isVisibleDeleteModal: false,
            cost: {},
        };

        const { tableColumns } = this.props;

        this.tableColumns = [
            { ...tableColumns[COLUMNS.zone], options: sortOptions },
            { ...tableColumns[COLUMNS.cost], options: sortOptions },
            { ...tableColumns[COLUMNS.defaultCost],
                options: {
                    customHeadRender: this.renderHeader,
                    customBodyRender: this.renderBody,
                    ...sortOptions
                }
            },
        ];
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isUpdating && !this.props.isUpdating) {
            this.onCloseConfirmationModal();
        }
    }

    get options() {
        return {
            selectableRows: "single",
            responsive: 'scroll',
            rowHover: false,
            filter: false,
            print: false,
            viewColumns: false,
            pagination: false,
            search: false,
            download: false,
            customToolbar: this.customToolbar,
            customToolbarSelect: this.customActionsToolbar,
            setRowProps: this.setRowProps,
            onRowsSelect: this.onRowsSelect,
        };
    }

    get tableActions() {
        const { i18n } = this.props;
        return (this.props.costs.length > 1 ?
            [
                ...ACTION_FOR_ALL_ROW(i18n, this.openEditCostModal),
                ...ACTION_FOR_MORE_THEN_ONE_ROW(i18n, this.openConfirmationModal),
            ] :
            ACTION_FOR_ALL_ROW(i18n, this.openEditCostModal))
    }

    renderHeader = columnMeta => {
        const { classes, getCustomFields, i18n } = this.props;
        return (
            <Core.TableCell key={columnMeta.label} className={classes.tableCustomCol}>
                <Core.Grid
                    container={true}
                    spacing={0}
                    className={classes.customColContainer}>
                    <Core.Grid
                        item={true}
                        xs={12}>
                        <span>{i18n.t("settings.postalServiceModal.defaultCost")}</span>
                    </Core.Grid>
                    {_.map(getCustomFields(columnMeta.name, columnMeta.label), this.tableCustomTitle)}
                </Core.Grid>
            </Core.TableCell>
        );
    };

    tableCustomTitle = item => {
        const { classes } = this.props;
        return (
            <Core.Grid
                item={true}
                xs={4}
                key={item.label}>
                <Core.Divider light={true} className={classes.divider} />
                <p className={classNames(classes.tabCustomField, classes.textOverflow)}>{item.label}</p>
            </Core.Grid>
        )
    };

    renderBody = (value, data) => (
        <Core.Grid
            container={true}
            spacing={0}
            key={data.tableData.name}
            className={this.props.classes.customColContainer}>
            {_.map(value, this.tableCustomBody)}
        </Core.Grid>
    );

    tableCustomBody = (item, id) => (
        <Core.Grid
            item={true}
            key={id}
            xs={4}
            className={this.props.classes.includedItem}>
            {COLUMNS.surcharge === id ? `${_.round(item * 100, ROUND_VALUES)} %` : item}
        </Core.Grid>
    );

    customToolbar = () => {
        const { i18n } = this.props;
        return ([
            <Core.TooltipButton key="addCost" onClick={this.openAddCostModal} icon={<AddIcon />} title={i18n.t("settings.postalServiceTable.addCost")} />,
        ])
    };

    onRowsSelect = currentRowsSelected => {
        const { costs } = this.props;
        this.setState({ cost: costs[currentRowsSelected[0].dataIndex] });
    };

    openAddCostModal = () => this.setState({ isVisibleCostModal: true, modalType: coreConstants.MODAL_TYPE.create, cost: {} });

    openEditCostModal = () => this.setState({ isVisibleCostModal: true, modalType: coreConstants.MODAL_TYPE.edit });

    onCloseCostModal = () => this.setState({ isVisibleCostModal: false });

    customToolbarSelected = item => (
        <Core.TooltipButton
            key={item.label}
            onClick={item.onClick}
            icon={item.icon}
            title={item.label}
        />
    );

    customActionsToolbar = () => (<div>{_.map(this.tableActions, this.customToolbarSelected)}</div>);

    openConfirmationModal = () => this.setState({ isVisibleDeleteModal: true });

    onCloseConfirmationModal = () => this.setState({ isVisibleDeleteModal: false });

    deletePostalService = () => this.props.deleteCostFromPostalService(this.state.cost.id);

    render() {
        const { classes, costs, isLoadingCosts, itemId, isUpdating } = this.props;
        const { isVisibleCostModal, isVisibleDeleteModal, modalType, cost } = this.state;

        return (
            <Core.TableRow>
                <Core.TableCell colSpan={24} className={classes.tableCell}>
                    <Core.Table
                        options={this.options}
                        columns={this.tableColumns}
                        data={costs}
                        isLoading={isLoadingCosts || isUpdating}
                    />
                    {isVisibleCostModal ? (
                        <CostModal
                            isVisible={isVisibleCostModal}
                            onClose={this.onCloseCostModal}
                            item={cost}
                            modalType={modalType}
                            postalServiceId={itemId}
                        />
                    ): null
                    }
                    {isVisibleDeleteModal ? (
                        <Core.ConfirmationModal
                            isVisible={isVisibleDeleteModal}
                            onClose={this.onCloseConfirmationModal}
                            onConfirm={this.deletePostalService}
                            isLoading={isUpdating}
                        />
                    ): null
                    }
                </Core.TableCell>
            </Core.TableRow>
        );
    }
}

const CENTRAL_SPACING_FIELD = 2;
const TABLE_FONT_SIZE = 0.75;
const MIN_WIDTH_CUSTOM_ROW = 350;


const styles = () => ({
    tableCell: {
        borderBottom: 0,
        paddingBottom: spacing.s2,
    },
    divider: {
        flex: 1,
        marginTop: spacing.s3
    },
    centerDivider: {
        marginTop: CENTRAL_SPACING_FIELD
    },
    tabCustomField: {
        margin: spacing.s1,
    },
    customColContainer: {
        display: "flex",
        justifyContent: "space-around;",
    },
    includedItem: {
        border: 0,
        display: "flex",
        justifyContent: "center",
    },
    textOverflow: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    tableCustomCol: {
        backgroundColor: colors.white,
        color: `rgba(${hexToRGB(colors.black)}, 0.54)`,
        fontSize: `${TABLE_FONT_SIZE}rem`,
        fontWeight: 500,
        textAlign: "center",
        minWidth: MIN_WIDTH_CUSTOM_ROW
    }
});

DetailsRows.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withDetailsRowsBase(DetailsRows));
