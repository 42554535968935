import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import withStyles from '@material-ui/core/styles/withStyles';
import Settings from '@material-ui/icons/Settings';
import Add from '@material-ui/icons/Add'
import Sync from '@material-ui/icons/Sync';
import Close from '@material-ui/icons/Close';
import withLinnworksBase from './Linnworks.base';
import { components as Core } from '../../../core';
import { fonts, spacing, colors } from '../../../../styles';
import Form from './Form';

const CUSTOM_TABLE_STYLES = {
    MuiTableCell: {
        paddingCheckbox: {
            padding: 0
        },
        root: {
            textAlign: 'center',
        },
        body: {
            ...fonts.mdNormal
        }
    }
};

class Linnworks extends React.Component {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        account: PropTypes.object.isRequired,
        onChangeFormState: PropTypes.func.isRequired,
        onRevokeToken: PropTypes.func.isRequired,
        data: PropTypes.array.isRequired,
        isLoading: PropTypes.bool,
        isSyncing: PropTypes.bool.isRequired,
        visibleForm: PropTypes.bool,
        popConfirmVisible: PropTypes.bool,
        actions: PropTypes.object.isRequired,
        closePopConfirm: PropTypes.func.isRequired,
        isKeyExist: PropTypes.bool,
    };

    static defaultProps = {
        isLoading: false,
        visibleForm: false,
        popConfirmVisible: false,
        isKeyExist: false,
    };
    get columns() {
        if (this.props.isKeyExist) {
            return [{
                name: 'linnworksUsername',
                label: this.props.i18n.t('linnworks.table.username'),
            }, {
                name: 'linnworksKey',
                label: this.props.i18n.t('linnworks.table.key'),
            }, {
                name: 'lastSyncDate',
                label: this.props.i18n.t('linnworks.table.lastSync')
            }, {
                name: 'totalItems',
                label: this.props.i18n.t('linnworks.table.totalItems')
            }]
        }

        return [{
            name: 'linnworksKey',
            options: {
                customHeadRender: this.getEmptyTableCell
            }
        }]
    }

    get options() {
        return {
            selectableRows: true,
            responsive: 'scroll',
            rowHover: false,
            onRowsSelect: this.onRowsSelect,
            filter: false,
            print: false,
            sort: false,
            viewColumns: false,
            download: false,
            serverSide: true,
            expandableRows: false,
            search: false,
            customToolbar: this.customToolbar,
            customFooter: this.getEmptyTableCell,
            customToolbarSelect: this.customToolbarSelected,
        };
    }

    get spinTitle() {
        const { classes, i18n } = this.props;
        return (
            <div className={classes.spinText}>{i18n.t('linnworks.isSyncing')}</div>
        )
    }

    getEmptyTableCell = () => (<Core.TableCell className={this.props.classes.tableFooter} />);

    customToolbar = () => {
        const { i18n, classes, onChangeFormState, account } = this.props;
        return !account.linnworksKey ? (
            <Tooltip title={i18n.t("linnworks.set")}>
                <Core.IconButton
                    className={classes.actionButton}
                    onClick={onChangeFormState}
                    aria-label={i18n.t("linnworks.set")}>
                    <Add />
                </Core.IconButton>
            </Tooltip>
        ) : (null)
    };

    customToolbarSelected = () => {
        const { i18n, classes, account, onChangeFormState, closePopConfirm, actions } = this.props;
        return account.linnworksKey ? (
            <div>
                <Tooltip title={i18n.t("linnworks.refresh")}>
                    <Core.IconButton
                        className={classes.actionButton}
                        onClick={actions.syncLinnworks}
                        aria-label={i18n.t("linnworks.refresh")}>
                        <Sync />
                    </Core.IconButton>
                </Tooltip>
                <Tooltip title={i18n.t("linnworks.details")}>
                    <Core.IconButton
                        className={classes.actionButton}
                        onClick={onChangeFormState}
                        aria-label={i18n.t("linnworks.details")}>
                        <Settings />
                    </Core.IconButton>
                </Tooltip>
                <Tooltip title={i18n.t("linnworks.revoke")}>
                    <Core.IconButton
                        className={classes.actionButton}
                        onClick={closePopConfirm}
                        aria-label={i18n.t("linnworks.revoke")}>
                        <Close />
                    </Core.IconButton>
                </Tooltip>
            </div>
        ) : (null)
    };

    render() {
        const { i18n, isLoading, data, onChangeFormState, visibleForm, popConfirmVisible, closePopConfirm, onRevokeToken, isSyncing, account, isKeyExist } = this.props;
        return (
            <div>
                <Core.Spin
                    text={account.isSyncing ? this.spinTitle : null}
                    spinning={isLoading || isSyncing || account.isSyncing}>
                    <Core.Table
                        title={i18n.t('linnworks.accounts')}
                        options={this.options}
                        columns={this.columns}
                        data={data}
                        overrides={!isKeyExist ? CUSTOM_TABLE_STYLES : null}
                    />
                </Core.Spin>
                {visibleForm ? (
                    <Form
                        visibility={visibleForm}
                        onClose={onChangeFormState}
                    />
                ) : (null)}
                {popConfirmVisible ? (
                    <Core.ConfirmationModal
                        descriptions={i18n.t('linnworks.revokeConfirm.title')}
                        confirmTextPath="linnworks.revokeConfirm.yes"
                        cancelTextPath="linnworks.revokeConfirm.no"
                        isVisible={popConfirmVisible}
                        onClose={closePopConfirm}
                        onConfirm={onRevokeToken}
                    />
                ) : (null)}
            </div>
        );
    }
}
const styles = () => ({
    actionButton: {
        borderRadius: 0,
    },
    container: {
        padding: `${spacing.s3}px`,
    },
    spin: {
        width: '100%',
        overflowX: 'auto',
    },
    tableFooter: {
        padding: spacing.s2,
    },
    spinText: {
        color: colors.blue.primary,
    }
});


export default withLinnworksBase(withStyles(styles)(Linnworks));
