import { NAME } from './constants';
import { selectors as coreSelectors, constants as coreConstants } from "../core";
import * as actionTypes from "./actionTypes";

export const getAccountProfile = state => state[NAME].account;
export const isAccount = state => coreSelectors.isLoading(state, actionTypes.GET_ACCOUNT.NAME);
export const isAccountUpdate = state => coreSelectors.isLoading(state, actionTypes.UPDATE_ACCOUNT.NAME);
export const isPasswordUpdating = state => coreSelectors.isLoading(state, actionTypes.CHANGE_PASSWORD.NAME);

export const isAdmin = state => state[NAME].account.role === coreConstants.ROLE.admin;
export const isCompanyCreator = state => state[NAME].account.role === coreConstants.ROLE.companyCreator;
export const accountRole = state => state[NAME].account.role;

export const getAccountPermissions = state => state[NAME].account.permissions;
export const getAccountSubscription = state => state[NAME].account.subscription;
export const subscriprionItemsLimit = state => state[NAME].account.itemLimit;

export const isOwner = state => getAccountSubscription(state) === coreConstants.SUBSCRIPTION_TYPES.owner.value;
