import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import * as actions from "../../../actions";
import { constants as coreConstants, translate } from '../../../../core';
import * as selectors from "../../../selectors";
import {
    CUSTOM_SELECT,
    MEASURES,
    COMPOSITE_COST_TYPE_FIELDS,
} from "../../../constants";

const DEFAULT_VALUES = {
    [COMPOSITE_COST_TYPE_FIELDS.type]: "",
    [COMPOSITE_COST_TYPE_FIELDS.value]: "",
};

export default function withCompositeCostTypeBase(WrappedComponent) {
    class CompositeCostType extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            compositeCostTypes: PropTypes.array,
            postalServiceId: PropTypes.string,
            costs: PropTypes.object.isRequired,
            onAddCost: PropTypes.func.isRequired,
            onChangeValue: PropTypes.func.isRequired,
        };

        static defaultProps = {
            compositeCostTypes: [],
            postalServiceId: null,
        };

        constructor(props) {
            super(props);

            const { i18n } = this.props;

            this.state = {
                fields: DEFAULT_VALUES,
            };

            this.fields = [
                {
                    name: COMPOSITE_COST_TYPE_FIELDS.value,
                    placeholder: i18n.t('settings.postalServiceTable.compositeCostType.value'),
                    type: coreConstants.INPUT_TYPES.number,
                    breakpoint: { sm: 7 }
                },
                {
                    name: COMPOSITE_COST_TYPE_FIELDS.type,
                    placeholder: i18n.t('settings.postalServiceTable.compositeCostType.measure'),
                    isRequired: true,
                    type: CUSTOM_SELECT,
                    options: MEASURES,
                    breakpoint: { sm: 3 }
                }
            ];
        }

        get isDisabledAddBtn() {
            const { value, type } = this.state.fields;
            const some = _.find(this.props.costs.conditionalCompositeCosts, i => i.value === value && i.type.id === type.id);
            return !value || !type || !!some;
        }

        onChangeValue = (val, field) => {
            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    [field.name]: field.name === 'type' ? _.find(MEASURES, i => i.id === val) : val,
                }
            }))
        };

        onAddCostVal = () => {
            this.props.onAddCost(this.state.fields);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    fields={this.fields}
                    values={this.state.fields}
                    onChangeValue={this.onChangeValue}
                    onChangeInnerValue={this.props.onChangeValue}
                    onAddCostVal={this.onAddCostVal}
                    isDisabledAddBtn={this.isDisabledAddBtn}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            compositeCostTypes: ownProps.postalServiceId ? selectors.getCompositeCostTypes(state, ownProps.postalServiceId) : []
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CompositeCostType));
}
