import * as _ from "lodash";

const isValidRequiredFields = (fields, values) => {
    let validated = true;
    _.forEach(fields, field => {
        if (field.isRequired && !values[field.name]) {
            validated = false;
        }
    });
    return validated;
};

const isValidEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export default { isValidRequiredFields, isValidEmail };
