import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import { reducer as coreReducer, constants as coreConstants } from '../modules/core';
import { reducer as inventoryReducer, constants as inventoryConstants } from '../modules/inventory';
import { reducer as authReducer, constants as authConstants } from '../modules/auth';
import { reducer as usersReducer, constants as usersConstants } from '../modules/users';
import { reducer as accountReducer, constants as accountConstants } from '../modules/account';
import { reducer as linnworksReducer, constants as linnworksConstants } from '../modules/linnworks';
import { reducer as settingsReducer, constants as settingsConstants } from '../modules/settings';
import { reducer as reportsReducer, constants as reportsConstants } from '../modules/reports';

const ROUTER_NAME = 'router';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        ROUTER_NAME,
        coreConstants.NAME,
        inventoryConstants.NAME,
        usersConstants.NAME,
        authConstants.NAME,
        accountConstants.NAME,
        linnworksConstants.NAME,
        settingsConstants.NAME,
        reportsConstants.NAME,
    ]
};

const rootReducer = combineReducers({
    [ROUTER_NAME]: routerReducer,
    [coreConstants.NAME]: coreReducer,
    [inventoryConstants.NAME]: inventoryReducer,
    [authConstants.NAME]: authReducer,
    [usersConstants.NAME]: usersReducer,
    [settingsConstants.NAME]: settingsReducer,
    [accountConstants.NAME]: accountReducer,
    [accountConstants.NAME]: accountReducer,
    [linnworksConstants.NAME]: linnworksReducer,
    [reportsConstants.NAME]: reportsReducer,
});

if (module.hot) {
    module.hot.accept(() => {
        // This fetch the new state of the above reducers.
        storage.replaceReducer(
            persistReducer(persistConfig, rootReducer)
        )
    })
}

export default rootReducer;
