import React from 'react';
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

class AlertDialog extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        open: PropTypes.bool,
        onClose: PropTypes.func,
        agreeButtonText: PropTypes.string,
        disagreeButtonText: PropTypes.string,
        onClickAgreeButton: PropTypes.func.isRequired,
        onClickDisagreeButton: PropTypes.func.isRequired,
    };

    static defaultProps = {
        open: true,
        onClose: undefined,
        agreeButtonText: 'Agree',
        disagreeButtonText: 'Disagree',
    };

    render() {
        const { open, title, onClose, agreeButtonText, disagreeButtonText, onClickAgreeButton, onClickDisagreeButton } = this.props;
        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogActions>
                    <Button onClick={onClickAgreeButton} color="primary" autoFocus={true}>
                        {agreeButtonText}
                    </Button>
                    <Button onClick={onClickDisagreeButton} color="primary">
                        {disagreeButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default AlertDialog;