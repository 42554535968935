import React from 'react';
import Storage from "@material-ui/icons/Storage";
import People from "@material-ui/icons/People";
import Apps from "@material-ui/icons/Apps";
import CompareArrows from "@material-ui/icons/CompareArrows";
import SettingsApplications from "@material-ui/icons/SettingsApplications";
import FileCopy from "@material-ui/icons/FileCopy";

import _ from "lodash";
import { constants as coreConstants, permissionHelper } from "../core";

export const NAME = "share";

const PERMISSION_TYPE = coreConstants.PERMISSION_TYPE;
const PERMISSION_VALUE = coreConstants.PERMISSION_VALUE;

export const loggedOutRoutes = [
    {
        route: coreConstants.ROUTES.signIn(),
        component: React.lazy(() => import('../auth/components/SignIn'))
    },
    {
        route: coreConstants.ROUTES.root(),
        component: React.lazy(() => import('../linnworks/components/Verify')),
    },
    {
        route: coreConstants.ROUTES.signUp(),
        component: React.lazy(() => import('../auth/components/SignUp'))
    },
    {
        route: coreConstants.ROUTES.verify(),
        component: React.lazy(() => import('../auth/components/Verify'))
    },
    {
        route: coreConstants.ROUTES.resetPassword(),
        component: React.lazy(() => import('../auth/components/ResetPassword'))
    },
    {
        route: coreConstants.ROUTES.resetPasswordToken(),
        component: React.lazy(() => import('../auth/components/ResetPasswordToken'))
    },
];

const generalRoutes = [
    {
        route: coreConstants.ROUTES.resetPassword(),
        permissions: [],
        isVisible: false,
        component: React.lazy(() => import('../auth/components/ResetPassword')),
        order: 0,
    },
    {
        route: coreConstants.ROUTES.root(),
        permissions: [],
        isVisible: false,
        component: React.lazy(() => import('../linnworks/components/Verify')),
        order: 0,
    },
    {
        route: coreConstants.ROUTES.inventory(),
        name: "sidebar.links.inventory", //i18n translate
        icon: <Storage />,
        permissions: [
            {
                type: PERMISSION_TYPE.inventoryItems,
                value: PERMISSION_VALUE.view
            }, {
                type: PERMISSION_TYPE.inventoryItems,
                value: PERMISSION_VALUE.edit
            }
        ],
        module: coreConstants.PERMISSION_TYPE.inventoryItems,
        component: React.lazy(() => import('../inventory/components/Inventory')),
        order: 1,
    },
    {
        route: coreConstants.ROUTES.settings(),
        name: "sidebar.links.settings", //i18n translate
        icon: <SettingsApplications />,
        module: coreConstants.PERMISSION_TYPE.settings,
        permissions: [
            {
                type: PERMISSION_TYPE.settings,
                value: PERMISSION_VALUE.view
            }, {
                type: PERMISSION_TYPE.settings,
                value: PERMISSION_VALUE.edit
            }, {
                type: PERMISSION_TYPE.settings,
                value: PERMISSION_VALUE.importAndExport
            }
        ],
        component: React.lazy(() => import('../settings/components/Settings')),
        order: 2,
    },
    {
        route: coreConstants.ROUTES.calculator(),
        name: "sidebar.links.calculator", //i18n translate
        icon: <Apps />,
        module: coreConstants.PERMISSION_TYPE.calculator,
        permissions: [
            {
                type: PERMISSION_TYPE.calculator,
                value: PERMISSION_VALUE.view
            }
        ],
        component: React.lazy(() => import('../inventory/components/Calculator')),
        order: 5,
    },
    {
        route: coreConstants.ROUTES.reports(),
        name: "sidebar.links.reports", //i18n translate
        module: coreConstants.PERMISSION_TYPE.profitReports,
        icon: <FileCopy />,
        permissions: [
            {
                type: PERMISSION_TYPE.profitReports,
                value: PERMISSION_VALUE.view
            }
        ],
        component: React.lazy(() => import('../reports/components/Reports')),
        order: 6,
    },
    {
        route: coreConstants.ROUTES.linnworks(),
        name: "sidebar.links.linnworks", //i18n translate
        icon: <CompareArrows />,
        permissions: [
            {
                type: PERMISSION_TYPE.account,
                value: PERMISSION_VALUE.view
            }
        ],
        component: React.lazy(() => import('../linnworks/components/Linnworks')),
        order: 4,
    },
    {
        route: coreConstants.ROUTES.root(),
        permissions: [],
        isVisible: false,
        component: React.lazy(() => import('../linnworks/components/Verify')),
        order: 0,
    },
    {
        route: coreConstants.ROUTES.users(),
        name: "sidebar.links.users", //i18n translate
        icon: <People/>,
        permissions: [
            {
                type: PERMISSION_TYPE.users,
                value: PERMISSION_VALUE.view
            }, {
                type: PERMISSION_TYPE.users,
                value: PERMISSION_VALUE.edit
            }
        ],
        component: React.lazy(() => import('../users/components/Users')),
        order: 3,
    },
];

const adminRoutes = [
    
];

export const ALLOWED_ROUTES = (role, permissions = []) => {
    switch (role) {
        case coreConstants.ROLE.companyUser:
            return _.filter(generalRoutes, item => permissionHelper.hasPermissions(permissions, item));
        case coreConstants.ROLE.admin:
        case coreConstants.ROLE.companyCreator:
            return [
                ...generalRoutes,
                ...adminRoutes
            ];
        default: return loggedOutRoutes;
    }
};


export const DRAWER_WIDTH = 260;

export const APPBAR_HEIGHT = 50;
