import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import withPermissionModalBase from "./PermissionModal.base";
import { components as Core, withStyles, constants as coreConstants } from "../../../core";
import { colors, fonts, spacing } from "../../../../styles";
import { hasAllPermissions } from "../../services";
import { PERMISSION_ITEMS as permissionItems } from "../../constants"

class PermissionModal extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        onChangePermission: PropTypes.func.isRequired,
        onChangeRole: PropTypes.func.isRequired,
        updateUser: PropTypes.func.isRequired,
        role: PropTypes.number.isRequired,
        isUpdatingUser: PropTypes.bool,
        updateUserPermissionsError: PropTypes.array,
        permissions: PropTypes.isRequired
    };

    static defaultProps = {
        isUpdatingUser: false,
        updateUserPermissionsError: undefined,
    };

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isUpdatingUser && !this.props.isUpdatingUser && !this.props.updateUserPermissionsError) {
            this.props.onClose();
        }
    }

    get permissionFields() {
        const { i18n, classes, onChangeRole, role } = this.props;
        return (
            <Core.Grid container={true} spacing={24} className={classes.gridContainer}>

                {_.map(permissionItems, this.renderItem)}

                <Core.Grid container={true} spacing={0} className={classes.gridContainer}>
                    <Core.Divider light={true} className={classes.divider} />
                </Core.Grid>

                <Core.Grid container={true} spacing={0} className={classes.gridContainer}>
                    <Core.Grid item={true} xs={4}>
                        {i18n.t('users.permissionModal.isAdmin')}
                    </Core.Grid>
                    <Core.Grid item={true} xs={4} className={classes.gridItemContainer}>
                        <Core.Checkbox onChange={onChangeRole} checked={role === coreConstants.ROLE.admin} />
                    </Core.Grid>
                </Core.Grid>
            </Core.Grid>
        );
    }


    renderItem = item => {
        const { classes, permissions, role } = this.props;
        const checked = hasAllPermissions(permissions, item);
        const onChangePermission = (e, val) => this.props.onChangePermission(item.permissions, val);

        return (
            <Core.Grid key={item.id} container={true} spacing={0} className={classes.gridContainer}>
                <Core.Grid item={true} xs={4}>
                    {item.modalTranslation}
                </Core.Grid>
                <Core.Grid item={true} xs={4} className={classes.gridItemContainer}>
                    <Core.Checkbox
                        disabled={role === coreConstants.ROLE.admin}
                        onChange={onChangePermission}
                        checked={checked}
                    />
                </Core.Grid>
            </Core.Grid>
        );
    };

    render() {
        const { i18n, isVisible, onClose, classes, updateUser } = this.props;
        return (
            <Core.Modal
                visible={isVisible}
                title={i18n.t('users.permissionModal.title')}
                isOkButtonLoading={true}
                onClose={onClose}
                onAccept={updateUser}
                acceptButtonTitle={i18n.t("users.permissionModal.buttonOk")}
                className={classes.modalContainer}>
                <div className={classes.container}>
                    {this.permissionFields}
                </div>
            </Core.Modal>
        )
    }
}

const MODAL_WIDTH = 430;

const styles = theme => ({
    container: {
        color: colors.background,
        ...fonts.smNormal
    },
    gridContainer: {
        alignItems: "center",
    },
    gridItemContainer: {
        justifyContent: "center",
        display: "flex",
    },
    modalContainer: {
        width: MODAL_WIDTH
    },
    divider: {
        flex: 1,
        backgroundColor: colors.gray.darkGray,
        marginTop: spacing.s3
    }
});

export default withPermissionModalBase(withStyles(styles)(PermissionModal));
