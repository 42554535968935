import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import withDetailsBase from './Details.base';
import { components as Core, constants as coreConstants } from '../../../../core';
import { fonts, colors, spacing } from "../../../../../styles";

const DEFAULT_CHAR = '-';
const ROUND = 2;
const { INPUT_ADORNMENT } = coreConstants;

class Details extends React.PureComponent {
    static propTypes = {
        shops: PropTypes.array,
        i18n: PropTypes.object.isRequired,
        onChangeMarketsTable: PropTypes.func,
        editable: PropTypes.bool.isRequired,
        isItemLoading: PropTypes.bool.isRequired,
        canEdit: PropTypes.bool,
        isPercentage: PropTypes.bool,
    };

    static defaultProps = {
        shops: [],
        onChangeMarketsTable: undefined,
        canEdit: false,
        isPercentage: false,
    };

    get shopHeader() {
        return _.map(this.props.shops, this.getShopHeader)
    }

    get shopTitle() {
        return _.map(this.props.shops, this.getShopTitle);
    }

    get shopRowMin() {
        return _.map(this.props.shops, this.getRowMin)
    }

    get shopRowCurrent() {
        return _.map(this.props.shops, this.getRowCurrent)
    }

    get shopRowMax() {
        const { classes, shops, onChangeMarketsTable, editable, canEdit, isPercentage } = this.props;

        return _.map(shops, (item, index) => ([
            <td key={`${index}-${item.marketplace}-profit-max`}>
                <div className={classes.valuesText}>
                    {editable && !isPercentage ? (
                        <Core.Input
                            name={item.marketplace}
                            className={classes.customTableInput}
                            onChange={event => onChangeMarketsTable(item.id, 'max', event.target.value)}
                            disabled={!canEdit}
                            value={_.round(item.max, ROUND)}
                            type="number"
                            endAdornment={null}
                        />
                    ) : (<span>{_.round(item.max, ROUND)}</span>)}
                </div>
            </td>,
            <td key={`${index}-${item.marketplace}-calc-max`}>
                <div className={classes.valuesText}>
                    <span>{_.round(item.maxCalculated, ROUND) || DEFAULT_CHAR}</span>
                </div>

            </td>,
            <td key={`${index}-${item.marketplace}-margin-max`}>
                <div className={classes.valuesText}>
                    {editable && isPercentage ? (
                        <Core.Input
                            name={item.marketplace}
                            className={classes.customTableInput}
                            onChange={event => onChangeMarketsTable(item.id, 'maxMargin', event.target.value)}
                            disabled={!canEdit}
                            defaultValue={_.round(item.maxMargin, ROUND)}
                            value={_.round(item.maxMargin, ROUND)}
                            type="number"
                            endAdornment={<Core.InputAdornment position="end">{INPUT_ADORNMENT.percentage}</Core.InputAdornment>}
                        />
                    ) : (<span>{`${_.round(item.maxMargin, ROUND)}%`}</span>)}
                </div>
            </td>
        ]))
    }

    getShopHeader = (item, index) => [
        <th key={`${index}-${item.marketplace}`} colSpan={index ? "3" : "4"}>{`${item.marketplace} (${_.get(item, 'currency.name', item.currency)})`}</th>,
    ];

    getShopTitle = (item, index) => ([
        <td key={`${index}-profit`}><div><span>{this.props.i18n.t('inventory.form.profit')}</span></div></td>,
        <td key={`${index}-calc`}><div><span>{this.props.i18n.t('inventory.form.calculated')}</span></div></td>,
        <td key={`${index}-margin`}><div><span>{this.props.i18n.t('inventory.form.margin')}</span></div></td>
    ]);

    getRowMin = (item, index) => {
        const { classes, onChangeMarketsTable, editable, canEdit, isPercentage } = this.props;
        const value = _.round(item.min, ROUND);
        return [
            <td key={`${index}-${item.marketplace}-profit-min`}>
                <div className={classes.valuesText}>
                    {editable && !isPercentage ? (
                        <Core.Input
                            name={item.marketplace}
                            className={classes.customTableInput}
                            onChange={event => onChangeMarketsTable(item.id, 'min', event.target.value)}
                            value={value}
                            disabled={!canEdit}
                            type="number"
                            endAdornment={isPercentage ? <Core.InputAdornment position="end">{INPUT_ADORNMENT.percentage}</Core.InputAdornment>: null}
                        />
                    ) : (<span>{value}</span>)}
                </div>
            </td>,
            <td key={`${index}-${item.marketplace}-calc-min`}>
                <div className={classes.valuesText}>
                    <span>{_.round(item.minCalculated, ROUND) || DEFAULT_CHAR}</span>
                </div>

            </td>,
            <td key={`${index}-${item.marketplace}-margin-min`}>
                <div className={classes.valuesText}>
                    {editable && isPercentage ? (
                        <Core.Input
                            name={item.marketplace}
                            className={classes.customTableInput}
                            onChange={event => onChangeMarketsTable(item.id, 'minMargin', event.target.value)}
                            value={_.round(item.minMargin, ROUND)}
                            defaultValue={_.round(item.minMargin, ROUND)}
                            disabled={!canEdit}
                            type="number"
                            endAdornment={<Core.InputAdornment position="end">{INPUT_ADORNMENT.percentage}</Core.InputAdornment>}
                        />
                    ) : (<span>{`${_.round(item.minMargin, ROUND)}%`}</span>)}
                </div>
            </td>
        ]
    };

    getRowCurrent = (item, index) => {
        const { classes, onChangeMarketsTable, editable, canEdit, isPercentage } = this.props;
        return [
            <td key={`${index}-${item.marketplace}-profit-current`}>
                <div className={classes.valuesText}>
                    <span>{_.round(item.current, ROUND)}</span>
                </div>
            </td>,
            <td key={`${index}-${item.marketplace}-calc-current`}>
                <div className={classes.valuesText}>
                    {editable && !isPercentage ? (
                        <Core.Input
                            name={item.marketplace}
                            className={classes.customTableInput}
                            onChange={event => onChangeMarketsTable(item.id, 'currentCalculated', event.target.value)}
                            value={_.round(item.currentCalculated, ROUND)}
                            defaultValue={_.round(item.currentCalculated, ROUND)}
                            disabled={!canEdit}
                            type="number"
                            endAdornment={null}
                        />
                    ) : (<span>{_.round(item.currentCalculated, ROUND)}</span>)}
                </div>
            </td>,
            <td key={`${index}-${item.marketplace}-margin-current`}>
                <div className={classes.valuesText}>
                    {editable && isPercentage ? (
                        <Core.Input
                            name={item.marketplace}
                            className={classes.customTableInput}
                            onChange={event => onChangeMarketsTable(item.id, 'currentMargin', event.target.value)}
                            value={_.round(item.currentMargin, ROUND)}
                            defaultValue={_.round(item.currentMargin, ROUND)}
                            disabled={!canEdit}
                            type="number"
                            endAdornment={<Core.InputAdornment position="end">{INPUT_ADORNMENT.percentage}</Core.InputAdornment>}
                        />
                    ) : (<span>{`${_.round(item.currentMargin, ROUND)}%`}</span>)}
                </div>
            </td>
        ]
    };


    render() {
        const { i18n, classes, isItemLoading, shops } = this.props;

        return (
            <Core.Spin spinning={isItemLoading} className={classes.spin}>
                {shops.length && !isItemLoading ? (
                    <table
                        className={classes.table}
                        align="center"
                        border="1px"
                        cellPadding="7">
                        <tbody>
                            <tr className={classes.headerText}>
                                {this.shopHeader}
                            </tr>
                            <tr>
                                <th />
                                {this.shopTitle}
                            </tr>
                            <tr>
                                <th>
                                    <div><span>{i18n.t('inventory.form.min')}</span></div>
                                </th>
                                {this.shopRowMin}
                            </tr>
                            <tr>
                                <th>
                                    <div><span>{i18n.t('inventory.form.max')}</span></div>
                                </th>
                                {this.shopRowMax}
                            </tr>
                            <tr>
                                <th>
                                    <div><span>{i18n.t('inventory.form.current')}</span></div>
                                </th>
                                {this.shopRowCurrent}
                            </tr>
                        </tbody>
                    </table>
                ) : (null)}
            </Core.Spin>
        );
    }
}

const styles = () => ({
    table: {
        margin: 0,
        textAlign: 'center',
        verticalAlign: 'middle',
        borderCollapse: 'collapse',
        border: `1px ${colors.gray.lightGray}`,
        color: colors.gray.mediumGray,
    },
    headerText: {
        ...fonts.mdNormal,
        color: colors.gray.mediumGray
    },
    subHeaderText: {
        ...fonts.smBold,
        color: colors.gray.mediumGray
    },
    valuesText: {
        ...fonts.xsNormal,
        color: colors.gray.mediumGray
    },
    tableCell: {
        borderBottom: '0px',
    },
    customTableInput: {
        width: `${spacing.s10 * 2}px`,
        maxWidth: `${spacing.s14 * 2}px`
    },
    spin: {
        minHeight: `${spacing.s14}px`,
        paddingBottom: spacing.s0,
    }
});

Details.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withDetailsBase(Details));
