import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ITEMS_PER_PAGE, FIELDS } from "../../constants";
import * as actions from "../../actions";
import * as selectors from '../../selectors';
import { getPagination, translate } from '../../../core';
import { selectors as accountSelectors } from "../../../account";
import ImageColumn from './ImageColumn';

const REQUEST_TIMEOUT = 1000;

const ID_COLUMN = {
    options: {
        filter: false,
        customBodyRender: value => (<ImageColumn itemId={value} />)
    },
    name: 'id',
    label: 'inventory.details.image',
    order: 0,
};

const SORT_TYPES = {
    ascending: 'asc',
    descending: 'desc',
};

export default function withInventoryBase(WrappedComponent) {
    class InventoryBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            columns: PropTypes.object.isRequired,
            isColumnsLoading: PropTypes.bool,
            isColumnsUpdating: PropTypes.bool,
        };

        static defaultProps = {
            isColumnsLoading: false,
            isColumnsUpdating: false,
        };

        constructor(props) {
            super(props);

            this.state = {
                reqParams: {
                    page: 1,
                    count: ITEMS_PER_PAGE,
                    filterText: '',
                    ordering: null,
                },
                editFormVisible: false,
                createFormVisible: false,
                columnSettingsFormVisible: false,
                tableColumns: props.columns,
                isColumnsLoading: props.isColumnsLoading,
                isColumnsUpdating: props.isColumnsUpdating,
                currentSortableColumn: null,
                currentSortType: null,
            };

            props.actions.getInventory(this.state.reqParams);
            this.debouncedSearch = _.debounce(this.onSearch, REQUEST_TIMEOUT);
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            if (prevState.isColumnsLoading && !nextProps.isColumnsLoading) {
                return {
                    tableColumns: nextProps.columns,
                    isColumnsLoading: nextProps.isColumnsLoading,
                }
            }
            if (prevState.isColumnsUpdating && !nextProps.isColumnsUpdating) {
                return {
                    tableColumns: nextProps.columns,
                    isColumnsUpdating: nextProps.isColumnsUpdating,
                }
            }

            return {
                isColumnsLoading: nextProps.isColumnsLoading,
                isColumnsUpdating: nextProps.isColumnsUpdating,
            }
        }

        getFormattedColumn = item => {
            const { currentSortableColumn, currentSortType } = this.state;

            let options = {
                filter: false,
                display: item.enabled,
            };

            if (currentSortableColumn && currentSortType && item.name === currentSortableColumn) {
                options = {
                    ...options,
                    sortDirection: SORT_TYPES[currentSortType],
                }
            }

            return {
                ...item,
                label: this.props.i18n.t(item.label),
                options
            }
        };

        get formattedColumns() {
            return _.chain(this.state.tableColumns).map(this.getFormattedColumn).orderBy('order', 'asc').value();
        }

        get tableFormattedColumns() {
            const columns = this.formattedColumns;
            return [{
                ...ID_COLUMN,
                label: this.props.i18n.t(ID_COLUMN.label)
            }, ...columns];
        }

        getInventory = () => {
            this.props.actions.getInventory(this.state.reqParams);
        };

        onSort = (changedColumn, direction) => {
            this.setState(prevState => (
                {
                    reqParams: {
                        ...prevState.reqParams,
                        ordering: `${changedColumn}${direction}`
                    },
                    currentSortableColumn: changedColumn,
                    currentSortType: direction,
                }), this.getInventory);
        };

        onFilterCategory = category => {
            this.setState(prevState => ({
                reqParams: {
                    ...prevState.reqParams,
                    category,
                }
            }), this.getInventory)
        };

        onSearch = searchText => {
            this.setState(prevState => ({ reqParams: { ...prevState.reqParams, filterText: searchText } }), this.getInventory);
        };

        onChangePagination = page => {
            this.setState(prevState => ({ reqParams: { ...prevState.reqParams, page } }), this.getInventory);
        };

        onChangeEditForm = () => {
            this.setState(prevState => ({ editFormVisible: !prevState.editFormVisible }))
        };

        onChangeCreateForm = () => {
            this.setState(prevState => ({ createFormVisible: !prevState.createFormVisible }))
        };

        onChangeColumnSettingsForm = () => {
            this.setState(prevState => ({ columnSettingsFormVisible: !prevState.columnSettingsFormVisible }))
        };

        onDelete = id => {
            this.props.actions.deleteItem(id);
        };

        onUploadFile = file => {
            this.props.actions.importFile(file, this.state.reqParams);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    tableColumns={this.formattedColumns}
                    tableFormattedColumns={this.tableFormattedColumns}
                    onSearch={this.debouncedSearch}
                    onSort={this.onSort}
                    editFormVisible={this.state.editFormVisible}
                    onChangeEditForm={this.onChangeEditForm}
                    createFormVisible={this.state.createFormVisible}
                    onChangeCreateForm={this.onChangeCreateForm}
                    onDelete={this.onDelete}
                    columnSettingsFormVisible={this.state.columnSettingsFormVisible}
                    onChangeColumnSettingsForm={this.onChangeColumnSettingsForm}
                    onUploadFile={this.onUploadFile}
                    onChangePagination={this.onChangePagination}
                    onFilterCategory={this.onFilterCategory}
                    reqParams={this.state.reqParams}
                />
            );
        }
    }

    function mapStateToProps(state) {
        const newPagination = getPagination(selectors.getInventoryPagination(state));

        return {
            isInventoryItemsImporting: selectors.isInventoryItemsImporting(state),
            isInventoryItemsExporting: selectors.isInventoryItemsExporting(state),
            isInventoryLoading: selectors.isInventory(state),
            isItemDelete: selectors.isItemDelete(state),
            isColumnsLoading: selectors.isColumnsLoading(state),
            isColumnsUpdating: selectors.isColumnsUpdating(state),
            columns: selectors.getColumns(state),
            items: selectors.getInventoryArray(state),
            permissions: accountSelectors.getAccountPermissions(state),
            isUserAdmin: accountSelectors.isAdmin(state),
            pagination: {
                ...newPagination,
                current: newPagination.pageNumber,
                total: newPagination.totalItemCount,
            }
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(InventoryBase));
}
