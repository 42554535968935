import _ from "lodash";
import { Toast } from "../../core";
import i18n from '../../../i18n/i18n';

const importErrorHandler = (error, name) => {
    const errors = _.get(error, 'response.data.errors');
    if (!errors || !errors.length) {
        Toast.error({
            message: i18n.t('settings.module', { name }),
            description: i18n.t('toast.error.description') });
    } else {
        Toast.errors({
            message: i18n.t('settings.module', { name }),
            errors
        });
    }
};

export default { importErrorHandler };
