import _ from "lodash";
import { NAME } from './constants';
import * as actionTypes from './actionTypes';

import { selectors as coreSelectors } from '../core';
import { constants as accountConstants } from "../account";

export const isAccountLoading = state => coreSelectors.isLoading(state, actionTypes.GET_COMPANY_ACCOUNT.NAME);
export const getAccount = state => state[NAME].account;
export const isTokenUpdating = state => coreSelectors.isLoading(state, actionTypes.SET_TOKEN.NAME);
export const isTokenRevoking = state => coreSelectors.isLoading(state, actionTypes.REVOKE_TOKEN.NAME);
export const getStoredToken = state => state[NAME].storedToken;
export const accountRole = state => state[accountConstants.NAME].account.role;
export const isSyncing = state => coreSelectors.isLoading(state, actionTypes.SYNC_LINNWORKS.NAME);
