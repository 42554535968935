import React from 'react';
import PropTypes from 'prop-types';
import * as _ from "lodash";
import withFeesModalBase from "./FeesModal.base";
import { components as Core, withStyles, constants as coreConstants } from "../../../../../core";
import { colors, fonts } from "../../../../../../styles";
import { ROUND_VALUES, SELECT_FIELDS, MODAL_WIDTH } from "../../../../constants";

class FeesModal extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
        fields: PropTypes.array.isRequired,
        onChangeValue: PropTypes.func.isRequired,
        updateMarketplace: PropTypes.func.isRequired,
        modalType: PropTypes.number.isRequired,
        isCompaniesLoading: PropTypes.bool,
        isUpdating: PropTypes.bool,
        companyPagination: PropTypes.object,
        updateMarketplaceError: PropTypes.array,
    };

    static defaultProps = {
        isUpdating: false,
        isCompaniesLoading: false,
        companyPagination: {},
        updateMarketplaceError: undefined
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isUpdating && !this.props.isUpdating && !this.props.updateMarketplaceError) {
            this.props.onClose();
        }
    }

    getSelectOption = item => ({ value: item.id, label: item.name });

    renderField = field => (
        <Core.Grid item={true} xs={12} sm={6} key={field.name}>
            {field.type !== coreConstants.INPUT_TYPES.select ? (
                <Core.FormInput
                    key={field.name}
                    required={field.isRequired}
                    htmlFor={field.name}
                    autoFocus={field.autoFocus}
                    inputLabel={field.placeholder}
                    onChange={event => this.props.onChangeValue(field.isPercentage ? event.target.value / 100 : event.target.value, field)}
                    defaultValue={_.toString(field.isPercentage ?
                        _.round(_.get(this.props.values, field.name, 0) * 100, ROUND_VALUES)
                        : _.get(this.props.values, field.name, field.type === coreConstants.INPUT_TYPES.number ? 0 : ""))}
                    type={field.type}
                    endAdornment={field.endAdornment}
                />
            ): (
                <Core.SearchableFormSelect
                    key={field.name}
                    inputLabel={field.placeholder}
                    required={field.isRequired}
                    onChange={event => this.props.onChangeValue(event.value, field)}
                    value={_.get(this.props.values, field.name)}
                    onSearch={field.onSearch}
                    isLoading={this.props.isCompaniesLoading}
                    selectName={SELECT_FIELDS.companies}
                    pagination={this.props.companyPagination}
                    options={_.map(this.props[SELECT_FIELDS.companies], this.getSelectOption)}
                />
            )}
        </Core.Grid>

    );

    render() {
        const { i18n, isVisible, onClose, fields, classes, modalType, isUpdating, updateMarketplace } = this.props;
        return (
            <Core.Modal
                visible={isVisible}
                title={modalType === coreConstants.MODAL_TYPE.edit ?
                    i18n.t('settings.marketplaceModal.editFee') :
                    i18n.t('settings.marketplaceModal.addFee')}
                onClose={onClose}
                isLoading={isUpdating}
                onAccept={updateMarketplace}
                className={classes.modalContainer}>
                <div className={classes.container}>
                    <Core.Grid container={true} spacing={24} >
                        {_.map(fields, this.renderField)}
                    </Core.Grid>
                </div>
            </Core.Modal>
        )
    }
}

const styles = theme => ({
    container: {
        color: colors.background,
        flex: 1,
        ...fonts.smNormal
    },
    modalContainer: {
        [theme.breakpoints.up("md")]: {
            width: MODAL_WIDTH.partWidth,
        },
        [theme.breakpoints.down("sm")]: {
            width: `${MODAL_WIDTH.fullWidth}vw`,
        },
    },
});


export default withFeesModalBase(withStyles(styles)(FeesModal));
