import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from "prop-types";
import _ from "lodash";
import Grid from "../Grid";

export default function LongMenu({ onChange, options, selectedKey }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleChange(event) {
        onChange(event.target.id);
        handleClose();
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function getOptions(option) {
        return (
            <MenuItem key={option.label} selected={option.id === selectedKey} id={option.id} onClick={handleChange}>
                {option.label}
            </MenuItem>
        )
    }

    return (
        <>
            <Grid container={true} justify="center">
                <Grid item={true} sm={6} onClick={handleClick}>
                    <span>
                        {_.get(_.find(options, item => item.id === _.toNumber(selectedKey)), "label", "------")}
                    </span>
                </Grid>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted={true}
                    open={open}
                    onClose={handleClose}>
                    { options.length ? _.map(options, getOptions) : "NONE" }
                </Menu>
            </Grid>
        </>
    );
}

LongMenu.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array,
    selectedKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

LongMenu.defaultProps = {
    options: [],
    selectedKey: ""
};