import React from 'react';
import PropTypes from 'prop-types';
import Settings from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import withCompanyTableBase, { COLUMNS } from './CompanyTable.base';
import { components as Core, constants as coreConstants } from "../../../core"
import CompanyModal from "./CompanyModal";

const hiddenOptions = {
    display: false,
};

const sortOptions = {
    sort: false
};

class CompanyTable extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        tableColumns: PropTypes.object.isRequired,
        deleteCompany: PropTypes.func.isRequired,
        isCompaniesLoading: PropTypes.bool,
        isDeletingCompany: PropTypes.bool,
        companies: PropTypes.array,
        getCompanies: PropTypes.func.isRequired,
        pagination: PropTypes.object,
        onSearch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isCompaniesLoading: false,
        isDeletingCompany: false,
        companies: [],
        pagination: null
    };

    constructor(props) {
        super(props);

        const { tableColumns, i18n } = this.props;

        this.state = {
            isVisibleCompanyModal: false,
            modalType: coreConstants.MODAL_TYPE.create,
            isVisibleDeleteModal: false,
            companyId: '',
            isSearchOpen: false,
            searchText: ""
        };

        this.tableColumns = [
            { ...tableColumns[COLUMNS.id], options: hiddenOptions },
            { ...tableColumns[COLUMNS.company], options: sortOptions },
        ];

        this.tableActions = {
            base: [
                {
                    label: i18n.t("settings.companyTable.addCompany"),
                    onClick: this.openAddCompanyModal,
                    icon: <AddIcon />
                }
            ],
            select: [
                {
                    label: i18n.t("table.edit"),
                    onClick: this.openEditCompanyModal,
                    icon: <Settings />
                },
                {
                    label: i18n.t("table.delete"),
                    onClick: this.openConfirmationModal,
                    icon: <Delete />
                }
            ]
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isDeletingCompany && !this.props.isDeletingCompany) {
            this.onCloseConfirmationModal();
        }
    }

    get options() {
        return {
            selectableRows: "single",
            responsive: 'scroll',
            rowHover: false,
            onRowsSelect: this.onRowsSelect,
            filter: false,
            print: false,
            searchText: this.state.searchText,
            viewColumns: false,
            download: false,
            onSearchChange: this.onSearch,
            onTableChange: this.tableChanged,
            onSearchOpen: this.onSearchOpen,
        };
    }

    tableChanged = (action, tableState) => {
        const { isSearchOpen } = this.state;
        this.setState({ searchText: tableState.searchText });
        if (action === "search" && isSearchOpen && !tableState.searchText) {
            this.setState({ isSearchOpen: false });
            this.onSearch("");
        }
    };

    onSearchOpen = () => this.setState({ isSearchOpen: true });

    onRowsSelect = currentRowsSelected => {
        const { companies } = this.props;
        this.setState({ companyId: companies[currentRowsSelected[0].dataIndex].id })
    };

    openAddCompanyModal = () => this.setState({ isVisibleCompanyModal: true, modalType: coreConstants.MODAL_TYPE.create, companyId: '' });

    openEditCompanyModal = () => this.setState({ isVisibleCompanyModal: true, modalType: coreConstants.MODAL_TYPE.edit });

    onCloseCompanyModal = () => this.setState({ isVisibleCompanyModal: false });

    openConfirmationModal = () => this.setState({ isVisibleDeleteModal: true });

    onCloseConfirmationModal = () => this.setState({ isVisibleDeleteModal: false });

    deleteCompany = () => this.props.deleteCompany(this.state.companyId);

    changePage = (e, page) => this.props.getCompanies(page + 1, this.state.searchText);

    onSearch = searchText => this.props.onSearch(1, searchText);

    render() {
        const { companies, isCompaniesLoading, i18n, isDeletingCompany } = this.props;
        const { isVisibleCompanyModal, isVisibleDeleteModal, modalType, companyId } = this.state;
        return (
            <div>
                <Core.Table
                    title={i18n.t('settings.companyTable.title')}
                    options={this.options}
                    columns={this.tableColumns}
                    data={companies}
                    isLoading={isCompaniesLoading || isDeletingCompany}
                    pagination={this.props.pagination}
                    changePage={this.changePage}
                    actionConfig={this.tableActions}
                />

                {isVisibleCompanyModal ? (
                    <CompanyModal
                        isVisible={isVisibleCompanyModal}
                        onClose={this.onCloseCompanyModal}
                        id={companyId}
                        modalType={modalType}
                    />
                ): null
                }
                {isVisibleDeleteModal ? (
                    <Core.ConfirmationModal
                        isVisible={isVisibleDeleteModal}
                        onClose={this.onCloseConfirmationModal}
                        onConfirm={this.deleteCompany}
                        isLoading={isDeletingCompany}
                    />
                ): null
                }
            </div>
        );
    }
}

export default withCompanyTableBase(CompanyTable);
