import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from "@material-ui/core/Typography/Typography";
import Paper from "@material-ui/core/Paper/Paper";
import Logo from "../../../../../assets/img/Logo.png";
import { spacing } from "../../../../styles";

class Form extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        headerLabel: PropTypes.string,
        className: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        isLogo: PropTypes.bool,
    };

    static defaultProps = {
        headerLabel: '',
        className: null,
        isLogo: false
    };

    render() {
        const { children, classes, headerLabel, className, isLogo } = this.props;
        return (
            <Paper className={classNames(classes.paper, className)}>
                {isLogo ? <img src={Logo} className={classes.logo} /> : null}
                <Typography component="h1" variant="h5">
                    {headerLabel}
                </Typography>
                <form className={classes.form}>
                    {children}
                </form>
            </Paper>
        );
    }
}

const DEFAULT_WIDTH = 400;

const LOGO_SIZE = 50;
const FORM_MOBILE_WIDTH = 95;

const styles = theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
        width: DEFAULT_WIDTH,
        [theme.breakpoints.down("xs")]: {
            width: `${FORM_MOBILE_WIDTH}vw`,
        },
    },
    form: {
        width: '100%',
        marginTop: theme.spacing.unit,
    },
    logo: {
        display: "flex",
        justifyContent: "center",
        height: LOGO_SIZE,
        marginBottom: spacing.s2,
    }
});

Form.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Form);
