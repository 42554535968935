import axios from 'axios';

const endpoints = {
    currencies: 'currencies',
    zone: 'zones',
    companies: 'companies',
    marketplaces: "marketplaces",
    postalServices: "postal-services",
    packagings: "packagings",
    staticValues: "static-values",
    exportSettings: "inventory/import-example",
    import: "import",
    export: "export",
    exportFiles: module => `${module}/export`,
    importFiles: module => `${module}/import`,
};

// Currencies

export const getCurrenciesRate = params => axios.get(endpoints.currencies, { params });
export const addCurrency = data => axios.post(endpoints.currencies, data);
export const deleteCurrency = currencyId => axios.delete(`${endpoints.currencies}/${currencyId}`);
export const updateCurrency = (currencyId, data) => axios.put(`${endpoints.currencies}/${currencyId}`, data);

export const exportCurrencies = () => axios({
    url: `${endpoints.currencies}/${endpoints.export}`,
    method: 'GET',
    responseType: 'blob'
});
export const importCurrencies = data => axios.post(`${endpoints.currencies}/${endpoints.import}`, data, {
    headers: {
        'content-type': 'multipart/form-data',
    }
});


// Zones

export const getZones = params => axios.get(endpoints.zone, { params });
export const addZone = data => axios.post(endpoints.zone, data);
export const deleteZone = id => axios.delete(`${endpoints.zone}/${id}`);
export const updateZone = (id, data) => axios.put(`${endpoints.zone}/${id}`, data);

export const exportZones = () => axios({
    url: `${endpoints.zone}/${endpoints.export}`,
    method: 'GET',
    responseType: 'blob'
});
export const importZones = data => axios.post(`${endpoints.zone}/${endpoints.import}`, data, {
    headers: {
        'content-type': 'multipart/form-data',
    }
});

// Companies

export const getCompanies = params => axios.get(endpoints.companies, { params });
export const addCompany = data => axios.post(endpoints.companies, data);
export const deleteCompany = id => axios.delete(`${endpoints.companies}/${id}`);
export const updateCompany = (id, data) => axios.put(`${endpoints.companies}/${id}`, data);

export const exportCompanies = () => axios({
    url: `${endpoints.companies}/${endpoints.export}`,
    method: 'GET',
    responseType: 'blob'
});
export const importCompanies = data => axios.post(`${endpoints.companies}/${endpoints.import}`, data, {
    headers: {
        'content-type': 'multipart/form-data',
    }
});

// Marketplace

export const getMarketplaces = params => axios.get(endpoints.marketplaces, { params });
export const getMarketplaceFees = marketplaceId => axios.get(`${endpoints.marketplaces}/${marketplaceId}/with-fees`);
export const addMarketplace = data => axios.post(endpoints.marketplaces, data);
export const deleteMarketplace = id => axios.delete(`${endpoints.marketplaces}/${id}`);
export const updateMarketplace = (id, data) => axios.put(`${endpoints.marketplaces}/${id}`, data);

export const exportMarketplaces = () => axios({
    url: `${endpoints.marketplaces}/${endpoints.export}`,
    method: 'GET',
    responseType: 'blob'
});
export const importMarketplaces = data => axios.post(`${endpoints.marketplaces}/${endpoints.import}`, data, {
    headers: {
        'content-type': 'multipart/form-data',
    }
});

// Postal Services

export const getPostalServices = params => axios.get(endpoints.postalServices, { params });
export const getPostalServiceCosts = postalServiceId => axios.get(`${endpoints.postalServices}/${postalServiceId}/with-costs`);
export const addPostalService = data => axios.post(endpoints.postalServices, data);
export const deletePostalService = id => axios.delete(`${endpoints.postalServices}/${id}`);
export const updatePostalService = (id, data) => axios.put(`${endpoints.postalServices}/${id}`, data);

export const exportPostalServices = () => axios({
    url: `${endpoints.postalServices}/${endpoints.export}`,
    method: 'GET',
    responseType: 'blob'
});
export const importPostalServices = data => axios.post(`${endpoints.postalServices}/${endpoints.import}`, data, {
    headers: {
        'content-type': 'multipart/form-data',
    }
});

// Packagings

export const getPackagings = params => axios.get(endpoints.packagings, { params });
export const addPackaging = data => axios.post(endpoints.packagings, data);
export const deletePackaging = id => axios.delete(`${endpoints.packagings}/${id}`);
export const updatePackaging = (id, data) => axios.put(`${endpoints.packagings}/${id}`, data);

export const exportPackagings = () => axios({
    url: `${endpoints.packagings}/${endpoints.export}`,
    method: 'GET',
    responseType: 'blob'
});
export const importPackagings = data => axios.post(`${endpoints.packagings}/${endpoints.import}`, data, {
    headers: {
        'content-type': 'multipart/form-data',
    }
});

// Static Values

export const getStaticValues = params => axios.get(endpoints.staticValues, { params });
export const addStaticValue = data => axios.post(endpoints.staticValues, data);
export const deleteStaticValue = id => axios.delete(`${endpoints.staticValues}/${id}`);
export const updateStaticValue = (id, data) => axios.put(`${endpoints.staticValues}/${id}`, data);

export const exportStaticValue = () => axios({
    url: `${endpoints.staticValues}/${endpoints.export}`,
    method: 'GET',
    responseType: 'blob'
});
export const importStaticValue = data => axios.post(`${endpoints.staticValues}/${endpoints.import}`, data, {
    headers: {
        'content-type': 'multipart/form-data',
    }
});

// Export

export const exportSettings = () => axios({
    url: endpoints.exportSettings,
    method: 'GET',
    responseType: 'blob'
});

export const exportFile = module => axios({
    url: endpoints.exportFiles(module),
    method: 'GET',
    responseType: 'blob'
});

export const importFile = obj => {
    const formData = new FormData();
    formData.append('file', obj.value);

    return axios.post(endpoints.importFiles(obj.name), formData, {
        headers: {
            'content-type': 'multipart/form-data',
        }
    }) };
