import _ from "lodash";
import { SPECIAL_COMPOSITE_COST_TYPE } from "../constants";

const normalizePostalServices = data => {
    /*const viewModal = {};
    _.forEach(data.costs, (item, id) => {
        const type = _.get(item, SPECIAL_COMPOSITE_COST_TYPE.higher21Centimeters) ?
            SPECIAL_COMPOSITE_COST_TYPE.higher21Centimeters :
            SPECIAL_COMPOSITE_COST_TYPE.heavier2Kilograms;
        _.assign(viewModal, {
            [id]: {
                ...item,
                specialCompositeCostType: type,
                specialCompositeCost: _.get(item, [type])
            }
        });
    });*/

    return data;
};

export default { normalizePostalServices };
