import { NAME } from './constants';
import { createActionType } from '../core';

export const GET_ACCOUNT = createActionType(`${NAME}/GET_ACCOUNT`);
export const UPDATE_ACCOUNT = createActionType(`${NAME}/UPDATE_ACCOUNT`);
export const CHANGE_PASSWORD = createActionType(`${NAME}/CHANGE_PASSWORD`);
export const GET_USER_PICTURE = createActionType(`${NAME}/GET_USER_PICTURE`);
export const UPLOAD_USER_PICTURE = createActionType(`${NAME}/UPLOAD_USER_PICTURE`);
export const REMOVE_USER_PICTURE = createActionType(`${NAME}/REMOVE_USER_PICTURE`);
export const RESET_STORE = createActionType(`${NAME}/RESET_STORE`);
