import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import Delete from '@material-ui/icons/Delete';
import DropZone from './DropZone.web';
import withImportModalBase from "./ImportModal.base";
import { components as Core, withStyles, Toast } from "../../../../core";
import { colors, fonts, spacing } from "../../../../../styles";

class ImportModal extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        items: PropTypes.array.isRequired,
        onImport: PropTypes.func.isRequired,
        isFilesImporting: PropTypes.bool,
    };

    static defaultProps = {
        isFilesImporting: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            files: {}
        }
    }

    get items() {
        return _.map(this.props.items, this.getItem)
    }

    get isImportBtnDisabled() {
        const values = _.map(this.state.files);

        return !_.find(values, i => i !== null);
    }

    getItem = item => {
        const { i18n, classes } = this.props;

        return (
            <Core.Grid key={item.name} item={true} xs={12} sm={4} className={classes.item}>
                <div className={classes.actionContainer}>
                    <span className={classes.span}>{i18n.t(`settings.modules.${item.label}`)}</span>
                    {this.state.files[item.name] ? (<Delete className={classes.cursor} onClick={() => this.onRemoveFile(item.name)} />) : (null) }
                </div>
                <DropZone onFilesChange={this.onFilesChange} onFilesError={this.onFilesError} file={this.state.files[item.name]} name={item.name} />
            </Core.Grid>
        )
    };

    onFilesChange = (files, name) => {
        if (files.length) {
            this.setState(prevState => ({
                files: { ...prevState.files, [name]: files[0] }
            }))
        }
    };

    onFilesError = (error, file, name) => {
        if (error.code === 4) {
            this.onFilesChange([file], name);
        } else {
            Toast.error({ description: error.message })
        }
    };

    onRemoveFile = name => {
        this.setState(prevState => ({
            files: {
                ...prevState.files,
                [name]: null,
            }
        }));
    };

    onImport = () => {
        this.props.onImport(this.state.files);
    };

    render() {
        const { i18n, isVisible, onClose, classes, isFilesImporting } = this.props;

        return (
            <Core.Modal
                visible={isVisible}
                title={i18n.t('settings.import')}
                onClose={onClose}
                className={classes.modalContainer}
                isLoading={isFilesImporting}
                footer={[
                    <Core.Button
                        key="importBtn"
                        fullWidth={false}
                        variant="contained"
                        color="primary"
                        disabled={this.isImportBtnDisabled}
                        onClick={this.onImport}>
                        {i18n.t('settings.import')}
                    </Core.Button>,
                    <Core.Button
                        key="importCancelBtn"
                        fullWidth={false}
                        variant="contained"
                        color="primary"
                        onClick={onClose}>
                        {i18n.t('Cancel')}
                    </Core.Button>
                ]}>
                <div className={classes.container}>
                    <Core.Grid container={true} spacing={24}>
                        {this.items}
                    </Core.Grid>
                </div>
            </Core.Modal>
        )
    }
}

const MODAL_WIDTH = {
    fullWidth: 95,
    partWidth: 900
};

const styles = theme => ({
    container: {
        color: colors.background,
        flex: 1,
        ...fonts.smNormal
    },
    modalContainer: {
        [theme.breakpoints.up("md")]: {
            width: MODAL_WIDTH.partWidth,
        },
        [theme.breakpoints.down("sm")]: {
            width: `${MODAL_WIDTH.fullWidth}vw`,
        },
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: `hidden`,
        padding: `${spacing.s2}px ${spacing.s7}px ${spacing.s2}px ${spacing.s7}px`,
        ...fonts.mdNormal
    },
    span: {
        padding: spacing.s2,
        ...fonts.mdBold
    },
    cursor: {
        cursor: 'pointer',
        width: spacing.s3,
        height: spacing.s3,
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
});


export default withImportModalBase(withStyles(styles)(ImportModal));
