import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate, Toast, fieldValidator } from '../../../core';
import * as actions from '../../actions';
import * as selectors from "../../selectors";

export default function withResetPasswordTokenBase(WrappedComponent) {
    class ResetPasswordTokenBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            isSending: PropTypes.bool
        };

        static defaultProps = {
            isSending: false
        };

        constructor(props) {
            super(props);
            this.state = {
                email: ''
            };
        }

        onChangeEmail = email => {
            this.setState({ email });
        };

        onResetPassword = () => {
            if (!fieldValidator.isValidEmail(this.state.email)) {
                Toast.error({ description: this.props.i18n.t('toast.incorrectEmail') })
            } else {
                this.props.actions.sendResetPasswordToken(this.state.email);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    email={this.state.email}
                    onChangeEmail={this.onChangeEmail}
                    onResetPassword={this.onResetPassword}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isSending: selectors.isSendingPasswordToken(state)
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ResetPasswordTokenBase));
}