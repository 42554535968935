import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import withResetPasswordTokenBase from './ResetPasswordToken.base';
import { AUTH_FIELDS } from '../../constants';
import { constants as coreConstants, components as Core } from "../../../core";
import { fonts } from "../../../../styles";

class ResetPasswordToken extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        email: PropTypes.string.isRequired,
        onChangeEmail: PropTypes.func.isRequired,
        onResetPassword: PropTypes.func.isRequired,
        isSending: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.isSending && !nextProps.isSending) {
            this.props.history.push(coreConstants.ROUTES.signIn());
        }
    }

    changeLocation = () => {
        this.props.history.push(coreConstants.ROUTES.signIn());
    };

    render() {
        const { classes, i18n, email, onChangeEmail, onResetPassword, isSending } = this.props;
        return (
            <Core.MainContainer>
                <Core.Spin spinning={isSending}>
                    <Core.Form headerLabel={i18n.t('forgotPassword')} isLogo={true}>
                        <Core.FormInput
                            key={AUTH_FIELDS.email}
                            required={true}
                            htmlFor={AUTH_FIELDS.email}
                            autoFocus={true}
                            inputLabel={i18n.t('fields.email')}
                            onChange={event => onChangeEmail(event.target.value)}
                            type={AUTH_FIELDS.email}
                        />
                        <Core.Button
                            fullWidth={true}
                            variant="contained"
                            disabled={!email}
                            color="primary"
                            onClick={onResetPassword}
                            className={classes.submit}>
                            {i18n.t('sendLinkToResetPassword')}
                        </Core.Button>
                        <div className={classes.link}>
                            <span>{this.props.i18n.t('or')}</span>
                            <Core.Link
                                component="button"
                                variant="body2"
                                className={classes.signUpButton}
                                onClick={this.changeLocation}>
                                {i18n.t("auth.signIn")}
                            </Core.Link>
                        </div>
                    </Core.Form>
                </Core.Spin>

            </Core.MainContainer>
        );
    }
}

const styles = theme => ({
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    link: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing.unit * 2,
        justifyContent: 'center',
        ...fonts.smLight,
    },
    signUpButton: {
        ...fonts.smLight,
    },
});

ResetPasswordToken.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(withResetPasswordTokenBase(ResetPasswordToken));
