import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate, parseQueryString } from '../../../core';
import * as actions from '../../actions';
import * as selectors from "../../selectors";

export default function withResetPasswordBase(WrappedComponent) {
    class ResetPasswordBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            isLoading: PropTypes.bool,
            email: PropTypes.string,
            token: PropTypes.string
        };

        static defaultProps = {
            isLoading: false,
            email: undefined,
            token: undefined
        };

        constructor(props) {
            super(props);
            this.state = {
                isValid: !!(props.email && props.token),
                isSent: false
            };
        }

        componentDidMount() {
            this.setState({ isSent: true });
            this.props.actions.signOut();
            this.props.actions.resetPassword(
                this.props.email,
                this.props.token,
            );
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isValid={this.state.isValid}
                    isSent={this.state.isSent}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const params = parseQueryString(_.get(ownProps, 'location.search'));
        return {
            email: params.email,
            token: params.token,
            isLoading: selectors.isResettingPassword(state)
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }


    return connect(mapStateToProps, mapDispatchToProps)(translate()(ResetPasswordBase));
}