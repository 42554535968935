import axios from 'axios';

const endpoints = {
    account: 'account/users/me',
    changePassword: 'identity/change-password',
    picture: 'account/users/me/picture',
    uploadPicture: 'account/users/me/picture',
};

export const getAccount = () => axios.get(endpoints.account);
export const updateAccount = data => axios.put(endpoints.account, data);
export const changePassword = params => axios.post(endpoints.changePassword, params);
export const getUserPicture = () => axios.get(endpoints.picture, {
    responseType: 'arraybuffer'
});
export const uploadUserPicture = picture => axios.post(endpoints.uploadPicture, picture, {
    headers: {
        'content-type': 'multipart/form-data',
    }
});
export const removeUserPicture = () => axios.post(endpoints.uploadPicture);