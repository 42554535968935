import _ from 'lodash';
import { MARKETPLACE_INCLUDE_FIELDS, MARKETPLACE_FIELDS } from '../constants';
import { Toast } from "../../core/services";

const validateFee = (values, i18n, companyIds) => {
    const errors = [];
    if (_.get(values, `${MARKETPLACE_FIELDS.company}.${MARKETPLACE_INCLUDE_FIELDS.id}`, "").length === 0)
    {
        errors.push({ description: i18n.t('settings.marketplaceModal.error.company') });
    }

    if (_.includes(companyIds, _.get(values, `${MARKETPLACE_FIELDS.company}.${MARKETPLACE_INCLUDE_FIELDS.id}`)))
    {
        errors.push({ description: i18n.t('settings.marketplaceModal.error.anotherCompany') });
    }

    const fee = _.toNumber(_.get(values, `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.fee}`, 0));
    if (fee < 0 || fee > 1) {
        Toast.error({ description: i18n.t('settings.marketplaceModal.error.fee') })
    }

    const minFinalValueFee = _.toNumber(_.get(values, `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.minFinalValueFee}`, 0));
    if (minFinalValueFee < 0)
    {
        errors.push({ description: i18n.t('settings.marketplaceModal.error.minFinalValueFee') });
    }
    const cost = _.toNumber(_.get(values, `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.cost}`, 0));
    if (cost < 0 || cost > 1)
    {
        errors.push({ description: i18n.t('settings.marketplaceModal.error.cost') });
    }

    if (_.toNumber(_.get(values, `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.transactionFee}`, 0)) < 0)
    {
        errors.push({ description: i18n.t('settings.marketplaceModal.error.transactionFee') });
    }

    return errors;
};

const validateMarketPlace = (values, i18n) => {
    const errors = [];

    if (_.get(values, MARKETPLACE_FIELDS.marketplace).length === 0)
    {
        errors.push({ description: i18n.t('settings.marketplaceModal.error.marketplaceName') });
    }
    if (_.get(values, `${MARKETPLACE_FIELDS.currency}.${MARKETPLACE_INCLUDE_FIELDS.id}`).length === 0)
    {
        errors.push({ description: i18n.t('settings.marketplaceModal.error.currency') });
    }
    if (_.get(values, `${MARKETPLACE_FIELDS.zone}.${MARKETPLACE_INCLUDE_FIELDS.id}`).length === 0)
    {
        errors.push({ description: i18n.t('settings.marketplaceModal.error.zone') });
    }

    return errors;
};


export default { validateFee, validateMarketPlace };
