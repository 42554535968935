import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { constants as coreConstants, Toast, translate } from '../../../../core';
import { ZONE_FIELDS } from "../../../constants"
import * as actions from "../../../actions";
import * as selectors from "../../../selectors";

export default function withZoneModalBase(WrappedComponent) {
    class ZoneModalBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            id: PropTypes.string,
            isVisible: PropTypes.bool.isRequired,
            onClose: PropTypes.func.isRequired,
            modalType: PropTypes.number.isRequired,
            zone: PropTypes.object,
        };

        static defaultProps = {
            id: '',
            zone: undefined
        };

        constructor(props) {
            super(props);

            const { i18n, zone } = this.props;

            this.state = {
                fields: {
                    [ZONE_FIELDS.name]: _.get(zone, `${[ZONE_FIELDS.name]}`, ''),
                    [ZONE_FIELDS.countries]: _.get(zone, `${[ZONE_FIELDS.countries]}`, ''),
                }
            };

            this.fields = [{
                name: ZONE_FIELDS.name,
                placeholder: i18n.t('settings.zoneModal.zone'),
                isRequired: true,
                type: coreConstants.INPUT_TYPES.text,
                autoFocus: true,
            }, {
                name: ZONE_FIELDS.countries,
                placeholder: i18n.t('settings.zoneModal.countries'),
                isRequired: false,
                type: coreConstants.INPUT_TYPES.text,
                autoFocus: false,
            }];
        }

        onChangeValue = (val, field) => {
            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    [field.name]: val
                }
            }))
        };


        addZone = () => {
            const { fields } = this.state;
            const { i18n, modalType, id } = this.props;

            if (_.isEmpty(fields[ZONE_FIELDS.name])) {
                Toast.error({ description: i18n.t('settings.zoneModal.error.zoneName') })
            }
            else {
                modalType === coreConstants.MODAL_TYPE.edit ? this.props.actions.updateZone(id, fields) : this.props.actions.addZone(fields);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    addZone={this.addZone}
                    values={this.state.fields}
                    fields={this.fields}
                    onChangeValue={this.onChangeValue}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        isAdding: selectors.isAddingZone(state),
        isUpdating: selectors.isUpdatingZone(state),
        zone: ownProps.modalType === coreConstants.MODAL_TYPE.edit ? selectors.getZoneById(state, ownProps.id) : null,
        addZoneError: selectors.getAddZoneError(state),
        updateZoneError: selectors.getUpdateZoneError(state)
    });

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ZoneModalBase));
}
