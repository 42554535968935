import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as _ from "lodash";
import { constants as coreConstants, Toast, translate } from '../../../../core';
import { COMPANY_FIELDS } from "../../../constants"
import * as actions from "../../../actions";
import * as selectors from "../../../selectors";

export default function withCompanyModalBase(WrappedComponent) {
    class CompanyModalBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            id: PropTypes.string,
            isVisible: PropTypes.bool.isRequired,
            onClose: PropTypes.func.isRequired,
            modalType: PropTypes.number.isRequired,
            company: PropTypes.object,
        };

        static defaultProps = {
            id: '',
            company: undefined
        };

        constructor(props) {
            super(props);

            const { i18n, company } = this.props;

            this.state = {
                fields: {
                    [COMPANY_FIELDS.name]: _.get(company, `${[COMPANY_FIELDS.name]}`, ''),
                }
            };

            this.fields = [
                {
                    name: COMPANY_FIELDS.name,
                    placeholder: i18n.t('settings.companyModal.company'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.text,
                    autoFocus: true,
                }
            ];
        }

        onChangeValue = (val, field) => {
            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    [field.name]: val
                }
            }))
        };

        addCompany = () => {
            const { fields } = this.state;
            const { i18n, modalType, id } = this.props;

            if (_.isEmpty(fields[COMPANY_FIELDS.name])) {
                Toast.error({ description: i18n.t('settings.companyModal.error.companyName') })
            }
            else {
                modalType === coreConstants.MODAL_TYPE.edit ?
                    this.props.actions.updateCompany(id, fields) :
                    this.props.actions.addCompany(fields);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    addCompany={this.addCompany}
                    values={this.state.fields}
                    fields={this.fields}
                    onChangeValue={this.onChangeValue}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        isAdding: selectors.isAddingCompany(state),
        isUpdating: selectors.isUpdatingCompany(state),
        company: ownProps.modalType === coreConstants.MODAL_TYPE.edit ? selectors.getCompanyById(state, ownProps.id) : null,
        addCompanyError: selectors.getAddCompanyError(state),
        updateCompanyError: selectors.getUpdateCompanyError(state)
    });

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CompanyModalBase));
}
