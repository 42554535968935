import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import withReportsBase from './Reports.base';
import ReportItem from "./ReportItem";
import { components as Core, withStyles } from "../../../core"
import { spacing, fonts, colors } from "../../../../styles";

class Reports extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
        reports: PropTypes.array
    };

    static defaultProps = {
        reports: []
    };

    reportItem = item => <ReportItem item={item} />;


    render() {
        const { i18n, classes, reports } = this.props;
        return (
            <Core.Grid container={true} spacing={24} direction="column" alignItems="stretch">
                {_.map(reports, this.reportItem)}
            </Core.Grid>
        );
    }
}

const styles = theme => ({

});

export default withReportsBase(withStyles(styles)(Reports));
