import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import * as actionTypes from './actionTypes';
import { NAME } from './constants';

const initialState = {
    account: {
        userPicture: '',
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ACCOUNT.SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                account: {
                    ...state.account,
                    ...data,
                }
            }
        }
        case actionTypes.UPDATE_ACCOUNT.SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                account: {
                    ...state.account,
                    ...data,
                }
            }
        }
        case actionTypes.GET_USER_PICTURE.SUCCESS: {
            const data = action.payload;
            return {
                ...state,
                account: {
                    ...state.account,
                    userPicture: data,
                }
            }
        }
        case actionTypes.REMOVE_USER_PICTURE.SUCCESS: {
            return {
                ...state,
                account: {
                    ...state.account,
                    userPicture: null,
                }
            }
        }
        case actionTypes.RESET_STORE: {
            return initialState;
        }
        default:
            return state;
    }
};

const persistConfig = {
    key: NAME,
    storage,
    whitelist: ['account']
};

export default persistReducer(persistConfig, reducer);