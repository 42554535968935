import React, { PureComponent } from 'react';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { PersistGate } from 'redux-persist/integration/react';
import { configAxios } from '../config';
import { store, persistor } from '../store';
import i18n from '../i18n/i18n';
import Router from './Router';
import { history } from '../modules/core';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file


configAxios(store);

class App extends PureComponent {
    render() {
        return (
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Router history={history} />
                        <ToastContainer hideProgressBar/>
                    </PersistGate>
                </Provider>
            </I18nextProvider>
        )
    }
}

export default App;
