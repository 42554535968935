import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { colors, spacing, fonts } from "../../../../styles";

class CustomButton extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        muiClasses: PropTypes.object,
        btnClasses: PropTypes.object,
        children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        round: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        disabled: PropTypes.bool,
        hasIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        className: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    };

    static defaultProps = {
        muiClasses: null,
        btnClasses: null,
        children: null,
        className: null,
        hasIcon: undefined,
        disabled: false,
        round: undefined,
    };

    get btnClasses() {
        const { classes, round, disabled, hasIcon, className } = this.props;
        return {
            [classes.round]: round,
            [classes.disabled]: disabled,
            [classes.hasIcon]: hasIcon,
            [className]: className
        };
    }

    render() {
        const { classes, muiClasses, btnClasses, children } = this.props;
        return (
            <Button
                classes={muiClasses}
                className={classNames(classes.button, this.btnClasses, btnClasses)}>
                {children}
            </Button>
        );
    }
}

const ICON_SIZE = 41;

const styles = theme => ({
    button: {
        color: colors.gray.darkGray,
        border: "none",
        borderRadius: "3px",
        position: "relative",
        textAlign: "center",
        verticalAlign: "middle",
        touchAction: "manipulation",
        cursor: "pointer",
        ...fonts.mdNormal,
        "&:hover,&:focus": {
            color: colors.gray.darkGray
        },
        [theme.breakpoints.down("sm")]: {
            padding: 0,
        }
    },
    round: {
        borderRadius: "50%"
    },
    disabled: {
        opacity: "0.65",
        pointerEvents: "none"
    },
    hasIcon: {
        paddingLeft: spacing.s2,
        paddingRight: spacing.s2,
        height: ICON_SIZE,
        minWidth: ICON_SIZE,
        "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
            marginRight: 0
        },

    },
});

export default withStyles(styles)(CustomButton);
