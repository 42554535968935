import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import * as _ from "lodash";
import withCompositeCostTypeBase from './СompositeСostType.base';
import { components as Core } from '../../../../core';
import { CUSTOM_SELECT, COMPOSITE_DEFAULT_ID } from "../../../constants";
import DnD from './DnD';
import { spacing, fonts, colors } from '../../../../../styles';

const FIELDS = {
    defaultCompositeCost: 'defaultCompositeCost',
    conditionalCompositeCosts: 'conditionalCompositeCosts',
};

class CompositeCostType extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        fields: PropTypes.array.isRequired,
        values: PropTypes.object.isRequired,
        onChangeValue: PropTypes.func.isRequired,
        costs: PropTypes.object.isRequired,
        onChangeInnerValue: PropTypes.func.isRequired,
        onChangeOrder: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
        onAddCostVal: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        onEdit: PropTypes.func.isRequired,
        isDisabledAddBtn: PropTypes.bool.isRequired,
    };

    get dndItems() {
        const { i18n, costs } = this.props;
        return [
            ..._.chain(costs)
                .get(FIELDS.conditionalCompositeCosts, [])
                .map(item => (
                    {
                        ...item,
                        key: FIELDS.conditionalCompositeCosts,
                        name: _.toUpper(`${i18n.t(item.type.type)} ${i18n.t('settings.postalServiceTable.cost')} ${item.value}${i18n.t(item.type.measure)}+`)
                    }))
                .value()
        ]
    }

    get defaultCost() {
        const { i18n, costs } = this.props;
        return {
            ...costs[FIELDS.defaultCompositeCost],
            key: FIELDS.defaultCompositeCost,
            name: _.toUpper(i18n.t(`settings.postalServiceTable.${FIELDS.defaultCompositeCost}`)),
            id: COMPOSITE_DEFAULT_ID
        }
    }

    optionFormatter = option => ({
        id: option.id,
        name: this.props.i18n.t(option.measure),
        type: option.type,
    });

    renderField = field => (
        <Core.Grid
            item={true}
            xs={12}
            sm={5}
            key={field.name}>
            {field.type === CUSTOM_SELECT ? (
                <Core.FormSelect
                    key={field.name}
                    disabled={field.disabled}
                    htmlFor={field.name}
                    items={_.map(field.options, this.optionFormatter)}
                    defaultValue={_.toString(_.get(this.props.values, field.name))}
                    inputLabel={field.placeholder}
                    onChange={event => this.props.onChangeValue(event.target.value, field)}
                />
            ) : (
                <Core.FormInput
                    key={field.name}
                    required={field.isRequired}
                    htmlFor={field.name}
                    autoFocus={field.autoFocus}
                    inputLabel={field.placeholder}
                    onChange={event => this.props.onChangeValue(event.target.value, field)}
                    value={_.toString(_.get(this.props.values, field.name, 0))}
                    type={field.type}
                    endAdornment={field.endAdornment}
                />
            )}
        </Core.Grid>
    );

    render() {
        const { i18n, isDisabledAddBtn, fields, onAddCostVal, onDelete, onChangeInnerValue, onChangeOrder, onEdit } = this.props;
        return (
            <div>
                <Core.Grid
                    container={true}
                    spacing={24}
                    alignItems="center">
                    {_.map(fields, this.renderField)}
                    <Core.Grid item={true} xs={12} sm={2}>
                        <Core.Button
                            fullWidth={false}
                            variant="contained"
                            color="primary"
                            disabled={isDisabledAddBtn}
                            onClick={onAddCostVal}>
                            {i18n.t('settings.postalServiceTable.addCompositeType')}
                        </Core.Button>
                    </Core.Grid>
                    <Core.Grid item={true} xs={12} sm={12}>
                        <DnD
                            items={this.dndItems}
                            onDelete={onDelete}
                            onEdit={onEdit}
                            onChangeValue={onChangeInnerValue}
                            onChangeOrder={onChangeOrder}
                            defaultCost={this.defaultCost}
                        />
                    </Core.Grid>
                </Core.Grid>
            </div>
        );
    }
}

const MODAL_WIDTH = {
    fullWidth: 95,
    partWidth: 500
};

const styles = theme => ({
    modalContainer: {
        [theme.breakpoints.up("md")]: {
            width: MODAL_WIDTH.partWidth,
        },
        [theme.breakpoints.down("sm")]: {
            width: `${MODAL_WIDTH.fullWidth}vw`,
        },
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: spacing.s2,
        color: colors.gray.mediumGray,
        ...fonts.mdBold
    }
});


export default withCompositeCostTypeBase(withStyles(styles)(CompositeCostType));
