import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader';
import { colors } from "../../../../styles";

class Spin extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node,
        spinning: PropTypes.bool,
        className: PropTypes.string,
        styles: PropTypes.object,
        text: PropTypes.node,
    };

    static defaultProps = {
        children: null,
        spinning: false,
        className: undefined,
        styles: null,
        text: undefined,
    };

    render() {
        const { children, spinning, className, styles, text } = this.props;
        const customOverlay = _.get(styles, 'overlay', {});
        return (
            <LoadingOverlay
                active={spinning}
                styles={{
                    ...styles,
                    overlay: base => ({
                        ...base,
                        background: colors.gray.transGray,
                        ...customOverlay
                    }),
                }}
                className={className}
                text={text}
                spinner={<ClipLoader color={colors.blue.primary} />}>
                {children}
            </LoadingOverlay>
        );
    }
}

export default Spin;
