import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from 'rc-dialog';
import 'rc-dialog/assets/index.css';
import classNames from "classnames";
import _ from 'lodash';
import { fonts, spacing, colors, hexToRGB } from "../../../../styles";
import Spin from "../Spin";
import Button from "../Button";
import { translate } from "../../services";

class Modal extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        onAccept: PropTypes.func,
        children: PropTypes.node.isRequired,
        footer: PropTypes.array,
        title: PropTypes.string,
        visible: PropTypes.bool,
        headerClassName: PropTypes.string,
        className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        isLoading: PropTypes.bool,
        onClose: PropTypes.func,
        defaultBodyStyle: PropTypes.bool,
        containerClassName: PropTypes.string,
        isEmptyFooter: PropTypes.bool,
        acceptButtonTitle: PropTypes.string
    };

    static defaultProps = {
        footer: null,
        title: '',
        visible: false,
        headerClassName: null,
        onClose: null,
        isLoading: false,
        className: null,
        defaultBodyStyle: true,
        containerClassName: null,
        onAccept: null,
        isEmptyFooter: false,
        acceptButtonTitle: ""
    };

    get title() {
        const { title, classes, headerClassName } = this.props;
        return (
            <span className={classes.headerTitle || headerClassName}>{title}</span>
        );
    }

    get customFooter() {
        const { footer, classes } = this.props;
        if (footer) {
            return (
                <div className={classes.footerMain}>
                    {_.map(footer, (element, index) => this.renderFooterElements(element, index))}
                </div>
            );
        }
        return footer;
    }

    get footer() {
        const { classes, onClose, onAccept, isLoading, i18n, isEmptyFooter, acceptButtonTitle } = this.props;
        return (
            !isEmptyFooter ? (
                <div className={classes.footerMain}>
                    {onAccept ? (
                        <Button variant="contained" className={classNames(classes.button, classes.acceptButton)} onClick={onAccept} disabled={isLoading}>
                            {acceptButtonTitle ? acceptButtonTitle : i18n.t("save")}
                        </Button>
                    ) : null}
                    {onClose ? (
                        <Button variant="contained" className={classes.button} onClick={onClose} disabled={isLoading}>
                            {i18n.t("cancel")}
                        </Button>
                    ) : null}
                </div>
            ): null
        );
    }

    renderFooterElements = (element, key) => (
        <div key={key} className={this.props.classes.footerElement}>
            {element}
        </div>
    );

    render() {
        const { children, visible, onClose, classes, className, isLoading, defaultBodyStyle, containerClassName, footer } = this.props;
        return (
            <Dialog
                title={this.title}
                visible={visible}
                footer={footer ? this.customFooter : this.footer}
                onClose={onClose}
                className={classNames(containerClassName, classes.container, className)}
                bodyStyle={defaultBodyStyle ? MODAL_BODY_STYLE : null} wrapClassName={classes.wrapper}>
                {isLoading ?
                    <Spin spinning={isLoading} className={classes.spinner} /> : null
                }
                {children}
            </Dialog>
        );
    }
}

const MODAL_WIDTH = {
    fullWidth: 95,
    planWidth: 97,
    partWidth: 900
};

const MODAL_BODY_STYLE = {
    display: 'flex',
    justifyContent: 'center',
};

const BUTTON_MIN_HEIGHT = 40;
const MIN_HEIGHT = 93;
const BODY_MAX_HEIGHT = 70;

const styles = theme => ({
    container: {
        minHeight: `${MIN_HEIGHT}vh`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .rc-dialog-content": {
            flex: 1,
        },
        "& .rc-dialog-body": {
            [theme.breakpoints.up("md")]: {
                padding: `${spacing.s4}px ${spacing.s14}px`
            },
            overflowY: "scroll",
            maxHeight: `${BODY_MAX_HEIGHT}vh`,
        },
        [theme.breakpoints.up("md")]: {
            width: MODAL_WIDTH.partWidth,
        },
        [theme.breakpoints.down("sm")]: {
            width: `${MODAL_WIDTH.planWidth}vw`,
        },
        [theme.breakpoints.down("xs")]: {
            width: `${MODAL_WIDTH.fullWidth}vw`,
        },

    },
    headerTitle: {
        ...fonts.lgNormal
    },
    footerMain: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: `${spacing.s1}px 0`,
    },
    footerElement: {
        paddingLeft: spacing.s1,
    },
    wrapper: {
        maxWidth: '100%',
        overflowX: 'auto',
    },
    spinner: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    button: {
        marginRight: spacing.s2,
        minHeight: BUTTON_MIN_HEIGHT,
    },
    acceptButton: {
        background: colors.blue.blue,
        color: colors.white,
        "&:hover": {
            background: `rgba(${hexToRGB(colors.blue.blue)}, 0.8)`,
        }
    }
});

Modal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate()(Modal));
