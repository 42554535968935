import React from 'react';
import { withRouter } from 'react-router-dom';
import withResetPasswordBase from './ResetPassword.base';
import { constants as coreConstants } from "../../../core";


class ResetPassword extends React.PureComponent {
    componentDidMount() {
        this.props.history.push(coreConstants.ROUTES.signIn());
    }

    render() {
        return (
            <div />
        );
    }
}

export default withRouter(withResetPasswordBase(ResetPassword));