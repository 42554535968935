import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import * as actions from "../../../actions";
import { components as Core } from '../../../../core';

class DetailsBase extends React.PureComponent {
    static propTypes = {
        tabs: PropTypes.array.isRequired,
        components: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedTabIndex: 0,
        }
    }

    renderTab = label => (<Core.Tab label={label} key={`${label}`} />);

    handleChange = (event, value) => this.setState({ selectedTabIndex: value });

    handleChangeIndex = index => this.setState({ selectedTabIndex: index });

    render() {
        const { tabs, classes, components } = this.props;
        return (
            <div>
                <Core.AppBar position="static" color="default" className={classes.customView}>
                    <Core.Tabs
                        value={this.state.selectedTabIndex}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="auto"
                        variant="scrollable">
                        {_.map(tabs, this.renderTab)}
                    </Core.Tabs>
                </Core.AppBar>
                <Core.SwipeableViews
                    className={classes.customView}
                    axis="x"
                    index={this.state.selectedTabIndex}
                    onChangeIndex={this.handleChangeIndex}>
                    {_.map(components)}
                </Core.SwipeableViews>
            </div>
        );
    }
}

const styles = () => ({
    customView: {
        maxWidth: "100%",
    }
});

DetailsBase.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DetailsBase));
