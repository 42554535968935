import _ from 'lodash';
import { POSTAL_SERVICE_FIELDS, POSTAL_SERVICE_INCLUDE_FIELDS } from '../constants';

const validateRepeatCost = (values, i18n, parentField) => {
    if (_.toNumber(_.get(values, `${POSTAL_SERVICE_FIELDS.costs}.${parentField}.${POSTAL_SERVICE_INCLUDE_FIELDS.perGramCost}`, -1)) < 0)
    {
        return { description: i18n.t('settings.postalServiceModal.error.perGramCost') };
    }
    if (_.toNumber(_.get(values, `${POSTAL_SERVICE_FIELDS.costs}.${parentField}.${POSTAL_SERVICE_INCLUDE_FIELDS.perPackageCost}`, -1)) < 0)
    {
        return { description: i18n.t('settings.postalServiceModal.error.perPackageCost') };
    }
    const surcharge = _.toNumber(_.get(values, `${POSTAL_SERVICE_FIELDS.costs}.${parentField}.${POSTAL_SERVICE_INCLUDE_FIELDS.surcharge}`, 0));
    if (surcharge < 0 || surcharge > 1)
    {
        return { description: i18n.t('settings.postalServiceModal.error.surcharge') };
    }
};

const validateCost = (values, i18n, costsIds) => {
    const errors = [];
    let costsError = null;

    if (_.get(values, `${POSTAL_SERVICE_FIELDS.zone}.${POSTAL_SERVICE_INCLUDE_FIELDS.id}`, "").length === 0)
    {
        errors.push({ description: i18n.t('settings.postalServiceModal.error.zone') });
    }

    if (_.includes(costsIds, _.get(values, `${POSTAL_SERVICE_FIELDS.zone}.${POSTAL_SERVICE_INCLUDE_FIELDS.id}`)))
    {
        errors.push({ description: i18n.t('settings.postalServiceModal.error.anotherZone') });
    }
    if (_.toNumber(_.get(values, `${POSTAL_SERVICE_FIELDS.costs}.${POSTAL_SERVICE_INCLUDE_FIELDS.cost}`, 0)) < 0) {
        errors.push({ description: i18n.t('settings.postalServiceModal.error.cost') })
    }
    if (_.get(values, `${POSTAL_SERVICE_FIELDS.costs}.${POSTAL_SERVICE_FIELDS.defaultCost}`))
    {
        costsError = validateRepeatCost(values, i18n, POSTAL_SERVICE_FIELDS.defaultCost);
        costsError ? errors.push(costsError) : null;
    }
    if (_.get(values, `${POSTAL_SERVICE_FIELDS.costs}.${POSTAL_SERVICE_FIELDS.specialCompositeCost}`))
    {
        costsError = validateRepeatCost(values, i18n, POSTAL_SERVICE_FIELDS.specialCompositeCost);
        costsError ? errors.push(costsError) : null;
    }

    return errors;
};

const validatePostalService = (values, i18n) => {
    const errors = [];

    if (_.get(values, POSTAL_SERVICE_FIELDS.name).length === 0)
    {
        errors.push({ description: i18n.t('settings.postalServiceModal.error.postalServiceName') });
    }

    return errors;
};


export default { validateCost, validatePostalService };
