import i18n from "../../i18n/i18n";
import { constants as coreConstants } from '../core';

export const NAME = 'users';

export const INVITE_FIELDS = {
    email: 'email',
    fullName: 'fullName'
};

export const PERMISSION_TYPE = coreConstants.PERMISSION_TYPE;
export const PERMISSION_VALUE = coreConstants.PERMISSION_VALUE;

export const PERMISSION_ITEMS = [
    {
        id: "Inventory items",
        modalTranslation: i18n.t('permissions.inventoryItems'),
        permissions: [
            {
                type: PERMISSION_TYPE.inventoryItems,
                value: PERMISSION_VALUE.view
            }, {
                type: PERMISSION_TYPE.inventoryItems,
                value: PERMISSION_VALUE.edit
            }
        ]
    }, {
        id: "Settings",
        modalTranslation: i18n.t('permissions.settings'),
        permissions: [
            {
                type: PERMISSION_TYPE.settings,
                value: PERMISSION_VALUE.view
            }, {
                type: PERMISSION_TYPE.settings,
                value: PERMISSION_VALUE.edit
            }, {
                type: PERMISSION_TYPE.settings,
                value: PERMISSION_VALUE.importAndExport
            }
        ]
    }, {
        id: "Calculator",
        modalTranslation: i18n.t('permissions.calculator'),
        permissions: [
            {
                type: PERMISSION_TYPE.calculator,
                value: PERMISSION_VALUE.view
            }
        ]
    }, {
        id: "Profit reports",
        modalTranslation: i18n.t('permissions.profitReports'),
        permissions: [
            {
                type: PERMISSION_TYPE.profitReports,
                value: PERMISSION_VALUE.view
            }
        ]
    }, {
        id: "Account",
        modalTranslation: i18n.t('permissions.account'),
        permissions: [
            {
                type: PERMISSION_TYPE.account,
                value: PERMISSION_VALUE.view
            }
        ]
    }, {
        id: "Users",
        modalTranslation: i18n.t('permissions.users'),
        permissions: [
            {
                type: PERMISSION_TYPE.users,
                value: PERMISSION_VALUE.view
            }, {
                type: PERMISSION_TYPE.users,
                value: PERMISSION_VALUE.edit
            }
        ]
    }, {
        id: "Import and export",
        modalTranslation: i18n.t('permissions.importAndExport'),
        permissions: [
            {
                type: PERMISSION_TYPE.importAndExport,
                value: PERMISSION_VALUE.importAndExport
            }
        ]
    }
];
