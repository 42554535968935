import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import withStyles from '@material-ui/core/styles/withStyles';
import withVerifyBase from './Verify.base';
import { constants as coreConstants, components as Core } from "../../../core";

class Verify extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
        error: PropTypes.object.isRequired,
        isValid: PropTypes.bool.isRequired
    };

    get message() {
        const { i18n } = this.props;
        if (!this.props.isValid) {
            return i18n.t('invalidLink');
        }
        if (this.props.error) {
            return i18n.t('verifyEmailError');
        }
        return i18n.t('verifyEmailMessage');
    }

    redirectToSignIn = () => {
        this.props.history.push(coreConstants.ROUTES.signIn());
    };

    render() {
        const { classes, i18n, isLoading } = this.props;
        return (
            <Core.MainContainer>
                <Core.Spin spinning={isLoading}>
                    <Core.Form headerLabel={this.message} isLogo={true}>
                        <Core.Button
                            fullWidth={true}
                            variant="contained"
                            color="primary"
                            onClick={this.redirectToSignIn}
                            className={classes.submit}>
                            {i18n.t('goToSignIn')}
                        </Core.Button>
                    </Core.Form>
                </Core.Spin>
            </Core.MainContainer>
        );
    }
}

const styles = theme => ({
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
});

Verify.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withVerifyBase(Verify)));
