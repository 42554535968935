import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions";
import { translate } from '../../../../core';
import * as selectors from "../../../selectors";

export default function withCompetitorsDetailsBase(WrappedComponent) {
    class CompetitorsDetailsBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            itemId: PropTypes.string.isRequired,
            data: PropTypes.object.isRequired,
        };

        get formattedCompetitors() {
            let res = [];

            _.forOwn(this.props.data, (value, key) => {
                res = [...res, { ...value, name: key }]
            });

            return res;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    competitors={this.formattedCompetitors}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            isItemLoading: selectors.isItem(state, ownProps.itemId),
            data: _.get(selectors.getCurrentItem(state, ownProps.itemId), 'competitors', {})
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CompetitorsDetailsBase));
}
