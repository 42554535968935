import moment from 'moment';
import * as actionTypes from './actionTypes';
import i18n from '../../i18n/i18n';

const DATE_FORMAT = 'YYYY.MM.DD HH:mm:ss';

const initialState = {
    account: {
        linnworksKey: null,
        isSyncing: false,
        lastSyncDate: null
    },
    storedToken: null,
};


export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPANY_ACCOUNT.SUCCESS: {
            const { data } = action.payload;
            let lastSyncDate = i18n.t('linnworks.table.never');

            if (data.lastSyncDate) {
                const formattedDate = moment(data.lastSyncDate);
                lastSyncDate = formattedDate.year() > 0 ? formattedDate.format(DATE_FORMAT) : lastSyncDate;
            }

            const newData = {
                ...data,
                lastSyncDate
            };

            return {
                ...state,
                account: {
                    ...state.account,
                    ...newData,
                }
            }
        }
        case actionTypes.REVOKE_TOKEN.SUCCESS: {
            return {
                ...state,
                account: {
                    ...state.account,
                    linnworksKey: null
                }
            }
        }
        case actionTypes.SET_TOKEN.ERROR: {
            return {
                ...state,
                storedToken: null,
            }
        }
        case actionTypes.STORE_TOKEN.SUCCESS: {
            const { token } = action.payload;
            return {
                ...state,
                storedToken: token
            }
        }
        case actionTypes.REMOVE_STORED_TOKEN.SUCCESS: {
            return {
                ...state,
                storedToken: null,
            }
        }
        default:
            return state;
    }
}
