import React from 'react';
import PropTypes from 'prop-types';
import ImageZoom from 'react-medium-image-zoom'
import withImageColumnBase from './ImageColumn.base';
import noImage from "../../../../../../assets/img/noImage.png";
import { spacing, colors } from '../../../../../styles';

class ImageColumn extends React.PureComponent {
    static propTypes = {
        smallImg: PropTypes.string,
        fullImg: PropTypes.string,
    };

    static defaultProps = {
        smallImg: null,
        fullImg: null,
    };

    get image() {
        return {
            src: this.props.smallImg || noImage,
            style: {
                height: `${spacing.s10}px`,
                border: `1px solid ${colors.gray.transGray}`,
                position: 'relative',
                cursor: this.props.fullImg ? 'zoom-in' : 'default',
                zIndex: 100,
            }
        }
    }

    get zoomImage() {
        return {
            src: this.props.fullImg || noImage,
        }
    }

    get containerStyles() {
        return {
            width: `${spacing.s10 + 2}px`,
            overflow: 'hidden'
        }
    }

    shouldHandleZoom = () => !!this.props.fullImg;

    render() {
        return (
            <div style={this.containerStyles}>
                <ImageZoom
                    image={this.image}
                    zoomImage={this.zoomImage}
                    shouldHandleZoom={this.shouldHandleZoom}
                />
            </div>
        );
    }
}

export default withImageColumnBase(ImageColumn);
