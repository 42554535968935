import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { AUTH_FIELDS as FIELDS } from '../../constants';
import * as actions from '../../actions';
import { authValidator } from '../../services';
import { constants as coreConstants, translate, fieldValidator, Toast } from '../../../core';
import * as selectors from '../../selectors';
import { constants as linnworksConstants } from "../../../linnworks";

export default function withSignUpBase(WrappedComponent) {
    class SignUpBase extends React.PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            isSignUp: PropTypes.bool
        };

        static defaultProps = {
            isSignUp: false
        };

        constructor(props) {
            super(props);
            const { i18n } = this.props;

            this.state = {
                fields: {
                    [FIELDS.email]: '',
                    [FIELDS.name]: '',
                    [FIELDS.password]: '',
                    [FIELDS.confirmPassword]: '',
                    [FIELDS.linnworkstoken]: '',
                }
            };

            this.fields = [
                {
                    name: FIELDS.name,
                    placeholder: i18n.t('fields.name'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.text,
                    autoFocus: true,
                },
                {
                    name: FIELDS.email,
                    placeholder: i18n.t('fields.email'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.email,
                },
                {
                    name: FIELDS.password,
                    placeholder: i18n.t('fields.password'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.password
                },
                {
                    name: FIELDS.confirmPassword,
                    placeholder: i18n.t('fields.confirmPassword'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.password
                },
                {
                    name: FIELDS.linnworkstoken,
                    placeholder: i18n.t('fields.linnworksToken'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.textWithLink,
                    link: linnworksConstants.LINNWORKS_URL,
                    linkText: i18n.t('fields.linnworksTokenLink')
                }
            ]
        }

        onChangeValue = (val, field) => {
            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    [field.name]: val
                }
            }))
        };

        onSignUp = () => {
            const validate = authValidator.validateSignUpData(this.state.fields, this.props.i18n);
            if (!validate.length) {
                this.props.actions.signUp(
                    {
                        [FIELDS.name]: this.state.fields[FIELDS.name],
                        [FIELDS.email]: this.state.fields[FIELDS.email],
                        [FIELDS.password]: this.state.fields[FIELDS.password],
                        [FIELDS.linnworkstoken]: this.state.fields[FIELDS.linnworkstoken]
                    }
                );
            } else {
                _.forEach(validate, error => Toast.error(error));
            }
        };

        get isDisabledSignUp() {
            return !fieldValidator.isValidRequiredFields(this.fields, this.state.fields);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    values={this.state.fields}
                    fields={this.fields}
                    onChangeValue={this.onChangeValue}
                    onSignUp={this.onSignUp}
                    isDisabledSignUp={this.isDisabledSignUp}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            isSignUp: selectors.isSignUp(state),
            error: selectors.getSignUpError(state)
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(SignUpBase));
}
