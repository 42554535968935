import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { constants as coreConstants, translate } from '../../../core';
import * as actions from "../../actions";
import {
    CALCULATOR_FIELDS,
    FIELDS,
    SELECTOR_NAMES,
    ROUND_DEFAULT_VALUE,
    DEFAULT_MARKET_VALUES,
} from "../../constants";
import { actions as settingsActions, selectors as settingsSelectors } from "../../../settings";
import {
    mapValues,
    validateObject
} from "../../services";
import * as selectors from "../../selectors";

export default function withCalculatorBase(WrappedComponent) {
    class CalculatorBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            settingsActions: PropTypes.object.isRequired,
            isAdditionalDataLoading: PropTypes.bool,
            isCalculations: PropTypes.bool,
        };

        static defaultProps = {
            isAdditionalDataLoading: false,
            isCalculations: false,
        };

        constructor(props) {
            super(props);

            this.state = {
                item: CALCULATOR_FIELDS,
                formattedMarkets: [],
                isAdditionalDataLoading: props.isAdditionalDataLoading,
                isCalculations: props.isCalculations,
                isPercentage: false,
            }
        }

        static getDerivedStateFromProps(nextProps, prevState) {
            if (prevState.isAdditionalDataLoading && !nextProps.isAdditionalDataLoading) {
                return {
                    formattedMarkets: _.map(nextProps.marketplaces, item => ({ ...item, marketplace: item.name, ...DEFAULT_MARKET_VALUES })),
                    isAdditionalDataLoading: nextProps.isAdditionalDataLoading,
                }
            }
            if (prevState.isCalculations && !nextProps.isCalculations) {
                return {
                    formattedMarkets: _.map(prevState.formattedMarkets, item => {
                        const calculation = _.find(nextProps.calculations, (calc, key) => key === item.id);
                        return {
                            ...item,
                            [FIELDS.minCalculated]: calculation.minCalculated,
                            [FIELDS.maxCalculated]: calculation.maxCalculated,
                            [FIELDS.minMargin]: calculation.minMargin * 100,
                            [FIELDS.maxMargin]: calculation.maxMargin * 100,
                            [FIELDS.current]: calculation.current,
                            [FIELDS.currentCalculated]: calculation.currentCalculated,
                            [FIELDS.currentMargin]: calculation.currentMargin * 100,
                        } }),
                    isCalculations: nextProps.isCalculations,
                }
            }
            return {
                isAdditionalDataLoading: nextProps.isAdditionalDataLoading,
                isCalculations: nextProps.isCalculations
            };
        }

        componentDidMount() {
            this.props.actions.getAdditionalInfo();
        }

        get isDisabledCalcButton() {
            return validateObject(this.state.item);
        }

        onChangeValue = (key, value, item) => {
            let newValue = value;

            if (item && item.type === 'number' && key === FIELDS.taxRate) {
                newValue = _.round(value, ROUND_DEFAULT_VALUE) / 100;
            }

            this.setState(prevState => ({ item: { ...prevState.item, [key]: { ...prevState.item[key], value: newValue } } }));
        };

        onSearch = (filterText, field, isMore, page) => {
            switch (field) {
                case SELECTOR_NAMES.postalService: {
                    return this.props.settingsActions.getPostalServices({
                        page: isMore ? page : 1,
                        count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                        filterText
                    }, true);
                }
                case SELECTOR_NAMES.company: {
                    return this.props.settingsActions.getCompanies({
                        page: isMore ? page : 1,
                        count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                        filterText
                    }, true);
                }
                case SELECTOR_NAMES.packaging: {
                    return this.props.settingsActions.getPackagings({
                        page: isMore ? page : 1,
                        count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                        filterText
                    }, true);
                }
                case SELECTOR_NAMES.zones: {
                    return this.props.settingsActions.getZones({
                        page: isMore ? page : 1,
                        count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                        filterText
                    }, true);
                }
                default:
                    break;
            }
        };

        onCalc = () => {
            const newPrices = {};

            const reqBody = {
                ..._.mapValues(this.state.item, this.mapObject),
                prices: _.chain(this.state.formattedMarkets)
                    .keyBy('id')
                    .mapValues()
                    .value()
            };

            _.forOwn(reqBody[FIELDS.prices], (val, key) => {
                if (!val) {
                    return;
                }

                if (!parseFloat(val.max) && !parseFloat(val.min)) {
                    reqBody[FIELDS.prices][key] = null;
                }

                const newItem = mapValues(val, true);
                const itemValues = this.state.isPercentage ? {
                    min: newItem.minMargin,
                    max: newItem.maxMargin,
                    currentCalculated: newItem.currentMargin,
                } : {
                    min: newItem.min,
                    max: newItem.max,
                    currentCalculated: newItem.currentCalculated,
                };
                _.assign(newPrices, { [key]: { ...newItem, ...itemValues, isPercentage: this.state.isPercentage } });
            });

            const request = {
                ...reqBody,
                [FIELDS.company]: _.get(reqBody[FIELDS.company], 'id', false) ?
                    reqBody[FIELDS.company] : null,
                [FIELDS.postalService]: _.get(reqBody[FIELDS.postalService], 'id', false) ?
                    reqBody[FIELDS.postalService] : null,
                [FIELDS.packaging]: _.get(reqBody[FIELDS.packaging], 'id', false) ?
                    reqBody[FIELDS.packaging] : null,
                [FIELDS.prices]: _.omitBy(newPrices, _.isNil),
            };

            this.props.actions.getCalculations(request);
        };

        mapObject = item => _.get(item, 'value', null);

        onChangeMarketsTable = (id, key, val) => {
            this.setState(prevState => {
                const value = parseFloat(val);
                return {
                    formattedMarkets: _.map(prevState.formattedMarkets, item => {
                        if (item.id === id) {
                            return {
                                ...item,
                                [key]: value ? value : 0
                            }
                        }

                        return item;
                    })
                } });
        };

        onApplyDefaultMarketValues = newMarkets => {
            this.setState({ formattedMarkets: newMarkets });
        };

        onChangeInputType = isPercentage => {
            if (isPercentage !== this.state.isPercentage) {
                this.setState(() => ({
                    isPercentage,
                    formattedMarkets: _.map(this.state.formattedMarkets, this.getNewItem),
                }));
            }
        };

        getNewItem = item => {
            let res = !this.state.isPercentage ? {
                ...item,
                min: 0,
                max: 0,
                currentCalculated: 0,
            } : {
                ...item,
                minMargin: 0,
                maxMargin: 0,
                currentMargin: 0,
            };

            if (this.props.calculations) {
                const calcItem = this.props.calculations[item.id];
                res = !this.state.isPercentage ? {
                    ...item,
                    min: _.get(calcItem, 'min', 0),
                    max: _.get(calcItem, 'max', 0),
                    currentCalculated: _.get(calcItem, 'currentCalculated', 0)
                } : {
                    ...item,
                    minMargin: _.get(calcItem, 'minMargin', 0),
                    maxMargin: _.get(calcItem, 'maxMargin', 0),
                    currentMargin: _.get(calcItem, 'currentMargin', 0)
                };
            }

            return res;
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    item={this.state.item}
                    onChangeValue={this.onChangeValue}
                    onSearch={this.onSearch}
                    onCalc={this.onCalc}
                    isDisabledCalcButton={this.isDisabledCalcButton}
                    formattedMarkets={this.state.formattedMarkets}
                    onChangeMarketsTable={this.onChangeMarketsTable}
                    onApplyDefaultMarketValues={this.onApplyDefaultMarketValues}
                    isAdditionalDataLoading={this.state.isAdditionalDataLoading}
                    isCalculations={this.state.isCalculations}
                    isPercentage={this.state.isPercentage}
                    onChangeInputType={this.onChangeInputType}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isAdditionalDataLoading: selectors.isAdditionalDataLoading(state),
            isCalculations: selectors.isCalculations(state),
            marketplaces: selectors.getMarketplaces(state),
            calculations: selectors.getCalculations(state),
            [SELECTOR_NAMES.postalService]: settingsSelectors.getPostalServicesItems(state),
            [SELECTOR_NAMES.packaging]: settingsSelectors.getPackagingsItems(state),
            [SELECTOR_NAMES.company]: settingsSelectors.getCompaniesItems(state),
            [SELECTOR_NAMES.zones]: settingsSelectors.getZonesItems(state),
            isLoading: {
                [SELECTOR_NAMES.postalService]: settingsSelectors.isPostalServices(state),
                [SELECTOR_NAMES.packaging]: settingsSelectors.isPackagings(state),
                [SELECTOR_NAMES.company]: settingsSelectors.isCompanies(state),
            },
            pagination: {
                [SELECTOR_NAMES.postalService]: settingsSelectors.getPostalServicesScrollPagination(state),
                [SELECTOR_NAMES.packaging]: settingsSelectors.getPackagingsScrollPagination(state),
                [SELECTOR_NAMES.company]: settingsSelectors.getCompaniesScrollPagination(state),
                [SELECTOR_NAMES.zones]: settingsSelectors.getZonesScrollPagination(state),
            },
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch),
            settingsActions: bindActionCreators(settingsActions, dispatch),
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CalculatorBase));
}
