import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import AsyncPaginate from 'react-select-async-paginate';
import { withStyles } from "../../services";
import { colors, spacing, hexToRGB, fonts } from "../../../../styles";

const WAIT_TIME_FOR_REQUEST = 1000;
const customRelativeStyles = {
    control: provided => ({
        ...provided,
        display: "flex",
        flex: 1,
        borderRadius: 0,
        position: 'relative',
        border: 0,
        borderBottom: `1px solid rgba(${hexToRGB(colors.black)}, 0.5)}`,
        boxShadow: "none",
        minHeight: HEIGHT_SELECTOR,
        "&:hover": {
            color: colors.black,
            borderBottom: `2px solid ${colors.blue.blue}`,
            boxShadow: "none",
        },
        "&:active": {
            color: colors.blue.blue,
            borderBottom: `2px solid ${colors.blue.blue}`,
            boxShadow: "none",
        },
    }),
    container: provided => ({
        ...provided,
        display: "flex",
        flex: 1,
        outline: "none",
        flexDirection: 'column',

    }),
    indicatorsContainer: provided => ({
        ...provided,
        position: 'relative',
    }),
    menu: provided => ({
        ...provided,
        position: 'relative'
    })
};

const HEIGHT_SELECTOR = 30;

const customStyles = {
    control: provided => ({
        ...provided,
        display: "flex",
        flex: 1,
        borderRadius: 0,
        border: 0,
        borderBottom: `1px solid rgba(${hexToRGB(colors.black)}, 0.5)}`,
        boxShadow: "none",
        minHeight: HEIGHT_SELECTOR,
        "&:hover": {
            color: colors.black,
            borderBottom: `2px solid ${colors.blue.blue}`,
            boxShadow: "none",
        },
        "&:active": {
            color: colors.blue.blue,
            borderBottom: `2px solid ${colors.blue.blue}`,
            boxShadow: "none",
        },
    }),
    container: provided => ({
        ...provided,
        display: "flex",
        flex: 1,
        outline: "none",

    }),
};

class SearchableFormSelect extends React.PureComponent {
    static propTypes = {
        options: PropTypes.array,
        onChange: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]).isRequired,
        selectName: PropTypes.string.isRequired,
        value: PropTypes.string,
        pagination: PropTypes.object,
        isLoading: PropTypes.bool,
        inputLabel: PropTypes.string,
        placeholder: PropTypes.string,
        isRequired: PropTypes.bool,
        isRelativeStyle: PropTypes.bool,
    };

    static defaultProps = {
        options: [],
        value: "",
        pagination: {},
        isLoading: false,
        inputLabel: "",
        placeholder: undefined,
        isRequired: false,
        isRelativeStyle: false,
    };

    constructor(props) {
        super(props);

        this.state ={
            searchText: "",
        }
    }

    onLoadMore = async (inputValue, loadedOptions, { page }) => {
        const { isLoading } = this.props;
        const { searchText } = this.state;
        if (!isLoading) {
            if (inputValue !== searchText && inputValue !== "") {
                await this.props.onSearch(inputValue, this.props.selectName, false, page);
                this.setState({ searchText: inputValue });
                return {
                    options: this.props.options,
                    additional: {
                        page: page + 1,
                    },
                    hasMore: _.get(this.props.pagination, "hasNextPage", false),
                }
            }
            await this.props.onSearch(null, this.props.selectName, true, page);
            return {
                options: this.props.options,
                additional: {
                    page: page + 1,
                },
                hasMore: _.get(this.props.pagination, "hasNextPage", false),
            }
        }
    };

    get label() {
        const { inputLabel, isRequired } = this.props;
        return isRequired ? `${inputLabel} *` : inputLabel
    }

    render() {
        const { value, classes, onChange, placeholder, isRelativeStyle } = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.titleContainer}><span className={classes.title}>{this.label}</span></div>
                <AsyncPaginate
                    defaultValue={value}
                    onInputChange={this.handleInputChange}
                    debounceTimeout={WAIT_TIME_FOR_REQUEST}
                    loadOptions={this.onLoadMore}
                    maxMenuHeight={200}
                    onChange={onChange}
                    className={classes.select}
                    placeholder={placeholder ? placeholder : ""}
                    additional={{
                        page: 1,
                    }}
                    styles={isRelativeStyle ? customRelativeStyles : customStyles}
                />
            </div>
        );
    }
}

const TOP_MARGIN_TITLE = 15;

const styles = theme => ({
    titleContainer: {
        flex: 1,
        color: `rgba(${hexToRGB(colors.black)}, 0.54)`,
        padding: 0,
        ...fonts.xsNormal,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        lineHeight: 1,
        marginTop: TOP_MARGIN_TITLE,
        marginBottom: 0,
    }
});
export default withStyles(styles)(SearchableFormSelect);
