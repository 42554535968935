import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../../../core';

export default function withSupplierBase(WrappedComponent) {
    class Supplier extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            item: PropTypes.array.isRequired,
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    return translate()(Supplier);
}
