import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { constants as coreConstants, Toast, translate } from '../../../../../core';
import {
    MARKETPLACE_FIELDS,
    MARKETPLACE_INCLUDE_FIELDS,
    SELECT_FIELDS,
    ROUND_VALUES_PERCENT
} from "../../../../constants"
import * as actions from "../../../../actions";
import * as selectors from "../../../../selectors";
import { marketplaceValidator } from "../../../../services";


const COMPANY_FIELD = (i18n, onSearch) => [
    {
        name: `${MARKETPLACE_FIELDS.company}.${MARKETPLACE_INCLUDE_FIELDS.id}`,
        selectName: SELECT_FIELDS.companies,
        onSearch,
        placeholder: i18n.t('settings.marketplaceTable.company'),
        isRequired: true,
        type: coreConstants.INPUT_TYPES.select,
        autoFocus: true,
    },
];

const FEES_FIELDS = i18n => [
    {
        name: `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.fee}`,
        placeholder: i18n.t('settings.marketplaceTable.fee'),
        type: coreConstants.INPUT_TYPES.number,
        endAdornment: coreConstants.INPUT_ADORNMENT.percentage,
        isPercentage: true,
    },
    {
        name: `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.minFinalValueFee}`,
        placeholder: i18n.t('settings.marketplaceTable.minFinalValueFee'),
        type: coreConstants.INPUT_TYPES.number,
    },
    {
        name: `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.paymentProvider}`,
        placeholder: i18n.t('settings.marketplaceTable.paymentProvider'),
        type: coreConstants.INPUT_TYPES.text,

    },
    {
        name: `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.cost}`,
        placeholder: i18n.t('settings.marketplaceTable.cost'),
        type: coreConstants.INPUT_TYPES.number,
        endAdornment: coreConstants.INPUT_ADORNMENT.percentage,
        isPercentage: true,
    },
    {
        name: `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.transactionFee}`,
        placeholder: i18n.t('settings.marketplaceTable.transactionFee'),
        type: coreConstants.INPUT_TYPES.number,

    },
    {
        name: `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.suffix}`,
        placeholder: i18n.t('settings.marketplaceTable.suffix'),
        type: coreConstants.INPUT_TYPES.text,
    },
    {
        name: `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.subSource}`,
        placeholder: i18n.t('settings.marketplaceTable.subSource'),
        type: coreConstants.INPUT_TYPES.text,
    },
];

export default function withFeesModalBase(WrappedComponent) {
    class FeesModalBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            item: PropTypes.object,
            isVisible: PropTypes.bool.isRequired,
            onClose: PropTypes.func.isRequired,
            modalType: PropTypes.number.isRequired,
            marketplace: PropTypes.object,
            isLoadingFees: PropTypes.bool,
            marketplaceId: PropTypes.string.isRequired
        };

        static defaultProps = {
            item: {},
            marketplace: undefined,
            isLoadingFees: false,
        };

        constructor(props) {
            super(props);

            const { i18n, item } = this.props;

            const isEdit = props.modalType === coreConstants.MODAL_TYPE.edit;

            this.state = {
                fields: {
                    [MARKETPLACE_FIELDS.company]: {
                        [MARKETPLACE_INCLUDE_FIELDS.id]: isEdit ? _.get(item, MARKETPLACE_INCLUDE_FIELDS.id): ""
                    },
                    [MARKETPLACE_FIELDS.fees]: {
                        [MARKETPLACE_INCLUDE_FIELDS.fee]: isEdit ? _.get(item, MARKETPLACE_INCLUDE_FIELDS.fee): 0,
                        [MARKETPLACE_INCLUDE_FIELDS.minFinalValueFee]: isEdit ? _.get(item, MARKETPLACE_INCLUDE_FIELDS.minFinalValueFee): 0,
                        [MARKETPLACE_INCLUDE_FIELDS.paymentProvider]: isEdit ? _.get(item, MARKETPLACE_INCLUDE_FIELDS.paymentProvider): "",
                        [MARKETPLACE_INCLUDE_FIELDS.cost]: isEdit ? _.get(item, MARKETPLACE_INCLUDE_FIELDS.cost): 0,
                        [MARKETPLACE_INCLUDE_FIELDS.transactionFee]: isEdit ? _.get(item, MARKETPLACE_INCLUDE_FIELDS.transactionFee): 0,
                        [MARKETPLACE_INCLUDE_FIELDS.subSource]: isEdit ? _.get(item, MARKETPLACE_INCLUDE_FIELDS.subSource) : "",
                        [MARKETPLACE_INCLUDE_FIELDS.suffix]: isEdit ? _.get(item, MARKETPLACE_INCLUDE_FIELDS.suffix, "") : "",
                    }
                }
            };

            this.fields = this.props.modalType === coreConstants.MODAL_TYPE.edit ?
                FEES_FIELDS(i18n) : [...COMPANY_FIELD(i18n, this.onSearch), ...FEES_FIELDS(i18n)];
        }

        onChangeValue = (val, field) => {
            const newValue = field.type === coreConstants.INPUT_TYPES.number ? _.round(val * 1, ROUND_VALUES_PERCENT) : val;

            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    ..._.set(prevState.fields, field.name, newValue)
                }
            }))
        };

        updateMarketplace = () => {
            const { fields } = this.state;
            const { i18n, marketplace, marketplaceId } = this.props;

            const errors = marketplaceValidator.validateFee(
                fields,
                i18n,
                this.props.modalType !== coreConstants.MODAL_TYPE.edit ? _.map(marketplace.fees, (value, key) => key): []);
            if (errors.length) {
                Toast.error(errors[0]);
                return;
            }

            this.props.actions.updateFee(marketplaceId, {
                ...marketplace,
                fees: {
                    ...marketplace.fees,
                    [_.get(fields, `${MARKETPLACE_FIELDS.company}.${MARKETPLACE_INCLUDE_FIELDS.id}`)]: fields.fees
                }
            });
        };

        onSearch = (filterText, field, isMore, page) => this.props.actions.getCompanies({
            page: isMore ? page : 1,
            count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
            filterText
        }, true);

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    updateMarketplace={this.updateMarketplace}
                    values={this.state.fields}
                    fields={this.fields}
                    onChangeValue={this.onChangeValue}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        isUpdating: selectors.isUpdatingMarketplaceById(state, ownProps.marketplaceId),
        [SELECT_FIELDS.companies]: selectors.getCompaniesItems(state),
        companyPagination: selectors.getCompaniesScrollPagination(state),
        isCompaniesLoading: selectors.isCompanies(state),
        marketplace: selectors.getMarketplaceById(state, ownProps.marketplaceId),
        updateMarketplaceError: selectors.getUpdateMarketplaceError(state)
    });

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(FeesModalBase));
}
