import { createSelector } from "reselect";
import * as _ from "lodash";
import { NAME, POSTAL_SERVICE_INCLUDE_FIELDS } from './constants';
import * as actionTypes from './actionTypes';

import { constants as coreConstants, selectors as coreSelectors } from '../core';

// Currencies

//GETTERS
export const getCurrenciesRate = state => state[NAME].currenciesRate.items;
export const getCurrenciesRateIds = state => state[NAME].currenciesRate.ids;
export const getCurrenciesRateScrollIds = state => state[NAME].currenciesRate.scroll.ids;
export const getCurrenciesRateArray = createSelector(getCurrenciesRate, getCurrenciesRateIds,
    (items, ids) => _.map(_.take(ids, coreConstants.DEFAULT_ITEMS_PER_PAGE), id => items[id]));
export const getCurrenciesRateItems = createSelector(getCurrenciesRate, getCurrenciesRateScrollIds,
    (items, ids) => _.map(ids, id => items[id]));
export const getCurrencyById = (state, id) => getCurrenciesRate(state)[id];

//PAGINATOR
export const getCurrenciesRatePagination = state => state[NAME].currenciesRate.pagination;
export const getCurrenciesRateScrollPagination = state => state[NAME].currenciesRate.scroll.pagination;

// LOADERS
export const isCurrenciesRate = state => coreSelectors.isLoading(state, actionTypes.GET_CURRENCIES_RATE.NAME);
export const isAddingCurrency = state => coreSelectors.isLoading(state, actionTypes.ADD_CURRENCY.NAME);
export const isUpdatingCurrency = state => coreSelectors.isLoading(state, actionTypes.UPDATE_CURRENCY.NAME);
export const isDeletingCurrency = state => coreSelectors.isLoading(state, actionTypes.DELETE_CURRENCY.NAME);

//ERRORS
export const getAddCurrencyError = state => coreSelectors.getError(state, actionTypes.ADD_CURRENCY.NAME);
export const getUpdateCurrencyError = state => coreSelectors.getError(state, actionTypes.UPDATE_CURRENCY.NAME);

//Zones

//GETTERS
export const getZones = state => state[NAME].zones.items;
export const getZonesIds = state => state[NAME].zones.ids;
export const getZonesScrollIds = state => state[NAME].zones.scroll.ids;
export const getZoneById = (state, id) => getZones(state)[id];
export const getZonesArray = createSelector(getZones, getZonesIds,
    (items, ids) => _.map(_.take(ids, coreConstants.DEFAULT_ITEMS_PER_PAGE), id => items[id]));
export const getZonesItems = createSelector(getZones, getZonesScrollIds,
    (items, ids) => _.map(ids, id => items[id]));

//PAGINATOR
export const getZonesPagination = state => state[NAME].zones.pagination;
export const getZonesScrollPagination = state => state[NAME].zones.scroll.pagination;

// LOADERS
export const isZones = state => coreSelectors.isLoading(state, actionTypes.GET_ZONES.NAME);
export const isAddingZone = state => coreSelectors.isLoading(state, actionTypes.ADD_ZONE.NAME);
export const isUpdatingZone = state => coreSelectors.isLoading(state, actionTypes.UPDATE_ZONE.NAME);
export const isDeletingZone = state => coreSelectors.isLoading(state, actionTypes.DELETE_ZONE.NAME);

//ERRORS
export const getAddZoneError = state => coreSelectors.getError(state, actionTypes.ADD_ZONE.NAME);
export const getUpdateZoneError = state => coreSelectors.getError(state, actionTypes.UPDATE_ZONE.NAME);

//Companies

//GETTERS
export const getCompanies = state => state[NAME].companies.items;
export const getCompaniesIds = state => state[NAME].companies.ids;
export const getCompaniesScrollIds = state => state[NAME].companies.scroll.ids;
export const getCompanyById = (state, id) => getCompanies(state)[id];
export const getCompaniesArray = createSelector(getCompanies, getCompaniesIds,
    (items, ids) => _.map(_.take(ids, coreConstants.DEFAULT_ITEMS_PER_PAGE), id => items[id]));
export const getCompaniesItems = createSelector(getCompanies, getCompaniesScrollIds,
    (items, ids) => _.map(ids, id => items[id]));

//PAGINATOR
export const getCompaniesPagination = state => state[NAME].companies.pagination;
export const getCompaniesScrollPagination = state => state[NAME].companies.scroll.pagination;


// LOADERS
export const isCompanies = state => coreSelectors.isLoading(state, actionTypes.GET_COMPANIES.NAME);
export const isAddingCompany = state => coreSelectors.isLoading(state, actionTypes.ADD_COMPANY.NAME);
export const isUpdatingCompany = state => coreSelectors.isLoading(state, actionTypes.UPDATE_COMPANY.NAME);
export const isDeletingCompany = state => coreSelectors.isLoading(state, actionTypes.DELETE_COMPANY.NAME);

//ERRORS
export const getAddCompanyError = state => coreSelectors.getError(state, actionTypes.ADD_COMPANY.NAME);
export const getUpdateCompanyError = state => coreSelectors.getError(state, actionTypes.UPDATE_COMPANY.NAME);

//Marketplaces

//GETTERS
export const getMarketplaces = state => state[NAME].marketplaces.items;
export const getMarketplacesIds = state => state[NAME].marketplaces.ids;
export const getMarketplacesArray = createSelector(getMarketplaces, getMarketplacesIds,
    (items, ids) => _.map(_.take(ids, coreConstants.DEFAULT_ITEMS_PER_PAGE), id => items[id]));
export const getFeesByMarketplaceId = (state, marketplaceId) => _.map(_.get(getMarketplaces(state)[marketplaceId], 'fees'),
    (item, key) => ({
        ...item,
        id: key,
    }));

//PAGINATOR
export const getMarketplacesPagination = state => state[NAME].marketplaces.pagination;
export const getMarketplaceById = (state, id) => getMarketplaces(state)[id];

// LOADERS
export const isMarketplaces = state => coreSelectors.isLoading(state, actionTypes.GET_MARKETPLACES.NAME);
export const isAddingMarketplace = state => coreSelectors.isLoading(state, actionTypes.ADD_MARKETPLACE.NAME);
export const isGettingFees = state => coreSelectors.isLoading(state, actionTypes.GET_MARKETPLACE_FEES.NAME);
export const isGettingFeesById = (state, id) => coreSelectors.isLoadingWithRequestId(state, actionTypes.GET_MARKETPLACE_FEES.NAME, id);
export const isUpdatingMarketplace = state => coreSelectors.isLoading(state, actionTypes.UPDATE_MARKETPLACE.NAME);
export const isUpdatingMarketplaceById = (state, id) => coreSelectors.isLoadingWithRequestId(state, actionTypes.UPDATE_MARKETPLACE.NAME, id);
export const isDeletingMarketplace = state => coreSelectors.isLoading(state, actionTypes.DELETE_MARKETPLACE.NAME);

//ERRORS
export const getAddMarketplaceError = state => coreSelectors.getError(state, actionTypes.ADD_MARKETPLACE.NAME);
export const getUpdateMarketplaceError = state => coreSelectors.getError(state, actionTypes.UPDATE_MARKETPLACE.NAME);

// Postal Services

//GETTERS
export const getPostalServices = state => state[NAME].postalServices.items;
export const getPostalServicesIds = state => state[NAME].postalServices.ids;
export const getPostalServicesScrollIds = state => state[NAME].postalServices.scroll.ids;
export const getPostalServiceById = (state, id) => getPostalServices(state)[id];
export const getCostsByPostalServiceId = (state, itemId) => _.map(_.get(getPostalServices(state)[itemId], 'costs.costs'),
    (item, key) => ({
        ...item,
        id: key,
    }));//, POSTAL_SERVICE_INCLUDE_FIELDS.specialCompositeCostType);
export const getPostalServicesArray = createSelector(getPostalServices, getPostalServicesIds,
    (items, ids) => _.map(_.take(ids, coreConstants.DEFAULT_ITEMS_PER_PAGE), id => items[id]));

export const getCompositeCostTypes = (state, postalServiceId) => state[NAME].postalServices.items[postalServiceId].compositeCostTypes;

//PAGINATOR
export const getPostalServicesScrollPagination = state => state[NAME].postalServices.scroll.pagination;
export const getPostalServicesItems = createSelector(getPostalServices, getPostalServicesScrollIds,
    (items, ids) => _.map(ids, id => items[id]));
export const getPostalServicesPagination = state => state[NAME].postalServices.pagination;


// LOADERS
export const isPostalServices = state => coreSelectors.isLoading(state, actionTypes.GET_POSTAL_SERVICES.NAME);
export const isAddingPostalService = state => coreSelectors.isLoading(state, actionTypes.ADD_POSTAL_SERVICE.NAME);
export const isGettingCosts = state => coreSelectors.isLoading(state, actionTypes.GET_POSTAL_SERVICE_COSTS.NAME);
export const isGettingCostsById = (state, id) => coreSelectors.isLoadingWithRequestId(state, actionTypes.GET_POSTAL_SERVICE_COSTS.NAME, id);
export const isUpdatingPostalService = state => coreSelectors.isLoading(state, actionTypes.UPDATE_POSTAL_SERVICE.NAME);
export const isUpdatingPostalServiceById = (state, id) => coreSelectors.isLoadingWithRequestId(state, actionTypes.UPDATE_POSTAL_SERVICE.NAME, id);
export const isDeletingPostalService = state => coreSelectors.isLoading(state, actionTypes.DELETE_POSTAL_SERVICE.NAME);

//ERRORS
export const getAddPostalServiceError = state => coreSelectors.getError(state, actionTypes.ADD_POSTAL_SERVICE.NAME);
export const getUpdatePostalServiceError = state => coreSelectors.getError(state, actionTypes.UPDATE_POSTAL_SERVICE.NAME);

// Packaging

//GETTERS
export const getPackagings = state => state[NAME].packagings.items;
export const getPackagingsIds = state => state[NAME].packagings.ids;
export const getPackagingsScrollIds = state => state[NAME].packagings.scroll.ids;
export const getPackagingById = (state, id) => getPackagings(state)[id];
export const getPackagingsItems = createSelector(getPackagings, getPackagingsScrollIds,
    (items, ids) => _.map(ids, id => items[id]));
export const getPackagingsArray = createSelector(getPackagings, getPackagingsIds,
    (items, ids) => _.map(_.take(ids, coreConstants.DEFAULT_ITEMS_PER_PAGE), id => items[id]));

//PAGINATOR
export const getPackagingsScrollPagination = state => state[NAME].packagings.scroll.pagination;
export const getPackagingsPagination = state => state[NAME].packagings.pagination;

// LOADERS
export const isPackagings = state => coreSelectors.isLoading(state, actionTypes.GET_PACKAGINGS.NAME);
export const isAddingPackaging = state => coreSelectors.isLoading(state, actionTypes.ADD_PACKAGING.NAME);
export const isUpdatingPackaging = state => coreSelectors.isLoading(state, actionTypes.UPDATE_PACKAGING.NAME);
export const isDeletingPackaging = state => coreSelectors.isLoading(state, actionTypes.DELETE_PACKAGING.NAME);

//ERRORS
export const getAddPackageError = state => coreSelectors.getError(state, actionTypes.ADD_PACKAGING.NAME);
export const getUpdatePackageError = state => coreSelectors.getError(state, actionTypes.UPDATE_PACKAGING.NAME);

// Static Values

//GETTERS
export const getStaticValues = state => state[NAME].staticValues.items;
export const getStaticValuesIds = state => state[NAME].staticValues.ids;
export const getStaticValuesArray = createSelector(getStaticValues, getStaticValuesIds,
    (items, ids) => _.map(_.take(ids, coreConstants.DEFAULT_ITEMS_PER_PAGE), id => items[id]));
export const getStaticValuesById = (state, id) => getStaticValues(state)[id];

//PAGINATOR
export const getStaticValuesPagination = state => state[NAME].staticValues.pagination;

// LOADERS
export const isStaticValues = state => coreSelectors.isLoading(state, actionTypes.GET_STATIC_VALUES.NAME);
export const isAddingStaticValue = state => coreSelectors.isLoading(state, actionTypes.ADD_STATIC_VALUE.NAME);
export const isUpdatingStaticValue = state => coreSelectors.isLoading(state, actionTypes.UPDATE_STATIC_VALUE.NAME);
export const isDeletingStaticValue = state => coreSelectors.isLoading(state, actionTypes.DELETE_STATIC_VALUE.NAME);

//ERRORS
export const getAddStaticValueError = state => coreSelectors.getError(state, actionTypes.ADD_STATIC_VALUE.NAME);
export const getUpdateStaticValueError = state => coreSelectors.getError(state, actionTypes.UPDATE_STATIC_VALUE.NAME);

// Export

export const isExporting = state => coreSelectors.isLoading(state, actionTypes.EXPORT_SETTINGS.NAME);

// Import and Export Modal

export const isVisibleExportModal = state => state[NAME].isVisibleExportModal;
export const isVisibleImportModal = state => state[NAME].isVisibleImportModal;
export const isFilesExporting = state => coreSelectors.isLoading(state, actionTypes.EXPORT_FILES.NAME);
export const isFilesImporting = state => coreSelectors.isLoading(state, actionTypes.IMPORT_FILES.NAME);
