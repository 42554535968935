import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import * as selectors from '../../selectors';
import { constants as coreConstants, translate } from '../../../core';
import { AUTH_FIELDS as FIELDS } from "../../../auth/constants";

const COLUMNS = {
    email: 'email',
    name: 'fullname'
};

export default function withPersonalInfoBase(WrappedComponent) {
    class PersonalInfo extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            account: PropTypes.object,
            actions: PropTypes.object.isRequired,
        };

        static defaultProps = {
            account: null,
        };

        constructor(props) {
            super(props);

            this.state = {
                [COLUMNS.name]: props.account[COLUMNS.name],
                account: props.account,
            };

            this.fields = [
                {
                    name: FIELDS.email,
                    placeholder: props.i18n.t('fields.email'),
                    type: coreConstants.INPUT_TYPES.email,
                    disabled: true
                },
                {
                    name: COLUMNS.name,
                    placeholder: props.i18n.t('fields.name'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.text,
                    autoFocus: true,
                    onChange: this.onChange,
                },
            ]
        }

        static getDerivedStateFromProps(props, state) {
            if (props.account[COLUMNS.name] !== state[COLUMNS.name] && !state.account) {
                return {
                    ...state,
                    [COLUMNS.name]: props.account[COLUMNS.name],
                    account: props.account,
                }
            }

            return state;
        }

        onChange = val => {
            this.setState(prevState => ({ [COLUMNS.name]: val, account: { ...prevState.account, [COLUMNS.name]: val } }));
        };

        onSave = () => {
            this.props.actions.updateAccount({ [COLUMNS.name]: this.state[COLUMNS.name] });
        };

        get isSaveButtonDisabled() {
            return this.state[COLUMNS.name] === this.props.account[COLUMNS.name];
        }


        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    fields={this.fields}
                    account={this.state.account}
                    onChange={this.onChange}
                    isSaveButtonDisabled={this.isSaveButtonDisabled}
                    onSave={this.onSave}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            account: selectors.getAccountProfile(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(PersonalInfo));
}
