import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import 'rc-dialog/assets/index.css';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';

class Panel extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        title: PropTypes.string,
        onDelete: PropTypes.func,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onEdit: PropTypes.func
    };

    static defaultProps = {
        title: '',
        onDelete: null,
        id: null,
        onEdit: null,
    };


    render() {
        const { children, title, classes, onDelete, id, onEdit } = this.props;
        return (
            <div className={classes.root}>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className={classes.titleContainer}>
                            <div>
                                <Typography className={classes.heading}>{title}</Typography>
                            </div>
                            {onDelete && id && onEdit ? (
                                <div>
                                    <IconButton key="editIcon" edge="end" aria-label="Delete" onClick={() => onEdit(id)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton key="deleteIcon" edge="end" aria-label="Delete" onClick={() => onDelete(id)}>
                                        <Delete />
                                    </IconButton>
                                </div>
                            ) : (null)}
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {children}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
}


const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    }
});

Panel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Panel);
