import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import * as actions from "../../../actions";
import { translate } from "../../../../core";
import { MODULES } from '../../../constants';
import { isFilesImporting } from '../../../selectors';

export default function withImportModalBase(WrappedComponent) {
    class ImportModalBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            onClose: PropTypes.func.isRequired,
        };

        constructor(props) {
            super(props);

            this.state = {
                modules: MODULES,
                selectedModules: [],
            };
        }

        onImport = files => {
            this.props.actions.importFiles(files);
        };

        onChange = (file, name) => {
            if (!_.get(file, 'length', false)) {
                this.setState(prevState => ({
                    selectedModules: _.filter(prevState.selectedModules, i => i.name !== name)
                }))
            } else {
                this.setState(prevState => ({
                    selectedModules: [...prevState.selectedModules, { name, file }]
                }))
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    items={this.state.modules}
                    values={this.state.selectedModules}
                    onImport={this.onImport}
                    onChange={this.onChange}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        isFilesImporting: isFilesImporting(state),
    });

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)

        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ImportModalBase));
}
