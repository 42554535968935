import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import Cancel from '@material-ui/icons/Cancel';
import _ from "lodash";
import { components as Core } from '../../core';
import withEditAccountBase from './EditAccount.base';
import { spacing, colors, hexToRGB } from "../../../styles";
import { PersonalInfo } from './PersonalInfo';
import { ChangePassword } from './ChangePassword';

const INPUT_DEFAULT = {
    type: "file",
    name: "filename",
    accept: 'image/png, image/jpg, image/jpeg'
};

class EditAccount extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        account: PropTypes.object,
        isAccountUpdating: PropTypes.bool,
        onCloseModal: PropTypes.func.isRequired,
        isGettingAccount: PropTypes.bool,
        isPasswordUpdating: PropTypes.bool,
    };

    static defaultProps = {
        isAccountUpdating: false,
        isPasswordUpdating: false,
        account: {},
        isGettingAccount: false,
    };

    get input() {
        return (
            <input
                {...INPUT_DEFAULT}
                ref={this.onRefFileInput}
                onChange={this.onFileChanged}
                style={{ display: 'none' }}
            />
        );
    }

    get avatar() {
        const { account, classes } = this.props;
        const props = {
            onClick: this.openFileInput,
            className: classes.avatar,
        };

        if (!account) {
            return null;
        }

        if (!_.get(account, 'userPicture', null)) {
            return (
                <div className={classes.avatarContainer}>
                    <Avatar {...props}>
                        {_.toUpper(_.get(account, "fullname", "").charAt(0))}
                        {this.input}
                    </Avatar>
                </div>
            )
        }

        return (
            <div className={classes.avatarContainer}>
                <div className={classes.subContainer}>
                    <Avatar {...props} src={account.userPicture} />
                    <Cancel className={classes.deleteIcon} onClick={this.props.actions.removeUserPhoto} />
                    {this.input}
                </div>
            </div>
        )
    }

    onRefFileInput = ref => this.file = ref;

    onFileChanged = async () => {
        if (!this.file.files[0]) return;
        this.props.actions.uploadUserPhoto(this.file.files[0]);
        this.file.value = '';
    };

    openFileInput = () => {
        this.file.click();
    };

    render() {
        const { i18n, classes, isGettingAccount, onCloseModal, isAccountUpdating, isPasswordUpdating } = this.props;
        return (
            <div>
                <Core.Modal
                    title={`${i18n.t('account.edit')}`} containerClassName={classes.modalContainer} onClose={onCloseModal} defaultBodyStyle={false} visible={true}
                    footer={[
                        <Core.Button
                            fullWidth={false}
                            variant="contained"
                            color="primary"
                            onClick={onCloseModal}>
                            {i18n.t('Close')}
                        </Core.Button>
                    ]}>
                    <Core.Spin className={classes.spin} spinning={isGettingAccount || isAccountUpdating || isPasswordUpdating}>
                        {this.avatar}
                        <PersonalInfo />
                        <ChangePassword />
                    </Core.Spin>
                </Core.Modal>
            </div>
        );
    }
}

const MODAL_WIDTH = {
    fullWidth: 95,
    partWidth: 500
};

const styles = theme => ({
    spin: {
        maxWidth: '100%',
        overflowX: 'auto',
    },
    modalContainer: {
        "& .rc-dialog-body": {
            [theme.breakpoints.up("md")]: {
                padding: `${spacing.s4}px ${spacing.s14}px`
            },
            overflowY: 'scroll',
            maxHeight: '70vh',
        },
        [theme.breakpoints.up("md")]: {
            width: MODAL_WIDTH.partWidth,
        },
        [theme.breakpoints.down("sm")]: {
            width: `${MODAL_WIDTH.fullWidth}vw`,
        },
    },
    avatar: {
        marginBottom: 10,
        width: 100,
        height: 100,
        border: `2px solid rgba(${hexToRGB(colors.gray.lightGray)}, 0.2)`,
        cursor: 'pointer',
        '&:hover': {
            border: `2px solid rgba(${hexToRGB(colors.gray.lightGray)}, 0.9)`,
        }
    },
    avatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: `${spacing.s3}px`,
    },
    subContainer: {
        '&:hover $deleteIcon': {
            visibility: 'visible !important'
        }
    },
    deleteIcon: {
        position: 'absolute',
        width: `${spacing.s3}px`,
        height: `${spacing.s3}px`,
        top: `${spacing.s0}px`,
        color: `${colors.gray.mediumGray}`,
        visibility: 'hidden',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        cursor: 'pointer',
        '&:hover': {
            width: `${spacing.s4}px`,
            height: `${spacing.s4}px`,
            color: colors.gray.darkGray,
        }
    },
});


export default withEditAccountBase(withStyles(styles)(EditAccount));
