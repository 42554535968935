import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { constants as coreConstants, getPagination, translate } from "../../../core";
import { TIMEOUT, ZONE_FIELDS } from "../../constants";
import * as actions from "../../actions";
import * as selectors from "../../selectors";

export const COLUMNS = {
    id: "id",
    zone: ZONE_FIELDS.name,
    countries: ZONE_FIELDS.countries
};

export default function withZoneTableBase(WrappedComponent) {
    class ZoneTableBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            pagination: PropTypes.object,
            actions: PropTypes.object.isRequired,
        };

        static defaultProps = {
            pagination: null,
        };

        constructor(props) {
            super(props);
            const { i18n } = this.props;

            this.tableColumns = {
                [COLUMNS.zone]: {
                    label: i18n.t("settings.zoneTable.zoneName"),
                    name: COLUMNS.zone,
                },
                [COLUMNS.countries]: {
                    label: i18n.t("settings.zoneTable.countries"),
                    name: COLUMNS.countries,
                },
                [COLUMNS.id]: {
                    label: i18n.t("settings.zoneTable.id"),
                    name: COLUMNS.id,
                }
            };

            this.props.actions.getZones();
            this.debouncedOnSearch = _.debounce(this.getZones, TIMEOUT);
        }

        deleteZone = zoneId => {
            const { pagination } = this.props;
            this.props.actions.deleteZone(zoneId).then(
                () => this.getZones(pagination.itemCount > 0 ? pagination.pageNumber : pagination.pageNumber-1)
            );
        };

        getZones = (page, filterText) => {
            this.props.actions.getZones({
                page,
                count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                filterText
            });
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    tableColumns={this.tableColumns}
                    deleteZone={this.deleteZone}
                    getZones={this.getZones}
                    onSearch={this.debouncedOnSearch}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isZonesLoading: selectors.isZones(state),
            isDeletingZone: selectors.isDeletingZone(state),
            pagination: getPagination(selectors.getZonesPagination(state)),
            zones: selectors.getZonesArray(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ZoneTableBase));
}
