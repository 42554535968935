import _ from "lodash";
import { normalizePS } from "./services";
import * as actionTypes from './actionTypes';
import { normalize } from '../core';


const initialState = {
    currenciesRate: {
        items: {},
        ids: [],
        scroll: {
            pagination: {
                pageCount: 1,
                totalItemCount: 0,
                pageNumber: 0
            },
            ids: []
        },
        pagination: {
            pageCount: 1,
            totalItemCount: 0,
            pageNumber: 0
        }
    },
    zones: {
        items: {},
        ids: [],
        scroll: {
            pagination: {
                pageCount: 1,
                totalItemCount: 0,
                pageNumber: 0
            },
            ids: []
        },
        pagination: {
            pageCount: 1,
            totalItemCount: 0,
            pageNumber: 0
        }
    },
    companies: {
        items: {},
        ids: [],
        scroll: {
            pagination: {
                pageCount: 1,
                totalItemCount: 0,
                pageNumber: 0
            },
            ids: []
        },
        pagination: {
            pageCount: 1,
            totalItemCount: 0,
            pageNumber: 0
        }
    },
    marketplaces: {
        items: {},
        ids: [],
        pagination: {
            pageCount: 1,
            totalItemCount: 0,
            pageNumber: 0
        }
    },
    postalServices: {
        items: {},
        ids: [],
        compositeCostTypes: [],
        scroll: {
            pagination: {
                pageCount: 1,
                totalItemCount: 0,
                pageNumber: 0
            },
            ids: []
        },
        pagination: {
            pageCount: 1,
            totalItemCount: 0,
            pageNumber: 0
        }
    },
    packagings: {
        items: {},
        ids: [],
        scroll: {
            pagination: {
                pageCount: 1,
                totalItemCount: 0,
                pageNumber: 0
            },
            ids: []
        },
        pagination: {
            pageCount: 1,
            totalItemCount: 0,
            pageNumber: 0
        }
    },
    staticValues: {
        items: {},
        ids: [],
        pagination: {
            pageCount: 1,
            totalItemCount: 0,
            pageNumber: 0
        }
    },
    isVisibleImportModal: false,
    isVisibleExportModal: false,
};


export default (state = initialState, action) => {
    switch (action.type) {
        // Currencies

        case actionTypes.GET_CURRENCIES_RATE.SUCCESS: {
            const { data: { items, ids }, pagination, isAllItems } = action.payload;
            const dataPagination = isAllItems ? { scroll: { pagination, ids } } : { pagination, ids } ;
            return {
                ...state,
                currenciesRate: {
                    ...state.currenciesRate,
                    items: {
                        ...state.currenciesRate.items,
                        ...items,
                    },
                    ...dataPagination
                }
            };
        }
        case actionTypes.ADD_CURRENCY.SUCCESS: {
            const { currency } = action.payload;
            return {
                ...state,
                currenciesRate: {
                    ...state.currenciesRate,
                    items: {
                        ...state.currenciesRate.items,
                        [currency.id]: currency,
                    },
                    ids: [
                        ...state.currenciesRate.ids,
                        currency.id
                    ],
                    pagination: {
                        ...state.currenciesRate.pagination,
                        totalItemCount: state.currenciesRate.pagination.totalItemCount + 1,
                    }
                }
            };
        }
        case actionTypes.DELETE_CURRENCY.SUCCESS: {
            const { currencyId } = action.payload;
            return {
                ...state,
                currenciesRate: {
                    ...state.currenciesRate,
                    items: _.omit(state.currenciesRate.items, currencyId),
                    ids: _.filter(state.currenciesRate.ids, id => id !== currencyId),
                    pagination: {
                        ...state.currenciesRate.pagination,
                        totalItemCount: state.currenciesRate.pagination.totalItemCount - 1,
                    }
                }
            };
        }
        case actionTypes.UPDATE_CURRENCY.SUCCESS: {
            const { data, currencyId } = action.payload;
            return {
                ...state,
                currenciesRate: {
                    ...state.currenciesRate,
                    items: {
                        ...state.currenciesRate.items,
                        [currencyId]: {
                            ...state.currenciesRate.items[currencyId],
                            ...data
                        }
                    }
                }
            };
        }

        // Zones

        case actionTypes.GET_ZONES.SUCCESS: {
            const { data: { items, ids }, pagination, isAllItems } = action.payload;
            const dataPagination = isAllItems ? { scroll: { pagination, ids } } : { pagination, ids } ;
            return {
                ...state,
                zones: {
                    ...state.zones,
                    items: _.merge(state.zones.items, items),
                    ...dataPagination
                }
            };
        }
        case actionTypes.ADD_ZONE.SUCCESS: {
            const { zone } = action.payload;
            return {
                ...state,
                zones: {
                    ...state.zones,
                    items: {
                        ...state.zones.items,
                        [zone.id]: zone,
                    },
                    ids: [
                        ...state.zones.ids,
                        zone.id
                    ],
                    pagination: {
                        ...state.zones.pagination,
                        totalItemCount: state.zones.pagination.totalItemCount + 1,
                    }
                }
            };
        }
        case actionTypes.DELETE_ZONE.SUCCESS: {
            const { zoneId } = action.payload;
            return {
                ...state,
                zones: {
                    ...state.zones,
                    items: _.omit(state.zones.items, zoneId),
                    ids: _.filter(state.zones.ids, id => id !== zoneId),
                    pagination: {
                        ...state.zones.pagination,
                        totalItemCount: state.zones.pagination.totalItemCount - 1,
                    }
                }
            };
        }
        case actionTypes.UPDATE_ZONE.SUCCESS: {
            const { data, zoneId } = action.payload;
            return {
                ...state,
                zones: {
                    ...state.zones,
                    items: {
                        ...state.zones.items,
                        [zoneId]: {
                            ...state.zones.items[zoneId],
                            ...data
                        }
                    }
                }
            };
        }

        // Companies

        case actionTypes.GET_COMPANIES.SUCCESS: {
            const { data: { items, ids }, pagination, isAllItems } = action.payload;
            const dataPagination = isAllItems ? { scroll: { pagination, ids } } : { pagination, ids } ;

            return {
                ...state,
                companies: {
                    ...state.companies,
                    items: {
                        ...state.companies.items,
                        ...items,
                    },
                    ...dataPagination
                }
            };
        }
        case actionTypes.ADD_COMPANY.SUCCESS: {
            const { company } = action.payload;
            return {
                ...state,
                companies: {
                    ...state.companies,
                    items: {
                        ...state.companies.items,
                        [company.id]: company,
                    },
                    ids: [
                        ...state.companies.ids,
                        company.id
                    ],
                    pagination: {
                        ...state.companies.pagination,
                        totalItemCount: state.companies.pagination.totalItemCount + 1,
                    }
                }
            };
        }
        case actionTypes.DELETE_COMPANY.SUCCESS: {
            const { companyId } = action.payload;
            return {
                ...state,
                companies: {
                    ...state.companies,
                    items: _.omit(state.companies.items, companyId),
                    ids: _.filter(state.companies.ids, id => id !== companyId),
                    pagination: {
                        ...state.companies.pagination,
                        totalItemCount: state.companies.pagination.totalItemCount - 1,
                    }
                }
            };
        }
        case actionTypes.UPDATE_COMPANY.SUCCESS: {
            const { data, companyId } = action.payload;
            return {
                ...state,
                companies: {
                    ...state.companies,
                    items: {
                        ...state.companies.items,
                        [companyId]: {
                            ...state.companies.items[companyId],
                            ...data
                        }
                    }
                }
            };
        }

        // Marketplaces

        case actionTypes.GET_MARKETPLACES.SUCCESS: {
            const { data: { items, ids }, pagination } = action.payload;
            const zones = normalize.normalizeArray({ items: _.map(items, "zone") });
            const currencies = normalize.normalizeArray({ items: _.map(items, "currency") });

            return {
                ...state,
                marketplaces: {
                    ...state.marketplaces,
                    items: {
                        ...state.marketplaces.items,
                        ...items,
                    },
                    ids,
                    pagination
                },
                currenciesRate: {
                    ...state.currenciesRate,
                    items: {
                        ...state.currenciesRate.items,
                        ...currencies.items,
                    },
                    ids: _.uniq([...state.currenciesRate.ids, ...currencies.ids]),
                },
                zones: {
                    ...state.zones,
                    items: {
                        ...state.zones.items,
                        ...zones.items,
                    },
                    ids: _.uniq([...state.zones.ids, ...zones.ids])
                }
            }
        }
        case actionTypes.GET_MARKETPLACE_FEES.SUCCESS: {
            const { data } = action.payload;
            const company = normalize.normalizeArray({ items: _.map(data.fees, 'company') });

            return {
                ...state,
                marketplaces: {
                    ...state.marketplaces,
                    items: {
                        ...state.marketplaces.items,
                        [data.id]: data
                    },
                },
                companies: {
                    ...state.companies,
                    items: {
                        ...state.companies.items,
                        ...company.items,
                    },
                    ids: _.uniq([...state.companies.ids, ...company.ids])
                }
            };
        }
        case actionTypes.ADD_MARKETPLACE.SUCCESS: {
            const { marketplace } = action.payload;

            return {
                ...state,
                marketplaces: {
                    ...state.marketplaces,
                    items: {
                        ...state.marketplaces.items,
                        [marketplace.id]: {
                            ...marketplace,
                            companyId: _.keys(marketplace.fees)[0]
                        },
                    },
                    ids: [
                        ...state.marketplaces.ids,
                        marketplace.id
                    ],
                    pagination: {
                        ...state.marketplaces.pagination,
                        totalItemCount: state.marketplaces.pagination.totalItemCount + 1,
                    }
                }
            };
        }
        case actionTypes.DELETE_MARKETPLACE.SUCCESS: {
            const { marketplaceId } = action.payload;
            return {
                ...state,
                marketplaces: {
                    ...state.marketplaces,
                    items: _.omit(state.marketplaces.items, marketplaceId),
                    ids: _.filter(state.marketplaces.ids, id => id !== marketplaceId),
                    pagination: {
                        ...state.marketplaces.pagination,
                        totalItemCount: state.marketplaces.pagination.totalItemCount - 1,
                    }
                }
            };
        }
        case actionTypes.UPDATE_MARKETPLACE.SUCCESS: {
            const { data, marketplaceId } = action.payload;
            return {
                ...state,
                marketplaces: {
                    ...state.marketplaces,
                    items: {
                        ...state.marketplaces.items,
                        [marketplaceId]: {
                            ...state.marketplaces.items[marketplaceId],
                            ...data
                        }
                    }
                }
            };
        }

        // Postal Services

        case actionTypes.GET_POSTAL_SERVICES.SUCCESS: {
            const { data: { items, ids }, pagination, isAllItems } = action.payload;
            const dataPagination = isAllItems ? { scroll: { pagination, ids } } : { pagination, ids } ;

            return {
                ...state,
                postalServices: {
                    ...state.postalServices,
                    items: {
                        ...state.postalServices.items,
                        ...items,
                    },
                    ...dataPagination
                },
            };
        }
        case actionTypes.GET_POSTAL_SERVICE_COSTS.SUCCESS: {
            const { data } = action.payload;
            const zones = normalize.normalizeArray({ items: _.map(data.costs, (item, key) => ({ id: key, name: item.zone })) });
            const viewModal = normalizePS.normalizePostalServices(data);
            return {
                ...state,
                postalServices: {
                    ...state.postalServices,
                    items: {
                        ...state.postalServices.items,
                        [data.id]: {
                            ...data,
                            costs: viewModal
                        }
                    },
                },
                zones: {
                    ...state.zones,
                    items: _.merge(state.zones.items, zones.items),
                    ids: _.uniq([...state.zones.ids, ...zones.ids])
                }
            };
        }

        case actionTypes.GET_COMPOSITE_COST_TYPE.SUCCESS: {
            const { items } = action.payload;

            return {
                ...state,
                postalServices: {
                    ...state.postalServices,
                    compositeCostTypes: [
                        ...state.compositeCostTypes,
                        ...items,
                    ]
                },
            };
        }

        case actionTypes.UPDATE_COMPOSITE_COST_TYPE.SUCCESS: {
            const { data, postalServiceId } = action.payload;

            return {
                ...state,
                postalServices: {
                    ...state.postalServices,
                    items: {
                        ...state.postalServices.items,
                        [postalServiceId]: {
                            ...state.postalServices.items[postalServiceId],
                            compositeCostTypes: data
                        }
                    }
                },
            };
        }

        case actionTypes.ADD_POSTAL_SERVICE.SUCCESS: {
            const { postalService } = action.payload;
            return {
                ...state,
                postalServices: {
                    ...state.postalServices,
                    items: {
                        ...state.postalServices.items,
                        [postalService.id]: postalService,
                    },
                    ids: [
                        ...state.postalServices.ids,
                        postalService.id
                    ],
                    pagination: {
                        ...state.postalServices.pagination,
                        totalItemCount: state.postalServices.pagination.totalItemCount + 1,
                    }
                }
            };
        }
        case actionTypes.DELETE_POSTAL_SERVICE.SUCCESS: {
            const { postalServiceId } = action.payload;
            return {
                ...state,
                postalServices: {
                    ...state.postalServices,
                    items: _.omit(state.postalServices.items, postalServiceId),
                    ids: _.filter(state.postalServices.ids, id => id !== postalServiceId),
                    pagination: {
                        ...state.postalServices.pagination,
                        totalItemCount: state.postalServices.pagination.totalItemCount - 1,
                    }
                }
            };
        }
        case actionTypes.UPDATE_POSTAL_SERVICE.SUCCESS: {
            const { data, postalServiceId } = action.payload;
            const viewModal = normalizePS.normalizePostalServices(data);
            return {
                ...state,
                postalServices: {
                    ...state.postalServices,
                    items: {
                        ...state.postalServices.items,
                        [postalServiceId]: {
                            ...state.postalServices.items[postalServiceId],
                            ...data,
                            costs: viewModal
                        }
                    }
                }
            };
        }

        // Packagings

        case actionTypes.GET_PACKAGINGS.SUCCESS: {
            const { data: { items, ids }, pagination, isAllItems } = action.payload;
            const dataPagination = isAllItems ? { scroll: { pagination, ids } } : { pagination, ids } ;

            return {
                ...state,
                packagings: {
                    ...state.packagings,
                    items: {
                        ...state.packagings.items,
                        ...items,
                    },
                    ...dataPagination
                }
            };
        }
        case actionTypes.ADD_PACKAGING.SUCCESS: {
            const { packaging } = action.payload;

            return {
                ...state,
                packagings: {
                    ...state.packagings,
                    items: {
                        ...state.packagings.items,
                        [packaging.id]: packaging,
                    },
                    ids: [
                        ...state.packagings.ids,
                        packaging.id
                    ],
                    pagination: {
                        ...state.packagings.pagination,
                        totalItemCount: state.packagings.pagination.totalItemCount + 1,
                    }
                }
            };
        }
        case actionTypes.DELETE_PACKAGING.SUCCESS: {
            const { packagingId } = action.payload;
            return {
                ...state,
                packagings: {
                    ...state.packagings,
                    items: _.omit(state.packagings.items, packagingId),
                    ids: _.filter(state.packagings.ids, id => id !== packagingId),
                    pagination: {
                        ...state.packagings.pagination,
                        totalItemCount: state.packagings.pagination.totalItemCount - 1,
                    }
                }
            };
        }
        case actionTypes.UPDATE_PACKAGING.SUCCESS: {
            const { data, packagingId } = action.payload;
            return {
                ...state,
                packagings: {
                    ...state.packagings,
                    items: {
                        ...state.packagings.items,
                        [packagingId]: {
                            ...state.packagings.items[packagingId],
                            ...data
                        }
                    }
                }
            };
        }

        // Static Values

        case actionTypes.GET_STATIC_VALUES.SUCCESS: {
            const { data: { items, ids }, pagination } = action.payload;
            return {
                ...state,
                staticValues: {
                    ...state.staticValues,
                    items: {
                        ...state.staticValues.items,
                        ...items,
                    },
                    ids,
                    pagination
                }
            };
        }
        case actionTypes.ADD_STATIC_VALUE.SUCCESS: {
            const { staticValue } = action.payload;
            return {
                ...state,
                staticValues: {
                    ...state.staticValues,
                    items: {
                        ...state.staticValues.items,
                        [staticValue.id]: staticValue,
                    },
                    ids: [
                        ...state.staticValues.ids,
                        staticValue.id
                    ],
                    pagination: {
                        ...state.staticValues.pagination,
                        totalItemCount: state.staticValues.pagination.totalItemCount + 1,
                    }
                }
            };
        }
        case actionTypes.DELETE_STATIC_VALUE.SUCCESS: {
            const { staticValueId } = action.payload;
            return {
                ...state,
                staticValues: {
                    ...state.staticValues,
                    items: _.omit(state.staticValues.items, staticValueId),
                    ids: _.filter(state.staticValues.ids, id => id !== staticValueId),
                    pagination: {
                        ...state.staticValues.pagination,
                        totalItemCount: state.staticValues.pagination.totalItemCount - 1,
                    }
                }
            };
        }
        case actionTypes.UPDATE_STATIC_VALUE.SUCCESS: {
            const { data, staticValueId } = action.payload;
            return {
                ...state,
                staticValues: {
                    ...state.staticValues,
                    items: {
                        ...state.staticValues.items,
                        [staticValueId]: {
                            ...state.staticValues.items[staticValueId],
                            ...data
                        }
                    }
                }
            };
        }
        case actionTypes.IS_VISIBLE_IMPORT_MODAL: {
            return {
                ...state,
                isVisibleImportModal: !state.isVisibleImportModal
            }
        }
        case actionTypes.IS_VISIBLE_EXPORT_MODAL: {
            return {
                ...state,
                isVisibleExportModal: !state.isVisibleExportModal
            }
        }

        default:
            return state;
    }
}
