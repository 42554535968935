import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import withColumnSettingsBase from './ColumnSettings.base';
import { components as Core } from '../../../../core';
import { spacing } from "../../../../../styles";

class ColumnSettings extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        visible: PropTypes.bool,
        onClose: PropTypes.func.isRequired,
        columns: PropTypes.array.isRequired,
        onChangeColumns: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        isColumnsUpdating: PropTypes.bool,
        onChangeVisibility: PropTypes.func.isRequired,
        isSaveBtnDisabled: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        visible: false,
        isColumnsUpdating: false,
    };

    render() {
        const { visible, onClose, i18n, classes, columns, onChangeColumns, onSave, isColumnsUpdating, onChangeVisibility, isSaveBtnDisabled } = this.props;
        return (
            <Core.Modal
                title={i18n.t('inventory.form.columnSettings')}
                visible={visible}
                onClose={onClose}
                containerClassName={classes.container}
                isLoading={isColumnsUpdating}
                footer={[
                    <Core.Button
                        key="saveSettingsBtn"
                        fullWidth={false}
                        disabled={isSaveBtnDisabled}
                        variant="contained"
                        color="primary"
                        onClick={onSave}>
                        {i18n.t('Save')}
                    </Core.Button>,
                    <Core.Button
                        key="cancelSettingsBtn"
                        fullWidth={false}
                        variant="contained"
                        color="primary"
                        onClick={onClose}>
                        {i18n.t('Cancel')}
                    </Core.Button>
                ]}>
                <Core.DnDList items={columns} onChange={onChangeColumns} onChangeVisibility={onChangeVisibility} />
            </Core.Modal>
        );
    }
}

const MODAL_WIDTH = {
    fullWidth: 45,
    partWidth: 400
};

const styles = theme => ({
    container: {
        "& .rc-dialog-body": {
            [theme.breakpoints.up("md")]: {
                padding: `${spacing.s4}px ${spacing.s14}px`
            },
            overflowY: 'scroll',
            maxHeight: '70vh',
        },
        [theme.breakpoints.up("md")]: {
            width: MODAL_WIDTH.partWidth,
        },
        [theme.breakpoints.down("sm")]: {
            width: `${MODAL_WIDTH.fullWidth}vw`,
        },
    },
});


export default withColumnSettingsBase(withStyles(styles)(ColumnSettings));
