import * as actionTypes from "./actionTypes";

export function addError(actionType, error) {
    return function (dispatch) {
        dispatch({
            type: actionTypes.ADD_ERROR,
            payload: {
                actionType,
                error
            }
        });
    }
}

export function removeError(actionType) {
    return function (dispatch) {
        dispatch({
            type: actionTypes.REMOVE_ERROR,
            payload: {
                actionType
            }
        });
    }
}