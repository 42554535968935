import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import uniqid from "uniqid";
import _ from "lodash";
import { constants as coreConstants, Toast, translate } from '../../../../../core';
import {
    POSTAL_SERVICE_FIELDS,
    POSTAL_SERVICE_INCLUDE_FIELDS,
    ROUND_VALUES_PERCENT,
    SELECT_FIELDS,
    COMPOSITE_DEFAULT_ID,
    MEASURES, COMPOSITE_COST_TYPE_FIELDS, CUSTOM_SELECT
} from "../../../../constants"
import * as actions from "../../../../actions";
import * as selectors from "../../../../selectors";
import { postalServiceValidator } from "../../../../services";

const ZONE_FIELD = (i18n, onSearch) => [
    {
        name: `${POSTAL_SERVICE_FIELDS.zone}.${POSTAL_SERVICE_INCLUDE_FIELDS.id}`,
        selectName: SELECT_FIELDS.zones,
        placeholder: i18n.t('settings.postalServiceTable.zone'),
        isRequired: true,
        type: coreConstants.INPUT_TYPES.select,
        autoFocus: true,
        onSearch
    },
];

const COSTS_MAIN_FIELDS = i18n => [
    {
        name: `${POSTAL_SERVICE_FIELDS.costs}.${POSTAL_SERVICE_INCLUDE_FIELDS.cost}`,
        placeholder: i18n.t('settings.postalServiceTable.cost'),
        type: coreConstants.INPUT_TYPES.number,
    },
];

const DEFAULT_VALUES = {
    [COMPOSITE_COST_TYPE_FIELDS.type]: "",
    [COMPOSITE_COST_TYPE_FIELDS.value]: "",
};

export default function withCostModalBase(WrappedComponent) {
    class CostModalBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            item: PropTypes.object,
            isVisible: PropTypes.bool.isRequired,
            onClose: PropTypes.func.isRequired,
            modalType: PropTypes.number.isRequired,
            postalService: PropTypes.object,
            isLoadingCosts: PropTypes.bool,
            postalServiceId: PropTypes.string.isRequired,
            actions: PropTypes.object.isRequired,
        };

        static defaultProps = {
            item: {},
            postalService: undefined,
            isLoadingCosts: false,
        };

        constructor(props) {
            super(props);

            const { i18n, item } = this.props;
            const isEdit = props.modalType === coreConstants.MODAL_TYPE.edit;

            this.state = {
                fields: {
                    zone: {
                        id: isEdit ? _.get(item, 'id'): ""
                    },
                    costs: {
                        cost: isEdit ? _.get(item, 'cost'): 0,
                        defaultCompositeCost: {
                            perGramCost: isEdit ? _.get(item, 'defaultCompositeCost.perGramCost'): 0,
                            perPackageCost: isEdit ? _.get(item, 'defaultCompositeCost.perPackageCost'): 0,
                            surcharge: isEdit ? _.get(item, 'defaultCompositeCost.surcharge'): 0,
                        },
                        conditionalCompositeCosts: isEdit ?
                            _.chain(item)
                                .get('conditionalCompositeCosts', [])
                                .map(i => ({ ...i, id: uniqid(), type: i.height !== null ? MEASURES[0] : MEASURES[1], value: i.height !== null ? i.height : i.weight }))
                                .value() : [],
                    },
                },
                modalValues: DEFAULT_VALUES,
                selectedForEditTypeId: null,
            };

            this.fields = this.props.modalType === coreConstants.MODAL_TYPE.edit ?
                COSTS_MAIN_FIELDS(i18n) :
                [...ZONE_FIELD(i18n, this.onSearch), ...COSTS_MAIN_FIELDS(i18n)];

            this.modalFields = [
                {
                    name: COMPOSITE_COST_TYPE_FIELDS.value,
                    placeholder: i18n.t('settings.postalServiceTable.compositeCostType.value'),
                    type: coreConstants.INPUT_TYPES.number,
                    breakpoint: { sm: 7 }
                },
                {
                    name: COMPOSITE_COST_TYPE_FIELDS.type,
                    placeholder: i18n.t('settings.postalServiceTable.compositeCostType.measure'),
                    isRequired: true,
                    type: CUSTOM_SELECT,
                    options: MEASURES,
                    breakpoint: { sm: 3 }
                }
            ];
        }

        onChangeValue = (val, field) => {
            const newValue = field.type === coreConstants.INPUT_TYPES.number ? _.round(val * 1, ROUND_VALUES_PERCENT) : val;

            this.setState(prevProps => ({ fields: {
                ...prevProps.fields,
                ..._.set(prevProps.fields, field.name, newValue)
            } }))
        };

        onChangeCompositeCost = (value, fieldName, id) => {
            this.setState(prevProps => {
                if (id === COMPOSITE_DEFAULT_ID) {
                    return {
                        fields: {
                            ...prevProps.fields,
                            costs: {
                                ...prevProps.fields.costs,
                                defaultCompositeCost: {
                                    ...prevProps.fields.costs.defaultCompositeCost,
                                    [fieldName]: value
                                }
                            }
                        }
                    }
                }

                const oldRecord = _.find(prevProps.fields.costs.conditionalCompositeCosts, i => i.id === id);
                const arr = prevProps.fields.costs.conditionalCompositeCosts;
                arr.splice(
                    _.findIndex(prevProps.fields.costs.conditionalCompositeCosts, { id }),
                    1,
                    {
                        ...oldRecord,
                        [fieldName]: value,
                    }
                );
                return {
                    fields: {
                        ...prevProps.fields,
                        costs: {
                            ...prevProps.fields.costs,
                            conditionalCompositeCosts: arr
                        }
                    } } })
        };

        onAddCompositeCost = val => {
            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    costs: {
                        ...prevState.fields.costs,
                        conditionalCompositeCosts: [
                            ...prevState.fields.costs.conditionalCompositeCosts,
                            {
                                perGramCost: 0,
                                perPackageCost: 0,
                                surcharge: 0,
                                ...val,
                                id: uniqid()
                            }
                        ]
                    }
                }
            }))
        };

        onDeleteCompositeCost = id => {
            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    costs: {
                        ...prevState.fields.costs,
                        conditionalCompositeCosts: _.filter(prevState.fields.costs.conditionalCompositeCosts, item => item.id !== id),
                    }
                }
            }))
        };

        onEditCompositeCost = id => {
            this.setState(prevState => {
                const item = _.find(prevState.fields.costs.conditionalCompositeCosts, i => i.id === id);
                return {
                    selectedForEditTypeId: id,
                    modalValues: {
                        type: item.type,
                        value: item.value,
                    },
                }
            });
        };

        onSaveEditedCompositeCost = () => {
            const { value, type } = this.state.modalValues;
            const some = _.find(this.state.fields.costs.conditionalCompositeCosts, i => i.value === parseFloat(value) && i.type.id === type.id && i.id !== this.state.selectedForEditTypeId);
            if (!value || !type || !!some) {
                Toast.error({ description: this.props.i18n.t('toast.compositeExistError') })
            } else {
                this.setState(prevProps => {
                    const oldRecord = _.find(prevProps.fields.costs.conditionalCompositeCosts, i => i.id === prevProps.selectedForEditTypeId);
                    const arr = prevProps.fields.costs.conditionalCompositeCosts;
                    arr.splice(
                        _.findIndex(prevProps.fields.costs.conditionalCompositeCosts, { id: prevProps.selectedForEditTypeId }),
                        1,
                        {
                            ...oldRecord,
                            ...prevProps.modalValues,
                        }
                    );
                    return {
                        modalValues: DEFAULT_VALUES,
                        selectedForEditTypeId: null,
                        fields: {
                            ...prevProps.fields,
                            costs: {
                                ...prevProps.fields.costs,
                                conditionalCompositeCosts: arr
                            }
                        } } })
            }
        };

        onCloseEditCompositeCostModal = () => {
            this.setState({ selectedForEditTypeId: null })
        };

        onChangeCompositeOrder = costs => {
            const filtered = _.filter(costs, i => i.id !== COMPOSITE_DEFAULT_ID);
            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    costs: {
                        ...prevState.fields.costs,
                        conditionalCompositeCosts: filtered,
                    }
                }
            }))
        };

        updatePostalService = () => {
            const { fields } = this.state;
            const { i18n, postalService, postalServiceId } = this.props;

            const errors = postalServiceValidator.validateCost(
                fields,
                i18n,
                this.props.modalType !== coreConstants.MODAL_TYPE.edit ? _.map(postalService.costs, (value, key) => key): []);
            if (errors.length) {
                Toast.error(errors[0]);
                return;
            }

            const { id, name, costs } = postalService.costs;
            this.props.actions.updateCosts(postalServiceId, {
                id,
                name,
                costs: {
                    ...costs,
                    [fields.zone.id]: {
                        ...fields.costs,
                        conditionalCompositeCosts: _.map(fields.costs.conditionalCompositeCosts, i => ({
                            perGramCost: i.perGramCost,
                            perPackageCost: i.perPackageCost,
                            surcharge: i.surcharge,
                            [i.type.name]: i.value
                        }))
                    }
                }
            });
        };

        onSearch = (filterText, field, isMore, page) => this.props.actions.getZones({
            page: isMore ? page : 1,
            count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
            filterText
        }, true);

        onChangeModalValue = (val, field) => {
            this.setState(prevState => ({
                modalValues: {
                    ...prevState.modalValues,
                    [field.name]: field.name === 'type' ? _.find(MEASURES, i => i.id === val) : val,
                }
            }))
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    updatePostalService={this.updatePostalService}
                    values={this.state.fields}
                    fields={this.fields}
                    onChangeValue={this.onChangeValue}
                    onAddCompositeCost={this.onAddCompositeCost}
                    onDeleteCompositeCost={this.onDeleteCompositeCost}
                    onChangeCompositeCost={this.onChangeCompositeCost}
                    onChangeCompositeOrder={this.onChangeCompositeOrder}
                    onEditCompositeCost={this.onEditCompositeCost}
                    onSaveEditedCompositeCost={this.onSaveEditedCompositeCost}
                    onCloseEditCompositeCostModal={this.onCloseEditCompositeCostModal}
                    selectedForEditTypeId={this.state.selectedForEditTypeId}
                    modalValues={this.state.modalValues}
                    modalFields={this.modalFields}
                    onChangeModalValue={this.onChangeModalValue}
                    isDisabledEditBtn={this.isDisabledEditBtn}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        isUpdating: selectors.isUpdatingPostalServiceById(state, ownProps.postalServiceId),
        isLoading: {
            [SELECT_FIELDS.zones]: selectors.isZones(state),
        },
        [SELECT_FIELDS.zones]: selectors.getZonesItems(state),
        pagination: {
            [SELECT_FIELDS.zones]: selectors.getZonesScrollPagination(state),
        },
        postalService: selectors.getPostalServiceById(state, ownProps.postalServiceId),
        updatePostalServiceError: selectors.getUpdatePostalServiceError(state)

    });

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CostModalBase));
}
