export const NAME = 'auth';

export const AUTH_FIELDS = {
    email: 'email',
    password: 'password',
    name: 'name',
    confirmPassword: 'confirmPassword',
    linnworkstoken: 'linnworkstoken'
};

export const PASSWORD_MIN_LENGTH = 6;

export const ERROR_CODES = {
    EmailNotConfirmed: 'EmailNotConfirmed',
    TrialExpired: 'TrialExpired',
    NoPlan: 'NoPlan'
};
