import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from "../Tooltip";
import IconButton from "../IconButton";

class TooltipButton extends React.PureComponent {
    static propTypes = {
        title: PropTypes.node.isRequired,
        icon: PropTypes.element.isRequired,
        onClick: PropTypes.func.isRequired,
        iconClass: PropTypes.string,
    };

    static defaultProps = {
        iconClass: undefined,
    };

    render() {
        const { icon, title, onClick, iconClass } = this.props;
        return (
            <Tooltip title={title}>
                <IconButton
                    className={iconClass}
                    aria-label={title}
                    onClick={onClick}>
                    {icon}
                </IconButton>
            </Tooltip>
        );
    }
}

export default TooltipButton;
