import React from 'react';
import PropTypes from 'prop-types';
import * as _ from "lodash";
import withMarketplaceModalBase from "./MarketplaceModal.base";
import { MODAL_WIDTH } from "../../../constants";
import { components as Core, withStyles, constants as coreConstants } from "../../../../core";
import { colors, fonts } from "../../../../../styles";

class MarketplaceModal extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
        fields: PropTypes.array.isRequired,
        onChangeValue: PropTypes.func.isRequired,
        addMarketplace: PropTypes.func.isRequired,
        modalType: PropTypes.number.isRequired,
        isAdding: PropTypes.bool,
        isUpdating: PropTypes.bool,
        isLoadingFees: PropTypes.bool,
        pagination: PropTypes.object,
        isLoading: PropTypes.object.isRequired,
        initNames: PropTypes.object.isRequired,
        addMarketplaceError: PropTypes.array,
        updateMarketplaceError: PropTypes.array,
    };

    static defaultProps = {
        isAdding: false,
        isUpdating: false,
        isLoadingFees: false,
        pagination: {},
        addMarketplaceError: undefined,
        updateMarketplaceError: undefined
    };

    componentDidUpdate(prevProps) {
        if ((prevProps.isAdding && !this.props.isAdding && !this.props.addMarketplaceError) ||
            (prevProps.isUpdating && !this.props.isUpdating && !this.props.updateMarketplaceError)) {
            this.props.onClose();
        }
    }

    getSelectOption = item => ({ value: item.id, label: item.name });

    renderField = field => (
        <Core.Grid item={true} xs={12} sm={6} key={field.name}>
            {field.type !== coreConstants.INPUT_TYPES.select ? (
                <Core.FormInput
                    key={field.name}
                    required={field.isRequired}
                    htmlFor={field.name}
                    autoFocus={field.autoFocus}
                    inputLabel={field.placeholder}
                    onChange={event => this.props.onChangeValue(field.isPercentage ? event.target.value / 100 : event.target.value, field)}
                    defaultValue={_.toString(field.isPercentage ?
                        _.get(this.props.values, field.name, 0) * 100
                        : _.get(this.props.values, field.name, field.type === coreConstants.INPUT_TYPES.number ? 0 : ""))}
                    type={field.type}
                    endAdornment={field.endAdornment}
                />
            ): (
                <Core.SearchableFormSelect
                    key={field.name}
                    inputLabel={field.placeholder}
                    isRequired={field.isRequired}
                    onChange={event => this.props.onChangeValue(event.value, field)}
                    value={_.get(this.props.values, field.name)}
                    onSearch={field.onSearch}
                    isLoading={this.props.isLoading[field.selectName]}
                    selectName={field.selectName}
                    placeholder={this.props.initNames[field.selectName]}
                    pagination={this.props.pagination[field.selectName]}
                    options={_.map(this.props[field.selectName], this.getSelectOption)}
                />
            )}
        </Core.Grid>
    );

    render() {
        const { i18n, isVisible, onClose, isAdding, fields, classes, modalType, isLoadingFees, isUpdating, addMarketplace } = this.props;
        return (
            <Core.Modal
                visible={isVisible}
                title={modalType === coreConstants.MODAL_TYPE.edit ?
                    i18n.t('settings.marketplaceModal.editTitle') :
                    i18n.t('settings.marketplaceModal.title')}
                onClose={onClose}
                onAccept={addMarketplace}
                isLoading={isAdding || isLoadingFees || isUpdating}
                className={classes.modalContainer}>
                <div className={classes.container}>
                    <Core.Grid container={true} spacing={24}>
                        {_.map(fields, this.renderField)}
                    </Core.Grid>
                </div>
            </Core.Modal>
        )
    }
}

const styles = theme => ({
    container: {
        color: colors.background,
        flex: 1,
        ...fonts.smNormal
    },
    modalContainer: {
        [theme.breakpoints.up("md")]: {
            width: MODAL_WIDTH.partWidth,
        },
        [theme.breakpoints.down("sm")]: {
            width: `${MODAL_WIDTH.fullWidth}vw`,
        },
    },
});


export default withMarketplaceModalBase(withStyles(styles)(MarketplaceModal));
