import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import withSignInBase from './SignIn.base';
import { constants as coreConstants, components as Core } from '../../../core';
import { fonts, spacing, colors } from '../../../../styles';

const BUTTON_REF = "BUTTON_REF";

class SignIn extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        fields: PropTypes.array.isRequired,
        values: PropTypes.object.isRequired,
        onChangeValue: PropTypes.func.isRequired,
        onSignIn: PropTypes.func.isRequired,
        isSignIn: PropTypes.bool.isRequired,
        errors: PropTypes.object,
        isDisabledSignIn: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        onConfirmEmail: PropTypes.func.isRequired,
        returnToSignIn: PropTypes.func.isRequired,
        isSendingVerifyToken: PropTypes.bool.isRequired,
        isSentVerifyToken: PropTypes.bool.isRequired,
        isHandledError: PropTypes.bool.isRequired,
        isSendingResetPassword: PropTypes.bool.isRequired,
        isAccountLoading: PropTypes.bool,
        isTrialExpired: PropTypes.bool.isRequired,
        isNoPlan: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        errors: [],
        error: '',
        isAccountLoading: false,
    };

    _handleKeyDown = e => {
        if (e.key === 'Enter' && !this.props.isDisabledSignIn) {
            this.props.onSignIn();
        }
    };

    get renderSignInForm() {
        const { classes, fields, i18n, isDisabledSignIn, isSignIn, isSendingResetPassword, isAccountLoading } = this.props;
        return (
            <Core.Spin spinning={isSignIn || isSendingResetPassword || isAccountLoading}>
                <Core.Form headerLabel={i18n.t('auth.signIn')} isLogo={true}>
                    <div onKeyDown={this._handleKeyDown}>
                        {_.map(fields, this.renderField)}
                        <div className={classes.resetPassword} onClick={this.onResetPassword}>
                            <span className={classes.signUpButton}>
                                {this.props.i18n.t("auth.resetPassword")}
                            </span>
                        </div>
                        <Core.Button
                            fullWidth={true}
                            variant="contained"
                            color="primary"
                            onClick={this.props.onSignIn}
                            disabled={isDisabledSignIn}
                            className={classes.submit}>
                            {i18n.t('auth.signIn')}
                        </Core.Button>
                        <div className={classes.link}>
                            <span>{this.props.i18n.t('auth.newHere')}</span>
                            <span className={classes.signUpButton} onClick={this.changeLocation}>
                                {this.props.i18n.t("auth.signUp")}
                            </span>
                        </div>
                    </div>
                </Core.Form>
            </Core.Spin>
        )
    }

    get errorDetails() {
        const { i18n, onConfirmEmail, isSentVerifyToken, isTrialExpired, isNoPlan } = this.props;

        if (isNoPlan) {
            return {
                title: i18n.t("noPlan.noPlan"),
                description: i18n.t("noPlan.description"),
                buttonText: i18n.t('noPlan.action'),
                onButtonPress: this.redirectToSignIn,
            }
        }
        if (isTrialExpired) {
            return {
                title: i18n.t("trial.expired"),
                description: i18n.t("trial.description"),
                buttonText: i18n.t('trial.action'),
                onButtonPress: this.redirectToSignIn,
            }
        }

        return {
            title: i18n.t("verifyEmailSent"),
            description: i18n.t("emailNotConfirmed"),
            buttonText: i18n.t('clickToSendEmail'),
            onButtonPress: onConfirmEmail,
            condition: isSentVerifyToken
        };
    }

    get renderErrorMessage() {
        const { classes, isDisabledSignIn, isSignIn } = this.props;
        return (
            <Core.Spin
                spinning={isSignIn}>
                <Core.Form headerLabel={this.errorDetails.title} className={classes.errorContainer}>
                    <span>{this.errorDetails.description}</span>
                    <Core.Button
                        fullWidth={true}
                        variant="contained"
                        color="primary"
                        onClick={this.errorDetails.onButtonPress}
                        className={classes.submit}>
                        {this.errorDetails.buttonText}
                    </Core.Button>
                </Core.Form>
            </Core.Spin>
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.isSignIn && !nextProps.isSignIn && !nextProps.error) {
            this.props.history.push(coreConstants.ROUTES.inventory());
        }
    }

    redirectToSignIn = () => {
        this.props.actions.removeTrialExpiredError();
        this.props.actions.removeNoPlanError();
        this.props.history.push(coreConstants.ROUTES.signIn());
    };

    changeLocation = () => {
        this.props.history.push(coreConstants.ROUTES.signUp())
    };


    onResetPassword = () => {
        this.props.history.push(coreConstants.ROUTES.resetPasswordToken());
    };

    renderField = field => (
        <div key={`${field.name}-authForm`}>
            <Core.FormInput
                required={field.isRequired}
                htmlFor={field.name}
                autoFocus={field.autoFocus}
                inputLabel={field.placeholder}
                onChange={event => this.props.onChangeValue(event.target.value, field)}
                type={field.type}
            />
        </div>
    );

    render() {
        const { isHandledError, isTrialExpired, isNoPlan } = this.props;
        return (
            <Core.MainContainer>
                {isHandledError || isTrialExpired || isNoPlan ? this.renderErrorMessage : this.renderSignInForm}
            </Core.MainContainer>
        );
    }
}

const styles = theme => ({
    link: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing.unit * 2,
        justifyContent: 'center',
        ...fonts.smLight,
    },
    signUpButton: {
        cursor: 'pointer',
        color: colors.link,
        '&:hover,&:focus': {
            textDecoration: 'underline',
        },
        ...fonts.smLight,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
    },
    resetPassword: {
        float: 'right',
        paddingTop: `${spacing.s2}px`,
        cursor: 'pointer',
        color: colors.link,
        '&:hover,&:focus': {
            textDecoration: 'underline',
        }
    },
    errorContainer: {
        textAlign: 'center',
    }
});

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSignInBase(SignIn));
