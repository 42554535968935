import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { AUTH_FIELDS as FIELDS, ERROR_CODES } from '../../constants';
import * as actions from '../../actions';
import { constants as coreConstants, translate, fieldValidator, Toast } from '../../../core';
import * as selectors from '../../selectors';
import { selectors as accountSelectors } from '../../../account';

export default function withSignInBase(WrappedComponent) {
    class SignInBase extends React.PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            isSignIn: PropTypes.bool,
            error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            isSendingVerifyToken: PropTypes.bool,
        };

        static defaultProps = {
            isSignIn: false,
            error: null,
            isSendingVerifyToken: false,
        };

        constructor(props) {
            super(props);
            const { i18n } = this.props;

            this.state = {
                fields: {
                    [FIELDS.email]: '',
                    [FIELDS.password]: '',
                },
                errors: {
                    [FIELDS.email]: '',
                    [FIELDS.password]: ''
                },
                isSentVerifyToken: false,
            };

            this.fields = [
                {
                    name: FIELDS.email,
                    placeholder: i18n.t('fields.email'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.email,
                    autoFocus: true,
                },
                {
                    name: FIELDS.password,
                    placeholder: i18n.t('fields.password'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.password
                }
            ];

            this.props.actions.removeSignInError();
        }

        get isDisabledSignIn() {
            return !fieldValidator.isValidRequiredFields(this.fields, this.state.fields);
        }

        get isHandledError() {
            return this.props.error === ERROR_CODES.EmailNotConfirmed;
        }

        onChangeValue = (val, field) => {
            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    [field.name]: val
                }
            }))
        };

        onConfirmEmail = () => {
            this.setState({ isSentVerifyToken: true });
            this.props.actions.sendVerifyEmailToken(this.state.fields[FIELDS.email]);
        };

        returnToSignIn = () => {
            this.setState({ isSentVerifyToken: false });
            this.props.actions.removeSignInError();
        };

        onSignIn = () => {
            if (!fieldValidator.isValidEmail(this.state.fields[FIELDS.email])) {
                Toast.error({ description: this.props.i18n.t('toast.incorrectEmail') })
            } else {
                this.props.actions.signIn(
                    this.state.fields[FIELDS.email],
                    this.state.fields[FIELDS.password]
                );
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    values={this.state.fields}
                    fields={this.fields}
                    onChangeValue={this.onChangeValue}
                    onSignIn={this.onSignIn}
                    isDisabledSignIn={this.isDisabledSignIn}
                    errors={this.state.errors}
                    onConfirmEmail={this.onConfirmEmail}
                    returnToSignIn={this.returnToSignIn}
                    isSentVerifyToken={this.state.isSentVerifyToken}
                    isHandledError={this.isHandledError}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isSignIn: selectors.isSignIn(state),
            error: selectors.getSignInError(state),
            isSendingVerifyToken: selectors.isSendingVerifyToken(state),
            isSendingResetPassword: selectors.isResettingPassword(state),
            isAccountLoading: accountSelectors.isAccount(state),
            isTrialExpired: selectors.getSubscriptionExpiredStatus(state),
            isNoPlan: selectors.getNoPlanStatus(state)
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(SignInBase));
}
