import _ from "lodash";
import * as actionTypes from "./actionTypes";
import * as api from './api';
import { normalize, actions as coreActions, handleErrors, Toast, constants as coreConstants, createFileLink } from '../core';
import { ALL_MODULES, MODULES } from "./constants";
import { importErrorHandler } from './services';
import i18n from "../../i18n/i18n";

const EXPORT_FILE_NAMES = {
    importExample: 'ImportExample',
    settings: 'Settings',
};

//currencies

export function getCurrenciesRate(params = { page: 1, count: coreConstants.DEFAULT_ITEMS_PER_PAGE }, isAllItems = false) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_CURRENCIES_RATE.REQUEST });
        return api.getCurrenciesRate(params)
            .then(response => {
                const { items, ...pagination } = response.data;
                dispatch({
                    type: actionTypes.GET_CURRENCIES_RATE.SUCCESS,
                    payload: {
                        data: normalize.normalizeArray({ items }),
                        pagination,
                        isAllItems
                    }
                })
            })
            .catch(error => {
                dispatch({ type: actionTypes.GET_CURRENCIES_RATE.ERROR });
            });
    }
}
export function addCurrency(data) {
    return function (dispatch) {
        dispatch(removeAddCurrencyError());
        dispatch({ type: actionTypes.ADD_CURRENCY.REQUEST });
        return api.addCurrency({ ...data })
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.ADD_CURRENCY.SUCCESS,
                    payload: { currency: response.data }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.ADD_CURRENCY.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.ADD_CURRENCY.ERROR });
            });
    }
}
export function removeAddCurrencyError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.ADD_CURRENCY.NAME));
    }
}
export function deleteCurrency(currencyId) {
    return function (dispatch) {
        dispatch({ type: actionTypes.DELETE_CURRENCY.REQUEST });
        return api.deleteCurrency(currencyId)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.DELETE_CURRENCY.SUCCESS,
                    payload: { currencyId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.DELETE_CURRENCY.ERROR });
            });
    }
}
export function updateCurrency(currencyId, data) {
    return function (dispatch) {
        dispatch(removeUpdateCurrencyError());
        dispatch({ type: actionTypes.UPDATE_CURRENCY.REQUEST });
        return api.updateCurrency(currencyId, data)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.UPDATE_CURRENCY.SUCCESS,
                    payload: { data: response.data, currencyId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.UPDATE_CURRENCY.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.UPDATE_CURRENCY.ERROR });
            });
    }
}
export function removeUpdateCurrencyError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.UPDATE_CURRENCY.NAME));
    }
}

//zones

export function getZones(params = { page: 1, count: coreConstants.DEFAULT_ITEMS_PER_PAGE }, isAllItems = false) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_ZONES.REQUEST });
        return api.getZones(params)
            .then(response => {
                const { items, ...pagination } = response.data;
                dispatch({
                    type: actionTypes.GET_ZONES.SUCCESS,
                    payload: {
                        data: normalize.normalizeArray({ items }),
                        pagination,
                        isAllItems
                    }
                })
            })
            .catch(error => {
                dispatch({ type: actionTypes.GET_ZONES.ERROR });
            });
    }
}
export function addZone(data) {
    return function (dispatch) {
        dispatch(removeAddZoneError());
        dispatch({ type: actionTypes.ADD_ZONE.REQUEST });
        return api.addZone({ ...data })
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.ADD_ZONE.SUCCESS,
                    payload: { zone: response.data }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.ADD_ZONE.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.ADD_ZONE.ERROR });
            });
    }
}
export function removeAddZoneError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.ADD_ZONE.NAME));
    }
}
export function deleteZone(zoneId) {
    return function (dispatch) {
        dispatch({ type: actionTypes.DELETE_ZONE.REQUEST });
        return api.deleteZone(zoneId)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.DELETE_ZONE.SUCCESS,
                    payload: { zoneId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.DELETE_ZONE.ERROR });
            });
    }
}
export function updateZone(zoneId, data) {
    return function (dispatch) {
        dispatch(removeUpdateZoneError());
        dispatch({ type: actionTypes.UPDATE_ZONE.REQUEST });
        return api.updateZone(zoneId, data)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.UPDATE_ZONE.SUCCESS,
                    payload: { data: response.data, zoneId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.UPDATE_ZONE.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.UPDATE_ZONE.ERROR });
            });
    }
}
export function removeUpdateZoneError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.UPDATE_ZONE.NAME));
    }
}

//company

export function getCompanies(params = { page: 1, count: coreConstants.DEFAULT_ITEMS_PER_PAGE }, isAllItems = false) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_COMPANIES.REQUEST });
        return api.getCompanies(params)
            .then(response => {
                const { items, ...pagination } = response.data;
                dispatch({
                    type: actionTypes.GET_COMPANIES.SUCCESS,
                    payload: {
                        data: normalize.normalizeArray({ items }),
                        pagination,
                        isAllItems
                    }
                })
            })
            .catch(error => {
                dispatch({ type: actionTypes.GET_COMPANIES.ERROR });
            });
    }
}
export function addCompany(data) {
    return function (dispatch) {
        dispatch(removeAddCompanyError());
        dispatch({ type: actionTypes.ADD_COMPANY.REQUEST });
        return api.addCompany({ ...data })
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.ADD_COMPANY.SUCCESS,
                    payload: { company: response.data }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.ADD_COMPANY.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.ADD_COMPANY.ERROR });
            });
    }
}
export function removeAddCompanyError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.ADD_COMPANY.NAME));
    }
}
export function deleteCompany(companyId) {
    return function (dispatch) {
        dispatch({ type: actionTypes.DELETE_COMPANY.REQUEST });
        return api.deleteCompany(companyId)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.DELETE_COMPANY.SUCCESS,
                    payload: { companyId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.DELETE_COMPANY.ERROR });
            });
    }
}
export function updateCompany(companyId, data) {
    return function (dispatch) {
        dispatch(removeUpdateCompanyError());
        dispatch({ type: actionTypes.UPDATE_COMPANY.REQUEST });
        return api.updateCompany(companyId, data)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.UPDATE_COMPANY.SUCCESS,
                    payload: { data: response.data, companyId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.UPDATE_COMPANY.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.UPDATE_COMPANY.ERROR });
            });
    }
}
export function removeUpdateCompanyError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.UPDATE_COMPANY.NAME));
    }
}

// Marketplace

export function getMarketplaces(params = { page: 1, count: coreConstants.DEFAULT_ITEMS_PER_PAGE }) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_MARKETPLACES.REQUEST });
        return api.getMarketplaces(params)
            .then(response => {
                const { items, ...pagination } = response.data;
                dispatch({
                    type: actionTypes.GET_MARKETPLACES.SUCCESS,
                    payload: {
                        data: normalize.normalizeArray({ items }),
                        pagination,
                    }
                })
            })
            .catch(error => {
                dispatch({ type: actionTypes.GET_MARKETPLACES.ERROR });
            });
    }
}
export function getMarketplaceFees(marketplaceId) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_MARKETPLACE_FEES.REQUEST, requestId: marketplaceId });
        return api.getMarketplaceFees(marketplaceId)
            .then(response => {
                dispatch({
                    type: actionTypes.GET_MARKETPLACE_FEES.SUCCESS,
                    requestId: marketplaceId,
                    payload: {
                        data: response.data,
                        marketplaceId
                    }
                })
            })
            .catch(error => {
                dispatch({ type: actionTypes.GET_MARKETPLACE_FEES.ERROR });
            });
    }
}
export function addMarketplace(data) {
    return function (dispatch) {
        dispatch(removeAddMarketplaceError());
        dispatch({ type: actionTypes.ADD_MARKETPLACE.REQUEST });
        return api.addMarketplace({ ...data })
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.ADD_MARKETPLACE.SUCCESS,
                    payload: { marketplace: response.data }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.ADD_MARKETPLACE.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.ADD_MARKETPLACE.ERROR });
            });
    }
}
export function removeAddMarketplaceError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.ADD_MARKETPLACE.NAME));
    }
}
export function deleteMarketplace(marketplaceId) {
    return function (dispatch) {
        dispatch({ type: actionTypes.DELETE_MARKETPLACE.REQUEST });
        return api.deleteMarketplace(marketplaceId)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.DELETE_MARKETPLACE.SUCCESS,
                    payload: { marketplaceId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.DELETE_MARKETPLACE.ERROR });
            });
    }
}
export function updateMarketplace(marketplaceId, data) {
    return function (dispatch) {
        dispatch(removeUpdateMarketplaceError());
        dispatch({ type: actionTypes.UPDATE_MARKETPLACE.REQUEST });
        return api.updateMarketplace(marketplaceId, data)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.UPDATE_MARKETPLACE.SUCCESS,
                    payload: { data: response.data, marketplaceId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.UPDATE_MARKETPLACE.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.UPDATE_MARKETPLACE.ERROR });
            });
    }
}
export function updateFee(marketplaceId, data) {
    return function (dispatch) {
        dispatch(removeUpdateMarketplaceError());
        dispatch({ type: actionTypes.UPDATE_MARKETPLACE.REQUEST, requestId: marketplaceId });
        return api.updateMarketplace(marketplaceId, data)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.UPDATE_MARKETPLACE.SUCCESS,
                    requestId: marketplaceId,
                    payload: { data: response.data, marketplaceId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.UPDATE_MARKETPLACE.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.UPDATE_MARKETPLACE.ERROR });
            });
    }
}
export function removeUpdateMarketplaceError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.UPDATE_MARKETPLACE.NAME));
    }
}

// Postal Services

export function getPostalServices(params = { page: 1, count: coreConstants.DEFAULT_ITEMS_PER_PAGE }, isAllItems = false) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_POSTAL_SERVICES.REQUEST });
        return api.getPostalServices(params)
            .then(response => {
                const { items, ...pagination } = response.data;
                dispatch({
                    type: actionTypes.GET_POSTAL_SERVICES.SUCCESS,
                    payload: {
                        data: normalize.normalizeArray({ items }),
                        pagination,
                        isAllItems
                    }
                })
            })
            .catch(error => {
                dispatch({ type: actionTypes.GET_POSTAL_SERVICES.ERROR });
            });
    }
}
export function getPostalServiceCosts(postalServiceId) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_POSTAL_SERVICE_COSTS.REQUEST, requestId: postalServiceId });
        return api.getPostalServiceCosts(postalServiceId)
            .then(response => {
                dispatch({
                    type: actionTypes.GET_POSTAL_SERVICE_COSTS.SUCCESS,
                    requestId: postalServiceId,
                    payload: {
                        data: response.data,
                        postalServiceId
                    }
                })
            })
            .catch(error => {
                dispatch({ type: actionTypes.GET_POSTAL_SERVICE_COSTS.ERROR });
            });
    }
}

export function updateCompositeCostTypes(compositeCostTypes, postalServiceId) {
    return function (dispatch) {
        dispatch({ type: actionTypes.UPDATE_COMPOSITE_COST_TYPE.REQUEST });

        //TO DO: ADD API
        dispatch({
            type: actionTypes.UPDATE_COMPOSITE_COST_TYPE.SUCCESS,
            requestId: postalServiceId,
            payload: {
                data: compositeCostTypes,
                postalServiceId
            }
        });
    }
}

export function addPostalService(data) {
    return function (dispatch) {
        dispatch(removeAddPostalServiceError());
        dispatch({ type: actionTypes.ADD_POSTAL_SERVICE.REQUEST });
        return api.addPostalService({ ...data })
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.ADD_POSTAL_SERVICE.SUCCESS,
                    payload: { postalService: response.data }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.ADD_POSTAL_SERVICE.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.ADD_POSTAL_SERVICE.ERROR });
            });
    }
}
export function removeAddPostalServiceError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.ADD_POSTAL_SERVICE.NAME));
    }
}
export function deletePostalService(postalServiceId) {
    return function (dispatch) {
        dispatch({ type: actionTypes.DELETE_POSTAL_SERVICE.REQUEST });
        return api.deletePostalService(postalServiceId)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.DELETE_POSTAL_SERVICE.SUCCESS,
                    payload: { postalServiceId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.DELETE_POSTAL_SERVICE.ERROR });
            });
    }
}
export function updatePostalService(postalServiceId, data) {
    return function (dispatch) {
        dispatch(removeUpdatePostalServiceError());
        dispatch({ type: actionTypes.UPDATE_POSTAL_SERVICE.REQUEST });
        return api.updatePostalService(postalServiceId, data)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.UPDATE_POSTAL_SERVICE.SUCCESS,
                    payload: { data: response.data, postalServiceId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.UPDATE_POSTAL_SERVICE.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.UPDATE_POSTAL_SERVICE.ERROR });
            });
    }
}
export function removeUpdatePostalServiceError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.UPDATE_POSTAL_SERVICE.NAME));
    }
}
export function updateCosts(postalServiceId, data) {
    return function (dispatch) {
        dispatch(removeUpdateCostsError());
        dispatch({ type: actionTypes.UPDATE_POSTAL_SERVICE.REQUEST, requestId: postalServiceId });
        return api.updatePostalService(postalServiceId, data)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.UPDATE_POSTAL_SERVICE.SUCCESS,
                    requestId: postalServiceId,
                    payload: { data: response.data, postalServiceId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.UPDATE_POSTAL_SERVICE.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.UPDATE_MARKETPLACE.ERROR });
            });
    }
}
export function removeUpdateCostsError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.UPDATE_POSTAL_SERVICE.NAME));
    }
}

// Packagings

export function getPackagings(params = { page: 1, count: coreConstants.DEFAULT_ITEMS_PER_PAGE }, isAllItems = false) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_PACKAGINGS.REQUEST });
        return api.getPackagings(params)
            .then(response => {
                const { items, ...pagination } = response.data;
                dispatch({
                    type: actionTypes.GET_PACKAGINGS.SUCCESS,
                    payload: {
                        data: normalize.normalizeArray({ items }),
                        pagination,
                        isAllItems
                    }
                })
            })
            .catch(error => {
                dispatch({ type: actionTypes.GET_PACKAGINGS.ERROR });
            });
    }
}
export function addPackaging(data) {
    return function (dispatch) {
        dispatch(removeAddPackagingError());
        dispatch({ type: actionTypes.ADD_PACKAGING.REQUEST });
        return api.addPackaging({ ...data })
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.ADD_PACKAGING.SUCCESS,
                    payload: { packaging: response.data }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.ADD_PACKAGING.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.ADD_PACKAGING.ERROR });
            });
    }
}
export function removeAddPackagingError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.ADD_PACKAGING.NAME));
    }
}
export function deletePackaging(packagingId) {
    return function (dispatch) {
        dispatch({ type: actionTypes.DELETE_PACKAGING.REQUEST });
        return api.deletePackaging(packagingId)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.DELETE_PACKAGING.SUCCESS,
                    payload: { packagingId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.DELETE_PACKAGING.ERROR });
            });
    }
}
export function updatePackaging(packagingId, data) {
    return function (dispatch) {
        dispatch(removeUpdatePackagingError());
        dispatch({ type: actionTypes.UPDATE_PACKAGING.REQUEST });
        return api.updatePackaging(packagingId, data)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.UPDATE_PACKAGING.SUCCESS,
                    payload: { data: response.data, packagingId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.UPDATE_PACKAGING.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.UPDATE_PACKAGING.ERROR });
            });
    }
}
export function removeUpdatePackagingError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.UPDATE_PACKAGING.NAME));
    }
}

// Static Values

export function getStaticValues(params = { page: 1, count: coreConstants.DEFAULT_ITEMS_PER_PAGE }) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_STATIC_VALUES.REQUEST });
        return api.getStaticValues(params)
            .then(response => {
                const { items, ...pagination } = response.data;
                dispatch({
                    type: actionTypes.GET_STATIC_VALUES.SUCCESS,
                    payload: {
                        data: normalize.normalizeArray({ items }),
                        pagination
                    }
                })
            })
            .catch(error => {
                dispatch({ type: actionTypes.GET_STATIC_VALUES.ERROR });
            });
    }
}
export function addStaticValue(data) {
    return function (dispatch) {
        dispatch(removeAddStaticValueError());
        dispatch({ type: actionTypes.ADD_STATIC_VALUE.REQUEST });
        return api.addStaticValue({ ...data })
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.ADD_STATIC_VALUE.SUCCESS,
                    payload: { staticValue: response.data }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.ADD_STATIC_VALUE.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.ADD_STATIC_VALUE.ERROR });
            });
    }
}
export function removeAddStaticValueError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.ADD_STATIC_VALUE.NAME));
    }
}
export function deleteStaticValue(staticValueId) {
    return function (dispatch) {
        dispatch({ type: actionTypes.DELETE_STATIC_VALUE.REQUEST });
        return api.deleteStaticValue(staticValueId)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.DELETE_STATIC_VALUE.SUCCESS,
                    payload: { staticValueId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.DELETE_STATIC_VALUE.ERROR });
            });
    }
}
export function updateStaticValue(staticValueId, data) {
    return function (dispatch) {
        dispatch(removeUpdateStaticValueError());
        dispatch({ type: actionTypes.UPDATE_STATIC_VALUE.REQUEST });
        return api.updateStaticValue(staticValueId, data)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.UPDATE_STATIC_VALUE.SUCCESS,
                    payload: { data: response.data, staticValueId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.UPDATE_STATIC_VALUE.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.UPDATE_STATIC_VALUE.ERROR });
            });
    }
}
export function removeUpdateStaticValueError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.UPDATE_STATIC_VALUE.NAME));
    }
}

// Export

export function exportSettings() {
    return function (dispatch) {
        dispatch({ type: actionTypes.EXPORT_SETTINGS.REQUEST });
        return api.exportSettings()
            .then(response => {
                createFileLink(response, EXPORT_FILE_NAMES.importExample, false);
                dispatch({
                    type: actionTypes.EXPORT_SETTINGS.SUCCESS,
                });
            })
            .catch(error => {
                dispatch({ type: actionTypes.EXPORT_SETTINGS.ERROR });
            });
    }
}


export function isVisibleImportModal() {
    return function (dispatch) {
        dispatch({ type: actionTypes.IS_VISIBLE_IMPORT_MODAL });
    }
}

export function isVisibleExportModal() {
    return function (dispatch) {
        dispatch({ type: actionTypes.IS_VISIBLE_EXPORT_MODAL });
    }
}

export function exportFiles(modules) {
    return function (dispatch) {
        const promises = _.map(modules, api.exportFile);

        dispatch({ type: actionTypes.EXPORT_FILES.REQUEST });
        return Promise.all(promises)
            .then(response => {
                _.map(response, (value, index) => createFileLink(value, `${EXPORT_FILE_NAMES.settings}-${modules[index]}`));
                dispatch({
                    type: actionTypes.EXPORT_FILES.SUCCESS,
                });
            })
            .catch(() => {
                dispatch({ type: actionTypes.EXPORT_FILES.ERROR });
            });
    }
}

export function importFiles(files) {
    return function (dispatch) {
        dispatch({ type: actionTypes.IMPORT_FILES.REQUEST });
        const filteredFiles = getFiles(_.pickBy(files));
        const primaryPromises = _.map(filteredFiles.primaryFiles, (value, name) => importFileRequest(value, name, dispatch));

        return Promise.all(primaryPromises)
            .then(() => {
                const secondaryPromises = _.map(filteredFiles.secondaryFiles, (value, name) => importFileRequest(value, name, dispatch));
                return Promise.all(secondaryPromises).then(() => {
                    dispatch({
                        type: actionTypes.IMPORT_FILES.SUCCESS,
                    });
                });
            });
    }
}

function importFileRequest(value, name, dispatch) {
    return api.importFile({ value, name })
        .then(() => {
            const func = getRefreshFunction(name);
            if (func) {
                dispatch(func());
                Toast.success({
                    message: `${i18n.t('settings.module')} ${name}`,
                    description: i18n.t('settings.importSuccess') });
            }
        })
        .catch(error => {
            importErrorHandler.importErrorHandler(error, name);
        })
}

function getFiles(files) {
    let primaryFiles = {};
    let secondaryFiles = {};

    _.map(files, (item, name) => {
        const module = _.find(MODULES, i => i.name === name);

        if (_.get(module, 'isSecondary', false)) {
            secondaryFiles = {
                ...secondaryFiles,
                [name]: item,
            }
        } else {
            primaryFiles = {
                ...primaryFiles,
                [name]: item,
            }
        }
    });

    return {
        primaryFiles,
        secondaryFiles,
    }
}

function getRefreshFunction(name) {
    switch (name) {
        case ALL_MODULES.currencies: {
            return getCurrenciesRate;
        }
        case ALL_MODULES.zones: {
            return getZones;
        }
        case ALL_MODULES.companies: {
            return getCompanies;
        }
        case ALL_MODULES.marketplaces: {
            return getMarketplaces;
        }
        case ALL_MODULES.packagings: {
            return getPackagings;
        }
        case ALL_MODULES.postalServices: {
            return getPostalServices;
        }
        case ALL_MODULES.staticValues: {
            return getStaticValues;
        }
        default: {
            return null
        }
    }
}
