import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import withCalculatorBase from './Calculator.base';
import { components as Core } from '../../../core';
import { colors, spacing } from "../../../../styles";
import Details from '../Inventory/Details';
import DefaultValues from '../Inventory/DefaultValues';

class Calculator extends React.Component {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        item: PropTypes.object.isRequired,
        isLoading: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        onSearch: PropTypes.func.isRequired,
        onChangeValue: PropTypes.func.isRequired,
        onCalc: PropTypes.func.isRequired,
        isDisabledCalcButton: PropTypes.bool,
        formattedMarkets: PropTypes.array,
        onChangeMarketsTable: PropTypes.func.isRequired,
        onApplyDefaultMarketValues: PropTypes.func.isRequired,
        isAdditionalDataLoading: PropTypes.bool,
        isCalculations: PropTypes.bool,
        isPercentage: PropTypes.bool,
        onChangeInputType: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isDisabledCalcButton: true,
        formattedMarkets: [],
        isAdditionalDataLoading: false,
        isCalculations: false,
        isPercentage: false,
    };

    get form() {
        return _.map(this.props.item, this.getFormItem)
    }

    getFormItem = (item, name) => {
        const { i18n, onChangeValue, onSearch, isLoading, pagination } = this.props;

        const params = {
            htmlFor: name,
            inputLabel: i18n.t(item.label),
        };

        return (
            <Core.Grid key={name} item={true} xs={12} sm={item.size || 3}>
                {item.isObject ? (
                    <Core.SearchableFormSelect
                        {...params}
                        key={name}
                        onChange={event => onChangeValue(name, { ...item.value, id: event.value })}
                        value={_.get(item, 'value.id', '')}
                        onSearch={onSearch}
                        isLoading={isLoading[item.selectName]}
                        selectName={item.selectName}
                        pagination={pagination[item.selectName]}
                        options={_.map(this.props[item.selectName], this.getSelectOption)}
                        isRequired={item.required}
                    />
                ) : (
                    <Core.FormInput
                        {...params}
                        required={item.required}
                        onChange={event => onChangeValue(name, event.target.value, item)}
                        defaultValue={_.get(item, 'value', '')}
                        endAdornment={item.endAdornment}
                        inputProps={item.inputProps}
                        type={item.type || 'text'}
                    />
                )}
            </Core.Grid>
        )
    };

    getSelectOption = item => ({ value: _.get(item, "id"), label: _.get(item, "name") });

    render() {
        const { i18n,
            classes,
            isDisabledCalcButton,
            onCalc,
            onChangeMarketsTable,
            formattedMarkets,
            onApplyDefaultMarketValues,
            isAdditionalDataLoading,
            isCalculations,
            isPercentage,
            onChangeInputType
        } = this.props;

        return (
            <Core.Spin spinning={isAdditionalDataLoading || isCalculations}>
                <Core.Grid container={true} spacing={24} className={classes.container}>
                    {this.form}
                    <Core.Grid item={true} xs={12} sm={12}>
                        <DefaultValues
                            formattedMarkets={formattedMarkets}
                            onApply={onApplyDefaultMarketValues}
                            isPercentage={isPercentage}
                            onChangeInputType={onChangeInputType}
                        />
                    </Core.Grid>
                    <Core.Grid item={true} xs={12} sm={12} className={classes.details}>
                        <Details
                            onChangeMarketsTable={onChangeMarketsTable}
                            shops={formattedMarkets}
                            canEdit={true}
                            isPercentage={isPercentage}
                        />
                    </Core.Grid>
                    <Core.Grid item={true} xs={12} sm={12} className={classes.calcBtn}>
                        <Core.Button
                            key="calcBtn"
                            fullWidth={false}
                            variant="contained"
                            color="primary"
                            disabled={isDisabledCalcButton}
                            onClick={onCalc}>
                            {i18n.t('settings.calculate')}
                        </Core.Button>
                    </Core.Grid>
                </Core.Grid>
            </Core.Spin>
        );
    }
}


const styles = () => ({
    container: {
        padding: spacing.s8,
        backgroundColor: colors.white,
    },
    details: {
        overflowY: 'auto',
    },
    calcBtn: {
        display: 'flex',
        justifyContent: 'center',
    }
});


export default withCalculatorBase(withStyles(styles)(Calculator));
