import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import withFormBase from './Form.base';
import { components as Core } from '../../../../core';
import { colors, fonts, spacing } from "../../../../../styles";
import { LINNWORKS_URL } from "../../../constants";

class Form extends React.Component {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        account: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired,
        onSetToken: PropTypes.func.isRequired,
        isDisabledSaveButton: PropTypes.bool.isRequired,
        onChangeToken: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        visibility: PropTypes.bool
    };

    static defaultProps = {
        isLoading: false,
        visibility: false,
    };

    get modalFooter() {
        const { i18n, onSetToken, isDisabledSaveButton, account, onClose } = this.props;

        if (account.linnworksKey) {
            return ([
                <Core.Button
                    key="okBtn"
                    fullWidth={false}
                    variant="contained"
                    color="primary"
                    onClick={onClose}>
                    {i18n.t('ok')}
                </Core.Button>
            ])
        }

        return ([
            <Core.Button
                key="setLinnBtn"
                fullWidth={false}
                variant="contained"
                color="primary"
                disabled={isDisabledSaveButton}
                onClick={onSetToken}>
                {i18n.t('Save')}
            </Core.Button>,
            <Core.Button
                key="cancelLinnBtn"
                fullWidth={false}
                variant="contained"
                color="primary"
                onClick={onClose}>
                {i18n.t('Cancel')}
            </Core.Button>
        ])
    }

    render() {
        const { i18n, isLoading, classes, onClose, account, onChangeToken, visibility } = this.props;

        return (
            <Core.Modal
                title={account.linnworksKey ? i18n.t('linnworks.details') : i18n.t('linnworks.addKey')}
                visible={visibility}
                onClose={onClose}
                footer={this.modalFooter}
                isLoading={isLoading}>
                <div className={classes.container}>
                    <Core.Grid item={true} xs={12} sm={12}>
                        {account.linnworksKey ? null : (
                            <p className={classes.label}>
                                {i18n.t('linnworks.linkLabel.part1')}
                                <Core.Link>
                                    <a rel="noopener noreferrer" target="_blank" href={LINNWORKS_URL}>
                                        {i18n.t('linnworks.linkLabel.link')}
                                    </a>
                                </Core.Link>

                                {i18n.t('linnworks.linkLabel.part2')}
                            </p>
                        )}
                        <Core.Grid spacing={24} container={true}>
                            {account.linnworksKey ? (
                                <Core.Grid item={true} xs={10} sm={4}>
                                    <Core.FormInput
                                        disabled={true}
                                        required={true}
                                        htmlFor="linnworksUsername"
                                        inputLabel={i18n.t("linnworks.table.username")}
                                        defaultValue={account.linnworksUsername}
                                        type="text"
                                    />
                                </Core.Grid>
                            ) : (null)}
                            <Core.Grid item={true} xs={10} sm={account.linnworksKey ? 8 : 12}>
                                <Core.FormInput
                                    disabled={!!account.linnworksKey}
                                    required={true}
                                    htmlFor="linnworksKey"
                                    inputLabel={i18n.t("linnworks.token")}
                                    onChange={event => onChangeToken(event.target.value)}
                                    defaultValue={account.linnworksKey}
                                    type="text"
                                />
                            </Core.Grid>
                        </Core.Grid>
                    </Core.Grid>
                </div>
            </Core.Modal>
        );
    }
}
const styles = () => ({
    actionButton: {
        borderRadius: 0,
    },
    container: {
        padding: `${spacing.s3}px`,
        width: '100%',
        overflowX: 'auto',
    },
    label: {
        color: colors.gray.darkGray,
        ...fonts.mdNormal,
        marginBottom: spacing.s4
    }
});


export default withFormBase(withStyles(styles)(Form));
