import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import Settings from '@material-ui/icons/Settings';
import _ from "lodash";
import withUsersBase, { COLUMNS } from './Users.base';
import { components as Core, constants as coreConstants } from "../../../core"
import InviteUserModal from "../InviteUserModal";
import PermissionModal from "../PermissionModal";

const hiddenOptions = {
    display: false,
};

const EDIT_ACTION = (i18n, onClick) => [{
    label: i18n.t("users.table.permission"),
    onClick,
    icon: <Settings />
}];

const DELETE_ACTION = (i18n, onClick) => [{
    label: i18n.t("table.delete"),
    onClick,
    icon: <Delete />
}];

class Users extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        tableColumns: PropTypes.object.isRequired,
        isUsersLoading: PropTypes.bool,
        users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        deleteUser: PropTypes.func.isRequired,
        isDeletingUser: PropTypes.bool,
        isCompanyCreator: PropTypes.bool,
        objUsers: PropTypes.object,
        pagination: PropTypes.object.isRequired,
        getUsers: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        accountProfile: PropTypes.object,
        accountRole: PropTypes.number
    };

    static defaultProps = {
        isUsersLoading: false,
        users: null,
        isDeletingUser: false,
        isCompanyCreator: false,
        objUsers: null,
        accountProfile: {},
        accountRole: coreConstants.ROLE.companyUser
    };

    constructor(props) {
        super(props);
        const { tableColumns } = this.props;

        this.state = {
            isVisibleInviteUser: false,
            isVisibleEditPermission: false,
            userId: '',
            isVisibleDeleteModal: false,
            isSearchOpen: false,
            searchText: ""
        };

        this.tableColumns = [
            { ...tableColumns[COLUMNS.id], options: hiddenOptions },
            tableColumns[COLUMNS.name],
            tableColumns[COLUMNS.email],
        ];
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isDeletingUser && !this.props.isDeletingUser) {
            this.onCloseConfirmationModal();
        }
    }

    get options() {
        return {
            selectableRows: "single",
            responsive: 'scroll',
            rowHover: false,
            onRowsSelect: this.onRowsSelect,
            filter: false,
            searchText: this.state.searchText,
            print: false,
            viewColumns: false,
            download: false,
            customToolbar: this.customToolbar,
            customToolbarSelect: this.customActionsToolbar,
            onSearchChange: this.onSearch,
            onTableChange: this.tableChanged,
            onSearchOpen: this.onSearchOpen,
        };
    }

    get tableActions() {
        const { i18n, objUsers, accountProfile, accountRole } = this.props;
        const { userId } = this.state;

        if (_.isEqual(_.get(accountProfile, "id"), userId)) {
            return [];
        }

        const userRole = _.get(objUsers, `${userId}.role`);
        if (userRole <= accountRole) {
            return [];
        }

        return [
            ...EDIT_ACTION(i18n, this.openEditPermissionModal),
            ...DELETE_ACTION(i18n, this.openConfirmationModal),
        ]
    }

    tableChanged = (action, tableState) => {
        const { isSearchOpen } = this.state;
        if (action === "search" && isSearchOpen && !tableState.searchText) {
            this.setState({ isSearchOpen: false, searchText: '' });
            this.onSearch("");
        } else if (action === "search") {
            this.setState({ searchText: tableState.searchText });
        }
    };

    onSearchOpen = () => this.setState({ isSearchOpen: true });

    onRowsSelect = currentRowsSelected => {
        const { users } = this.props;
        this.setState({ userId: users[currentRowsSelected[0].dataIndex].id })
    };

    customToolbar = () => {
        const { i18n } = this.props;
        return (<Core.TooltipButton onClick={this.openInviteUserModal} icon={<AddIcon />} title={i18n.t("users.table.inviteUser")} />)
    };

    customToolbarSelected = item => (
        <Core.TooltipButton
            onClick={item.onClick}
            icon={item.icon}
            title={item.label}
            key={item.label}
        />
    );

    customActionsToolbar = () => (<div>{_.map(this.tableActions, this.customToolbarSelected)}</div>);

    openInviteUserModal = () => this.setState({ isVisibleInviteUser: true });

    onCloseInviteUserModal = () => this.setState({ isVisibleInviteUser: false });

    openEditPermissionModal = () => this.setState({ isVisibleEditPermission: true });

    onCloseEditPermissionModal = () => this.setState({ isVisibleEditPermission: false });

    openConfirmationModal = () => this.setState({ isVisibleDeleteModal: true });

    onCloseConfirmationModal = () => this.setState({ isVisibleDeleteModal: false });

    changePage = (e, page) => this.props.getUsers(page + 1);

    deleteUser = () => this.props.deleteUser(this.state.userId);

    onSearch = searchText => this.props.onSearch(1, searchText);

    render() {
        const { users, isUsersLoading, isDeletingUser, i18n } = this.props;
        const { isVisibleInviteUser, userId, isVisibleEditPermission, isVisibleDeleteModal } = this.state;
        return (
            <div>
                <Core.Table
                    options={this.options}
                    columns={this.tableColumns}
                    data={users}
                    isLoading={isUsersLoading || isDeletingUser}
                    pagination={this.props.pagination}
                    changePage={this.changePage}
                />

                {isVisibleInviteUser ? (
                    <InviteUserModal
                        isVisible={isVisibleInviteUser}
                        onClose={this.onCloseInviteUserModal}
                    />
                ): null
                }
                {isVisibleEditPermission ? (
                    <PermissionModal
                        userId={userId}
                        isVisible={isVisibleEditPermission}
                        onClose={this.onCloseEditPermissionModal}
                    />
                ): null
                }
                {isVisibleDeleteModal ? (
                    <Core.ConfirmationModal
                        isVisible={isVisibleDeleteModal}
                        onClose={this.onCloseConfirmationModal}
                        onConfirm={this.deleteUser}
                        isLoading={isDeletingUser}
                        descriptions={i18n.t("users.table.confirmationDescriptions")}
                    />
                ): null
                }
            </div>
        );
    }
}

export default withUsersBase(Users);
