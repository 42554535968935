import axios from 'axios';
import moment from 'moment';
import _ from "lodash";
import { constants as coreConstants } from "../modules/core";
import { actions as authActions, selectors as authSelectors, constants as authConstants } from "../modules/auth";

const clockSkew = 15;

let refreshTokenPromise = null;
export default async function configAxios(store) {
    axios.defaults.baseURL = coreConstants.BASE_URL;
    axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
    axios.defaults.headers.common.Pragma = 'no-cache';
    axios.interceptors.request.use(async config => {
        if (_.includes(coreConstants.ANONYMOUS_ROUTES, config.url)) {
            return config;
        }
        const accessToken = authSelectors.getAccessToken(store.getState());
        const validTo = _.get(accessToken, 'validTo');
        const isValid = validTo && moment().add({ seconds: clockSkew }).isBefore(validTo);

        if (isValid) {
            return updateAuthorizationHeader(config, accessToken);
        }

        if (!refreshTokenPromise) {
            refreshTokenPromise = store.dispatch(authActions.refreshToken())
                .then(() => authSelectors.getAccessToken(store.getState()))
                .finally(() => refreshTokenPromise = null);
        }
        const newAccessToken = await refreshTokenPromise;

        return updateAuthorizationHeader(config, newAccessToken);

    }, error => Promise.reject(error));
    axios.interceptors.response.use(response => response,
        error => {
            if (error && _.get(error, "response.status") === 401) {
                store.dispatch(authActions.signOut());
                throw error;
            }
            if (error && _.get(error, "response.status") === 404) {
                throw new Error(coreConstants.ERROR_TYPES.ERROR_404);
            }

            if (
                error &&
                _.chain(error)
                    .get('response.data.errors')
                    .some(i => i.code === authConstants.ERROR_CODES.TrialExpired)
                    .value()
            ) {
                store.dispatch(authActions.signOut(true, false));
                throw error;
            }

            if (
                error &&
                _.chain(error)
                    .get('response.data.errors')
                    .some(i => i.code === authConstants.ERROR_CODES.NoPlan)
                    .value()
            ) {
                store.dispatch(authActions.signOut(false, true));
                throw error;
            }

            throw error;
        }
    )
}

const updateAuthorizationHeader = (config, accessToken) => {
    const c = config;
    c.headers.common.Authorization = `bearer ${accessToken.token}`;
    return c;
};
