import React from 'react';
import PropTypes from 'prop-types';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

import SearchIcon from "@material-ui/icons/Search";

import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomButton from "../CustomButton";
import { colors, spacing, fonts, hexToRGB } from "../../../../styles";

class Search extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        formControlProps: PropTypes.object,
        inputProps: PropTypes.object,
        labelText: PropTypes.string,
        labelProps: PropTypes.object,
        iconStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    };

    static defaultProps = {
        formControlProps: null,
        inputProps: null,
        labelProps: null,
        labelText: "",
        iconStyle: null
    };

    render() {
        const { classes, formControlProps, labelText, inputProps, labelProps, iconStyle } = this.props;
        return (
            <div className={classes.searchWrapper}>
                <FormControl
                    {...formControlProps}
                    className={classNames(formControlProps.className, classes.formControl)}>
                    {labelText !== undefined ? (
                        <InputLabel
                            className={classes.labelRoot}
                            {...labelProps}>
                            {labelText}
                        </InputLabel>
                    ) : null}
                    <Input
                        classes={{
                            root: labelText === undefined ? classes.marginTop : null,
                            disabled: classes.disabled,
                            underline: classes.underline
                        }}
                        {...inputProps}
                    />
                </FormControl>
                <CustomButton className={classes.searchButton} aria-label="edit" hasIcon={true} round={true}>
                    <SearchIcon className={classNames(classes.search, iconStyle)} />
                </CustomButton>
            </div>
        );
    }
}

const styles = theme => ({
    searchWrapper: {
        [theme.breakpoints.down("sm")]: {
            margin: `${spacing.s2}px ${spacing.s3}px 0`
        },
        display: "inline-block"
    },
    formControl: {
        paddingBottom: spacing.s2,
        //margin: `${spacing.s6}px 0 0 0`,
        position: "relative",
        verticalAlign: "unset"
    },
    labelRoot: {
        color: colors.gray.darkGray,
        ...fonts.smNormal,
    },
    marginTop: {
        marginTop: spacing.s3
    },
    disabled: {
        "&:before": {
            backgroundColor: colors.transparent
        }
    },
    underline: {
        "&:hover:not($disabled):before,&:before": {
            borderColor: colors.gray.lightGray,
            borderWidth: 1
        },
        "&:after": {
            borderColor: colors.gray.lightGray
        }
    },
    searchButton: {
        marginRight: spacing.s0,
        color: colors.gray.darkGray,
        backgroundColor: colors.white,
        boxShadow: `0 14px 26px -12px rgba(${hexToRGB(colors.gray.gray)}, 0.42), 0 4px 23px 0px rgba(${hexToRGB(colors.black)}, 0.12), 0 8px 10px -5px rgba(${hexToRGB(colors.gray.gray)}, 0.2)`,
        "&:focus,&:hover": {
            backgroundColor: colors.gray.lightGray,
            color: colors.gray.gray,
        }
    },
});

export default withStyles(styles)(Search);
