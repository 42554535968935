import * as actionTypes from "./actionTypes";
import * as api from './api';
import { normalize, actions as coreActions, handleErrors, Toast, constants as coreConstants } from '../core';


export function getUsers(params = { page: 1, count: coreConstants.DEFAULT_ITEMS_PER_PAGE }) {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_USERS.REQUEST });
        return api.getUsers(params)
            .then(response => {
                const { items, ...pagination } = response.data;
                dispatch({
                    type: actionTypes.GET_USERS.SUCCESS,
                    payload: {
                        data: normalize.normalizeArray({ items }),
                        pagination
                    }
                });
            })
            .catch(error => {
                dispatch({ type: actionTypes.GET_USERS.ERROR });
            });
    }
}

export function inviteUser(data) {
    return function (dispatch) {
        dispatch(removeInviteUserError());
        dispatch({ type: actionTypes.INVITE_USER.REQUEST });
        return api.inviteUsers({ ...data })
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.INVITE_USER.SUCCESS,
                    payload: { user: response.data }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.INVITE_USER.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.INVITE_USER.ERROR });
            });
    }
}

export function removeInviteUserError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.INVITE_USER.NAME));
    }
}

export function updateUserPermissions(data, userId) {
    return function (dispatch) {
        dispatch(removeUpdateUserPermissionsError());
        dispatch({ type: actionTypes.UPDATE_USER_PERMISSIONS.REQUEST });
        return api.updateUser(data, userId)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.UPDATE_USER_PERMISSIONS.SUCCESS,
                    payload: { data, userId }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.UPDATE_USER_PERMISSIONS.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.UPDATE_USER_PERMISSIONS.ERROR });
            });
    }
}

export function removeUpdateUserPermissionsError() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.INVITE_USER.NAME));
    }
}

export function deleteUser(userId) {
    return function (dispatch) {
        dispatch({ type: actionTypes.DELETE_USER.REQUEST });
        return api.deleteUser(userId)
            .then(response => {
                Toast.success();
                dispatch({
                    type: actionTypes.DELETE_USER.SUCCESS,
                    payload: { userId }
                });
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.DELETE_USER.ERROR });
            });
    }
}
