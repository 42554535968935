import { createSelector } from "reselect";
import _ from 'lodash';
import { NAME, FIELDS, ITEMS_PER_PAGE } from './constants';
import * as actionTypes from './actionTypes';
import { selectors as coreSelectors } from '../core';

const getAllInventoryIds = state => state[NAME].inventory.ids;
const getInventoryItems = state => state[NAME].inventory.items;

export const getInventoryArray = createSelector(getInventoryItems, getAllInventoryIds, (items, ids) => _.map(_.take(ids, ITEMS_PER_PAGE), id => {
    let obj = {};
    _.forOwn(items[id], (val, key) => {
        let value = val;

        if (key === FIELDS.taxRate) {
            value = `${val * 100}%`
        }

        if (_.isObject(value)) {
            obj = { ...obj, [key]: _.get(value, 'name', '') }
        } else {
            obj = { ...obj, [key]: value }
        }
    });

    return obj;
}));
export const getItem = (state, id) => _.find(state[NAME].inventory.items, item => item.id === id);
export const isInventory = state => coreSelectors.isLoading(state, actionTypes.GET_INVENTORY.NAME);
export const isItem = (state, id) => coreSelectors.isLoadingWithRequestId(state, actionTypes.GET_ITEM.NAME, id);
export const getCurrentItem = (state, id) => state[NAME].fullItems[id];
export const isItemUpdate = state => coreSelectors.isLoading(state, actionTypes.EDIT_ITEM.NAME);
export const isItemDelete = state => coreSelectors.isLoading(state, actionTypes.DELETE_ITEM.NAME);
export const getInventoryPagination = state => state[NAME].inventory.pagination;
export const getMarketplaces = state => state[NAME].marketplaces;
export const isMarketplaces = state => coreSelectors.isLoading(state, actionTypes.GET_MARKETPLACES.NAME);
export const isAdditionalDataLoading = state => coreSelectors.isLoading(state, actionTypes.GET_ADDITIONAL_DATA.NAME);
export const isItemCreating = state => coreSelectors.isLoading(state, actionTypes.CREATE_ITEM.NAME);
export const getColumns = state => state[NAME].columns;
export const isColumnsLoading = state => coreSelectors.isLoading(state, actionTypes.GET_COLUMNS.NAME);
export const isColumnsUpdating = state => coreSelectors.isLoading(state, actionTypes.UPDATE_COLUMNS.NAME);
export const getCategoriesPagination = state => state[NAME].filters.category.pagination;
export const getCategories = state => state[NAME].filters.category.items;
export const isCategoriesLoading = state => coreSelectors.isLoading(state, actionTypes.GET_FILTER_CATEGORIES);
export const isCalculations = state => coreSelectors.isLoading(state, actionTypes.GET_CALCULATIONS.NAME);
//TO DO: rename
export const getCalculations = state => state[NAME].calculations;

export const isGetReportByDate = state => coreSelectors.isLoading(state, actionTypes.GET_REPORT_BY_DATE.NAME);
export const isInventoryItemsImporting = state => coreSelectors.isLoading(state, actionTypes.IMPORT_FILE.NAME);
export const isInventoryItemsExporting = state => coreSelectors.isLoading(state, actionTypes.EXPORT_FILE.NAME);
