import { constants as coreConstants } from '../core';

export const NAME = 'inventory';

export const DEFAULT_MARKET = {
    marketplace: '',
    max: 0,
    min: 0,
    current: null,
    minCalculated: null,
    maxCalculated: null,
    minMargin: null,
    maxMargin: null,
    currentCalculated: null,
    currentMargin: null,
};

export const COLUMNS = {
    id: "id",
    sku: 'sku',
    title: 'title',
    company: 'company',
    barcode: 'barcode',
};

export const FIELDS = {
    ...COLUMNS,
    weight: 'weight',
    height: 'height',
    purchasePrice: 'purchasePrice',
    postalService: 'postalService',
    packaging: 'packaging',
    taxRate: 'taxRate',
    category: 'category',
    subCategory: 'subCategory',
    subSubCategory: 'subSubCategory',
    msrp: 'msrp',
    prices: 'prices',
    imageUrl: 'imageUrl',
    competitors: 'competitors',
    thumbnailUrl: 'thumbnailUrl',
    supplier: 'supplier',
    depth: 'depth',
    width: 'width',
    minCalculated: "minCalculated",
    maxCalculated: "maxCalculated",
    minMargin: "minMargin",
    maxMargin: "maxMargin",
    isPercentage: "isPercentage",
    current: 'current',
    currentMargin: 'currentMargin',
    currentCalculated: 'currentCalculated',
};

export const SELECTOR_NAMES = {
    postalService: 'postalService',
    packaging: 'packaging',
    company: 'company',
    zones: "zones",
};

export const CREATE_MODEL = {
    [FIELDS.company]: {
        id: '',
    },
    [FIELDS.sku]: '',
    [FIELDS.barcode]: '',
    [FIELDS.title]: '',
    [FIELDS.category]: '',
    [FIELDS.subCategory]: '',
    [FIELDS.subSubCategory]: '',
    [FIELDS.purchasePrice]: 0,
    [FIELDS.taxRate]: 0,
    [FIELDS.weight]: 0,
    [FIELDS.height]: 0,
    [FIELDS.width]: 0,
    [FIELDS.depth]: 0,
    [FIELDS.postalService]: {
        id: ''
    },
    [FIELDS.packaging]: {
        id: '',
    },
    [FIELDS.msrp]: '',
    [FIELDS.prices]: {},
    [FIELDS.competitors]: {},
};

export const ADDITIONAL_FIELD_PROPS = {
    [FIELDS.imageUrl]: {
        order: 1,
        hidden: false,
        editable: false,
        isImage: true,
        size: 4,
    },
    [FIELDS.title]: {
        editable: true,
        order: 2,
        required: true,
        size: 12,
    },
    [FIELDS.weight]: {
        editable: true,
        order: 3,
        required: true,
        size: 6,
        type: coreConstants.INPUT_TYPES.number
    },
    [FIELDS.height]: {
        editable: true,
        order: 4,
        required: true,
        size: 6,
        type: coreConstants.INPUT_TYPES.number
    },
    [FIELDS.depth]: {
        editable: true,
        order: 5,
        required: true,
        size: 6,
        type: coreConstants.INPUT_TYPES.number
    },
    [FIELDS.width]: {
        editable: true,
        order: 6,
        required: true,
        size: 6,
        type: coreConstants.INPUT_TYPES.number
    },
    [FIELDS.postalService]: {
        editable: true,
        order: 7,
        required: false,
        size: 4,
        isObject: true,
        selectName: SELECTOR_NAMES.postalService,
    },
    [FIELDS.packaging]: {
        editable: true,
        order: 8,
        required: false,
        size: 4,
        isObject: true,
        selectName: SELECTOR_NAMES.packaging,
    },
    [FIELDS.id]: {
        hidden: true,
        order: 0,
    },
    [FIELDS.prices]: {
        hidden: true,
        order: 0,
    },
    [FIELDS.company]: {
        order: 9,
        editable: true,
        required: false,
        size: 4,
        isObject: true,
        selectName: SELECTOR_NAMES.company,
    },
    [FIELDS.purchasePrice]: {
        editable: true,
        order: 10,
        required: true,
        size: 3,
        type: coreConstants.INPUT_TYPES.number
    },
    [FIELDS.taxRate]: {
        order: 11,
        editable: true,
        required: true,
        size: 3,
        type: coreConstants.INPUT_TYPES.number
    },
    [FIELDS.sku]: {
        order: 12,
        disabled: true,
        required: true,
        size: 3,
        type: coreConstants.INPUT_TYPES.text
    },
    [FIELDS.competitors]: {
        order: 0,
        hidden: true,
    },
    [FIELDS.thumbnailUrl]: {
        order: 0,
        hidden: true,
    },
    [FIELDS.supplier]: {
        order: 0,
        hidden: true,
        isObject: true,
    }
};

export const ITEMS_PER_PAGE = 20;

export const ALL_OPTION = 'all';

export const TAX_RATE_PROPS = {
    min: '0.1',
    max: '100',
    step: '0.1',
};

export const ROUND_DEFAULT_VALUE = 2;

export const CALCULATOR_FIELDS = {
    [FIELDS.weight]: {
        editable: true,
        order: 1,
        required: true,
        size: 3,
        type: coreConstants.INPUT_TYPES.number,
        value: 0,
        label: `inventory.details.${FIELDS.weight}`
    },
    [FIELDS.height]: {
        editable: true,
        order: 2,
        required: true,
        size: 3,
        type: coreConstants.INPUT_TYPES.number,
        value: 0,
        label: `inventory.details.${FIELDS.height}`
    },
    [FIELDS.taxRate]: {
        order: 3,
        editable: true,
        required: true,
        size: 3,
        type: coreConstants.INPUT_TYPES.number,
        value: 0,
        endAdornment: '%',
        inputProps: TAX_RATE_PROPS,
        label: `inventory.details.${FIELDS.taxRate}`
    },
    [FIELDS.purchasePrice]: {
        editable: true,
        order: 4,
        required: true,
        size: 3,
        type: coreConstants.INPUT_TYPES.number,
        value: 0,
        label: `inventory.details.${FIELDS.purchasePrice}`
    },
    [FIELDS.postalService]: {
        editable: true,
        order: 5,
        required: true,
        size: 4,
        isObject: true,
        selectName: SELECTOR_NAMES.postalService,
        value: null,
        label: `inventory.details.${FIELDS.postalService}`
    },
    [FIELDS.packaging]: {
        editable: true,
        order: 6,
        required: true,
        size: 4,
        isObject: true,
        selectName: SELECTOR_NAMES.packaging,
        value: null,
        label: `inventory.details.${FIELDS.packaging}`
    },
    [FIELDS.company]: {
        order: 7,
        editable: true,
        required: true,
        size: 4,
        isObject: true,
        selectName: SELECTOR_NAMES.company,
        value: null,
        label: `inventory.details.${FIELDS.company}`
    },
};

export const DEFAULT_MARKET_VALUES = {
    min: 0,
    max: 0,
    minMargin: 0,
    maxMargin: 0,
    currentCalculated: 0,
    current: 0,
    currentMargin: 0,
};