import * as actionTypes from './actionTypes';
import { selectors as coreSelectors } from '../core';
import { NAME } from './constants';

export const isLoggedIn = state => state[NAME].isLoggedIn;
export const isVerifyingEmail = state => coreSelectors.isLoading(state, actionTypes.VERIFY_EMAIL.NAME);
export const getVerifyError = state => coreSelectors.getError(state, actionTypes.VERIFY_EMAIL.NAME);
export const isSignUp = state => coreSelectors.isLoading(state, actionTypes.SIGN_UP.NAME);
export const isSignIn = state => coreSelectors.isLoading(state, actionTypes.SIGN_IN.NAME);
export const getSignInError = state => coreSelectors.getError(state, actionTypes.SIGN_IN.NAME);
export const isSendingVerifyToken = state => coreSelectors.isLoading(state, actionTypes.SEND_VERIFY_EMAIL_TOKEN.NAME);
export const getSignUpError = state => coreSelectors.getError(state, actionTypes.SIGN_UP.NAME);
export const isResettingPassword = state => coreSelectors.isLoading(state, actionTypes.RESET_PASSWORD.NAME);
export const isSendingPasswordToken = state => coreSelectors.isLoading(state, actionTypes.SEND_RESET_PASSWORD_TOKEN.NAME);
export const getAccessToken = state => state[NAME].user.accessToken;
export const getRefreshToken = state => state[NAME].user.refreshToken;
export const getSubscriptionExpiredStatus = state => state[NAME].isTrialExpired;
export const getNoPlanStatus = state => state[NAME].isNoPlan;