import * as actionTypes from "./actionTypes";
import * as api from './api';
import { actions as authActions } from '../auth';
import { actions as coreActions, handleErrors, Toast, convertImage } from '../core';

const TOAST_TYPES = {
    changePasswordSuccess: 'changePassword.success',
};

export function getAccount() {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_ACCOUNT.REQUEST });
        return api.getAccount()
            .then(async response => {
                await dispatch(getUserPicture());
                await dispatch({
                    type: actionTypes.GET_ACCOUNT.SUCCESS,
                    payload: {
                        data: response.data
                    }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.GET_ACCOUNT.ERROR });
            });
    }
}

export function updateAccount(data) {
    return function (dispatch) {
        dispatch({ type: actionTypes.UPDATE_ACCOUNT.REQUEST });
        return api.updateAccount(data)
            .then(() => {
                Toast.success();
                dispatch({
                    type: actionTypes.UPDATE_ACCOUNT.SUCCESS,
                    payload: {
                        data
                    }
                })
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.UPDATE_ACCOUNT.ERROR });
            });
    }
}

export function changePassword(data) {
    return function (dispatch) {
        dispatch({ type: actionTypes.CHANGE_PASSWORD.REQUEST });
        return api.changePassword(data)
            .then(() => {
                Toast.success({ type: TOAST_TYPES.changePasswordSuccess });
                dispatch({ type: actionTypes.CHANGE_PASSWORD.SUCCESS });
                dispatch(authActions.signOut());
            })
            .catch(error => {
                handleErrors(error);
                dispatch({ type: actionTypes.CHANGE_PASSWORD.ERROR });
            });
    }
}

export function getUserPicture() {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_USER_PICTURE.REQUEST });
        return api.getUserPicture()
            .then(res => {
                const data = convertImage(res.data, res.headers['content-type']);

                dispatch({
                    type: actionTypes.GET_USER_PICTURE.SUCCESS,
                    payload: data,
                });
            })
            .catch(() => {
                dispatch({ type: actionTypes.GET_USER_PICTURE.ERROR });
            });
    }
}

export function uploadUserPhoto(file) {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.UPLOAD_USER_PICTURE.NAME));
        dispatch({ type: actionTypes.UPLOAD_USER_PICTURE.REQUEST });
        const formData = new FormData();
        formData.append('file', file);
        return api.uploadUserPicture(formData)
            .then(async () => {
                await dispatch(getAccount());
                await dispatch({ type: actionTypes.UPLOAD_USER_PICTURE.SUCCESS });
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.UPLOAD_USER_PICTURE.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.UPLOAD_USER_PICTURE.ERROR });
            });
    }
}

export function removeUserPhoto() {
    return function (dispatch) {
        dispatch(coreActions.removeError(actionTypes.REMOVE_USER_PICTURE.NAME));
        dispatch({ type: actionTypes.REMOVE_USER_PICTURE.REQUEST });
        return api.removeUserPicture()
            .then(async () => {
                await dispatch(getAccount());
                await dispatch({ type: actionTypes.REMOVE_USER_PICTURE.SUCCESS });
            })
            .catch(error => {
                handleErrors(error);
                dispatch(coreActions.addError(actionTypes.REMOVE_USER_PICTURE.NAME, _.get(error, 'response.data.errors')));
                dispatch({ type: actionTypes.REMOVE_USER_PICTURE.ERROR });
            });
    }
}

export function clearStore() {
    return async function (dispatch) {
        await dispatch({
            type: actionTypes.RESET_STORE
        });
    }
}
