import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import _ from "lodash";
import { connect } from "react-redux";
import { constants as coreConstants, Toast, translate } from '../../../../core';
import {
    MARKETPLACE_FIELDS,
    MARKETPLACE_INCLUDE_FIELDS,
    SELECT_FIELDS,
    ROUND_VALUES_PERCENT
} from "../../../constants"
import * as actions from "../../../actions";
import * as selectors from "../../../selectors";
import { marketplaceValidator } from "../../../services";

const EDIT_FIELDS = (i18n, onSearch) => [
    {
        name: `${MARKETPLACE_FIELDS.marketplace}`,
        placeholder: i18n.t('settings.marketplaceTable.marketplace'),
        isRequired: true,
        type: coreConstants.INPUT_TYPES.text,
        autoFocus: true,
    },
    {
        name: `${MARKETPLACE_FIELDS.currency}.${MARKETPLACE_INCLUDE_FIELDS.id}`,
        selectName: SELECT_FIELDS.currencies,
        placeholder: i18n.t('settings.marketplaceTable.currency'),
        isRequired: true,
        type: coreConstants.INPUT_TYPES.select,
        onSearch

    },
    {
        name: `${MARKETPLACE_FIELDS.zone}.${MARKETPLACE_INCLUDE_FIELDS.id}`,
        selectName: SELECT_FIELDS.zones,
        placeholder: i18n.t('settings.marketplaceTable.zone'),
        isRequired: true,
        type: coreConstants.INPUT_TYPES.select,
        onSearch

    },
    {
        name: `${MARKETPLACE_FIELDS.source}`,
        placeholder: i18n.t('settings.marketplaceTable.source'),
        isRequired: false,
        type: coreConstants.INPUT_TYPES.text,
    },
];

const ADD_FEES_FIELDS = (i18n, onSearch) => [
    {
        name: `${MARKETPLACE_FIELDS.company}.${MARKETPLACE_INCLUDE_FIELDS.id}`,
        selectName: SELECT_FIELDS.companies,
        placeholder: i18n.t('settings.marketplaceTable.company'),
        isRequired: true,
        type: coreConstants.INPUT_TYPES.select,
        onSearch

    },
    {
        name: `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.fee}`,
        placeholder: i18n.t('settings.marketplaceTable.fee'),
        type: coreConstants.INPUT_TYPES.number,
        endAdornment: coreConstants.INPUT_ADORNMENT.percentage,
        isPercentage: true,
    },
    {
        name: `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.minFinalValueFee}`,
        placeholder: i18n.t('settings.marketplaceTable.minFinalValueFee'),
        type: coreConstants.INPUT_TYPES.number,

    },
    {
        name: `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.paymentProvider}`,
        placeholder: i18n.t('settings.marketplaceTable.paymentProvider'),
        type: coreConstants.INPUT_TYPES.text,

    },
    {
        name: `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.cost}`,
        placeholder: i18n.t('settings.marketplaceTable.cost'),
        type: coreConstants.INPUT_TYPES.number,
        endAdornment: coreConstants.INPUT_ADORNMENT.percentage,
        isPercentage: true,
    },
    {
        name: `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.transactionFee}`,
        placeholder: i18n.t('settings.marketplaceTable.transactionFee'),
        type: coreConstants.INPUT_TYPES.number,

    },
    {
        name: `${MARKETPLACE_FIELDS.fees}.${MARKETPLACE_INCLUDE_FIELDS.subSource}`,
        placeholder: i18n.t('settings.marketplaceTable.subSource'),
        type: coreConstants.INPUT_TYPES.text,

    },
];

export default function withMarketplaceModalBase(WrappedComponent) {
    class MarketplaceModalBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            id: PropTypes.string,
            isVisible: PropTypes.bool.isRequired,
            onClose: PropTypes.func.isRequired,
            modalType: PropTypes.number.isRequired,
            marketplace: PropTypes.object,
            isLoadingFees: PropTypes.bool,
            pagination: PropTypes.object.isRequired
        };

        static defaultProps = {
            id: '',
            marketplace: undefined,
            isLoadingFees: false,
        };

        constructor(props) {
            super(props);

            const { i18n, marketplace } = this.props;

            this.state = {
                fields: {
                    [MARKETPLACE_FIELDS.marketplace]: _.get(marketplace, MARKETPLACE_FIELDS.marketplace, ''),
                    [MARKETPLACE_FIELDS.currency]: {
                        [MARKETPLACE_INCLUDE_FIELDS.id]: _.get(marketplace, `${MARKETPLACE_FIELDS.currency}.${MARKETPLACE_INCLUDE_FIELDS.id}`, ''),
                        name: _.get(marketplace, `${MARKETPLACE_FIELDS.currency}.name`, '')
                    },
                    [MARKETPLACE_FIELDS.zone]: {
                        [MARKETPLACE_INCLUDE_FIELDS.id]: _.get(marketplace, `${MARKETPLACE_FIELDS.zone}.${MARKETPLACE_INCLUDE_FIELDS.id}`, ''),
                        name: _.get(marketplace, `${MARKETPLACE_FIELDS.zone}.name`, '')
                    },
                    [MARKETPLACE_FIELDS.company]: {
                        [MARKETPLACE_INCLUDE_FIELDS.id]: _.get(marketplace, `${MARKETPLACE_FIELDS.company}.${MARKETPLACE_INCLUDE_FIELDS.id}`, ''),
                    },
                    [MARKETPLACE_FIELDS.fees]: {
                        [MARKETPLACE_INCLUDE_FIELDS.cost]: 0,
                        [MARKETPLACE_INCLUDE_FIELDS.fee]: 0,
                        [MARKETPLACE_INCLUDE_FIELDS.minFinalValueFee]: 0,
                        [MARKETPLACE_INCLUDE_FIELDS.paymentProvider]: "",
                        [MARKETPLACE_INCLUDE_FIELDS.transactionFee]: 0,
                        [MARKETPLACE_INCLUDE_FIELDS.subSource]: ""
                    },
                    [MARKETPLACE_FIELDS.source]: _.get(marketplace, MARKETPLACE_FIELDS.source, ''),
                },
                errors: {
                    [MARKETPLACE_FIELDS.marketplace]: '',
                },
            };

            this.fields = this.props.modalType === coreConstants.MODAL_TYPE.edit ? EDIT_FIELDS(i18n, this.onSearch) : [
                ...EDIT_FIELDS(i18n, this.onSearch),
                ...ADD_FEES_FIELDS(i18n, this.onSearch),
            ];

            this.props.modalType === coreConstants.MODAL_TYPE.edit ?
                this.props.actions.getMarketplaceFees(marketplace.id) :
                null;
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (!nextProps.isLoadingFees && this.props.isLoadingFees) {
                const { marketplace } = nextProps;
                this.setState(prevState => ({
                    ...prevState,
                    fields: {
                        ...marketplace,
                    }
                }))
            }
        }

        onChangeValue = (val, field) => {
            const newValue = field.type === coreConstants.INPUT_TYPES.number ? _.round(val * 1, ROUND_VALUES_PERCENT) : val;

            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    ..._.set(prevState.fields, field.name, newValue)
                }
            }))
        };

        onSearch = (filterText, field, isMore, page) => {
            switch (field) {
                case SELECT_FIELDS.currencies: {
                    return this.props.actions.getCurrenciesRate({
                        page: isMore ? page : 1,
                        count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                        filterText
                    }, true);
                }
                case SELECT_FIELDS.companies: {
                    return this.props.actions.getCompanies({
                        page: isMore ? page : 1,
                        count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                        filterText
                    }, true);
                }
                case SELECT_FIELDS.zones: {
                    return this.props.actions.getZones({
                        page: isMore ? page : 1,
                        count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                        filterText
                    }, true);
                }
                default:
                    break;
            }
        };

        addMarketplace = () => {
            const { fields } = this.state;
            const { i18n, modalType, id, marketplace } = this.props;

            if (modalType === coreConstants.MODAL_TYPE.edit) {
                const errors = marketplaceValidator.validateMarketPlace(fields, i18n);
                if (errors.length) {
                    Toast.error(errors[0]);
                    return;
                }
                this.props.actions.updateMarketplace(id, { ...fields, [MARKETPLACE_FIELDS.fees]: marketplace.fees })
            } else {
                let errors = marketplaceValidator.validateMarketPlace(fields, i18n);
                if (errors.length) {
                    Toast.error(errors[0]);
                    return;
                }
                errors = marketplaceValidator.validateFee(fields, i18n, []);
                if (errors.length) {
                    Toast.error(errors[0]);
                    return;
                }

                this.props.actions.addMarketplace({
                    ...fields,
                    [MARKETPLACE_FIELDS.fees]: {
                        [_.get(fields, `${MARKETPLACE_FIELDS.company}.${MARKETPLACE_INCLUDE_FIELDS.id}`)]: fields.fees
                    }
                });
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    addMarketplace={this.addMarketplace}
                    values={this.state.fields}
                    fields={this.fields}
                    onChangeValue={this.onChangeValue}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => {
        const marketplace = ownProps.modalType === coreConstants.MODAL_TYPE.edit ? selectors.getMarketplaceById(state, ownProps.id) : null;
        return ({
            isAdding: selectors.isAddingMarketplace(state),
            isUpdating: selectors.isUpdatingMarketplace(state),
            isLoadingFees: selectors.isGettingFees(state),
            isLoading: {
                [SELECT_FIELDS.currencies]: selectors.isCurrenciesRate(state),
                [SELECT_FIELDS.companies]: selectors.isCompanies(state),
                [SELECT_FIELDS.zones]: selectors.isZones(state),
            },
            [SELECT_FIELDS.currencies]: selectors.getCurrenciesRateItems(state),
            [SELECT_FIELDS.companies]: selectors.getCompaniesItems(state),
            [SELECT_FIELDS.zones]: selectors.getZonesItems(state),
            initNames: {
                [SELECT_FIELDS.currencies]: _.get(marketplace, `${MARKETPLACE_FIELDS.currency}.name`, ''),
                [SELECT_FIELDS.companies]: _.get(marketplace, `${MARKETPLACE_FIELDS.company}.name`, ''),
                [SELECT_FIELDS.zones]: _.get(marketplace, `${MARKETPLACE_FIELDS.zone}.name`, ''),
            },
            pagination: {
                [SELECT_FIELDS.currencies]: selectors.getCurrenciesRateScrollPagination(state),
                [SELECT_FIELDS.companies]: selectors.getCompaniesScrollPagination(state),
                [SELECT_FIELDS.zones]: selectors.getZonesScrollPagination(state),
            },
            marketplace,
            addMarketplaceError: selectors.getAddMarketplaceError(state),
            updateMarketplaceError: selectors.getUpdateMarketplaceError(state),
        })
    };

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(MarketplaceModalBase));
}
