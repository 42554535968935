import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { constants as coreConstants, getPagination, translate } from "../../../core";

import {COMPANY_FIELDS, TIMEOUT} from "../../constants";

import * as actions from "../../actions";
import * as selectors from "../../selectors";

export const COLUMNS = {
    id: "id",
    company: COMPANY_FIELDS.name,
};

export default function withCompanyTableBase(WrappedComponent) {
    class CompanyTableBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            pagination: PropTypes.object,
            actions: PropTypes.object.isRequired,
        };

        static defaultProps = {
            pagination: null
        };

        constructor(props) {
            super(props);
            const { i18n } = this.props;

            this.tableColumns = {
                [COLUMNS.company]: {
                    label: i18n.t("settings.companyTable.companyName"),
                    name: COLUMNS.company,
                },
                [COLUMNS.id]: {
                    label: i18n.t("settings.companyTable.id"),
                    name: COLUMNS.id,
                }
            };

            this.props.actions.getCompanies();
            this.debouncedOnSearch = _.debounce(this.getCompanies, TIMEOUT);
        }

        getCompanies = (page, filterText) => {
            this.props.actions.getCompanies({
                page,
                count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                filterText
            });
        };

        deleteCompany = companyId => {
            const { pagination } = this.props;
            this.props.actions.deleteCompany(companyId).then(
                () => this.getCompanies(pagination.itemCount > 0 ? pagination.pageNumber : pagination.pageNumber-1 )
            );
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    tableColumns={this.tableColumns}
                    deleteCompany={this.deleteCompany}
                    getCompanies={this.getCompanies}
                    onSearch={this.debouncedOnSearch}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isCompaniesLoading: selectors.isCompanies(state),
            isDeletingCompany: selectors.isDeletingCompany(state),
            pagination: getPagination(selectors.getCompaniesPagination(state)),
            companies: selectors.getCompaniesArray(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CompanyTableBase));
}
