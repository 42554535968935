import React from 'react';
import PropTypes from 'prop-types';
import Settings from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import withMarketplaceTableBase, { COLUMNS } from './MarketplaceTable.base';
import { components as Core, constants as coreConstants } from "../../../core"
import MarketplaceModal from "./MarketplaceModal";
import DetailsRows from "./DetailsRows";

const hiddenOptions = {
    display: false,
};

const sortOptions = {
    sort: false
};

class MarketplaceTable extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        tableColumns: PropTypes.object.isRequired,
        deleteMarketplace: PropTypes.func.isRequired,
        isMarketplacesLoading: PropTypes.bool,
        isDeletingMarketplace: PropTypes.bool,
        marketplaces: PropTypes.array,
        getMarketplaces: PropTypes.func.isRequired,
        pagination: PropTypes.object,
        onSearch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isMarketplacesLoading: false,
        isDeletingMarketplace: false,
        marketplaces: [],
        pagination: null
    };

    constructor(props) {
        super(props);

        const { tableColumns, i18n } = this.props;

        this.state = {
            isVisibleMarketplaceModal: false,
            modalType: coreConstants.MODAL_TYPE.create,
            isVisibleDeleteModal: false,
            marketplaceId: '',
            isSearchOpen: false,
            searchText: ""
        };

        this.tableColumns = [
            { ...tableColumns[COLUMNS.id], options: hiddenOptions },
            { ...tableColumns[COLUMNS.marketplace], options: sortOptions },
            { ...tableColumns[COLUMNS.currency], options: { customBodyRender: this.renderSelectionBody, ...sortOptions } },
            { ...tableColumns[COLUMNS.zone], options: { customBodyRender: this.renderSelectionBody, ...sortOptions } },
            { ...tableColumns[COLUMNS.source], options: sortOptions },
        ];

        this.tableActions = {
            base: [
                {
                    label: i18n.t("settings.marketplaceTable.add"),
                    onClick: this.openAddMarketplaceModal,
                    icon: <AddIcon />
                }
            ],
            select: [
                {
                    label: i18n.t("table.edit"),
                    onClick: this.openEditMarketplaceModal,
                    icon: <Settings />
                },
                {
                    label: i18n.t("table.delete"),
                    onClick: this.openConfirmationModal,
                    icon: <Delete />
                }
            ]
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isDeletingMarketplace && !this.props.isDeletingMarketplace) {
            this.onCloseConfirmationModal();
        }
    }

    get options() {
        return {
            selectableRows: "single",
            responsive: 'scroll',
            rowHover: false,
            onRowsSelect: this.onRowsSelect,
            filter: false,
            print: false,
            viewColumns: false,
            download: false,
            searchText: this.state.searchText,
            renderExpandableRow: this.renderDetails,
            expandableRows: true,
            onSearchChange: this.onSearch,
            onTableChange: this.tableChanged,
            onSearchOpen: this.onSearchOpen,
        };
    }

    tableChanged = (action, tableState) => {
        const { isSearchOpen } = this.state;
        this.setState({ searchText: tableState.searchText });
        if (action === "search" && isSearchOpen && !tableState.searchText) {
            this.setState({ isSearchOpen: false });
            this.onSearch("");
        }
    };

    onSearchOpen = () => this.setState({ isSearchOpen: true });

    onRowsSelect = currentRowsSelected => {
        const { marketplaces } = this.props;
        this.setState({ marketplaceId: marketplaces[currentRowsSelected[0].dataIndex].id })
    };

    openAddMarketplaceModal = () => this.setState({ isVisibleMarketplaceModal: true, modalType: coreConstants.MODAL_TYPE.create, marketplaceId: '' });

    openEditMarketplaceModal = () => this.setState({ isVisibleMarketplaceModal: true, modalType: coreConstants.MODAL_TYPE.edit });

    onCloseMarketplaceModal = () => this.setState({ isVisibleMarketplaceModal: false });

    openConfirmationModal = () => this.setState({ isVisibleDeleteModal: true });

    onCloseConfirmationModal = () => this.setState({ isVisibleDeleteModal: false });

    deleteMarketplace = () => this.props.deleteMarketplace(this.state.marketplaceId);

    renderSelectionBody = value => value.name;

    renderDetails = rowData => <DetailsRows itemId={rowData[0]} />;

    changePage = (e, page) => this.props.getMarketplaces(page + 1, this.state.searchText);

    onSearch = searchText => this.props.onSearch(1, searchText);

    render() {
        const { marketplaces, isMarketplacesLoading, i18n, isDeletingMarketplace, pagination } = this.props;
        const { isVisibleMarketplaceModal, isVisibleDeleteModal, modalType, marketplaceId } = this.state;
        return (
            <div>
                <Core.Table
                    title={i18n.t('settings.marketplaceTable.title')}
                    options={this.options}
                    columns={this.tableColumns}
                    data={marketplaces}
                    isLoading={isMarketplacesLoading || isDeletingMarketplace}
                    changePage={this.changePage}
                    pagination={pagination}
                    actionConfig={this.tableActions}
                />

                {isVisibleMarketplaceModal ? (
                    <MarketplaceModal
                        isVisible={isVisibleMarketplaceModal}
                        onClose={this.onCloseMarketplaceModal}
                        id={marketplaceId}
                        modalType={modalType}
                    />
                ): null
                }
                {isVisibleDeleteModal ? (
                    <Core.ConfirmationModal
                        isVisible={isVisibleDeleteModal}
                        onClose={this.onCloseConfirmationModal}
                        onConfirm={this.deleteMarketplace}
                        isLoading={isDeletingMarketplace}
                    />
                ): null
                }
            </div>
        );
    }
}

export default withMarketplaceTableBase(MarketplaceTable);
