import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as _ from "lodash";
import { constants as coreConstants, Toast, translate } from '../../../../core';
import { CURRENCY_RATE_FIELDS } from "../../../constants"
import * as actions from "../../../actions";
import * as selectors from "../../../selectors";

export default function withCurrencyRateModalBase(WrappedComponent) {
    class CurrencyRateModalBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            currencyId: PropTypes.string,
            isVisible: PropTypes.bool.isRequired,
            onClose: PropTypes.func.isRequired,
            modalType: PropTypes.number.isRequired,
            currency: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            isAddingToFavorites: PropTypes.bool,
        };

        static defaultProps = {
            isAddingToFavorites: false,
            currencyId: '',
            currency: undefined
        };

        constructor(props) {
            super(props);

            const { i18n, currency } = this.props;

            this.state = {
                fields: {
                    [CURRENCY_RATE_FIELDS.name]: _.get(currency, `${[CURRENCY_RATE_FIELDS.name]}`, ''),
                    [CURRENCY_RATE_FIELDS.value]: _.get(currency, `${[CURRENCY_RATE_FIELDS.value]}`, 0),
                },
            };

            this.fields = [
                {
                    name: CURRENCY_RATE_FIELDS.name,
                    placeholder: i18n.t('settings.currencyModal.currency'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.text,
                    autoFocus: true,
                },
                {
                    name: CURRENCY_RATE_FIELDS.value,
                    placeholder: i18n.t('settings.currencyModal.exchangeRate'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.number
                }
            ];
        }

        onChangeValue = (val, field) => {
            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    [field.name]: val
                }
            }))
        };


        addCurrency = () => {
            const { fields } = this.state;
            const { i18n, modalType, currencyId } = this.props;

            if (_.isEmpty(fields[CURRENCY_RATE_FIELDS.name])) {
                Toast.error({ description: i18n.t('settings.currencyModal.error.currencyName') })
            }
            if (_.toNumber(fields[CURRENCY_RATE_FIELDS.value]) === 0) {
                Toast.error({ description: i18n.t('settings.currencyModal.error.currencyValue') })
            }
            else {
                modalType === coreConstants.MODAL_TYPE.edit ? this.props.actions.updateCurrency(currencyId, fields) : this.props.actions.addCurrency(fields);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    addCurrency={this.addCurrency}
                    values={this.state.fields}
                    fields={this.fields}
                    onChangeValue={this.onChangeValue}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        isAddingCurrency: selectors.isAddingCurrency(state),
        isUpdatingCurrency: selectors.isUpdatingCurrency(state),
        currency: ownProps.modalType === coreConstants.MODAL_TYPE.edit ? selectors.getCurrencyById(state, ownProps.currencyId) : null,
        addCurrencyError: selectors.getAddCurrencyError(state),
        updateCurrencyError: selectors.getUpdateCurrencyError(state)
    });

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CurrencyRateModalBase));
}
