import * as actionTypes from "./actionTypes";
import * as api from "./api";
import { handleErrors, actions as coreActions, Toast } from "../core";
import { actions as inventoryActions } from '../inventory';

export function getAccount() {
    return function (dispatch) {
        dispatch({ type: actionTypes.GET_COMPANY_ACCOUNT.REQUEST });
        return api.getAccount()
            .then(res => {
                dispatch({
                    type: actionTypes.GET_COMPANY_ACCOUNT.SUCCESS,
                    payload: {
                        data: res.data,
                    }
                })
            })
            .catch(error => {
                const errors = _.get(error, 'response.data.errors');
                dispatch(coreActions.addError(actionTypes.GET_COMPANY_ACCOUNT.NAME, errors));
                handleErrors(error);
                dispatch({ type: actionTypes.GET_COMPANY_ACCOUNT.ERROR });
            });
    }
}

export function revokeToken() {
    return function (dispatch) {
        dispatch({ type: actionTypes.REVOKE_TOKEN.REQUEST });
        return api.revoke()
            .then(() => {
                Toast.success();
                dispatch({
                    type: actionTypes.REVOKE_TOKEN.SUCCESS,
                })
            })
            .catch(error => {
                const errors = _.get(error, 'response.data.errors');
                dispatch(coreActions.addError(actionTypes.REVOKE_TOKEN.NAME, errors));
                handleErrors(error);
                dispatch({ type: actionTypes.REVOKE_TOKEN.ERROR });
            });
    }
}

export function setToken(token) {
    return function (dispatch) {
        dispatch({ type: actionTypes.SET_TOKEN.REQUEST });
        return api.set(token)
            .then(async res => {
                await dispatch(removeStoredToken());
                await dispatch({ type: actionTypes.SET_TOKEN.SUCCESS });
                await dispatch(getAccount());
                Toast.success();
            })
            .catch(error => {
                const errors = _.get(error, 'response.data.errors');
                dispatch(coreActions.addError(actionTypes.SET_TOKEN.NAME, errors));
                handleErrors(error);
                dispatch({ type: actionTypes.SET_TOKEN.ERROR });
            });
    }
}

export function syncLinnworks() {
    return function (dispatch) {
        dispatch({ type: actionTypes.SYNC_LINNWORKS.REQUEST });
        Toast.info({ type: 'syncInProgress' });
        return api.sync()
            .then(async () => {
                await dispatch(inventoryActions.resetStore());
                await dispatch({
                    type: actionTypes.SYNC_LINNWORKS.SUCCESS,
                });
                await dispatch(getAccount());
                Toast.success();
            })
            .catch(error => {
                const errors = _.get(error, 'response.data.errors');
                dispatch(coreActions.addError(actionTypes.SYNC_LINNWORKS.NAME, errors));
                handleErrors(error);
                dispatch({ type: actionTypes.SYNC_LINNWORKS.ERROR });
            });
    }
}

export function storeToken(token) {
    return function (dispatch) {
        dispatch({ type: actionTypes.STORE_TOKEN.SUCCESS, payload: { token } });
    }
}

export function removeStoredToken() {
    return function (dispatch) {
        dispatch({ type: actionTypes.REMOVE_STORED_TOKEN.SUCCESS });
    }
}