import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

class FormSelect extends React.PureComponent {
    static propTypes = {
        items: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        defaultValue: PropTypes.string,
        htmlFor: PropTypes.string.isRequired,
        inputLabel: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        fullWidth: PropTypes.bool,
        margin: PropTypes.string,
    };

    static defaultProps = {
        disabled: false,
        required: false,
        margin: "normal",
        fullWidth: true,
        defaultValue: '',
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedValue: props.defaultValue,
        }
    }

    onChange = event => {
        this.setState({ selectedValue: event.target.value }, () => this.props.onChange(event));
    };

    get items() {
        return _.map(this.props.items, this.getItem);
    }

    getItem = val => (<MenuItem key={val.id} value={val.id}>{val.name}</MenuItem>);

    render() {
        const { margin, fullWidth, inputLabel, htmlFor, disabled, required } = this.props;
        return (
            <FormControl margin={margin} required={required} fullWidth={fullWidth} disabled={disabled}>
                <InputLabel htmlFor={htmlFor}>{inputLabel}</InputLabel>
                <Select
                    value={this.state.selectedValue}
                    onChange={this.onChange}>
                    {this.items}
                </Select>
            </FormControl>
        );
    }
}

export default FormSelect;
