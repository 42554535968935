import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { constants as coreConstants, getPagination, translate } from "../../../core";
import { POSTAL_SERVICE_FIELDS, TIMEOUT } from "../../constants";
import * as actions from "../../actions";
import * as selectors from "../../selectors";

export const COLUMNS = {
    id: "id",
    name: POSTAL_SERVICE_FIELDS.name
};

export default function withPostalServiceTableBase(WrappedComponent) {
    class PostalServiceTableBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            pagination: PropTypes.object,
            actions: PropTypes.object.isRequired,
        };

        static defaultProps = {
            pagination: null
        };

        constructor(props) {
            super(props);
            const { i18n } = this.props;

            this.tableColumns = {
                [COLUMNS.name]: {
                    label: i18n.t("settings.postalServiceTable.name"),
                    name: COLUMNS.name,
                },
                [COLUMNS.id]: {
                    label: i18n.t("settings.postalServiceTable.id"),
                    name: COLUMNS.id,
                }
            };

            this.props.actions.getPostalServices();
            this.debouncedOnSearch = _.debounce(this.getPostalServices, TIMEOUT);
        }

        getPostalServices = (page, filterText) => {
            this.props.actions.getPostalServices({
                page,
                count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                filterText
            });
        };

        deletePostalService = postalServiceId => {
            const { pagination } = this.props;
            this.props.actions.deletePostalService(postalServiceId).then(
                () => this.getPostalServices(pagination.itemCount > 0 ? pagination.pageNumber : pagination.pageNumber-1)
            );
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    tableColumns={this.tableColumns}
                    deletePostalService={this.deletePostalService}
                    getPostalServices={this.getPostalServices}
                    onSearch={this.debouncedOnSearch}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isPostalServicesLoading: selectors.isPostalServices(state),
            isDeletingPostalService: selectors.isDeletingPostalService(state),
            pagination: getPagination(selectors.getPostalServicesPagination(state)),
            postalServices: selectors.getPostalServicesArray(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(PostalServiceTableBase));
}
