import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import withSignUpBase from './SignUp.base';
import { constants as coreConstants, components as Core } from "../../../core";
import { fonts } from "../../../../styles";

class SignUp extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        fields: PropTypes.array.isRequired,
        onChangeValue: PropTypes.func.isRequired,
        onSignUp: PropTypes.func.isRequired,
        isSignUp: PropTypes.bool.isRequired,
        isDisabledSignUp: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
    };

    get renderSignUpForm() {
        const { classes, fields, i18n, isDisabledSignUp, isSignUp, history } = this.props;
        return (
            <Core.Spin
                spinning={isSignUp}>
                <Core.Form headerLabel={i18n.t('auth.signUp')} isLogo={true}>
                    {_.map(fields, this.renderField)}
                    <Core.Button
                        onClick={this.props.onSignUp}
                        fullWidth={true}
                        variant="contained"
                        color="primary"
                        disabled={isDisabledSignUp}
                        className={classes.submit}>
                        {i18n.t('auth.signUp')}
                    </Core.Button>
                    <div className={classes.link}>
                        <span>{i18n.t('or')}</span>
                        <Core.Link
                            component="button"
                            variant="body2"
                            className={classes.signUpButton}
                            onClick={this.changeLocation}>
                            {i18n.t("auth.signIn")}
                        </Core.Link>
                    </div>
                </Core.Form>
            </Core.Spin>
        )
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.isSignUp && !nextProps.isSignUp && !nextProps.error) {
            this.props.history.push(coreConstants.ROUTES.signIn());
        }
    }

    changeLocation = () => {
        this.props.history.push(coreConstants.ROUTES.signIn())
    };

    renderField = field => (
        <Core.FormInput
            {...field}
            key={field.name}
            required={field.isRequired}
            htmlFor={field.name}
            autoFocus={field.autoFocus}
            inputLabel={field.placeholder}
            onChange={event => this.props.onChangeValue(event.target.value, field)}
            type={field.type}
        />
    );

    render() {
        return (
            <Core.MainContainer>
                {this.renderSignUpForm}
            </Core.MainContainer>
        );
    }
}

const styles = theme => ({
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    link: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing.unit * 2,
        justifyContent: 'center',
        ...fonts.smLight,
    },
    signUpButton: {
        ...fonts.smLight,
    }
});

SignUp.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withSignUpBase(SignUp));
