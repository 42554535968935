const spacing = {
    s0: 4,
    s1: 8,
    s2: 12,
    s3: 16,
    s4: 20,
    s5: 24,
    s6: 28,
    s7: 32,
    s8: 36,
    s9: 40,
    s10: 44,
    s11: 48,
    s12: 52,
    s13: 56,
    s14: 60
};

export default spacing;