import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import { translate, parseQueryString } from '../../../core';
import * as actions from "../../actions";
import { selectors as authSelectors } from "../../../auth";
import { selectors as accountSelectors } from "../../../account";

export default function withVerifyBase(WrappedComponent) {
    class VerifyBase extends React.PureComponent {
        static propTypes = {
            linnworkstoken: PropTypes.string,
            actions: PropTypes.object.isRequired,
            isLoggedIn: PropTypes.bool,
        };

        static defaultProps = {
            linnworkstoken: undefined,
            isLoggedIn: false
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const params = parseQueryString(_.get(ownProps, 'location.search'));
        return {
            linnworkstoken: params.linnworkstoken,
            isLoggedIn: authSelectors.isLoggedIn(state),
            accountRole: accountSelectors.accountRole(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(VerifyBase));
}