import axios from 'axios';

const endpoints = {
    users: 'account/users',
    updateUser: userId => `account/users/${userId}`
};

export const getUsers = params => axios.get(endpoints.users, { params });
export const inviteUsers = data => axios.post(endpoints.users, data);
export const updateUser = (user, userId) => axios.put(endpoints.updateUser(userId), user);
export const deleteUser = id => axios.delete(`${endpoints.users}/${id}`);
