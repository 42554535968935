import React from 'react';
import PropTypes from 'prop-types';
//import Warning from '@material-ui/icons/ReportProblemOutlined';
import Warning from '@material-ui/icons/ErrorOutline';
import classNames from 'classnames';
import CoreModal from '../Modal';
import Button from '../Button';
import Grid from '../Grid';
import { translate, withStyles } from '../../services';
import { colors, fonts, spacing, hexToRGB } from "../../../../styles";

class ConfirmationModal extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
        onClose: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        isVisible: PropTypes.bool,
        descriptions: PropTypes.string,
        confirmTextPath: PropTypes.string,
        cancelTextPath: PropTypes.string,
    };

    static defaultProps = {
        isLoading: false,
        isVisible: false,
        confirmTextPath: "delete",
        cancelTextPath: "cancel",
        descriptions: ""
    };

    get cancelButton() {
        const { classes, i18n, onClose, isLoading, cancelTextPath } = this.props;
        return (
            <Button variant="contained" className={classNames(classes.button, classes.buttonCancel)} onClick={onClose} disabled={isLoading}>
                {i18n.t(cancelTextPath)}
            </Button>
        )
    }

    get confirmButton() {
        const { classes, i18n, onConfirm, isLoading, confirmTextPath } = this.props;
        return (
            <Button variant="contained" className={classNames(classes.button, classes.buttonConfirm)} onClick={onConfirm} disabled={isLoading}>
                {i18n.t(confirmTextPath)}
            </Button>
        )
    }

    render() {
        const { i18n, isLoading, onClose, isVisible, classes, descriptions } = this.props;
        return (
            <CoreModal
                visible={isVisible}
                onClose={onClose}
                className={classes.modalContainer}
                isEmptyFooter={true}
            >
                <div className={classes.container} >
                    <div className={classes.flexContainerWithMargin}>
                        <Warning className={classNames(classes.icon, isLoading ? classes.loading : null)} />
                    </div>
                    <div className={classes.flexContainerWithMargin}>
                        <span className={classes.title}>{i18n.t('confirmationModal.title')}</span>
                    </div>
                    <div className={classes.flexContainerWithMargin}>
                        <span className={classes.descriptions}>{descriptions ? descriptions : i18n.t('confirmationModal.descriptions')}</span>
                    </div>
                    <Grid container={true} spacing={24} className={classes.flexContainerWithMargin}>
                        <Grid item={true} xs={4} className={classes.item}>
                            {this.confirmButton}
                        </Grid>
                        <Grid item={true} xs={4} className={classes.item}>
                            {this.cancelButton}
                        </Grid>
                    </Grid>
                </div>
            </CoreModal>
        );
    }
}

const MODAL_WIDTH = {
    fullWidth: 95,
    partWidth: 500
};

const ICON_SIZE = 150;
const BUTTON_WIDTH = 110;

const styles = theme => ({
    container: {
        color: colors.gray.darkGray,
        ...fonts.mdNormal
    },
    flexContainerWithMargin: {
        display: "flex",
        flex: 1,
        justifyContent: "center",
        marginTop: spacing.s3,
    },
    '@keyframes rotation': {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)"
        }
    },
    loading: {
        animation: `rotation 2s linear infinite`,
    },
    icon: {
        color: colors.red,
        width: ICON_SIZE,
        height: ICON_SIZE,
    },
    title: {
        ...fonts.lgNormal
    },
    descriptions: {
        textAlign: "center",
    },
    item: {
        display: "flex",
        minWidth: BUTTON_WIDTH,
    },
    button: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        color: colors.white,
    },
    buttonConfirm: {
        backgroundColor: colors.red,
        "&:hover": {
            backgroundColor: `rgba(${hexToRGB(colors.red)}, 0.8)`
        }
    },
    buttonCancel: {
        backgroundColor: colors.gray.darkGray,
        "&:hover": {
            backgroundColor: `rgba(${hexToRGB(colors.gray.darkGray)}, 0.8)`
        }
    },
    modalContainer: {
        [theme.breakpoints.up("xs")]: {
            width: MODAL_WIDTH.partWidth,
        },
        [theme.breakpoints.down('xs')]: {
            width: `${MODAL_WIDTH.fullWidth}vw`,
        },
        "& .rc-dialog-header": {
            display: "none",
        }
    },


});

export default translate()(withStyles(styles)(ConfirmationModal));
