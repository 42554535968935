import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

class LoadingComponent extends React.PureComponent {
    static propTypes = {
        item: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
        customProps: PropTypes.object
    };

    static defaultProps = {
        customProps: {},
        item: null
    };

    get suspenseComponent() {
        return (
            <div>Loading...</div>
        )
    }

    render() {
        const { item, customProps } = this.props;
        return (
            <Suspense fallback={this.suspenseComponent}>
                <item.component {...item.props} {...customProps} />
            </Suspense>
        );
    }
}

export default LoadingComponent;
