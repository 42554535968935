import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import withZoneModalBase from "./ZoneModal.base";
import { MODAL_WIDTH } from "../../../constants";
import { components as Core, withStyles, constants as coreConstants } from "../../../../core";
import { colors, fonts } from "../../../../../styles";

class ZoneModal extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        values: PropTypes.object.isRequired,
        fields: PropTypes.array.isRequired,
        onChangeValue: PropTypes.func.isRequired,
        addZone: PropTypes.func.isRequired,
        modalType: PropTypes.number.isRequired,
        isAdding: PropTypes.bool,
        isUpdating: PropTypes.bool,
        addZoneError: PropTypes.array,
        updateZoneError: PropTypes.array,
    };

    static defaultProps = {
        isAdding: false,
        isUpdating: false,
        addZoneError: undefined,
        updateZoneError: undefined,
    };

    componentDidUpdate(prevProps) {
        if ((prevProps.isAdding && !this.props.isAdding && !this.props.addZoneError) ||
            (prevProps.isUpdating && !this.props.isUpdating && !this.props.updateZoneError)) {
            this.props.onClose();
        }
    }

    renderField = field => (
        <Core.Grid item={true} xs={12} key={field.name}>
            <Core.FormInput
                key={field.name}
                required={field.isRequired}
                htmlFor={field.name}
                autoFocus={field.autoFocus}
                inputLabel={field.placeholder}
                onChange={event => this.props.onChangeValue(event.target.value, field)}
                defaultValue={this.props.values[field.name]}
                type={field.type}
            />
        </Core.Grid>
    );

    render() {
        const { i18n, isVisible, onClose, isAdding, fields, classes, modalType, addZone, isUpdating } = this.props;
        return (
            <Core.Modal
                visible={isVisible}
                title={modalType === coreConstants.MODAL_TYPE.edit ?
                    i18n.t('settings.zoneModal.editTitle') :
                    i18n.t('settings.zoneModal.title')}
                onClose={onClose}
                onAccept={addZone}
                isLoading={isAdding || isUpdating}
                className={classes.modalContainer}>
                <div className={classes.container}>
                    <Core.Grid container={true}>
                        {_.map(fields, this.renderField)}
                    </Core.Grid>
                </div>
            </Core.Modal>
        )
    }
}

const styles = theme => ({
    container: {
        color: colors.background,
        flex: 1,
        ...fonts.smNormal
    },
    modalContainer: {
        [theme.breakpoints.up("md")]: {
            width: MODAL_WIDTH.partWidth,
        },
        [theme.breakpoints.down("sm")]: {
            width: `${MODAL_WIDTH.fullWidth}vw`,
        },
    },
});


export default withZoneModalBase(withStyles(styles)(ZoneModal));
