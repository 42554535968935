import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import withChangePasswordBase from './ChangePassword.base';
import { components as Core } from '../../../core';
import { spacing, fonts } from '../../../../styles';

class ChangePassword extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        isSaveButtonDisabled: PropTypes.bool,
        onSave: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        fields: PropTypes.array.isRequired,
        values: PropTypes.object.isRequired,
    };

    static defaultProps = {
        isSaveButtonDisabled: true,
    };

    getItem = item => (
        <Core.Grid key={item.name}>
            <Core.FormInput
                key={item.name}
                htmlFor={item.name}
                defaultValue={this.props.values[item.name]}
                inputLabel={item.placeholder}
                isRequired={item.isRequired}
                type={item.type}
                onChange={event => this.props.onChange(item.name, event.target.value)}
            />
        </Core.Grid>
    );

    get form() {
        return _.map(this.props.fields, this.getItem);
    }

    render() {
        const { i18n, classes, isSaveButtonDisabled, onSave } = this.props;
        return (
            <div>
                <div className={classes.changePasswordHeader}>
                    <span>{i18n.t('account.changeYourPassword')}</span>
                </div>
                <div className={classes.container}>
                    {this.form}
                    <div className={classes.changePasswordButton}>
                        <Core.Button
                            fullWidth={true}
                            variant="contained"
                            color="primary"
                            disabled={isSaveButtonDisabled}
                            onClick={onSave}>
                            {i18n.t('changePassword')}
                        </Core.Button>
                    </div>
                </div>
            </div>
        );
    }
}

const styles = theme => ({
    container: {
        padding: `0px 0px 0px ${spacing.s5}px`
    },
    changePasswordHeader: {
        paddingTop: `${spacing.s4}px`,
        '& span': {
            ...fonts.mdBold
        }
    },
    changePasswordButton: {
        padding: `${spacing.s2}px`
    }
});


export default withChangePasswordBase(withStyles(styles)(ChangePassword))
