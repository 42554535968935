import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import _ from 'lodash';
import { bindActionCreators } from "redux";
import * as actions from "../../../actions";
import * as selectors from '../../../selectors';
import { detailsObjectFormatter, getMarketPlaces, validateData, validatePrice, formatMarketPlaces, getCompetitors, getSupplierFields, mapValues, formatFullItem } from '../../../services';
import { constants as coreConstants, translate } from '../../../../core';
import { ADDITIONAL_FIELD_PROPS, SELECTOR_NAMES, FIELDS, CREATE_MODEL, ROUND_DEFAULT_VALUE } from '../../../constants'
import { selectors as settingsSelectors, actions as settingsActions } from "../../../../settings";

const TIMEOUT = 250;

export default function withEditFormBase(WrappedComponent) {
    class EditForm extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            itemId: PropTypes.string,
            actions: PropTypes.object.isRequired,
            fullItem: PropTypes.object,
            isItemLoading: PropTypes.bool,
            editFormVisible: PropTypes.bool.isRequired,
            isItemUpdate: PropTypes.bool,
            onChangeEditForm: PropTypes.func.isRequired,
            isAdditionalDataLoading: PropTypes.bool,
            isCreate: PropTypes.bool,
            settingsActions: PropTypes.object.isRequired,
        };

        static defaultProps = {
            fullItem: {},
            isItemLoading: false,
            isItemUpdate: false,
            isAdditionalDataLoading: false,
            isCreate: false,
            itemId: null,
        };

        constructor(props) {
            super(props);

            this.state = {
                fullItem: {},
                formattedItem: [],
                formattedMarkets: [],
                formattedCompetitors: [],
                formattedSupplier: [],
                isItemLoading: props.isItemLoading,
                isItemUpdate: props.isItemUpdate,
                isAdditionalDataLoading: props.isAdditionalDataLoading,
                selectedZone: '',
                isPercentage: false,
                defaultMarketValues: {
                    min: 0,
                    max: 0,
                    currentCalculated: 0
                },
            };

            this.debouncedOnChangeValue = _.debounce(this.onChangeValue, TIMEOUT);
        }

        componentDidMount = () => {
            this.props.actions.getAdditionalInfo();
            const { fullItem, itemId, isCreate } = this.props;

            if (!isCreate && _.isEmpty(fullItem) && itemId && fullItem.id !== itemId) {
                this.props.actions.getItem(this.props.itemId);
            } else {
                const newMarketPlaces = formatMarketPlaces(this.props.marketplaces);

                this.setState({
                    fullItem: formatFullItem(fullItem),
                    formattedItem: detailsObjectFormatter(fullItem, ADDITIONAL_FIELD_PROPS),
                    formattedMarkets: getMarketPlaces(fullItem.prices, newMarketPlaces),
                    formattedCompetitors: getCompetitors(fullItem.competitors),
                    formattedSupplier: getSupplierFields(fullItem.supplier),
                })
            }
        };

        static getDerivedStateFromProps(nextProps, prevState) {
            if (prevState.isItemLoading && !nextProps.isItemLoading) {
                const newMarketPlaces = formatMarketPlaces(nextProps.marketplaces);
                const isPercentage = _.some(nextProps.fullItem[FIELDS.prices], FIELDS.isPercentage);

                return {
                    fullItem: formatFullItem(nextProps.fullItem),
                    formattedItem: detailsObjectFormatter(nextProps.fullItem, ADDITIONAL_FIELD_PROPS),
                    formattedMarkets: getMarketPlaces(nextProps.fullItem.prices, newMarketPlaces),
                    formattedCompetitors: getCompetitors(nextProps.fullItem.competitors),
                    formattedSupplier: getSupplierFields(nextProps.fullItem.supplier),
                    isPercentage,
                    isItemLoading: nextProps.isItemLoading,
                };
            }

            if (prevState.isItemUpdate && !nextProps.isItemUpdate) {
                const newMarketPlaces = formatMarketPlaces(nextProps.marketplaces);
                const isPercentage = _.some(nextProps.fullItem[FIELDS.prices], FIELDS.isPercentage);
                return {
                    fullItem: formatFullItem(nextProps.fullItem),
                    formattedItem: detailsObjectFormatter(nextProps.fullItem, ADDITIONAL_FIELD_PROPS),
                    formattedMarkets: getMarketPlaces(nextProps.fullItem.prices, newMarketPlaces),
                    formattedCompetitors: getCompetitors(nextProps.fullItem.competitors),
                    formattedSupplier: getSupplierFields(nextProps.fullItem.supplier),
                    isItemUpdate: nextProps.isItemUpdate,
                    isPercentage,
                }
            }

            if (prevState.isAdditionalDataLoading && !nextProps.isAdditionalDataLoading) {
                const newMarketPlaces = formatMarketPlaces(nextProps.marketplaces);
                const isPercentage = _.some(nextProps.fullItem[FIELDS.prices], FIELDS.isPercentage);
                return {
                    fullItem: formatFullItem(nextProps.fullItem),
                    formattedItem: detailsObjectFormatter(nextProps.fullItem, ADDITIONAL_FIELD_PROPS),
                    formattedMarkets: getMarketPlaces(nextProps.fullItem.prices, newMarketPlaces),
                    formattedCompetitors: getCompetitors(nextProps.fullItem.competitors),
                    formattedSupplier: getSupplierFields(nextProps.fullItem.supplier),
                    isAdditionalDataLoading: nextProps.isAdditionalDataLoading,
                    isPercentage,
                }
            }

            return {
                isItemLoading: nextProps.isItemLoading,
                isItemUpdate: nextProps.isItemUpdate,
                isAdditionalDataLoading: nextProps.isAdditionalDataLoading,
            };
        }

        onChangeValue = (key, value, item) => {
            let newValue = value;

            if (item && item.type === 'number' && item.name === FIELDS.taxRate) {
                newValue = _.round(value, ROUND_DEFAULT_VALUE) / 100;
            }

            this.setState(prevState => ({ fullItem: { ...prevState.fullItem, [key]: newValue } }));
        };

        onChangeMarketsTable = (id, key, val) => {
            this.setState(prevState => {
                const value = parseFloat(val);
                let currentItem = prevState.fullItem.prices[id];

                if (!currentItem) {
                    currentItem = _.find(prevState.formattedMarkets, i => i.id === id);
                }

                return {
                    fullItem: {
                        ...prevState.fullItem,
                        prices: {
                            ...prevState.fullItem.prices,
                            [id]: {
                                ...currentItem,
                                [key]: value ? value : 0
                            }
                        }
                    },
                    formattedMarkets: _.map(prevState.formattedMarkets, item => {
                        if (item.id === id) {
                            return {
                                ...item,
                                [key]: value ? value : 0
                            }
                        }

                        return item;
                    })
                } });
        };

        onRemoveCompetitors = name => {
            this.setState(prevState => {
                const newKeys = _.chain(_.keys(prevState.fullItem.competitors)).map(value => {
                    if (value === name) {
                        return null
                    }

                    return value;
                }).compact().value();

                return {
                    fullItem: {
                        ...prevState.fullItem,
                        competitors: _.pick(prevState.fullItem.competitors, newKeys)
                    },
                    formattedCompetitors: _.chain(prevState.formattedCompetitors).map((item, index) => {
                        if (item.name === name) {
                            return null
                        }

                        return { ...item, id: index };
                    }).compact().value()
                } });
        };

        onAddCompetitors = item => {
            if (!this.state.fullItem.competitors[item.name]) {
                this.setState(prevState => ({
                    fullItem: {
                        ...prevState.fullItem,
                        competitors: {
                            ...prevState.fullItem.competitors,
                            [item.name]: {
                                price: item.price,
                                shippingPrice: item.shippingPrice,
                            }
                        }
                    },
                    formattedCompetitors: [...prevState.formattedCompetitors, { ...item, id: prevState.formattedCompetitors.length + 1 }]
                }));
            }
        };

        onUpdateCompetitor = (oldName, newValue) => {
            this.setState(prevState => {
                const newCompetitors = _.omitBy({
                    ...prevState.fullItem.competitors,
                    [oldName]: null,
                    [newValue.name]: {
                        ...newValue,
                    },
                }, _.isNil);

                const newFormattedCompetitors = _.map(prevState.formattedCompetitors, item => {
                    if (item.name === oldName) {
                        return newValue;
                    }

                    return item;
                });

                return {
                    ...prevState,
                    fullItem: {
                        ...prevState.fullItem,
                        competitors: newCompetitors,
                    },
                    formattedCompetitors: newFormattedCompetitors,
                } });
        };

        onChangeDefaultMarketValues = (key, value) => {
            this.setState(prevState => ({ defaultMarketValues: { ...prevState.defaultMarketValues, [key]: value } }))
        };

        onApplyDefaultMarketValues = newMarkets => {
            this.setState(prevState => {
                const newFullItems = {
                    ...prevState.fullItem,
                    prices: _.chain(newMarkets)
                        .keyBy('id')
                        .mapValues()
                        .value(),
                };

                return {
                    fullItem: newFullItems,
                    formattedItem: detailsObjectFormatter(newFullItems, ADDITIONAL_FIELD_PROPS),
                    formattedMarkets: newMarkets,
                } });
        };

        onSave = () => {
            const reqBody = { ...this.state.fullItem };
            const newPrices = {};
            let isValid = true;

            _.forOwn(reqBody[FIELDS.prices], (val, key) => {
                if (!val) {
                    return;
                }

                if (!parseFloat(val.max) && !parseFloat(val.min)) {
                    reqBody[FIELDS.prices][key] = null;
                } else if (!validatePrice(val.marketplace, val.min, val.max, _.get(val, 'current', 0), this.props.i18n)) {
                    isValid = false;
                }

                const newItem = mapValues(val, true);
                const itemValues = this.getMarketplaceValue(val, newItem, this.state.isPercentage);

                _.assign(newPrices, { [key]: { ...newItem, ...itemValues, isPercentage: this.state.isPercentage } });
            });

            if (isValid) {
                const request = {
                    ...reqBody,
                    [FIELDS.company]: _.get(reqBody[FIELDS.company], 'id', false) ?
                        reqBody[FIELDS.company] : null,
                    [FIELDS.postalService]: _.get(reqBody[FIELDS.postalService], 'id', false) ?
                        reqBody[FIELDS.postalService] : null,
                    [FIELDS.packaging]: _.get(reqBody[FIELDS.packaging], 'id', false) ?
                        reqBody[FIELDS.packaging] : null,
                    [FIELDS.prices]: _.omitBy(newPrices, _.isNil),
                };

                this.props.isCreate ? this.props.actions.createItem(request) : this.props.actions.editItem(request);
            }
        };

        getMarketplaceValue = (val, newItem, isPercentage) => {
            if (isPercentage) {
                return parseFloat(val.minMargin) && parseFloat(val.maxMargin) ? {
                    min: newItem.minMargin,
                    max: newItem.maxMargin,
                    currentCalculated: newItem.currentMargin
                } : null
            }

            return parseFloat(val.max) && parseFloat(val.min) ? {
                min: newItem.min,
                max: newItem.max,
                currentCalculated: newItem.currentCalculated
            } : null
        };

        get isDisabledSaveButton() {
            return _.isEmpty(this.state.fullItem) || validateData(this.state.fullItem, ADDITIONAL_FIELD_PROPS) || !this.props.canEdit;
        }

        onSearch = (filterText, field, isMore, page) => {
            switch (field) {
                case SELECTOR_NAMES.postalService: {
                    return this.props.settingsActions.getPostalServices({
                        page: isMore ? page : 1,
                        count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                        filterText
                    }, true);
                }
                case SELECTOR_NAMES.company: {
                    return this.props.settingsActions.getCompanies({
                        page: isMore ? page : 1,
                        count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                        filterText
                    }, true);
                }
                case SELECTOR_NAMES.packaging: {
                    return this.props.settingsActions.getPackagings({
                        page: isMore ? page : 1,
                        count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                        filterText
                    }, true);
                }
                case SELECTOR_NAMES.zones: {
                    return this.props.settingsActions.getZones({
                        page: isMore ? page : 1,
                        count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                        filterText
                    }, true);
                }
                default:
                    break;
            }
        };

        onChangeZone = selectedZone => (this.setState({ selectedZone }));

        onChangeInputType = isPercentage => {
            if (isPercentage !== this.state.isPercentage) {
                let newPrices = {};
                _.forOwn(this.state.fullItem.prices, (item, key) => {
                    if (!isPercentage) {
                        newPrices = {
                            ...newPrices,
                            [key]: {
                                ...item,
                                minMargin: _.get(this.props.fullItem.prices[key], 'minMargin', 0),
                                maxMargin: _.get(this.props.fullItem.prices[key], 'maxMargin', 0),
                                isPercentage,
                            }
                        }
                    } else {
                        newPrices = {
                            ...newPrices,
                            [key]: {
                                ...item,
                                min: _.get(this.props.fullItem.prices[key], 'min', 0),
                                max: _.get(this.props.fullItem.prices[key], 'max', 0),
                                isPercentage,
                            }
                        }
                    }
                });

                const newMarketPlaces = formatMarketPlaces(this.props.marketplaces);
                const formattedMarkets = getMarketPlaces(this.props.fullItem.prices, newMarketPlaces);

                this.setState(prevState => ({
                    isPercentage,
                    fullItem: {
                        ...prevState.fullItem,
                        prices: newPrices,
                    },
                    formattedMarkets,
                }));
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    item={this.state.formattedItem}
                    onChangeValue={this.debouncedOnChangeValue}
                    onSave={this.onSave}
                    fullItem={this.state.fullItem}
                    isDisabledSaveButton={this.isDisabledSaveButton}
                    onChangeMarketsTable={this.onChangeMarketsTable}
                    formattedMarkets={this.state.formattedMarkets}
                    formattedSupplier={this.state.formattedSupplier}
                    onChangeDefaultMarketValues={this.onChangeDefaultMarketValues}
                    onApplyDefaultMarketValues={this.onApplyDefaultMarketValues}
                    defaultMarketValues={this.state.defaultMarketValues}
                    onAddCompetitors={this.onAddCompetitors}
                    onUpdateCompetitor={this.onUpdateCompetitor}
                    onRemoveCompetitors={this.onRemoveCompetitors}
                    formattedCompetitors={this.state.formattedCompetitors}
                    selectedZone={this.state.selectedZone}
                    onChangeZone={this.onChangeZone}
                    onSearch={this.onSearch}
                    isPercentage={this.state.isPercentage}
                    onChangeInputType={this.onChangeInputType}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const fullItem = ownProps.isCreate ? { ...CREATE_MODEL } : selectors.getCurrentItem(state, ownProps.itemId);

        return {
            fullItem,
            isItemLoading: selectors.isItem(state, ownProps.itemId),
            isItemUpdate: selectors.isItemUpdate(state),
            marketplaces: selectors.getMarketplaces(state),
            isItemCreating: selectors.isItemCreating(state),
            isAdditionalDataLoading: selectors.isAdditionalDataLoading(state),
            [SELECTOR_NAMES.postalService]: settingsSelectors.getPostalServicesItems(state),
            [SELECTOR_NAMES.packaging]: settingsSelectors.getPackagingsItems(state),
            [SELECTOR_NAMES.company]: settingsSelectors.getCompaniesItems(state),
            [SELECTOR_NAMES.zones]: settingsSelectors.getZonesItems(state),
            isLoading: {
                [SELECTOR_NAMES.postalService]: settingsSelectors.isPostalServices(state),
                [SELECTOR_NAMES.packaging]: settingsSelectors.isPackagings(state),
                [SELECTOR_NAMES.company]: settingsSelectors.isCompanies(state),
            },
            pagination: {
                [SELECTOR_NAMES.postalService]: settingsSelectors.getPostalServicesScrollPagination(state),
                [SELECTOR_NAMES.packaging]: settingsSelectors.getPackagingsScrollPagination(state),
                [SELECTOR_NAMES.company]: settingsSelectors.getCompaniesScrollPagination(state),
                [SELECTOR_NAMES.zones]: settingsSelectors.getZonesScrollPagination(state),
            },
            initNames: {
                [SELECTOR_NAMES.postalService]: _.get(fullItem, `${SELECTOR_NAMES.postalService}.name`, ''),
                [SELECTOR_NAMES.packaging]: _.get(fullItem, `${SELECTOR_NAMES.packaging}.name`, ''),
                [SELECTOR_NAMES.company]: _.get(fullItem, `${SELECTOR_NAMES.company}.name`, ''),
            },
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch),
            settingsActions: bindActionCreators(settingsActions, dispatch),
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(EditForm));
}
