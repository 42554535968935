import React from 'react';
import PropTypes from 'prop-types';
import Settings from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import withPackagingTableBase, { COLUMNS } from './PackagingTable.base';
import { components as Core, constants as coreConstants } from "../../../core"
import PackagingModal from "./PackagingModal";

const hiddenOptions = {
    display: false,
};

const sortOptions = {
    sort: false
};

class PackagingTable extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        tableColumns: PropTypes.object.isRequired,
        deletePackaging: PropTypes.func.isRequired,
        isPackagingsLoading: PropTypes.bool,
        isDeletingPackaging: PropTypes.bool,
        packagings: PropTypes.array,
        getPackagings: PropTypes.func.isRequired,
        pagination: PropTypes.object,
        onSearch: PropTypes.func.isRequired,

    };

    static defaultProps = {
        isPackagingsLoading: false,
        isDeletingPackaging: false,
        packagings: [],
        pagination: null
    };

    constructor(props) {
        super(props);

        const { tableColumns, i18n } = this.props;

        this.state = {
            isVisiblePackagingModal: false,
            modalType: coreConstants.MODAL_TYPE.create,
            isVisibleDeleteModal: false,
            packagingId: '',
            isSearchOpen: false,
            searchText: ""
        };

        this.tableColumns = [
            { ...tableColumns[COLUMNS.id], options: hiddenOptions },
            { ...tableColumns[COLUMNS.packaging], options: sortOptions },
            { ...tableColumns[COLUMNS.cost], options: sortOptions },
        ];

        this.tableActions = {
            base: [
                {
                    label: i18n.t("settings.packagingTable.addPackage"),
                    onClick: this.openAddPackagingModal,
                    icon: <AddIcon />
                }
            ],
            select: [
                {
                    label: i18n.t("table.edit"),
                    onClick: this.openEditPackagingModal,
                    icon: <Settings />
                },
                {
                    label: i18n.t("table.delete"),
                    onClick: this.openConfirmationModal,
                    icon: <Delete />
                }
            ]
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isDeletingPackaging && !this.props.isDeletingPackaging) {
            this.onCloseConfirmationModal();
        }
    }

    get options() {
        return {
            selectableRows: "single",
            responsive: 'scroll',
            rowHover: false,
            onRowsSelect: this.onRowsSelect,
            filter: false,
            print: false,
            viewColumns: false,
            searchText: this.state.searchText,
            download: false,
            onSearchChange: this.onSearch,
            onTableChange: this.tableChanged,
            onSearchOpen: this.onSearchOpen,
        };
    }

    tableChanged = (action, tableState) => {
        const { isSearchOpen } = this.state;
        this.setState({ searchText: tableState.searchText });
        if (action === "search" && isSearchOpen && !tableState.searchText) {
            this.setState({ isSearchOpen: false });
            this.onSearch("");
        }
    };

    onSearchOpen = () => this.setState({ isSearchOpen: true });

    onRowsSelect = currentRowsSelected => {
        const { packagings } = this.props;
        this.setState({ packagingId: packagings[currentRowsSelected[0].dataIndex].id })
    };

    openAddPackagingModal = () => this.setState({ isVisiblePackagingModal: true, modalType: coreConstants.MODAL_TYPE.create, packagingId: '' });

    openEditPackagingModal = () => this.setState({ isVisiblePackagingModal: true, modalType: coreConstants.MODAL_TYPE.edit });

    onClosePackagingModal = () => this.setState({ isVisiblePackagingModal: false });

    openConfirmationModal = () => this.setState({ isVisibleDeleteModal: true });

    onCloseConfirmationModal = () => this.setState({ isVisibleDeleteModal: false });

    deletePackaging = () => this.props.deletePackaging(this.state.packagingId);

    changePage = (e, page) => this.props.getPackagings(page + 1, this.state.searchText);

    onSearch = searchText => this.props.onSearch(1, searchText);

    render() {
        const { packagings, isPackagingsLoading, i18n, isDeletingPackaging, pagination } = this.props;
        const { isVisiblePackagingModal, isVisibleDeleteModal, modalType, packagingId } = this.state;
        return (
            <div>
                <Core.Table
                    title={i18n.t('settings.packagingTable.title')}
                    options={this.options}
                    columns={this.tableColumns}
                    data={packagings}
                    isLoading={isPackagingsLoading || isDeletingPackaging}
                    changePage={this.changePage}
                    pagination={pagination}
                    actionConfig={this.tableActions}
                />

                {isVisiblePackagingModal ? (
                    <PackagingModal
                        isVisible={isVisiblePackagingModal}
                        onClose={this.onClosePackagingModal}
                        id={packagingId}
                        modalType={modalType}
                    />
                ): null
                }
                {isVisibleDeleteModal ? (
                    <Core.ConfirmationModal
                        isVisible={isVisibleDeleteModal}
                        onClose={this.onCloseConfirmationModal}
                        onConfirm={this.deletePackaging}
                        isLoading={isDeletingPackaging}
                    />
                ): null
                }
            </div>
        );
    }
}

export default withPackagingTableBase(PackagingTable);
