import React from 'react';
import PropTypes from 'prop-types';
import Send from '@material-ui/icons/SaveAlt';

import withSettingsBase from './Settings.base';
import { components as Core, constants as coreConstants, permissionHelper, withStyles } from "../../../core"
import CurrencyRateTable from "../CurrencyRateTable";
import ZoneTable from "../ZoneTable";
import CompanyTable from "../CompanyTable";
import MarketplaceTable from "../MarketplaceTable";
import PackagingTable from "../PackagingTable";
import StaticValueTable from "../StaticValueTable";
import PostalServiceTable from "../PostalServiceTable";
import { spacing, fonts, colors } from "../../../../styles";
import ImportModal from "./ImportModal";
import ExportModal from "./ExportModal";

class Settings extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
        exportSettings: PropTypes.func.isRequired,
        isExporting: PropTypes.bool,
        isVisibleExportModal: PropTypes.bool,
        isVisibleImportModal: PropTypes.bool,
        onSettingsImport: PropTypes.func.isRequired,
        onSettingsExport: PropTypes.func.isRequired,
        permissions: PropTypes.array.isRequired,
        isUserAdmin: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        isExporting: false,
        isVisibleExportModal: false,
        isVisibleImportModal: false,
    };

    constructor(props) {
        super(props);

        this.permissions = {
            canImportExport: props.isUserAdmin || permissionHelper.hasPermissions(props.permissions, { permissions: [{
                type: coreConstants.PERMISSION_TYPE.settings,
                value: coreConstants.PERMISSION_VALUE.importAndExport
            }] }),
        }
    }

    render() {
        const { i18n, classes, isExporting, isVisibleExportModal, isVisibleImportModal, onSettingsImport, onSettingsExport } = this.props;
        return (
            <Core.Grid container={true} spacing={24} direction="column" alignItems="stretch">
                {this.permissions.canImportExport ? (
                    <div className={classes.headerButtons}>
                        <Core.TooltipButton key={i18n.t("settings.import")} onClick={onSettingsImport} icon={<Send className={classes.exportIcon} />} title={i18n.t("settings.import")} />
                        <Core.TooltipButton key={i18n.t("settings.export")} onClick={onSettingsExport} icon={<Send />} title={i18n.t("settings.export")} />
                    </div>
                ) : (null)}
                <Core.Grid item={true} className={classes.gridItem}>
                    <CurrencyRateTable />
                </Core.Grid>
                <Core.Grid item={true} className={classes.gridItem}>
                    <Core.Grid container={true} spacing={24} alignItems="stretch">
                        <Core.Grid item={true} xs={12} md={6} className={classes.gridItem}>
                            <ZoneTable />
                        </Core.Grid>
                        <Core.Grid item={true} xs={12} md={6} className={classes.gridItem}>
                            <CompanyTable />
                        </Core.Grid>
                    </Core.Grid>
                </Core.Grid>
                <Core.Grid item={true} className={classes.gridItem}>
                    <MarketplaceTable />
                </Core.Grid>
                <Core.Grid item={true} className={classes.gridItem}>
                    <PackagingTable />
                </Core.Grid>
                <Core.Grid item={true} className={classes.gridItem}>
                    <StaticValueTable />
                </Core.Grid>
                <Core.Grid item={true} className={classes.gridItem}>
                    <PostalServiceTable />
                </Core.Grid>
                <Core.Grid item={true} className={classes.actionButton}>
                    <Core.Spin
                        spinning={isExporting}
                        styles={{
                            overlay: base => ({
                                ...base,
                                background: colors.transparent,
                                position: "unset"
                            }),
                            content: base => ({
                                ...base,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }),
                            spinner: base => ({
                                ...base,
                                width: SPINNER_SIZE,
                                height: SPINNER_SIZE
                            })
                        }}
                    />
                    <span onClick={!isExporting ? this.props.exportSettings : null} className={classes.template}>{i18n.t("settings.downloadTemplate")}</span>
                </Core.Grid>
                {isVisibleImportModal ? (
                    <ImportModal
                        isVisible={isVisibleImportModal}
                        onClose={onSettingsImport}
                    />
                ): null
                }
                {isVisibleExportModal ? (
                    <ExportModal
                        isVisible={isVisibleExportModal}
                        onClose={onSettingsExport}
                    />
                ): null
                }
            </Core.Grid>
        );
    }
}

const SPINNER_SIZE = 15;

const styles = theme => ({
    actionButton: {
        margin: spacing.s1,
        display: "flex",
        alignItems: "center",
        ...fonts.mdNormal,
        "& :hover": {
            cursor: "pointer",
        },
    },
    template: {
        marginLeft: spacing.s1
    },
    gridItem: {
        width: "100%"
    },
    exportIcon: {
        transform: "rotate(180deg)"
    },
    headerButtons: {
        display: "flex",
        justifyContent: "flex-end"
    },
});

export default withSettingsBase(withStyles(styles)(Settings));
