const en = {
    appName: "auto-pricer",
    cancel: "Cancel",
    changePassword: 'Change password',
    ok: "Ok",
    all: 'ALL',
    delete: "Delete",
    save: "Save",
    table: {
        edit: "Edit",
        delete: "Delete",
        reload: "Reload data"
    },
    fields: {
        email: "Email",
        password: "Password",
        name: "Full name",
        confirmPassword: "Confirm password",
        oldPassword: 'Old password',
        newPassword: 'New password',
        linnworksToken: 'Linnworks token',
        linnworksTokenLink: "Get a linnworks token"
    },
    auth: {
        signIn: "Sign In",
        rememberMe: "Remember me",
        signUp: "Sign Up",
        newHere: 'New here?',
        resetPassword: 'Reset password',
        signOut: 'Log Out',
        myProfile: 'My Profile',
    },
    trial: {
        expired: 'Your trial has expired',
        description: 'Please contact support or try again later',
        action: 'Back to sign in',
    },
    emailNotConfirmed: 'Your account is not confirmed',
    forgotPassword: 'Forgot password?',
    sendLinkToResetPassword: 'Send link to reset password',
    verifyEmailSent: 'An email has been sent for you to finish signing in.',
    clickToSendEmail: 'Click here to send an email for confirmation!',
    verifyEmailMessage: 'You are successfully registered!',
    goToSignIn: 'Click here to sign in',
    invalidLink: 'Seems like this link is invalid!',
    verifyEmailError: 'Error happened during verifying your account!',
    toast: {
        incorrectEmail: 'Please enter correct email!',
        compositeExistError: 'Composite type with same parameters already exists!',
        passwordAreNotSame: 'Passwords don\'t match.',
        passwordLength: 'Password must contain at least 6 symbols',
        passwordMustContainLetter: 'Password must contain a letter',
        serverUnavailable: 'Server is unavailable',
        setStoredTokenError: 'Before set token by the link, revoke your previous token and try again.',
        editInventoryItem: {
            success: {
                message: '',
                description: 'Please check your email to finish resetting your password!'
            }
        },
        resetPasswordToken: {
            success: {
                message: '',
                description: 'Please check your email to finish resetting your password!'
            }
        },
        resetPassword: {
            success: {
                message: '',
                description: 'You have successfully reset your password! Please check your email.'
            }
        },
        refreshToken: {
            error: {
                message: '',
                description: 'Your login is expired! Please log in to continue!'
            }
        },
        error: {
            message: 'Error',
            description: 'Something went wrong...'
        },
        success: {
            message: 'Success',
            description: 'Action was executed successfully'
        },
        info: {
            message: 'Info',
            description: ''
        },
        signUp: {
            success: {
                message: '',
                description: 'You have successfully registered! Please check your email to finish signing up!'
            }
        },
        changePassword: {
            success: {
                message: '',
                description: 'You have successfully changed your password! Please sign in again!'
            }
        },
        syncInProgress: {
            message: 'Sync started',
            description: 'Closing tab will stop syncing!'
        }
    },
    sidebar: {
        links: {
            inventory: "Inventory",
            users: "Users",
            settings: "Settings",
            linnworks: "Linnworks Account",
            calculator: "Calculator",
            reports: "Reports",
        }
    },
    inventory: {
        filters: {
            resetFilters: 'Reset Filters',
            name: "Filters"
        },
        close: "Close",
        exportByDate: "Profit report",
        update: {
            confirmation: {
                yes: 'Yes',
                no: 'No',
                title: 'Current price of one or more marketplaces was changed. Do you want to proceed?'
            }
        },
        delete: {
            first: {
                yes: 'Yes',
                no: 'Cancel',
                title: 'Are you sure you want to delete this item?',
            },
            second: {
                yes: 'Confirm',
                no: 'Cancel',
                title: 'This item will be deleted from the system (integrated services included). Confirm?',
            }
        },
        details: {
            category: "Category",
            subCategory: "Sub Category",
            subSubCategory: "Sub-sub Category",
            purchasePrice: "Purchase Price",
            taxRate: "Tax Rate",
            weight: "Weight",
            height: "Height",
            postalService: "Postal Service",
            packaging: "Packaging",
            msrp: "MSRP",
            barcode: "Barcode",
            company: "Company",
            sku: "SKU",
            title: "Title",
            image: 'Image',
            id: "Id",
            competitors: 'Competitors',
            supplier: 'Supplier',
            depth: 'Depth',
            width: 'Width',
            marketplaces: 'Marketplaces'
        },
        table: {
            barcode: "Barcode",
            company: "Company",
            sku: "SKU",
            title: "Title",
            id: "Id"
        },
        form: {
            edit: "Edit",
            view: "View",
            max: "Max",
            min: "Min",
            current: 'Current',
            value: "Value",
            percentage: "%",
            delete: 'Delete',
            export: 'Export',
            import: 'Import',
            profit: 'Profit',
            calculated: 'Calculated',
            margin: 'Margin',
            create: 'Create',
            defaultValues: 'Default prices',
            apply: 'Apply',
            columnSettings: 'Column settings',
            price: 'Price',
            shipping: 'Shipping',
            zones: 'Zone',
        },
        messages: {
            minPriceError: 'Min price for {{marketName}} must be greater than 0',
            maxPriceError: 'Max price for {{marketName}} must be greater than 0',
            currentPriceError: 'Current price for {{marketName}} must be greater than 0',
            competitorsNameError: 'One of competitor has same name.',
            exportStart: 'Export started...'
        },
        competitors: {
            min: 'Min',
            max: 'Max',
            title: 'Competitors',
            name: 'Name',
            add: 'Add',
            price: 'Price',
            shipping: 'Shipping'
        },
        supplier: {
            title: 'Supplier',
            fields: {
                name: 'Name',
                code: 'Code',
                barcode: 'Barcode',
                leadTime: 'Lead time',
                purchasePrice: 'Purchase price',
                currency: 'Currency',
                minOrderQuantity: 'Min order quantity',
                packSize: 'Pack size',
            }
        }
    },
    users: {
        table: {
            name: "Name",
            email: "Email",
            inviteUser: "Invite user",
            permission: "Permission",
            confirmationDescriptions: "Selected user will be deleted."
        },
        inviteModal: {
            email: "Email",
            fullName: "Full name",
            title: "Invite user",
            buttonOk: "Send",
            emptyUserFullName: "Please enter full name"
        },
        permissionModal: {
            permission: "Permission",
            isAdmin: "Is Admin",
            title: "Edit permissions",
            buttonOk: "Update",
            permissionRoles: {
                admin: "Admin",
                companyUser: "User"
            },
            permissionTypes: {
                inventoryItems: "Inventory items",
                settings: "Settings",
                users: "Users",
                account: "Account",
                calculator: "Calculator",
                profitReports: "Profit report",
            },
            permissionValue: {
                edit: "Edit",
                importAndExport: "Import and export",
            },
        }
    },
    settings: {
        import: "Import",
        export: "Export",
        upload: "Drop files here or click to upload",
        importSuccess: "Successfully imported",
        module: "Module {{name}}",
        calculate: "Calculate",
        modules: {
            currencies: 'Currencies',
            zones: 'Zones',
            companies: 'Companies',
            marketplaces: 'Marketplaces',
            packagings: 'Packagings',
            postalServices: 'Postal Services',
            staticValues: 'Static Values'
        },
        currencyTable: {
            currency: "Currency",
            exchangeRate: "Exchange rate",
            addCurrency: "Add currency",
            title: "Currency rate"
        },
        currencyModal: {
            currency: "Currency name",
            exchangeRate: "Exchange rate",
            addCurrency: "Add currency",
            title: "Add currency",
            editTitle: "Edit currency",
            error: {
                currencyName: 'Please enter currency name',
                currencyValue: 'Please enter exchange rate'
            }
        },
        zoneTable: {
            title: "Zone",
            zoneName: "Zone name",
            countries: "Zone Countries",
            addZone: "Add zone",
        },
        zoneModal: {
            zone: "Zone name",
            countries: "Countries",
            title: "Add zone",
            editTitle: "Edit zone",
            error: {
                zoneName: 'Please enter zone name',
            }
        },
        companyTable: {
            title: "Company",
            companyName: "Company name",
            addCompany: "Add company",
        },
        companyModal: {
            company: "Company name",
            title: "Add company",
            editTitle: "Edit company",
            error: {
                companyName: 'Please enter company name',
            }
        },
        marketplaceTable: {
            title: "Marketplace",
            marketplace: "Marketplace",
            currency: "Currency",
            zone: "Zone",
            company: "Company",
            subSource: "Sub Source",
            fee: "Fee",
            minFinalValueFee: "Min final value fee",
            paymentProvider: "Payment provider",
            cost: "Provider cost",
            transactionFee: "Provider transaction fee",
            add: "Add Marketplace",
            addFee: "Add fee",
            source: "Source",
            suffix: "Suffix",
        },
        marketplaceModal: {
            company: "Company name",
            title: "Add marketplace",
            editTitle: "Edit marketplace",
            editFee: "Edit fee",
            addFee: "Add fee",
            error: {
                marketplaceName: 'Please enter marketplace name',
                company: "Please choose company.",
                anotherCompany: "Please choose another company.",
                fee: "Fee must be between 0 and 100",
                minFinalValueFee: "Min final value fee must be between 0 and 100",
                paymentProvider: "Payment provider is empty",
                cost: "Payment cost must be between 0 and 100",
                transactionFee: "Payment transaction fee must be greater than 0",
                currency: "Please choose currency",
                zone: "Please choose zone",
            }
        },
        postalServiceTable: {
            title: "Postal service",
            name: "Name",
            zone: "Zone",
            cost: "Cost",
            defaultCompositeCost: "Default composite cost",
            perPackageCost: "Per package cost",
            perGramCost: "Per gram cost",
            surcharge: "Surcharge",
            add: "Add postal service",
            addCost: "Add cost",
            addCompositeType: "Add",
            addEditCompositeCostType: "Add composite cost type",
            compositeCostType: {
                value: "Value",
                measure: "Measure",
                types: {
                    height: "Height",
                    weight: "Weight",
                },
                measures: {
                    cm: "cm",
                    gram: "g",
                }
            },
            includeTable: {
                height21CompositeCost: "Height",
                weight2CompositeCost: "Weight",
                customTitle: {
                    height21CompositeCost: "Height cost 21+",
                    weight2CompositeCost: "Weight cost 750+",
                }
            }
        },
        postalServiceModal: {
            name: "Postal service name",
            title: "Add postal service",
            editTitle: "Edit postal service",
            defaultCost: "Default cost",
            heightCost21: "Height cost 21+",
            weightCost2000: "Weight cost 750+",
            specialCompositeCost: "Special composite cost type",
            perGramCost: "Per gram cost",
            perPackageCost: "Per package cost",
            surcharge: "Surcharge",
            editCost: "Edit cost",
            addCost: "Add cost",
            error: {
                postalServiceName: 'Please enter postal service name',
                perGramCost: "Cost per gram must be greater or equal to 0",
                perPackageCost: "Cost per package must be greater or equal to 0",
                surcharge: "Surcharge must be between 0 and 100",
                zone: "Please choose zone.",
                anotherZone: "Please choose another zone.",
                cost: "Cost must be greater or equal to 0",

            }
        },
        packagingTable: {
            title: "Packagings",
            packageName: "Packaging",
            packageCost: "Cost",
            addPackage: "Add packaging",
        },
        packagingModal: {
            packaging: "Packaging name",
            packageCost: "Packaging cost",
            title: "Add packaging",
            editTitle: "Edit packaging",
            error: {
                packageName: 'Please enter packaging name',
                cost: "Cost must be greater than 0",
            }
        },
        staticValuesTable: {
            title: "Static values",
            name: "Name",
            value: "Value",
            addStaticValue: "Add static value",
        },
        staticValueModal: {
            name: "Static value name",
            value: "Static value cost",
            title: "Add static value",
            editTitle: "Edit static value",
            error: {
                staticValueName: 'Please enter static value name',
                value: "Value must be greater than 0"
            }
        },
        downloadTemplate: "Download template"
    },
    account: {
        editPersonalInfo: 'Edit personal info',
        changeYourPassword: 'Change your password',
        edit: 'Edit account'
    },
    search: "Search",
    person: "Person",
    confirmationModal: {
        title: 'Are you sure?',
        descriptions: 'Do you really want to delete this record? This process can\'t be undone.',
        delete: 'Delete',
        cancel: 'Cancel',
    },
    linnworks: {
        title: 'Linnworks',
        token: 'Key',
        set: 'Set',
        revoke: 'Revoke',
        notSync: 'Not Sync',
        addKey: 'Add Token',
        details: 'Details',
        accounts: 'Accounts',
        isSyncing: "Linnworks sync is currently in progress.",
        isSyncInProgress: "Linnworks sync is currently in progress. Closing tab will stop syncing. Are you sure?",
        refresh: 'Sync',
        revokeConfirm: {
            yes: 'Yes',
            no: 'No',
            title: 'You are going to revoke selected service integration. Are you sure?',
        },
        linkLabel: {
            part1: "Please follow the ",
            link: "installation link",
            part2: ". Insert granted token below."
        },
        table: {
            key: 'Key',
            username: 'Username',
            lastSync: 'Last Sync',
            never: 'Never',
            totalItems: 'Total Items'
        }
    },
    reports: {
        profitReport: "Profit report",
        rangeReachedError: "Maximum reporting range is {{days}} days"
    },
    footer: "Auto-pricer. Developed by Brainence",
    plan: {
        plan: 'Plan',
        aboutSubscription: 'Your plan is',
        subscription: {
            0: 'No plan',
            1: 'Trial Expired',
            2: 'Trial',
            3: 'Full',
            4: 'Owner'
        },
        itemLimit: 'Up to {{itemLimit}} items'
    },
    permissions: {
        inventoryItems: 'Inventory Items',
        settings: 'Settings',
        calculator: 'Calculator',
        profitReports: 'Profit Reports',
        users: 'Users',
        account: 'Account',
        importAndExport: 'Import and Export'
    },
    noPlan: {
        noPlan: 'You have no subscription',
        description: 'Please get a subscription',
        action: 'Back to sign in',
    }
};

export default en;
