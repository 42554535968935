import { createSelector } from "reselect"
import _ from "lodash";
import { NAME } from './constants';
import * as actionTypes from './actionTypes';

import { selectors as coreSelectors, constants as coreConstants } from '../core';

export const getUsers = state => state[NAME].users.items;
export const getUsersIds = state => state[NAME].users.ids;
export const getUsersPagination = state => state[NAME].users.pagination;
export const getUserById = (state, id) => getUsers(state)[id];

export const getUsersArray = createSelector(getUsers, getUsersIds, (items, ids) => _.map(_.take(ids, coreConstants.DEFAULT_ITEMS_PER_PAGE), id => items[id]));

export const isUsers = state => coreSelectors.isLoading(state, actionTypes.GET_USERS.NAME);
export const isInvitingUser = state => coreSelectors.isLoading(state, actionTypes.INVITE_USER.NAME);
export const isUpdatingUser = state => coreSelectors.isLoading(state, actionTypes.UPDATE_USER_PERMISSIONS.NAME);
export const isDeletingUser = state => coreSelectors.isLoading(state, actionTypes.DELETE_USER.NAME);

export const getInviteUserError = state => coreSelectors.getError(state, actionTypes.INVITE_USER.NAME);
export const getUpdateUserPermissionsError = state => coreSelectors.getError(state, actionTypes.UPDATE_USER_PERMISSIONS.NAME);
