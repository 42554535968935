import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from 'lodash';
import * as actions from "../../../actions";
import { translate } from '../../../../core';
import * as selectors from "../../../selectors";
import {
    getMarketPlaces,
} from '../../../services';

export default function withDetailsBase(WrappedComponent) {
    class DetailsBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            itemId: PropTypes.string,
            shops: PropTypes.array.isRequired,
            editable: PropTypes.bool,
            loading: PropTypes.bool,
            isItemLoading: PropTypes.bool,
        };

        static defaultProps = {
            editable: true,
            loading: false,
            itemId: null,
            isItemLoading: false,
        };

        constructor(props) {
            super(props);

            if (props.loading && props.itemId) {
                props.actions.getItem(props.itemId);
            }
        }

        componentDidUpdate = prevProps => {
            if (prevProps.loading && prevProps.itemId !== this.props.itemId) {
                this.props.actions.getItem(this.props.itemId);
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        const shops = ownProps.loading ? _.get(selectors.getCurrentItem(state, ownProps.itemId), 'prices', {}) : {};
        return ownProps.loading ? {
            isItemLoading: selectors.isItem(state, ownProps.itemId),
            shops: getMarketPlaces(shops, shops),
        } : {
            isItemLoading: false,
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(DetailsBase));
}
