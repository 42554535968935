import axios from 'axios';

const endpoints = {
    createItem: 'inventory',
    getInventoryShort: 'inventory/short',
    inventory: id => `inventory/${id}`,
    postalServices: 'postal-services',
    packagings: 'packagings',
    companies: 'companies',
    export: 'inventory/export',
    marketplaces: 'marketplaces',
    columns: 'settings/columns/inventory',
    import: 'inventory/import',
    exportFileByDate: 'orders/profit-report',
    calculate: 'inventory/calculate',
    categories: 'inventory/categories',
};

export const getInventory = params => axios.get(endpoints.getInventoryShort, { params });
export const getItem = id => axios.get(endpoints.inventory(id));
export const createItem = item => axios.post(endpoints.createItem, item);
export const editItem = item => axios.put(endpoints.inventory(item.id), item);
export const deleteItem = id => axios.delete(endpoints.inventory(id));
export const exportFile = () => axios({
    url: endpoints.export,
    method: 'GET',
    responseType: 'blob',
});
export const getMarkets = params => axios.get(endpoints.marketplaces, { params });
export const getColumns = () => axios.get(endpoints.columns);
export const updateColumns = columns => axios.put(endpoints.columns, columns);
export const importFile = file => axios.post(endpoints.import, file, {
    headers: {
        'content-type': 'multipart/form-data',
    }
});
export const getFilterCategories = params => axios.get(endpoints.categories, { params });
export const exportFileByDate = date => axios({
    url: endpoints.exportFileByDate,
    method: 'GET',
    responseType: 'blob',
    params: { from: date.startDate, to: date.endDate }
});
export const getCalculations = params => axios.post(endpoints.calculate, params);
