import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions";
import { translate, Toast } from '../../../../core';

const DEFAULT_ITEM = {
    name: '',
    price: 0,
    shippingPrice: 0,
};

export default function withCompetitorsBase(WrappedComponent) {
    class Competitors extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            items: PropTypes.array.isRequired,
            onRemove: PropTypes.func.isRequired,
            onAdd: PropTypes.func.isRequired,
            onUpdate: PropTypes.func.isRequired,
        };

        constructor(props) {
            super(props);

            this.state = {
                item: DEFAULT_ITEM,
                selectedItem: null,
                isDisabledSaveBtn: false,
            }
        }

        onChange = (key, value) => {
            this.setState(prevState => ({
                item: {
                    ...prevState.item,
                    [key]: value,
                }
            }))
        };

        onClickEdit = item => this.setState({ selectedItem: item });

        onCancel = () => this.setState({ selectedItem: null });

        onClickUpdate = oldItem => {
            const { selectedItem } = this.state;
            const { i18n, items } = this.props;

            if (selectedItem && _.find(items, item => item.name === selectedItem.name && oldItem.name !== item.name) && selectedItem.isEdited) {
                Toast.error({ description: i18n.t('inventory.messages.competitorsNameError') });
                this.setState({ isDisabledSaveBtn: true })
            } else {
                this.setState({ selectedItem: null, isDisabledSaveBtn: false }, () => this.props.onUpdate(oldItem.name, selectedItem))
            }
        };

        onUpdateChangeItem = (key, value) => {
            this.setState(prevState => ({
                selectedItem: {
                    ...prevState.selectedItem,
                    [key]: value,
                    isEdited: true,
                },
                isDisabledSaveBtn: false
            }))
        };

        onAddItem = () => {
            const item = this.state.item;
            this.setState({ item: DEFAULT_ITEM }, () => this.props.onAdd(item));
        };

        get isDisabledAddButton() {
            const { item: { name, price, shippingPrice } } = this.state;
            const isExist = _.find(this.props.items, item => item.name === name);
            return !name || isExist || parseFloat(price) < 0 || parseFloat(shippingPrice) < 0;
        }

        get isDisabledUpdateBtn() {
            const { selectedItem, isDisabledSaveBtn } = this.state;
            if (selectedItem && (!selectedItem.name || parseFloat(selectedItem.price) < 0 || parseFloat(selectedItem.shippingPrice) < 0)) {
                return true;
            }

            return isDisabledSaveBtn;
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isDisabledAddButton={this.isDisabledAddButton}
                    selectedItem={this.state.selectedItem}
                    onAddItem={this.onAddItem}
                    onChange={this.onChange}
                    defaultValues={this.state.item}
                    onClickEdit={this.onClickEdit}
                    onCancel={this.onCancel}
                    onUpdateChangeItem={this.onUpdateChangeItem}
                    onClickUpdate={this.onClickUpdate}
                    isDisabledSaveBtn={this.state.isDisabledSaveBtn}
                    isDisabledUpdateBtn={this.isDisabledUpdateBtn}
                />
            );
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(null, mapDispatchToProps)(translate()(Competitors));
}
