import React from 'react';
import PropTypes from 'prop-types';
import { DateRange } from "react-date-range";
import enGb from 'react-date-range/dist/locale/en-GB';
import Paper from '@material-ui/core/Paper';
import Send from '@material-ui/icons/SaveAlt';
import IconButton from "@material-ui/core/IconButton";
import withReportsBase from './ReportItem.base';
import { components as Core, withStyles } from "../../../../core"
import { spacing, fonts, colors } from "../../../../../styles";

class ReportItem extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
        onExport: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        onChangeDateRange: PropTypes.func.isRequired,
        selectedDateRange: PropTypes.object.isRequired,
        item: PropTypes.object.isRequired,
        rangeReached: PropTypes.bool.isRequired,
        i18n: PropTypes.object.isRequired,
        reportDaysLimit: PropTypes.number.isRequired
    };

    static defaultProps = {
        isLoading: false,
    };

    render() {
        const { classes, isLoading, selectedDateRange, onChangeDateRange, onExport, item, rangeReached, i18n, reportDaysLimit } = this.props;
        return (
            <Core.Grid item={true} xs={12} sm={6} lg={4}>
                <Core.Spin spinning={isLoading}>
                    <Paper className={classes.root}>
                        <div className={classes.titleContainer} >
                            <div className={classes.title}>{item.title}</div>
                            <IconButton
                                disabled={rangeReached}
                                onClick={onExport}>
                                <Send />
                            </IconButton>
                        </div>
                        <DateRange
                            locale={enGb}
                            ranges={[selectedDateRange]}
                            onChange={onChangeDateRange}
                            moveRangeOnFirstSelection={false}
                            maxDate={new Date()}
                            twoStepChange={true}
                            className={classes.datePickerRangeContainer}
                        />
                        {rangeReached ? (
                            <div className={classes.error}>
                                <span>{i18n.t("reports.rangeReachedError", { days: reportDaysLimit })}</span>
                            </div>
                        ) : null}
                    </Paper>
                </Core.Spin>
            </Core.Grid>
        );
    }
}

const MONTH_WIDTH = 90;

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
    },
    datePickerRangeContainer: {
        "& .rdrDateDisplayWrapper": {
            display: "none"
        },
        "&.rdrCalendarWrapper": {
            width: "100%",
        },
        "& .rdrMonths": {
            alignItems: "center",
        },
        "& .rdrMonth": {
            width: `${MONTH_WIDTH}%`,
        }
    },
    titleContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: `${spacing.s0}px ${spacing.s2}px`,
    },
    title: {
        padding: spacing.s2,
        ...fonts.mdNormal
    },
    error: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        color: colors.red,
        padding: `${spacing.s0}px 0px`,
    }
});

export default withReportsBase(withStyles(styles)(ReportItem));
