import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import _ from 'lodash';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import ExitToApp from '@material-ui/icons/ExitToApp';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { withRouter, NavLink } from 'react-router-dom';
import { constants } from "../../index";
import { withStyles, translate, components as Core } from '../../../core';
import { components as Account } from '../../../account';
import withMenuBase from './Sidebar.base';

import { fonts, colors, spacing, hexToRGB } from "../../../../styles";

const SWIPE_AREA_WIDTH = 20;
const MIN_FLING_VELOCITY = 100;

class Sidebar extends React.PureComponent {
    static propTypes = {
        account: PropTypes.object.isRequired,
        i18n: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        open: PropTypes.bool,
        logoText: PropTypes.string.isRequired,
        logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        handleDrawerToggle: PropTypes.func.isRequired,
        onClickAccount: PropTypes.func.isRequired,
        onEditAccountForm: PropTypes.func.isRequired,
        isEditFormOpen: PropTypes.bool,
        isAccountOpen: PropTypes.bool,
        onSignOut: PropTypes.func.isRequired,
        isAccount: PropTypes.bool,
        accountRole: PropTypes.number.isRequired,
        permissions: PropTypes.array
    };

    static defaultProps = {
        open: false,
        isAccountOpen: false,
        isAccount: false,
        isEditFormOpen: false,
        permissions: []
    };

    constructor(props) {
        super(props);

        this.getLinkItem = this.getLinkItem.bind(this);
    }

    activeRoute = routeName => this.props.history.location.pathname.indexOf(routeName) > -1;

    getLinkItem(prop) {
        const { classes, i18n } = this.props;
        const listItemClasses = classNames({ [`${classes.mainColor}`]: this.activeRoute(prop.route) });
        return (
            _.get(prop, "isVisible", true) ? (
                <NavLink
                    to={prop.route}
                    className={classes.item}
                    activeClassName="active"
                    order={prop.order}
                    key={prop.route}>
                    <Core.ListItem button={true} className={classNames(classes.itemLink, listItemClasses)}>
                        {typeof prop.icon === "string" ? (
                            <Core.Icon className={classes.itemIcon}>
                                {prop.icon}
                            </Core.Icon>
                        ) : (<Icon className={classes.itemIcon} >{prop.icon}</Icon>)}
                        <Core.ListItemText
                            primary={i18n.t(prop.name)}
                            className={classes.itemText}
                            disableTypography={true}
                        />
                    </Core.ListItem>
                </NavLink>
            ): null
        );
    }

    get routes() {
        return _.chain(constants.ALLOWED_ROUTES(this.props.accountRole, this.props.permissions)).map(this.getLinkItem).orderBy(['props.order'], ['asc']).value();
    }

    get avatar() {
        const { account, classes } = this.props;

        if (!account) {
            return null;
        }

        if (!_.get(account, 'userPicture', null)) {
            return (<Avatar className={classes.avatar}>{_.toUpper(account.fullname.charAt(0))}</Avatar>);
        }

        return (
            <Avatar className={classes.avatar} src={account.userPicture} />
        )
    }

    get links() {
        const { classes, i18n, isAccountOpen, onClickAccount, onSignOut, account, isAccount, onEditAccountForm } = this.props;
        return (
            <Core.List className={classes.list}>
                <Core.Spin spinning={isAccount}>
                    <div className={classes.accountContainer}>
                        {_.get(account, 'fullname', false) ? (
                            <Core.ListItem button={true} onClick={onClickAccount} className={classNames(classes.itemLink, classes.customItemLink)}>
                                {this.avatar}
                                <Core.ListItemText className={classes.itemText} disableTypography={true} primary={account.fullname} />
                                {isAccountOpen ? <ExpandLess /> : <ExpandMore />}
                            </Core.ListItem>
                        ) : (null)}
                        <Collapse in={isAccountOpen} timeout="auto" unmountOnExit={true}>
                            <Core.List>
                                <Core.ListItem onClick={onEditAccountForm} button={true} className={classNames(classes.subItem)}>
                                    <Core.Icon className={classes.customIcon}>
                                        <AccountCircle />
                                    </Core.Icon>
                                    <Core.ListItemText primary={i18n.t('auth.myProfile')} className={classes.itemText} disableTypography={true} />
                                </Core.ListItem>
                                <Core.ListItem button={true} className={classNames(classes.subItem)} onClick={onSignOut}>
                                    <Core.Icon className={classes.customIcon}>
                                        <ExitToApp />
                                    </Core.Icon>
                                    <Core.ListItemText primary={i18n.t('auth.signOut')} className={classes.itemText} disableTypography={true} />
                                </Core.ListItem>
                            </Core.List>
                        </Collapse>
                    </div>
                </Core.Spin>
                {this.routes}
            </Core.List>
        )
    }

    get logo() {
        const { logo, logoText, classes } = this.props;
        return (
            <div className={classes.logo}>
                <a href="#" className={classes.logoLink}>
                    <div className={classes.logoImage}>
                        <img src={logo} alt="logo" className={classes.img} />
                    </div>
                    {logoText}
                </a>
            </div>
        );
    }

    render() {
        const { classes, open, handleDrawerToggle, onEditAccountForm, isEditFormOpen } = this.props;
        return (
            <div>
                <Core.Hidden mdUp={true} implementation="css">
                    <Core.SwipeableDrawer
                        variant="temporary"
                        anchor="left"
                        open={open}
                        classes={{ paper: classNames(classes.drawerPaper) }}
                        onClose={handleDrawerToggle}
                        onOpen={handleDrawerToggle}
                        swipeAreaWidth={SWIPE_AREA_WIDTH}
                        minFlingVelocity={MIN_FLING_VELOCITY}
                        hysteresis={0.2}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}>
                        {this.logo}
                        <div className={classes.sidebarWrapper}>
                            {this.links}
                        </div>
                        <div className={classes.background} />
                    </Core.SwipeableDrawer>
                </Core.Hidden>
                <Core.Hidden smDown={true} implementation="css">
                    <Core.Drawer
                        anchor="left"
                        variant="permanent"
                        open={true}
                        classes={{ paper: classNames(classes.drawerPaper) }}>
                        {this.logo}
                        <div className={classes.sidebarWrapper}>{this.links}</div>
                        <div className={classes.background} />
                    </Core.Drawer>
                </Core.Hidden>
                {isEditFormOpen ? (<Account.EditAccount onCloseModal={onEditAccountForm} />) : (null)}
            </div>
        );
    }
}

const ICON_WIDTH = 24;
const ICON_HEIGHT = 30;
const LOGO_IMAGE = 30;
const IMAGE = 30;
const DRAWER_BOX_SHADOW = `0 10px 30px -12px rgba(${hexToRGB(colors.black)}, 0.42), 0 4px 25px 0px rgba(${hexToRGB(colors.black)}, 0.12), 0 8px 10px -5px rgba(${hexToRGB(colors.black)}, 0.2)`;
const AVATAR_SIZE = 30;
const CIRCLE_ICON = 24;
const FONT_SIZE_LOGO = 20;

const styles = theme => ({
    list: {
        marginTop: spacing.s1,
    },
    item: {
        position: "relative",
        textDecoration: "none",
        "&:hover,&:focus,&:visited,&": {
            color: colors.white
        }
    },
    itemLink: {
        width: "auto",
        transition: "all 300ms linear",
        borderRadius: "3px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        padding: `${spacing.s1}px ${spacing.s2}px`,
        ...fonts.mdNormal,
        margin: `${spacing.s1}px ${spacing.s2}px 0`,
    },
    customItemLink: {
        padding: `0px ${spacing.s2}px !important`,
    },
    accountContainer: {
        margin: `0px 0px ${spacing.s3}px 0px`,
        padding: `0px 0px ${spacing.s4}px 0px`,
        position: "relative",
        zIndex: 4,
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            height: 1,
            right: spacing.s3,
            width: "calc(100% - 30px)",
            backgroundColor: `rgba(${hexToRGB(colors.gray.lightGray)} , 0.3)`
        }
    },
    subItem: {
        width: "auto",
        transition: "all 300ms linear",
        borderRadius: "3px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        padding: `${spacing.s1}px ${spacing.s2}px ${spacing.s1}px ${spacing.s7}px`,
        ...fonts.mdNormal,
        margin: `0px ${spacing.s2}px 0`,
    },
    avatar: {
        width: AVATAR_SIZE,
        height: AVATAR_SIZE,
        float: "left",
        textAlign: "center",
        verticalAlign: "middle",
        color: `rgba(${hexToRGB(colors.white)}, 0.8)`
    },
    itemIcon: {
        width: ICON_WIDTH,
        height: ICON_HEIGHT,
        float: "left",
        textAlign: "center",
        verticalAlign: "middle",
        color: `rgba(${hexToRGB(colors.white)}, 0.8)`,
        alignItems: "center",
        display: "flex",
    },
    customIcon: {
        width: CIRCLE_ICON,
        height: CIRCLE_ICON,
        float: "left",
        textAlign: "center",
        verticalAlign: "middle",
        color: `rgba(${hexToRGB(colors.white)}, 0.8)`
    },
    itemText: {
        margin: 0,
        color: colors.white,
        textDecorationStyle: "unset",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...fonts.mdNormal
    },
    logo: {
        position: "relative",
        padding: `${spacing.s3}px ${spacing.s3}px`,
        zIndex: 4,
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            height: 1,
            right: spacing.s3,
            width: "calc(100% - 30px)",
            backgroundColor: `rgba(${hexToRGB(colors.gray.lightGray)} , 0.3)`
        }
    },
    logoLink: {
        padding: `${spacing.s1}px 0`,
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        ...fonts.mdNormal,
        fontSize: FONT_SIZE_LOGO,
        "&,&:hover": {
            color: colors.white
        }
    },
    sidebarWrapper: {
        position: "relative",
        height: "calc(100vh - 75px)",
        overflow: "auto",
        width: constants.DRAWER_WIDTH,
        zIndex: 4,
        overflowScrolling: "touch"
    },
    drawerPaper: {
        border: "none",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 1030,
        boxShadow: DRAWER_BOX_SHADOW,
        width: constants.DRAWER_WIDTH,
        [theme.breakpoints.up("md")]: {
            width: constants.DRAWER_WIDTH,
            position: "fixed",
            height: "100%"
        },
        [theme.breakpoints.down("sm")]: {
            width: constants.DRAWER_WIDTH,
            boxShadow: DRAWER_BOX_SHADOW,
            position: "fixed",
            display: "block",
            top: 0,
            height: "100vh",
            right: "auto",
            left: 0,
            zIndex: 1032,
            visibility: "visible",
            overflowY: "visible",
            borderTop: "none",
            textAlign: "left",
            paddingRight: 0,
            paddingLeft: 0,
            transform: "none",
            transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
        }
    },
    logoImage: {
        width: LOGO_IMAGE,
        display: "inline-block",
        maxHeight: LOGO_IMAGE,
        marginLeft: spacing.s1,
        marginRight: spacing.s3
    },
    mainColor: {
        backgroundColor: `${colors.blue.blue} !important`,
    },
    img: {
        width: IMAGE,
        top: spacing.s5,
        verticalAlign: "middle",
        border: 0
    },
    background: {
        position: "absolute",
        zIndex: 1,
        height: "100%",
        width: "100%",
        display: "block",
        top: 0,
        left: 0,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundColor: `rgba(${hexToRGB(colors.black)} , 0.9)`,
        "&:after": {
            position: "absolute",
            zIndex: 3,
            width: "100%",
            height: "100%",
            content: '""',
            display: "block",
        }
    },
});

export default withRouter(translate()(withStyles(styles)(withMenuBase(Sidebar))));
