import React from 'react';
import PropTypes from 'prop-types';
import Table from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import { spacing, colors } from "../../../../styles";
import { DEFAULT_ITEMS_PER_PAGE } from "../../constants"
import Spin from "../Spin";
import { components as Core } from "../../index";

const CHECKBOX_ROW_WIDTH = 90;

class CustomTable extends React.PureComponent {
    static propTypes = {
        data: PropTypes.array.isRequired,
        columns: PropTypes.array.isRequired,
        changePage: PropTypes.func,
        options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        actionConfig: PropTypes.object,
        title: PropTypes.string,
        isLoading: PropTypes.bool,
        overrides: PropTypes.object,
        pagination: PropTypes.object,
        itemPerPage: PropTypes.number,
    };

    static defaultProps = {
        options: {},
        title: '',
        isLoading: false,
        pagination: null,
        changePage: null,
        itemPerPage: DEFAULT_ITEMS_PER_PAGE,
        actionConfig: null
    };

    get options() {
        const { actionConfig } = this.props;
        return {
            textLabels: {
                body: {
                    noMatch: "No records",
                },
            },
            customFooter: this.props.pagination ? this.customFooter : null,
            serverSide: !!this.props.pagination,
            customToolbar: _.get(actionConfig, "base") ? this.customToolbar : null,
            customToolbarSelect: _.get(actionConfig, "select") ? this.customActionsToolbar : null,
        };
    }

    customItemToolbar = item => (
        <Core.TooltipButton
            onClick={item.onClick}
            icon={item.icon}
            title={item.label}
            key={`${item.label}-select`}
        />
    );

    customToolbar = () => (<div>{_.map(this.props.actionConfig.base, this.customItemToolbar)}</div>);

    customActionsToolbar = () => (<div>{_.map(this.props.actionConfig.select, this.customItemToolbar)}</div>);

    customFooter = () => {
        const { pagination, changePage, isLoading, itemPerPage } = this.props;
        return (
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[itemPerPage]}
                        count={_.get(pagination, "count")}
                        rowsPerPage={itemPerPage}
                        page={_.get(pagination, "page")}
                        onChangePage={!isLoading ? changePage: () => {}}
                    />
                </TableRow>
            </TableFooter>
        )
    };

    render() {
        const { data, columns, title, options, isLoading } = this.props;
        return (
            <Spin spinning={isLoading}>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                    <Table title={title} data={data} columns={columns} options={{ ...this.options, ...options }} />
                </MuiThemeProvider>
            </Spin>
        );
    }

    getMuiTheme = () => createMuiTheme({
        typography: {
            useNextVariants: true,
        },
        overrides: {
            MUIDataTable: {
                responsiveScroll: {
                    maxHeight: "100%"
                }
            },
            MuiTableCell: {
                root: {
                    whiteSpace: "nowrap",
                }
            },
            MuiIconButton: {
                root: {
                    color: colors.blue.blue,
                    "&:hover": {
                        color: colors.blue.blue
                    }
                }
            },
            MUIDataTableToolbar: {
                icon: {
                    color: colors.blue.blue,
                    "&:hover": {
                        color: colors.blue.blue
                    }
                },
                actions:{
                    display: "flex",
                    justifyContent: "flex-end",
                }
            },
            MUIDataTableToolbarSelect: {
                root: {
                    "& div:last-of-type": {
                        marginRight: spacing.s5,
                    }
                }
            },
            MUIDataTableSelectCell: {
                root: {
                    "@media (max-width: 960px)": {
                        display: "table-cell"
                    }
                },
                checkboxRoot: {
                    position: "absolute",
                    opacity: 0,
                    width: `${CHECKBOX_ROW_WIDTH}%`,
                    padding: 0,
                    marginLeft: spacing.s5,
                },
                fixedHeader: {
                    position: "unset"
                }
            },
            ...this.props.overrides,
        }
    })
}

export default CustomTable;
