import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import * as actions from "../../../actions";
import { translate } from '../../../../core';
import { MARKETPLACE_INCLUDE_FIELDS, MARKETPLACE_FIELDS } from "../../../constants";
import * as selectors from "../../../selectors";

export const COLUMNS = {
    id: MARKETPLACE_INCLUDE_FIELDS.id,
    company: MARKETPLACE_FIELDS.company,
    subSource: MARKETPLACE_INCLUDE_FIELDS.subSource,
    fee: MARKETPLACE_INCLUDE_FIELDS.fee,
    transactionFee: MARKETPLACE_INCLUDE_FIELDS.transactionFee,
    cost: MARKETPLACE_INCLUDE_FIELDS.cost,
    paymentProvider: MARKETPLACE_INCLUDE_FIELDS.paymentProvider,
    minFinalValueFee: MARKETPLACE_INCLUDE_FIELDS.minFinalValueFee,
    suffix: MARKETPLACE_INCLUDE_FIELDS.suffix,
};

export default function withDetailsRowsBase(WrappedComponent) {
    class DetailsRowsBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            itemId: PropTypes.string.isRequired,
            fees: PropTypes.array,
            marketplace: PropTypes.object,
        };

        static defaultProps = {
            fees: [],
            marketplace: undefined
        };

        constructor(props) {
            super(props);

            const { i18n, itemId } = this.props;

            this.tableColumns = {
                [COLUMNS.company]: {
                    label: i18n.t("settings.marketplaceTable.company"),
                    name: COLUMNS.company,
                },
                [COLUMNS.subSource]: {
                    label: i18n.t("settings.marketplaceTable.subSource"),
                    name: COLUMNS.subSource,
                },
                [COLUMNS.fee]: {
                    label: i18n.t("settings.marketplaceTable.fee"),
                    name: COLUMNS.fee,
                },
                [COLUMNS.transactionFee]: {
                    label: i18n.t("settings.marketplaceTable.transactionFee"),
                    name: COLUMNS.transactionFee,
                },
                [COLUMNS.cost]: {
                    label: i18n.t("settings.marketplaceTable.cost"),
                    name: COLUMNS.cost,
                },
                [COLUMNS.paymentProvider]: {
                    label: i18n.t("settings.marketplaceTable.paymentProvider"),
                    name: COLUMNS.paymentProvider,
                },
                [COLUMNS.minFinalValueFee]: {
                    label: i18n.t("settings.marketplaceTable.minFinalValueFee"),
                    name: COLUMNS.minFinalValueFee,
                },
                [COLUMNS.suffix]: {
                    label: i18n.t("settings.marketplaceTable.suffix"),
                    name: COLUMNS.suffix
                }
            };

            this.props.actions.getMarketplaceFees(itemId)
        }

        componentDidUpdate(prevProps) {
            if (prevProps.itemId !== this.props.itemId) {
                this.props.actions.getMarketplaceFees(this.props.itemId)
            }
        }

        deleteFeeFromMarketplace = feeId => {
            const { marketplace, itemId } = this.props;

            this.props.actions.updateFee(itemId, {
                ...marketplace,
                fees: _.omit(marketplace.fees, feeId)
            });
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    tableColumns={this.tableColumns}
                    deleteFeeFromMarketplace={this.deleteFeeFromMarketplace}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            isUpdating: selectors.isUpdatingMarketplaceById(state, ownProps.itemId),
            isLoadingFees: selectors.isGettingFeesById(state, ownProps.itemId),
            companies: selectors.getCompanies(state),
            marketplace: selectors.getMarketplaceById(state, ownProps.itemId),
            fees: selectors.getFeesByMarketplaceId(state, ownProps.itemId)
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(DetailsRowsBase));
}
