import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as _ from "lodash";
import * as actions from "../../../actions";
import { translate } from '../../../../core';
import { POSTAL_SERVICE_INCLUDE_FIELDS, POSTAL_SERVICE_FIELDS } from "../../../constants";
import * as selectors from "../../../selectors";

export const COLUMNS = {
    id: POSTAL_SERVICE_INCLUDE_FIELDS.id,
    cost: POSTAL_SERVICE_INCLUDE_FIELDS.cost,
    defaultCost: POSTAL_SERVICE_FIELDS.defaultCost,
    perGramCost: POSTAL_SERVICE_INCLUDE_FIELDS.perGramCost,
    perPackageCost: POSTAL_SERVICE_INCLUDE_FIELDS.perPackageCost,
    surcharge: POSTAL_SERVICE_INCLUDE_FIELDS.surcharge,
    zone: POSTAL_SERVICE_INCLUDE_FIELDS.zone
};

export default function withDetailsRowsBase(WrappedComponent) {
    class DetailsRowsBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            itemId: PropTypes.string.isRequired,
            costs: PropTypes.array,
            postalService: PropTypes.object,
        };

        static defaultProps = {
            costs: [],
            postalService: undefined
        };

        constructor(props) {
            super(props);

            const { i18n, itemId } = this.props;

            this.tableColumns = {
                [COLUMNS.zone]: {
                    label: i18n.t("settings.postalServiceTable.zone"),
                    name: COLUMNS.zone,
                },
                [COLUMNS.cost]: {
                    label: i18n.t("settings.postalServiceTable.cost"),
                    name: COLUMNS.cost,
                },
                [COLUMNS.defaultCost]: {
                    label: i18n.t("settings.postalServiceModal.defaultCost"),
                    name: COLUMNS.defaultCost
                },
            };

            this.props.actions.getPostalServiceCosts(itemId)
        }

        componentDidUpdate(prevProps) {
            if (prevProps.itemId !== this.props.itemId) {
                this.props.actions.getPostalServiceCosts(this.props.itemId)
            }
        }

        getCustomFields = (parent, parentLabel) => {
            const { i18n } = this.props;
            return ({
                [COLUMNS.perPackageCost]: {
                    label: i18n.t("settings.postalServiceTable.perPackageCost"),
                    name: {
                        parentField: parent,
                        nameField: COLUMNS.perPackageCost,
                    }
                },
                [COLUMNS.perGramCost]: {
                    label: i18n.t("settings.postalServiceTable.perGramCost"),
                    name: {
                        parentField: parent,
                        nameField: COLUMNS.perGramCost,
                        parentFieldLabel: parentLabel,
                        primary: true
                    }
                },
                [COLUMNS.surcharge]: {
                    label: i18n.t("settings.postalServiceTable.surcharge"),
                    name: {
                        parentField: parent,
                        nameField: COLUMNS.surcharge,
                    }
                } })
        };

        deleteCostFromPostalService = costId => {
            const { postalService, itemId } = this.props;
            this.props.actions.updateCosts(itemId, {
                ...postalService,
                costs: _.omit(postalService.costs, costId)
            });
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    tableColumns={this.tableColumns}
                    deleteCostFromPostalService={this.deleteCostFromPostalService}
                    getCustomFields={this.getCustomFields}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            isUpdating: selectors.isUpdatingPostalServiceById(state, ownProps.itemId),
            isLoadingCosts: selectors.isGettingCostsById(state, ownProps.itemId),
            zones: selectors.getZones(state),
            postalService: selectors.getPostalServiceById(state, ownProps.itemId),
            costs: selectors.getCostsByPostalServiceId(state, ownProps.itemId),
            compositeCostTypes: selectors.getCompositeCostTypes(state, ownProps.itemId)
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(DetailsRowsBase));
}
