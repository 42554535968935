import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import FilterList from '@material-ui/icons/FilterList';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import withCategoryFilterBase from './CategoryFilter.base';
import { components as Core } from '../../../../core';
import { spacing } from '../../../../../styles';
import { ALL_OPTION } from '../../../constants';

class CategoryFilter extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        categories: PropTypes.array,
        onSearch: PropTypes.func.isRequired,
        onChangeValue: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        categoryPagination: PropTypes.object.isRequired,
        selected: PropTypes.string,
    };

    static defaultProps = {
        categories: [],
        isLoading: false,
        selected: '',
    };

    get items() {
        const allOption = { value: '', label: this.props.i18n.t(ALL_OPTION) };
        const options = _.map(this.props.categories, this.getCategoriesItem);

        if (this.props.categoryPagination.pageNumber === 1) {
            return [allOption, ...options]
        }

        return options;
    }

    getCategoriesItem = item => ({ value: item, label: item });

    getMuiTheme = () => createMuiTheme({
        typography: {
            useNextVariants: true,
        },
        overrides: {
            MuiPaper: {
                root: {
                    minWidth: `${MIN_BODY_WIDTH}px !important`,
                    padding: spacing.s2
                }
            }
        }
    });

    onResetFileds = popState => {
        popState.close();
        this.props.onChangeValue();
    };

    onChange = (event, popState) => {
        popState.close();
        this.props.onChangeValue(event.value);
    };

    render() {
        const { i18n, categoryPagination, isLoading, onSearch, selected } = this.props;
        return (
            <Core.IconButton>
                <MuiThemeProvider theme={this.getMuiTheme}>
                    <PopupState variant="popover" popupId="filter-popup-popover">
                        {popupState => ([
                            <Core.Tooltip title={i18n.t("inventory.filters.name")} >
                                <FilterList key="button-icon" {...bindTrigger(popupState)} />
                            </Core.Tooltip>,
                            <Popover
                                key="filter-menu"
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}>
                                <Core.Button key="resetBtn" size="small" color="primary" onClick={() => this.onResetFileds(popupState)}>
                                    {i18n.t('inventory.filters.resetFilters')}
                                </Core.Button>
                                <Core.SearchableFormSelect
                                    key="filter-category-select"
                                    isRelativeStyle={true}
                                    inputLabel={i18n.t('inventory.details.category')}
                                    onChange={event => this.onChange(event, popupState)}
                                    onSearch={onSearch}
                                    value={selected}
                                    placeholder={selected || i18n.t(ALL_OPTION)}
                                    selectName="Categories"
                                    pagination={categoryPagination}
                                    isLoading={isLoading}
                                    options={this.items}
                                />
                            </Popover>
                        ])}
                    </PopupState>
                </MuiThemeProvider>
            </Core.IconButton>
        );
    }
}

const MIN_BODY_WIDTH = 180;

export default withCategoryFilterBase(CategoryFilter);
