import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions";
import { constants as coreConstants, translate } from '../../../../core';
import * as selectors from "../../../selectors";

export default function withCategoryFilterBase(WrappedComponent) {
    class CategoryFilter extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            isInventoryLoading: PropTypes.bool,
            onFilter: PropTypes.func.isRequired,
            requestParams: PropTypes.object.isRequired,
        };

        static defaultProps = {
            isInventoryLoading: false,
        };

        constructor(props) {
            super(props);

            this.state = {
                selectedCategory: _.get(props, 'category', ''),
            };
        }

        onSearch = (filterText, field, isMore, page) => this.props.actions.getCategories({
            page: isMore ? page : 1,
            count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
            filterText
        });

        onChangeValue = val => {
            this.setState({ selectedCategory: val }, () => this.props.onFilter(val));
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    selected={this.state.selectedCategory}
                    onSearch={this.onSearch}
                    onChangeValue={this.onChangeValue}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            categoryPagination: selectors.getCategoriesPagination(state),
            isLoading: selectors.isCategoriesLoading(state),
            categories: selectors.getCategories(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(CategoryFilter));
}
