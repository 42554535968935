import axios from 'axios';

const endpoints = {
    account: 'account',
    revoke: 'account/linnworks/revoke',
    set: 'account/linnworks/set',
    refresh: 'inventory/sync'

};

export const getAccount = () => axios.get(endpoints.account);
export const revoke = () => axios.post(endpoints.revoke);
export const set = token => axios.post(endpoints.set, { linnworksKey: token });
export const sync = () => axios.post(endpoints.refresh);