import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { constants as coreConstants, fieldValidator, Toast, translate } from '../../../core';
import { INVITE_FIELDS } from "../../constants"
import * as actions from "../../actions";
import * as selectors from "../../selectors";

export default function withInviteUserModalBase(WrappedComponent) {
    class InviteUserModalBase extends React.PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            i18n: PropTypes.object.isRequired,
            isVisible: PropTypes.bool.isRequired,
            onClose: PropTypes.func.isRequired,
            isAddingToFavorites: PropTypes.bool
        };

        static defaultProps = {
            isAddingToFavorites: false,
        };

        constructor(props) {
            super(props);

            const { i18n } = this.props;

            this.state = {
                fields: {
                    [INVITE_FIELDS.email]: '',
                    [INVITE_FIELDS.fullName]: '',
                },
            };

            this.fields = [
                {
                    name: INVITE_FIELDS.email,
                    placeholder: i18n.t('users.inviteModal.email'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.email,
                    autoFocus: true,
                },
                {
                    name: INVITE_FIELDS.fullName,
                    placeholder: i18n.t('users.inviteModal.fullName'),
                    isRequired: true,
                    type: coreConstants.INPUT_TYPES.text
                }
            ];
        }

        onChangeValue = (val, field) => {
            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    [field.name]: val
                }
            }))
        };


        inviteUser = () => {
            const { fields } = this.state;
            const { i18n } = this.props;
            if (!fieldValidator.isValidEmail(this.state.fields[INVITE_FIELDS.email])) {
                return Toast.error({ description: i18n.t('toast.incorrectEmail') })
            }
            if (_.isEmpty(this.state.fields[INVITE_FIELDS.fullName])) {
                return Toast.error({ description: i18n.t('users.inviteModal.emptyUserFullName') })
            }

            this.props.actions.inviteUser(fields);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    inviteUser={this.inviteUser}
                    values={this.state.fields}
                    fields={this.fields}
                    onChangeValue={this.onChangeValue}
                />
            );
        }
    }

    const mapStateToProps = state => ({
        isInvitingUser: selectors.isInvitingUser(state),
        inviteUserError: selectors.getInviteUserError(state),
    });

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(InviteUserModalBase));
}
