import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions";
import { translate } from '../../../../core';
import * as selectors from '../../../selectors';

export default function withFormBase(WrappedComponent) {
    class FormBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            account: PropTypes.object.isRequired,
        };

        static defaultProps = {
        };

        constructor(props) {
            super(props);

            this.state = {
                token: props.account.linnworksKey,
            };
        }

        get isDisabledSaveButton() {
            return !this.state.token;
        }

        onChangeToken = value => (this.setState({ token: value }));

        onSetToken = () => (this.props.actions.setToken(this.state.token));

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onRevokeToken={this.onRevokeToken}
                    onSetToken={this.onSetToken}
                    onChangeToken={this.onChangeToken}
                    isDisabledSaveButton={this.isDisabledSaveButton}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            account: selectors.getAccount(state),
            isLoading: selectors.isAccountLoading(state) || selectors.isTokenUpdating(state) || selectors.isTokenRevoking(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(FormBase));
}
