import i18n from 'i18next';
import en from './translations/en';

i18n
    .init({
        interpolation: {
            escapeValue: false,
        },
        lng: 'en',
        resources: {
            en: {
                translation: en,
            },
        }
    });

export default i18n;