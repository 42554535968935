import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import * as selectors from '../../selectors';
import { constants as coreConstants, translate, fieldValidator } from '../../../core';

const COLUMNS = {
    oldPassword: 'oldPassword',
    newPassword: 'newPassword',
    confirmPassword: 'confirmPassword',
};

export default function withChangePasswordBase(WrappedComponent) {
    class ChangePassword extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
        };

        constructor(props) {
            super(props);

            this.state = {
                [COLUMNS.oldPassword]: '',
                [COLUMNS.newPassword]: '',
                [COLUMNS.confirmPassword]: '',
            };

            this.fields = [
                {
                    name: COLUMNS.oldPassword,
                    placeholder: props.i18n.t(`fields.${COLUMNS.oldPassword}`),
                    type: coreConstants.INPUT_TYPES.password,
                    isRequired: true,
                },
                {
                    name: COLUMNS.newPassword,
                    placeholder: props.i18n.t(`fields.${COLUMNS.newPassword}`),
                    type: coreConstants.INPUT_TYPES.password,
                    isRequired: true,
                },
                {
                    name: COLUMNS.confirmPassword,
                    placeholder: props.i18n.t(`fields.${COLUMNS.confirmPassword}`),
                    type: coreConstants.INPUT_TYPES.password,
                    isRequired: true,
                },
            ]
        }

        onChange = (key, val) => {
            this.setState({ [key]: val });
        };

        onSave = () => {
            this.props.actions.changePassword(
                {
                    [COLUMNS.oldPassword]: this.state[COLUMNS.oldPassword],
                    [COLUMNS.newPassword]: this.state[COLUMNS.newPassword]
                });
        };

        get isSaveButtonDisabled() {
            return !fieldValidator.isValidRequiredFields(this.fields, this.state) || this.state[COLUMNS.newPassword] !== this.state[COLUMNS.confirmPassword];
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    fields={this.fields}
                    onChange={this.onChange}
                    isSaveButtonDisabled={this.isSaveButtonDisabled}
                    onSave={this.onSave}
                    values={this.state}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            account: selectors.getAccountProfile(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ChangePassword));
}
