import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { translate } from '../../../core';
import { actions as authActions } from '../../../auth';
import { selectors as accountSelectors, actions as accountActions } from '../../../account';

export default function withMenuBase(WrappedComponent) {
    class MenuBase extends React.PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            accountActions: PropTypes.object.isRequired,
        };

        constructor(props) {
            super(props);

            this.state = {
                isAccountOpen: false,
                isEditFormOpen: false,
            };

            if (!_.get(props, 'account.fullname', false)) {
                props.accountActions.getAccount();
            }
        }

        onSignOut = () => {
            this.props.actions.signOut();
        };

        onClickAccount = () => {
            this.setState(prevState => ({ isAccountOpen: !prevState.isAccountOpen }))
        };

        onEditAccountForm = () => {
            this.setState(prevState => ({ isEditFormOpen: !prevState.isEditFormOpen }))
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onClickAccount={this.onClickAccount}
                    isAccountOpen={this.state.isAccountOpen}
                    onEditAccountForm={this.onEditAccountForm}
                    isEditFormOpen={this.state.isEditFormOpen}
                    onSignOut={this.onSignOut}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            account: accountSelectors.getAccountProfile(state),
            isAccount: accountSelectors.isAccount(state),
            permissions: accountSelectors.getAccountPermissions(state),
            accountRole: accountSelectors.accountRole(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(authActions, dispatch),
            accountActions: bindActionCreators(accountActions, dispatch),
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(MenuBase));
}
