import axios from 'axios';

const endpoints = {
    signUp: 'identity/sign-up',
    signIn: 'identity/token',
    verifyEmail: 'identity/verify-email',
    verifyEmailToken: 'identity/verify-email-token',
    resetPasswordToken: 'identity/reset-password-token',
    resetPassword: 'identity/reset-password',
};

export const signUp = params => axios.post(endpoints.signUp, params);

export const signIn = parameters => axios.post(endpoints.signIn, { ...parameters, grantType: 0 });

export const verifyEmail = params => axios.post(endpoints.verifyEmail, params);

export const sendVerifyEmailToken = params => axios.post(endpoints.verifyEmailToken, params);

export const sendResetPasswordToken = params => axios.post(endpoints.resetPasswordToken, params);

export const resetPassword = params => axios.post(endpoints.resetPassword, params);

export const refreshToken = parameters => axios.post(endpoints.signIn, { ...parameters, grantType: 1 });