import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import classesNames from "classnames";
import withDefaultValuesBase from './DefaultValues.base';
import { components as Core, constants as coreConstants } from '../../../../core';
import { ALL_OPTION, SELECTOR_NAMES, TAX_RATE_PROPS } from "../../../constants";
import { colors, spacing, fonts, hexToRGB } from "../../../../../styles";

const { INPUT_ADORNMENT } = coreConstants;

const GRID_ITEMS_SIZE = isFullPage => (isFullPage ? { //fullPage
    title: {
        xs: 12,
        sm: 12,
    },
    switch: {
        xs: 6,
        sm: 1,
    },
    minInput: {
        xs: 6,
        sm: 2,
    },
    maxInput: {
        xs: 6,
        sm: 2,
    },
    currentInput: {
        xs: 6,
        sm: 2,
    },
    zonesSelector: {
        xs: 6,
        sm: 3,
    },
    applyButton: {
        xs: 6,
        sm: 2,
    },
} : {
    title: {
        xs: 12,
        sm: 12,
    },
    switch: {
        xs: 4,
        sm: 2,
    },
    minInput: {
        xs: 4,
        sm: 2,
    },
    maxInput: {
        xs: 4,
        sm: 2,
    },
    currentInput: {
        xs: 6,
        sm: 2,
    },
    zonesSelector: {
        xs: 6,
        sm: 4,
    },
    applyButton: {
        xs: 12,
        sm: 12,
    },
    innerApplyButton: {
        xs: 4,
        sm: 3,
    }
});

class DefaultValues extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        onChangeDefaultMarketValues: PropTypes.func.isRequired,
        defaultMarketValues: PropTypes.object.isRequired,
        onSearch: PropTypes.func.isRequired,
        onChangeZone: PropTypes.func.isRequired,
        selectedZone: PropTypes.string,
        isLoading: PropTypes.bool,
        pagination: PropTypes.object.isRequired,
        onApplyDefaultMarketValues: PropTypes.func.isRequired,
        zones: PropTypes.array,
        formattedMarkets: PropTypes.array.isRequired,
        isPercentage: PropTypes.bool.isRequired,
        onChangeInputType: PropTypes.func.isRequired,
        isEditForm: PropTypes.bool,
    };

    static defaultProps = {
        selectedZone: null,
        isLoading: false,
        zones: [],
        isEditForm: false,
    };

    constructor(props) {
        super(props);

        this.gridSizes = GRID_ITEMS_SIZE(!props.isEditForm);
    }

    get selectItems() {
        const allOption = { value: '', label: this.props.i18n.t(ALL_OPTION) };
        const options = _.map(this.props.zones, this.getSelectOption);

        if (this.props.pagination.pageNumber === 1) {
            return [allOption, ...options]
        }

        return options;
    }

    get applyBtn() {
        const { classes, i18n, onApplyDefaultMarketValues, isEditForm } = this.props;
        const button = (
            <Core.Button
                fullWidth={false}
                variant="contained"
                color="primary"
                className={classes.fullContainer}
                onClick={onApplyDefaultMarketValues}>
                {i18n.t('inventory.form.apply')}
            </Core.Button>
        );

        return isEditForm ? (
            <Core.Grid item={true} {...this.gridSizes.applyButton} className={classes.buttonContainerStyle}>
                <Core.Grid {...this.gridSizes.innerApplyButton} item={true}>
                    {button}
                </Core.Grid>
            </Core.Grid>
        ) : (
            <Core.Grid item={true} {...this.gridSizes.applyButton}>
                {button}
            </Core.Grid>
        )
    }

    getSelectOption = item => ({ value: _.get(item, "id"), label: _.get(item, "name") });

    setPercentAsDefaultValues = () => this.props.onChangeInputType(true);

    setDefaultValues = () => this.props.onChangeInputType(false);

    render() {
        const {
            i18n,
            classes,
            onChangeDefaultMarketValues,
            defaultMarketValues,
            onSearch,
            onChangeZone,
            selectedZone,
            isLoading,
            pagination,
            formattedMarkets,
            isPercentage
        } = this.props;
        return formattedMarkets.length ? (
            <Core.Grid item={true} xs={12} sm={12} className={classes.defaultValuesContainer}>
                <Core.Grid container={true} spacing={24} alignItems="center">
                    <Core.Grid item={true} {...this.gridSizes.title} className={classes.defaultTitle}>
                        <span>{i18n.t('inventory.form.defaultValues')}</span>
                    </Core.Grid>
                    <Core.Grid {...this.gridSizes.switch} item={true} className={classes.switch}>
                        <Core.Grid
                            xs={6}
                            sm={6}
                            item={true}
                            onClick={this.setDefaultValues}
                            className={classesNames(classes.switchItem, !isPercentage ? classes.selected : null)}>
                            {i18n.t('inventory.form.value')}
                        </Core.Grid>
                        <Core.Grid
                            xs={6}
                            sm={6}
                            item={true}
                            onClick={this.setPercentAsDefaultValues}
                            className={classesNames(classes.switchItem, isPercentage ? classes.selected : null)}>
                            {i18n.t('inventory.form.percentage')}
                        </Core.Grid>
                    </Core.Grid>
                    <Core.Grid item={true} {...this.gridSizes.minInput}>
                        <Core.FormInput
                            key="defaultMinInput"
                            htmlFor="defaultMin"
                            inputLabel={i18n.t('inventory.form.min')}
                            onChange={event => onChangeDefaultMarketValues('min', event.target.value)}
                            value={defaultMarketValues.min}
                            defaultValue={defaultMarketValues.min}
                            type="number"
                            inputProps={isPercentage ? TAX_RATE_PROPS : undefined}
                            endAdornment={isPercentage ? <Core.InputAdornment position="end">{INPUT_ADORNMENT.percentage}</Core.InputAdornment>: null}
                        />
                    </Core.Grid>
                    <Core.Grid item={true} {...this.gridSizes.maxInput}>
                        <Core.FormInput
                            key="defaultMaxInput"
                            htmlFor="defaultMax"
                            inputLabel={i18n.t('inventory.form.max')}
                            onChange={event => onChangeDefaultMarketValues('max', event.target.value)}
                            value={defaultMarketValues.max}
                            defaultValue={defaultMarketValues.max}
                            type="number"
                            inputProps={isPercentage ? TAX_RATE_PROPS : undefined}
                            endAdornment={isPercentage ? <Core.InputAdornment position="end">{INPUT_ADORNMENT.percentage}</Core.InputAdornment>: null}
                        />
                    </Core.Grid>
                    <Core.Grid item={true} {...this.gridSizes.currentInput}>
                        <Core.FormInput
                            key="defaultCurrentInput"
                            htmlFor="defaultCurrent"
                            inputLabel={i18n.t('inventory.form.current')}
                            onChange={event => onChangeDefaultMarketValues('currentCalculated', event.target.value)}
                            value={defaultMarketValues.currentCalculated}
                            defaultValue={defaultMarketValues.currentCalculated}
                            type="number"
                            inputProps={isPercentage ? TAX_RATE_PROPS : undefined}
                            endAdornment={isPercentage ? <Core.InputAdornment position="end">{INPUT_ADORNMENT.percentage}</Core.InputAdornment>: null}
                        />
                    </Core.Grid>
                    <Core.Grid item={true} {...this.gridSizes.zonesSelector}>
                        <Core.SearchableFormSelect
                            inputLabel={i18n.t('inventory.form.zones')}
                            key="inventory.form.zones"
                            onChange={event => onChangeZone(event.value)}
                            value={selectedZone || i18n.t(ALL_OPTION)}
                            onSearch={onSearch}
                            selectName={SELECTOR_NAMES.zones}
                            isLoading={isLoading}
                            placeholder={selectedZone || i18n.t(ALL_OPTION)}
                            pagination={pagination}
                            options={this.selectItems}
                        />
                    </Core.Grid>
                    {this.applyBtn}
                </Core.Grid>
            </Core.Grid>
        ) : null;
    }
}

const DEFAULT_SWITCHER_HEIGHT = 72;

const styles = () => ({
    defaultValuesContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    buttonContainer: {
        height: DEFAULT_SWITCHER_HEIGHT,
        alignItems: "center",
        display: "flex",
    },
    fullContainer: {
        width: '100%',
    },
    switch: {
        display: "flex",
        height: DEFAULT_SWITCHER_HEIGHT,
        "& :first-child": {
            marginRight: spacing.s1,
        },
        ...fonts.smNormal
    },
    defaultTitle: {
        paddingBottom: `0 !important`,
    },
    switchItem: {
        justifyContent: "center",
        display: "flex",
        color: colors.gray.lightGray,
        cursor: "pointer",
        height: "100%",
        alignItems: "center",
        border: `2px solid ${colors.gray.lightGray}`,
        boxShadow: `0px 0px 3px rgba(${hexToRGB(colors.gray.lightGray)}, 0.5)`,
        borderRadius: spacing.s0,
    },
    selected: {
        color: colors.blue.primary,
    },
    buttonContainerStyle: {
        display: 'flex',
        justifyContent: 'center',
    }
});


export default withDefaultValuesBase(withStyles(styles)(DefaultValues));
