import React from 'react';
import PropTypes from 'prop-types';
import Settings from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import _ from "lodash";
import withCurrencyRateTableBase, { COLUMNS } from './CurrencyRateTable.base';
import { components as Core, constants as coreConstants } from "../../../core"
import CurrencyRateModal from "./CurrencyRateModal";

const hiddenOptions = {
    display: false,
};

const sortOptions = {
    sort: false
};

class CurrencyRateTable extends React.PureComponent {
    static propTypes = {
        i18n: PropTypes.object.isRequired,
        tableColumns: PropTypes.object.isRequired,
        deleteCurrency: PropTypes.func.isRequired,
        isCurrenciesRateLoading: PropTypes.bool,
        isDeletingCurrency: PropTypes.bool,
        currenciesRate: PropTypes.array,
        getCurrenciesRate: PropTypes.func.isRequired,
        pagination: PropTypes.object,
        onSearch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isCurrenciesRateLoading: false,
        isDeletingCurrency: false,
        currenciesRate: [],
        pagination: null
    };

    constructor(props) {
        super(props);

        const { tableColumns, i18n } = this.props;

        this.state = {
            isVisibleCurrencyRateModal: false,
            modalType: coreConstants.MODAL_TYPE.create,
            isVisibleDeleteCurrencyModal: false,
            currencyId: '',
            isSearchOpen: false,
            searchText: ""
        };

        this.tableColumns = [
            { ...tableColumns[COLUMNS.id], options: hiddenOptions },
            { ...tableColumns[COLUMNS.currency], options: sortOptions },
            { ...tableColumns[COLUMNS.exchangeRate], options: sortOptions }
        ];

        this.tableActions = {
            base: [
                {
                    label: i18n.t("settings.currencyTable.addCurrency"),
                    onClick: this.openAddCurrencyRateModal,
                    icon: <AddIcon />
                }
            ],
            select: [
                {
                    label: i18n.t("table.edit"),
                    onClick: this.openEditCurrencyRateModal,
                    icon: <Settings />
                },
                {
                    label: i18n.t("table.delete"),
                    onClick: this.openConfirmationModal,
                    icon: <Delete />
                }
            ]
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isDeletingCurrency && !this.props.isDeletingCurrency) {
            this.onCloseConfirmationModal();
        }
    }

    get options() {
        return {
            selectableRows: "single",
            responsive: 'scroll',
            rowHover: false,
            onRowsSelect: this.onRowsSelect,
            filter: false,
            print: false,
            searchText: this.state.searchText,
            viewColumns: false,
            download: false,
            onSearchChange: this.onSearch,
            onTableChange: this.tableChanged,
            onSearchOpen: this.onSearchOpen,
        };
    }

    tableChanged = (action, tableState) => {
        const { isSearchOpen } = this.state;
        this.setState({ searchText: tableState.searchText });
        if (action === "search" && isSearchOpen && !tableState.searchText) {
            this.setState({ isSearchOpen: false });
            this.onSearch("");
        }
    };

    onSearchOpen = () => this.setState({ isSearchOpen: true });

    onRowsSelect = currentRowsSelected => {
        const { currenciesRate } = this.props;
        this.setState({ currencyId: currenciesRate[currentRowsSelected[0].dataIndex].id })
    };

    openAddCurrencyRateModal = () => this.setState({ isVisibleCurrencyRateModal: true, modalType: coreConstants.MODAL_TYPE.create, currencyId: '' });

    openEditCurrencyRateModal = () => this.setState({ isVisibleCurrencyRateModal: true, modalType: coreConstants.MODAL_TYPE.edit });

    onCloseCurrencyRateModal = () => this.setState({ isVisibleCurrencyRateModal: false });

    openConfirmationModal = () => this.setState({ isVisibleDeleteCurrencyModal: true });

    onCloseConfirmationModal = () => this.setState({ isVisibleDeleteCurrencyModal: false });

    deleteCurrency = () => this.props.deleteCurrency(this.state.currencyId);

    changePage = (e, page) => this.props.getCurrenciesRate(page + 1, this.state.searchText);

    onSearch = searchText => this.props.onSearch(1, searchText);

    render() {
        const { currenciesRate, isCurrenciesRateLoading, i18n, isDeletingCurrency } = this.props;
        const { isVisibleCurrencyRateModal, isVisibleDeleteCurrencyModal, modalType, currencyId } = this.state;
        return (
            <div>
                <Core.Table
                    title={i18n.t('settings.currencyTable.title')}
                    options={this.options}
                    columns={this.tableColumns}
                    data={currenciesRate}
                    isLoading={isCurrenciesRateLoading || isDeletingCurrency}
                    pagination={this.props.pagination}
                    changePage={this.changePage}
                    actionConfig={this.tableActions}
                />

                {isVisibleCurrencyRateModal ? (
                    <CurrencyRateModal
                        isVisible={isVisibleCurrencyRateModal}
                        onClose={this.onCloseCurrencyRateModal}
                        currencyId={currencyId}
                        modalType={modalType}
                    />
                ): null
                }
                {isVisibleDeleteCurrencyModal ? (
                    <Core.ConfirmationModal
                        isVisible={isVisibleDeleteCurrencyModal}
                        onClose={this.onCloseConfirmationModal}
                        onConfirm={this.deleteCurrency}
                        isLoading={isDeletingCurrency}
                    />
                ): null
                }
            </div>
        );
    }
}

export default withCurrencyRateTableBase(CurrencyRateTable);
