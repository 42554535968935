import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { translate, getPagination, constants as coreConstants } from "../../../core";
import { selectors as accountSelectors } from "../../../account";

import * as actions from "../../actions";
import * as selectors from "../../selectors";

export const COLUMNS = {
    id: "id",
    email: 'email',
    name: 'fullname',
};

const TIMEOUT = 1000;

export default function withUsersBase(WrappedComponent) {
    class UsersBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            pagination: PropTypes.object,
            actions: PropTypes.object.isRequired,
        };

        static defaultProps = {
            pagination: null,
        };

        constructor(props) {
            super(props);
            const { i18n } = this.props;

            this.tableColumns = {
                [COLUMNS.name]: {
                    label: i18n.t("users.table.email"),
                    name: COLUMNS.email,
                },
                [COLUMNS.email]: {
                    label: i18n.t("users.table.name"),
                    name: COLUMNS.name,
                },
                [COLUMNS.id]: {
                    label: i18n.t("users.table.id"),
                    name: COLUMNS.id,
                }
            };

            this.props.actions.getUsers();
            this.debouncedOnSearch = _.debounce(this.getUsers, TIMEOUT);
        }

        deleteUser = userId => {
            const { pagination } = this.props;
            this.props.actions.deleteUser(userId).then(
                this.getUsers(pagination.itemCount > 0 ? pagination.pageNumber-1 : pagination.pageNumber)
            );
        };

        getUsers = (page, filterText) => {
            this.props.actions.getUsers({
                page,
                count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
                filterText
            });
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    tableColumns={this.tableColumns}
                    deleteUser={this.deleteUser}
                    getUsers={this.getUsers}
                    onSearch={this.debouncedOnSearch}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isUsersLoading: selectors.isUsers(state),
            isDeletingUser: selectors.isDeletingUser(state),
            pagination: getPagination(selectors.getUsersPagination(state)),
            users: selectors.getUsersArray(state),
            objUsers: selectors.getUsers(state),
            isCompanyCreator: accountSelectors.isCompanyCreator(state),
            accountProfile: accountSelectors.getAccountProfile(state),
            accountRole: accountSelectors.accountRole(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(UsersBase));
}
