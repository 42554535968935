import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import * as actions from "../../../actions";
import { translate } from '../../../../core';
import * as selectors from "../../../selectors";
import { FIELDS } from '../../../constants';

export default function withColumnSettingsBase(WrappedComponent) {
    class ColumnSettings extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            columns: PropTypes.array.isRequired,
            onClose: PropTypes.func.isRequired,
        };

        static defaultProps = {
        };

        constructor(props) {
            super(props);

            this.state = {
                columns: props.columns,
            };
        }

        onChangeColumns = columns => {
            this.setState({ columns: _.map(columns, (item, index) => ({ ...item, order: index + 1 })) })
        };

        onSave = () => {
            this.props.actions.updateColumns(_.chain(this.state.columns)
                .keyBy('name')
                .mapValues(item => ({ order: item.order, enabled: item.enabled }))
                .value());
            this.props.onClose();
        };

        onChangeVisibility = (checked, key) => {
            this.setState(prevState => ({ columns: _.map(prevState.columns, item => {
                if (item.name === key) {
                    return { ...item, enabled: checked }
                }

                return item;
            }) }));
        };

        get isSaveBtnDisabled() {
            return !_.some(this.state.columns, item => item.enabled);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    columns={this.state.columns}
                    onChangeColumns={this.onChangeColumns}
                    onSave={this.onSave}
                    onChangeVisibility={this.onChangeVisibility}
                    isSaveBtnDisabled={this.isSaveBtnDisabled}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isColumnsUpdating: selectors.isColumnsUpdating(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(ColumnSettings));
}
