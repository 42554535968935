import React from 'react';
import PropTypes from 'prop-types';
import * as _ from "lodash";
import { components as Core, withStyles } from "../../../../../core";
import { spacing, hexToRGB, colors } from "../../../../../../styles";
import { ROUND_VALUES } from "../../../../constants";

class Tabs extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        tabFields: PropTypes.array,
        values: PropTypes.object.isRequired,
        onChangeValue: PropTypes.func.isRequired,
    };

    static defaultProps = {
        tabFields: []
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedTabIndex: 0,
        }
    }

    handleChange = (event, value) => this.setState({ selectedTabIndex: value });

    handleChangeIndex = index => this.setState({ selectedTabIndex: index });


    renderTabsHeader = field => <Core.Tab key={field.placeholder} label={field.placeholder} />;

    renderTabBody = field => (
        <Core.Grid key={field.placeholder} container={true} spacing={24} className={this.props.classes.gridContainer}>
            {_.map(field.fields, this.renderField)}
        </Core.Grid>
    );

    renderField = field => (
        <Core.Grid item={true} xs={12} sm={6} key={field.name}>
            <Core.FormInput
                key={field.name}
                required={field.isRequired}
                htmlFor={field.name}
                autoFocus={field.autoFocus}
                inputLabel={field.placeholder}
                onChange={event => this.props.onChangeValue(field.isPercentage ? event.target.value / 100 : event.target.value, field)}
                defaultValue={_.toString(field.isPercentage ? _.round(_.get(this.props.values, field.name, 0) * 100, ROUND_VALUES) : _.get(this.props.values, field.name, 0))}
                type={field.type}
                endAdornment={field.endAdornment}
            />
        </Core.Grid>
    );

    render() {
        const { tabFields, classes } = this.props;
        const { selectedTabIndex } = this.state;
        return (
            <div className={classes.tabsContainer}>
                <Core.AppBar position="static" color="default">
                    <Core.Tabs
                        value={selectedTabIndex}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth">
                        {_.map(tabFields, this.renderTabsHeader)}
                    </Core.Tabs>
                </Core.AppBar>
                <Core.SwipeableViews
                    axis="x"
                    index={selectedTabIndex}
                    onChangeIndex={this.handleChangeIndex}>
                    {_.map(tabFields, this.renderTabBody)}
                </Core.SwipeableViews>
            </div>
        )
    }
}

const styles = theme => ({
    tabsContainer: {
        marginTop: spacing.s3,
        boxShadow: `0px 2px 4px -1px rgba(${hexToRGB(colors.black)},0.2), 
                    0px 4px 5px 0px rgba(${hexToRGB(colors.black)},0.14),
                    0px 1px 10px 0px rgba(${hexToRGB(colors.black)},0.12)`,
        border: colors.gray.darkGray
    },
    gridContainer: {
        margin: 0,
        width: "100%"
    },
});


export default withStyles(styles)(Tabs);
