import { NAME } from './constants';
import { createActionType } from '../core';

export const SIGN_UP = createActionType(`${NAME}/SIGN_UP`);
export const SIGN_IN = createActionType(`${NAME}/SIGN_IN`);
export const REMOVE_TRIAL_EXPIRED_ERROR = `${NAME}/REMOVE_TRIAL_EXPIRED_ERROR`;
export const SET_TRIAL_EXPIRED_ERROR = `${NAME}/SET_TRIAL_EXPIRED_ERROR`;
export const REFRESH_TOKEN = createActionType(`${NAME}/REFRESH_TOKEN`);
export const SIGN_OUT = `${NAME}/SIGN_OUT`;
export const SEND_VERIFY_EMAIL_TOKEN = createActionType(`${NAME}/SEND_VERIFY_EMAIL_TOKEN`);
export const VERIFY_EMAIL = createActionType(`${NAME}/VERIFY_EMAIL`);
export const SEND_RESET_PASSWORD_TOKEN = createActionType(`${NAME}/SEND_RESET_PASSWORD_TOKEN`);
export const RESET_PASSWORD = createActionType(`${NAME}/RESET_PASSWORD`);
export const ENABLE_ACCOUNT = createActionType(`${NAME}/ENABLE_ACCOUNT`);
export const VERIFY_ENABLE_ACCOUNT = createActionType(`${NAME}/VERIFY_ENABLE_ACCOUNT`);
export const CHANGE_EMAIL_TOKEN = createActionType(`${NAME}/CHANGE_EMAIL_TOKEN`);

export const LOGIN_SUCCESS = `${NAME}/LOGIN_SUCCESS`;

export const SET_NO_PLAN_ERROR = `${NAME}/SET_NO_PLAN_ERROR`;
export const REMOVE_NO_PLAN_ERROR = `${NAME}/REMOVE_NO_PLAN_ERROR`;

