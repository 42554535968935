import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { constants as coreConstants, Toast, translate } from '../../../../core';
import { POSTAL_SERVICE_FIELDS, POSTAL_SERVICE_INCLUDE_FIELDS, SELECT_FIELDS, ROUND_VALUES_PERCENT, SPECIAL_COMPOSITE_COST_TYPE, CUSTOM_SELECT } from "../../../constants"
import * as actions from "../../../actions";
import * as selectors from "../../../selectors";
import { postalServiceValidator } from "../../../services";

const DEFAULT_COST = {
    perPackageCost: 0,
    perGramCost: 0,
    surcharge: 0,
};

const EDIT_FIELDS = i18n => [
    {
        name: `${POSTAL_SERVICE_FIELDS.name}`,
        placeholder: i18n.t('settings.postalServiceTable.name'),
        isRequired: true,
        type: coreConstants.INPUT_TYPES.text,
        autoFocus: true,
    },
];

const ADD_COST_FIELDS = (i18n, onSearch) => [
    {
        name: `${POSTAL_SERVICE_FIELDS.zone}.${POSTAL_SERVICE_INCLUDE_FIELDS.id}`,
        selectName: SELECT_FIELDS.zones,
        placeholder: i18n.t('settings.postalServiceTable.zone'),
        isRequired: true,
        type: coreConstants.INPUT_TYPES.select,
        onSearch,
    },
    {
        name: `${POSTAL_SERVICE_FIELDS.costs}.${POSTAL_SERVICE_INCLUDE_FIELDS.cost}`,
        placeholder: i18n.t('settings.postalServiceTable.cost'),
        type: coreConstants.INPUT_TYPES.number,
    },
];

export const TAB_FIELDS = i18n => [
    {
        name: POSTAL_SERVICE_FIELDS.defaultCost,
        placeholder: i18n.t('settings.postalServiceModal.defaultCost'),
        fields: COSTS_FIELDS(i18n, POSTAL_SERVICE_FIELDS.defaultCost)
    }
];

export const COSTS_FIELDS = (i18n, parentName) => [
    {
        name: `${POSTAL_SERVICE_FIELDS.costs}.${parentName}.${POSTAL_SERVICE_INCLUDE_FIELDS.perGramCost}`,
        placeholder: i18n.t('settings.postalServiceModal.perGramCost'),
        type: coreConstants.INPUT_TYPES.number,
    },
    {
        name: `${POSTAL_SERVICE_FIELDS.costs}.${parentName}.${POSTAL_SERVICE_INCLUDE_FIELDS.perPackageCost}`,
        placeholder: i18n.t('settings.postalServiceModal.perPackageCost'),
        type: coreConstants.INPUT_TYPES.number,
    },
    {
        name: `${POSTAL_SERVICE_FIELDS.costs}.${parentName}.${POSTAL_SERVICE_INCLUDE_FIELDS.surcharge}`,
        placeholder: i18n.t('settings.postalServiceModal.surcharge'),
        type: coreConstants.INPUT_TYPES.number,
        endAdornment: coreConstants.INPUT_ADORNMENT.percentage,
        isPercentage: true,
    }
];

export default function withPostalServiceModalBase(WrappedComponent) {
    class PostalServiceModalBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired,
            id: PropTypes.string,
            isVisible: PropTypes.bool.isRequired,
            onClose: PropTypes.func.isRequired,
            modalType: PropTypes.number.isRequired,
            postalService: PropTypes.object,
            isLoadingCosts: PropTypes.bool,
        };

        static defaultProps = {
            id: '',
            postalService: undefined,
            isLoadingCosts: false,
        };

        constructor(props) {
            super(props);

            const { i18n, postalService } = this.props;
            const isEdit = this.props.modalType === coreConstants.MODAL_TYPE.edit;

            this.state = {
                fields: {
                    [POSTAL_SERVICE_FIELDS.name]: _.get(postalService, POSTAL_SERVICE_FIELDS.name, ''),
                    [POSTAL_SERVICE_FIELDS.costs]: {
                        [POSTAL_SERVICE_FIELDS.zone]: _.get(postalService, 'costs.zone', ''),
                        [POSTAL_SERVICE_INCLUDE_FIELDS.cost]: _.get(postalService, 'costs.cost', ''),
                        [POSTAL_SERVICE_FIELDS.defaultCost]: _.get(postalService, 'costs.defaultCost', DEFAULT_COST),
                        [POSTAL_SERVICE_INCLUDE_FIELDS.conditionalCompositeCosts]: _.get(postalService, 'costs.conditionalCompositeCosts', []),
                    },
                },
                errors: {
                    [POSTAL_SERVICE_FIELDS.name]: '',
                },
            };

            this.fields = isEdit ? EDIT_FIELDS(i18n, isEdit) : [...EDIT_FIELDS(i18n, isEdit), ...ADD_COST_FIELDS(i18n, this.onSearch)];

            isEdit ? this.props.actions.getPostalServiceCosts(postalService.id) : null;
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (!nextProps.isLoadingCosts && this.props.isLoadingCosts) {
                const { postalService } = nextProps;
                this.setState(prevState => ({
                    ...prevState,
                    fields: {
                        ...postalService,
                    }
                }))
            }
        }

        onChangeValue = (val, field) => {
            const newValue = field.type === coreConstants.INPUT_TYPES.number ? _.round(val * 1, ROUND_VALUES_PERCENT) : val;

            this.setState(prevProps => ({ fields: {
                ...prevProps.fields,
                ..._.set(prevProps.fields, field.name, newValue)
            } }))
        };

        addPostalService = () => {
            const { fields } = this.state;
            const { i18n, modalType, id, postalService } = this.props;
            if (modalType === coreConstants.MODAL_TYPE.edit) {
                const errors = postalServiceValidator.validatePostalService(fields, i18n);
                if (errors.length) {
                    Toast.error(errors[0]);
                    return;
                }
                this.props.actions.updatePostalService(id, { ...fields, [POSTAL_SERVICE_FIELDS.costs]: postalService[POSTAL_SERVICE_FIELDS.costs] })
            } else {
                let errors = postalServiceValidator.validatePostalService(fields, i18n);
                if (errors.length) {
                    Toast.error(errors[0]);
                    return;
                }
                errors = postalServiceValidator.validateCost(fields, i18n, []);
                if (errors.length) {
                    Toast.error(errors[0]);
                    return;
                }

                this.props.actions.addPostalService({
                    ...fields,
                    [POSTAL_SERVICE_FIELDS.costs]: {
                        [_.get(fields, `${POSTAL_SERVICE_FIELDS.zone}.${POSTAL_SERVICE_INCLUDE_FIELDS.id}`)]: fields[POSTAL_SERVICE_FIELDS.costs],
                    }
                });
            }
        };

        onSearch = (filterText, field, isMore, page) => this.props.actions.getZones({
            page: isMore ? page : 1,
            count: coreConstants.DEFAULT_ITEMS_PER_PAGE,
            filterText
        }, true);

        get tabFields() {
            return TAB_FIELDS(this.props.i18n)
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    addPostalService={this.addPostalService}
                    values={this.state.fields}
                    fields={this.fields}
                    tabFields={this.tabFields}
                    onChangeValue={this.onChangeValue}
                />
            );
        }
    }

    const mapStateToProps = (state, ownProps) => ({
        isAdding: selectors.isAddingPostalService(state),
        isUpdating: selectors.isUpdatingPostalService(state),
        isLoadingCosts: selectors.isGettingCosts(state),
        isLoading: {
            [SELECT_FIELDS.zones]: selectors.isZones(state),
        },
        [SELECT_FIELDS.zones]: selectors.getZonesItems(state),
        pagination: {
            [SELECT_FIELDS.zones]: selectors.getZonesScrollPagination(state),
        },
        postalService: ownProps.modalType === coreConstants.MODAL_TYPE.edit ? selectors.getPostalServiceById(state, ownProps.id) : null,
        addPostalServiceError: selectors.getAddPostalServiceError(state),
        updatePostalServiceError: selectors.getUpdatePostalServiceError(state),

    });

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(PostalServiceModalBase));
}
