import * as actionTypes from './actionTypes';
import { selectors as coreSelectors } from '../core';
import { selectors as accountSelectors } from '../account';
import { MAX_REPORTING_DAYS } from "./constants";

export const isProfitReport = state => coreSelectors.isLoading(state, actionTypes.GET_PROFIT_REPORT_BY_DATE.NAME);

export const reportDaysLimit = state => (accountSelectors.isOwner(state)
    ? MAX_REPORTING_DAYS.owner
    : MAX_REPORTING_DAYS.others);
