exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./fonts/HelveticaNeueCyr-Light.eot"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./fonts/HelveticaNeueCyr-Light.eot") + "?#iefix");
var ___CSS_LOADER_URL___2___ = urlEscape(require("./fonts/HelveticaNeueCyr-Light.woff"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./fonts/HelveticaNeueCyr-Light.ttf"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./fonts/HelveticaNeueCyr-Medium.eot"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("./fonts/HelveticaNeueCyr-Medium.eot") + "?#iefix");
var ___CSS_LOADER_URL___6___ = urlEscape(require("./fonts/HelveticaNeueCyr-Medium.woff"));
var ___CSS_LOADER_URL___7___ = urlEscape(require("./fonts/HelveticaNeueCyr-Medium.ttf"));
var ___CSS_LOADER_URL___8___ = urlEscape(require("./fonts/HelveticaNeueCyr-Bold.eot"));
var ___CSS_LOADER_URL___9___ = urlEscape(require("./fonts/HelveticaNeueCyr-Bold.eot") + "?#iefix");
var ___CSS_LOADER_URL___10___ = urlEscape(require("./fonts/HelveticaNeueCyr-Bold.woff"));
var ___CSS_LOADER_URL___11___ = urlEscape(require("./fonts/HelveticaNeueCyr-Bold.ttf"));

// Module
exports.push([module.id, "@font-face {\n    font-family: 'HelveticaNeueCyr';\n    font-weight: 100;\n    src: local('HelveticaNeueCyr');\n    src: url(" + ___CSS_LOADER_URL___0___ + ");\n    src: url(" + ___CSS_LOADER_URL___1___ + ") format('embedded-opentype'),\n        url(" + ___CSS_LOADER_URL___2___ + ") format('woff'),\n        url(" + ___CSS_LOADER_URL___3___ + ") format('truetype')\n}\n@font-face {\n    font-family: 'HelveticaNeueCyr';\n    font-weight: 400;\n    src: local('HelveticaNeueCyr');\n    src: url(" + ___CSS_LOADER_URL___4___ + ");\n    src: url(" + ___CSS_LOADER_URL___5___ + ") format('embedded-opentype'),\n        url(" + ___CSS_LOADER_URL___6___ + ") format('woff'),\n        url(" + ___CSS_LOADER_URL___7___ + ") format('truetype')\n}\n@font-face {\n    font-family: 'HelveticaNeueCyr';\n    font-weight: 700;\n    src: local('HelveticaNeueCyr');\n    src: url(" + ___CSS_LOADER_URL___8___ + ");\n    src: url(" + ___CSS_LOADER_URL___9___ + ") format('embedded-opentype'),\n        url(" + ___CSS_LOADER_URL___10___ + ") format('woff'),\n        url(" + ___CSS_LOADER_URL___11___ + ") format('truetype')\n}\nbody {\n    font-family: \"Ubuntu\", \"HelveticaNeueCyr\", \"Helvetica\", \"sans-serif\" !important;\n    margin: 0 !important;\n}\n\n*::-webkit-scrollbar {\n    width: 6px;\n    height:15px;\n}\n\n*::-webkit-scrollbar-track {\n    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);\n}\n\n*::-webkit-scrollbar-thumb {\n    -webkit-box-shadow: inset 0 0 6px rgb(80, 78, 78);\n}\n\np {\n    margin-bottom: 0;\n}\n#app {\n    height: 100%;\n}\n", ""]);

