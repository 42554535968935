import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions";
import * as selectors from '../selectors';
import { translate } from '../../core';

export default function withEditAccountBase(WrappedComponent) {
    class EditAccount extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            account: PropTypes.object,
            isAccount: PropTypes.bool,
            onCloseModal: PropTypes.func.isRequired,
        };

        static defaultProps = {
            account: {},
            isAccount: false,
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isGettingAccount: selectors.isAccount(state),
            isAccountUpdating: selectors.isAccountUpdate(state),
            isPasswordUpdating: selectors.isPasswordUpdating(state),
            account: selectors.getAccountProfile(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(EditAccount));
}
