import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { translate } from "../../../core";
import * as actions from "../../actions";
import * as selectors from "../../selectors";
import { selectors as accountSelectors } from "../../../account";

export default function withSettingsBase(WrappedComponent) {
    class SettingsBase extends React.PureComponent {
        static propTypes = {
            i18n: PropTypes.object.isRequired,
            actions: PropTypes.object.isRequired
        };

        exportSettings = () => this.props.actions.exportSettings();

        onSettingsImport = () => this.props.actions.isVisibleImportModal();

        onSettingsExport = () => this.props.actions.isVisibleExportModal();

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    exportSettings={this.exportSettings}
                    onSettingsImport={this.onSettingsImport}
                    onSettingsExport={this.onSettingsExport}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            isExporting: selectors.isExporting(state),
            isVisibleExportModal: selectors.isVisibleExportModal(state),
            isVisibleImportModal: selectors.isVisibleImportModal(state),
            permissions: accountSelectors.getAccountPermissions(state),
            isUserAdmin: accountSelectors.isAdmin(state),
        }
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(SettingsBase));
}
