export const NAME = 'settings';

export const CURRENCY_RATE_FIELDS = {
    name: 'name',
    value: 'value'
};

export const ZONE_FIELDS = {
    name: 'name',
    countries: "countries"
};

export const COMPANY_FIELDS = {
    name: 'name',
};

export const MARKETPLACE_FIELDS = {
    marketplace: "name",
    currency: "currency",
    zone: "zone",
    company: "company",
    fees: "fees",
    source: 'source'
};

export const MARKETPLACE_INCLUDE_FIELDS = {
    id: "id",
    fee: "fee",
    minFinalValueFee: "minFinalValueFee",
    paymentProvider: "paymentProvider",
    cost: "cost",
    transactionFee: "transactionFee",
    subSource: "subSource",
    suffix: 'suffix'
};

export const POSTAL_SERVICE_FIELDS = {
    name: "name",
    zone: "zone",
    costs: "costs",
    defaultCost: "defaultCompositeCost",
    height21CompositeCost: "height21CompositeCost",
    weight2CompositeCost: "weight2CompositeCost",
    specialCompositeCost: "specialCompositeCost",
};

export const POSTAL_SERVICE_INCLUDE_FIELDS = {
    id: "id",
    cost: "cost",
    perPackageCost: "perPackageCost",
    perGramCost: "perGramCost",
    surcharge: "surcharge",
    zone: "zone",
    conditionalCompositeCosts: "conditionalCompositeCosts"
};

export const COMPOSITE_COST_TYPE_FIELDS = {
    value: "value",
    type: "type",
};

export const PACKAGING_FIELDS = {
    name: 'name',
    cost: 'cost',
};

export const STATIC_VALUE_FIELDS = {
    name: 'name',
    value: 'value',
};

export const SELECT_FIELDS = {
    currencies: "currencies",
    zones: "zones",
    companies: "companies"
};

export const ROUND_VALUES = 2;
export const ROUND_VALUES_PERCENT = 4;

export const TIMEOUT = 1000;

export const SPECIAL_COMPOSITE_COST_TYPE = {
    higher21Centimeters: "height21CompositeCost",
    heavier2Kilograms: "weight2CompositeCost",
};

export const CUSTOM_SELECT = "customSelect";

export const MENU_SELECT = "menu";

export const ALL_MODULES = {
    currencies: 'currencies',
    zones: 'zones',
    companies: 'companies',
    marketplaces: 'marketplaces',
    packagings: 'packagings',
    postalServices: 'postal-services',
    staticValues: 'static-values',
};

export const MODULES = [
    {
        name: ALL_MODULES.currencies,
        label: 'currencies',
        isSecondary: false,
    },
    {
        name: ALL_MODULES.zones,
        label: 'zones',
        isSecondary: false,
    },
    {
        name: ALL_MODULES.companies,
        label: 'companies',
        isSecondary: false,
    },
    {
        name: ALL_MODULES.marketplaces,
        label: 'marketplaces',
        isSecondary: true,
    },
    {
        name: ALL_MODULES.packagings,
        label: 'packagings',
        isSecondary: false,
    },
    {
        name: ALL_MODULES.postalServices,
        label: 'postalServices',
        isSecondary: true,
    },
    {
        name: ALL_MODULES.staticValues,
        label: 'staticValues',
        isSecondary: false,
    },
];

export const MODAL_WIDTH = {
    fullWidth: 95,
    partWidth: 600
};

export const MEASURES = [
    {
        id: 0,
        type: "settings.postalServiceTable.compositeCostType.types.height", // i18n translate
        measure: "settings.postalServiceTable.compositeCostType.measures.cm", // i18n translate
        name: 'height',
    },
    {
        id: 1,
        type: "settings.postalServiceTable.compositeCostType.types.weight", // i18n translate
        measure: "settings.postalServiceTable.compositeCostType.measures.gram", // i18n translate
        name: 'weight'
    }
];

export const COMPOSITE_DEFAULT_ID = 'defaultId';